import {
    EmployeePayrollLock,
    EmployeePayrollLockLatestSearchRequest,
    EmployeePayrollLockSearchRequest,
    LatestPayrollLocks,
} from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { searchEmployeePayrollLock, searchLatestEmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.service';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { EmployeePayrollProfileChange, PayrollProfileChangeSearch } from '@/domain/payroll/Payroll.model';
import { getPayrollEmployeeProfileChanges, getPayrollTransactions } from '@/domain/payroll/Payroll.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';

import { useCallback, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetLatestEmployeePayrollLock = (search: EmployeePayrollLockLatestSearchRequest): UseQueryResult<LatestPayrollLocks[]> => {
    const [latestEmployeePayrollLocks, setLatestEmployeePayrollLocks] = useState<LatestPayrollLocks[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeePayrollLocks = useCallback(async (search: EmployeePayrollLockLatestSearchRequest) => {
        try {
            const employeePayrollLocks = await searchLatestEmployeePayrollLock(search);
            setLatestEmployeePayrollLocks(employeePayrollLocks);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeePayrollLocks(search).catch(handleError);
    }, [fetchEmployeePayrollLocks, search]);

    return {
        data: latestEmployeePayrollLocks,
        setData: setLatestEmployeePayrollLocks,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeePayrollLocks(search),
    };
};

export const useGetEmployeePayrollLock = (search: EmployeePayrollLockSearchRequest): UseQueryResult<EmployeePayrollLock[]> => {
    const [employeePayrollLocks, setEmployeePayrollLocks] = useState<EmployeePayrollLock[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeePayrollLocks = useCallback(async (search: EmployeePayrollLockSearchRequest) => {
        try {
            const employeePayrollLocks = await searchEmployeePayrollLock(search);
            setEmployeePayrollLocks(employeePayrollLocks);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeePayrollLocks(search).catch(handleError);
    }, [fetchEmployeePayrollLocks, search]);

    return {
        data: employeePayrollLocks,
        setData: setEmployeePayrollLocks,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeePayrollLocks(search),
    };
};

export const useGetPayrollProfileChanges = (search: PayrollProfileChangeSearch): UseQueryResult<EmployeePayrollProfileChange[]> => {
    const [changes, setChanges] = useState<EmployeePayrollProfileChange[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPayrollEmployeeProfileChanges = useCallback(async (search: PayrollProfileChangeSearch) => {
        try {
            const profileChanges = await getPayrollEmployeeProfileChanges(search);
            setChanges(profileChanges);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchPayrollEmployeeProfileChanges(search).catch(handleError);
    }, [fetchPayrollEmployeeProfileChanges, search]);

    return {
        data: changes,
        setData: setChanges,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPayrollEmployeeProfileChanges(search),
    };
};

export const useGetPayrollTransactions = ({ startDate }: { startDate: LocalDate }): UseQueryResult<LeaveTransaction[]> => {
    const [transactions, setTransactions] = useState<LeaveTransaction[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPayrollTransactions = useCallback(async ({ startDate }: { startDate: LocalDate }) => {
        try {
            const transactions = await getPayrollTransactions({ startDate });
            setTransactions(transactions);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchPayrollTransactions({ startDate }).catch(handleError);
    }, [fetchPayrollTransactions, startDate]);

    return {
        data: transactions,
        setData: setTransactions,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPayrollTransactions({ startDate }),
    };
};
