import { SectionColumn } from '@/components/section/types';
import { ReportFieldType } from '@/domain/report/Report.model';
import { useTranslation } from 'react-i18next';
import { SectionFieldType } from '@/domain/section-setting/Section.model';

export const useAddressColumns = (): (SectionColumn & { fieldType: ReportFieldType })[] => {
    const { t } = useTranslation();
    const columns: (SectionColumn & { fieldType: ReportFieldType })[] = [
        {
            title: t('employee.address.start_date'),
            type: SectionFieldType.DATE,
            fieldType: 'ADDRESS_START_DATE',
        },
        {
            title: t('employee.address.address_line_1'),
            type: SectionFieldType.STRING,
            fieldType: 'ADDRESS_ADDRESS_LINE_1',
        },
        {
            title: t('employee.address.address_line_2'),
            type: SectionFieldType.STRING,
            fieldType: 'ADDRESS_ADDRESS_LINE_2',
        },
        {
            title: t('employee.address.post_code'),
            type: SectionFieldType.STRING,
            fieldType: 'ADDRESS_POST_CODE',
        },
        {
            title: t('employee.address.city'),
            type: SectionFieldType.STRING,
            fieldType: 'ADDRESS_CITY',
        },
        {
            title: t('employee.address.region'),
            type: SectionFieldType.STRING,
            fieldType: 'ADDRESS_REGION',
        },
        {
            title: t('employee.address.country'),
            type: SectionFieldType.COUNTRY,
            fieldType: 'ADDRESS_COUNTRY',
        },
    ];

    return columns;
};
