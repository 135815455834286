import { CountrySelect } from '@/components/country-select/CountrySelect';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { EmployeeAddress } from '@/domain/employee/EmployeeAddress.model';
import {
    EmployeeAddressSectionFormValues,
    employeeAddressSectionSchema,
} from '@/page/employee-profile/employee-profile-info/EmployeeAddressSection/Components/EmployeeAddressDialog.util';
import { getCountry } from '@/utils/countries.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    employeeId: number;
    employeeAddress?: EmployeeAddress;
    onSave: (employeeAddressFormValues: EmployeeAddressSectionFormValues) => void;
    onClose: () => void;
};

export const EmployeeAddressDialog: FC<Props> = ({ open, onClose, onSave, employeeAddress }) => {
    const { t } = useTranslation();
    const title = t('employee.sections.address');

    const country = employeeAddress?.country ? getCountry(employeeAddress.country) : undefined;

    const defaultValues = {
        startDate: employeeAddress?.startDate,
        addressLine1: employeeAddress?.addressLine1 ?? '',
        addressLine2: employeeAddress?.addressLine2 ?? '',
        region: employeeAddress?.region ?? '',
        city: employeeAddress?.city ?? '',
        postCode: employeeAddress?.postCode ?? '',
        country,
    };

    const formMethods = useForm<EmployeeAddressSectionFormValues>({
        resolver: yupResolver(employeeAddressSectionSchema),
        defaultValues,
    });

    const { control, handleSubmit } = formMethods;

    const onCloseDialog = () => {
        formMethods.reset();
        onClose();
    };

    return (
        <FormProvider {...formMethods}>
            <DialogContainer title={title} open={open} onSave={handleSubmit(onSave, console.error)} onClose={onCloseDialog}>
                <Stack direction='column' spacing={2}>
                    <Stack direction='column'>
                        <Typography variant='body1'>{t('employee.address.start_date')}</Typography>

                        <FieldLocalDate control={control} name='startDate' />
                    </Stack>
                    <Stack direction='column'>
                        <Typography variant='body1'>{t('employee.address.address_line_1')}</Typography>
                        <FieldController fieldName='addressLine1' />
                    </Stack>
                    <Stack direction='column'>
                        <Typography variant='body1'>{t('employee.address.address_line_2')}</Typography>
                        <FieldController fieldName='addressLine2' />
                    </Stack>
                    <Stack direction='column'>
                        <Typography variant='body1'>{t('employee.address.post_code')}</Typography>
                        <FieldController fieldName='postCode' />
                    </Stack>
                    <Stack direction='column'>
                        <Typography variant='body1'>{t('employee.address.city')}</Typography>
                        <FieldController fieldName='city' />
                    </Stack>
                    <Stack direction='column'>
                        <Typography variant='body1'>{t('employee.address.region')}</Typography>
                        <FieldController fieldName='region' />
                    </Stack>
                    <Stack direction='column'>
                        <Typography variant='body1'>{t('employee.address.country')}</Typography>
                        <CountrySelect countryValue={employeeAddress?.country ? getCountry(employeeAddress?.country) : getNull()} formValueName={'country'} />
                    </Stack>
                </Stack>
            </DialogContainer>
        </FormProvider>
    );
};

type EmployeeFieldControllerProps = {
    fieldName: keyof EmployeeAddressSectionFormValues;
};

const FieldController: FC<EmployeeFieldControllerProps> = ({ fieldName }) => {
    const { control } = useFormContext<Record<string, string>>();
    return (
        <Controller
            name={fieldName}
            control={control}
            render={({ field: { value, ...restField }, fieldState }) => (
                <TextField
                    fullWidth
                    {...restField}
                    value={value}
                    inputProps={{
                        'aria-label': fieldName,
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                />
            )}
        />
    );
};
