import { FileMetadata } from '@/components/file-picker-wrapper/FilePickerWrapper';
import { EmployeeSectionFieldDocumentMutation, EmployeeSectionFieldMutation } from '@/domain/employee-section/EmployeeSection.model';
import { SectionDefinition, SectionFieldType } from '@/domain/section-setting/Section.model';
import { CustomSectionRowFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeCustomSectionRowDialog.hook';
import { Country } from '@/utils/countries.util';

/**
 * Format the given value to the correct type
 */
const getPropertyValue = (type: SectionFieldType, value: unknown) => {
    const getEmployeeSectionFieldDocumentRequest = (value: FileMetadata[]): EmployeeSectionFieldDocumentMutation[] => {
        return value.map(document => {
            return {
                id: document.itemId,
                name: document.filename,
                mimeType: document.mimetype,
                s3Key: document.key ?? '',
            };
        });
    };

    switch (type) {
        case SectionFieldType.COUNTRY:
            return { stringValue: (value as Country)?.value };
        case SectionFieldType.STRING:
        case SectionFieldType.PHONE_NUMBER:
        case SectionFieldType.IBAN_NUMBER:
            return { stringValue: value as string };
        case SectionFieldType.NUMBER:
            return { numberValue: value || value === 0 ? Number(value) : undefined };
        case SectionFieldType.DATE:
            return { dateValue: value };
        case SectionFieldType.EMPLOYEE_REFERENCE:
            return { employeeReferenceId: Number(value) };
        case SectionFieldType.CUSTOM_LIST_ITEM:
            return { customListItemReferenceIds: value ? ([value] as number[]) : [] };
        case SectionFieldType.CUSTOM_MULTI_LIST_ITEM:
            return { customListItemReferenceIds: value as number[] };
        case SectionFieldType.SECTION_FIELD_DOCUMENT:
            return { documents: getEmployeeSectionFieldDocumentRequest(value as FileMetadata[]) };
        default:
            return undefined;
    }
};

/**
 * Convert the form values to a fields array
 * @param formValues
 * @param sectionDefinition
 * @returns the edited fields
 */
export const convertFormValuesToFields = (formValues: CustomSectionRowFormValues, sectionDefinition: SectionDefinition): EmployeeSectionFieldMutation[] => {
    // We create the row with the fields
    const fields: EmployeeSectionFieldMutation[] = Object.keys(formValues)?.map(key => {
        // we store the field id in the form value name
        const sectionFieldDefinitionId = parseInt(key);

        const sectionFieldDefinition = sectionDefinition.fields.find(field => field.id === sectionFieldDefinitionId);

        if (!sectionFieldDefinition) {
            throw new Error(`Field with id ${sectionFieldDefinitionId} not found`);
        }

        return {
            ...getPropertyValue(sectionFieldDefinition.type, formValues[key]),
            sectionFieldDefinitionId,
            order: sectionFieldDefinition.order ?? 0,
            employeeReferenceId: undefined,
        };
    });

    return fields;
};
