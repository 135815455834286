import { Label, LabelRequest } from '../label/Label.model';
import { ReportFieldType, ReportFieldValueType } from '@/domain/report/Report.model';
import { EmployeeSectionFieldDocument } from '@/domain/employee-section/EmployeeSection.model';
import { EmployeeAvatar, EmployeeFieldType } from '@/domain/employee/Employee.model';
import { CustomList } from '@/domain/custom-list/CustomList.model';

export enum SectionType {
    BASIC_INFO = 'BASIC_INFO',
    PERSONAL_INFO = 'PERSONAL_INFO',
    CONTRACT = 'CONTRACT',
    EMPLOYMENT = 'EMPLOYMENT',
    WORK_PATTERN = 'WORK_PATTERN',
    ADDRESS = 'ADDRESS',
    LONG_LEAVE = 'LONG_LEAVE',
    CUSTOM_SINGLE_ROW = 'CUSTOM_SINGLE_ROW',
    CUSTOM_MULTI_ROW = 'CUSTOM_MULTI_ROW',
}

export enum SectionFieldType {
    STRING = 'STRING',
    DOT_STRING = 'DOT_STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    EMPLOYEE_REFERENCE = 'EMPLOYEE_REFERENCE',
    CUSTOM_LIST_ITEM = 'CUSTOM_LIST_ITEM',
    CUSTOM_MULTI_LIST_ITEM = 'CUSTOM_MULTI_LIST_ITEM',
    SECTION_FIELD_DOCUMENT = 'SECTION_FIELD_DOCUMENT',
    COUNTRY = 'COUNTRY',
    PHONE_NUMBER = 'PHONE_NUMBER',
    BIRTHDAY = 'BIRTHDAY',
    ENUM_LIST_ITEM = 'ENUM_LIST_ITEM',
    IBAN_NUMBER = 'IBAN_NUMBER',
    AVS_NUMBER = 'AVS_NUMBER',
}

type SectionTab = 'PROFILE' | 'JOB';

export type SectionDefinition = {
    id: number;
    name: Label;
    type: SectionType;
    order: number;
    includeInPayroll: boolean;
    fields: SectionFieldDefinition[];
    tab: SectionTab;
};

export enum SectionRowStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
}

/**
 * Field definition used to define a custom field in a section
 */
export type SectionFieldDefinition = {
    id: number;
    name: Label;
    /**
     * @deprecated use valueType instead
     */
    type: SectionFieldType;
    fieldType: EmployeeFieldType | ReportFieldType;
    // TODO : Rename reportFieldValueType to something more generic and not related to reports
    valueType: ReportFieldValueType;
    order: number;
    mandatory: boolean;
    customList?: CustomList;
};

export type SectionDefinitionIncludeInPayrollMutation = {
    includeInPayroll: boolean;
};

/**
 * Common field definition used to handle non custom fields and custom fields
 * Non custom fields are fields existing in the system by default
 * Custom fields are fields created by admins in real settings page (/settings/employees/fields)
 */
export type FieldDefinition = {
    id?: number;
    name?: Label;
    // TODO move reportFieldType enum to this file
    fieldType: ReportFieldType;
    valueType: ReportFieldValueType;
};

/**
 * Field value that can be used to display a field value in a table
 * TODO: Improve typing by using a union type for the value
 */
export type SectionFieldValue = {
    sectionFieldDefinition: FieldDefinition;
    dateValue?: LocalDate;
    numberValue?: number;
    stringValue?: string;
    documents: { name: EmployeeSectionFieldDocument }[];
    labelValue?: Label;
    customListItemReferences: { id: number; label: Label }[];
    // TODO: Back end should use the same type employeeReferences or employeeValues and not both
    employeeValues: EmployeeAvatar[];
    employeeReferences: EmployeeAvatar[];
};

export type SectionDefinitionCreateRequest = {
    name: LabelRequest;
    type: SectionType;
    order: number;
    includeInPayroll: boolean;
    // not used by the frontend to create a section definition
    fields?: SectionFieldDefinitionRequest[];
    tab: SectionTab;
};

export type SectionDefinitionIncludeInPayrollRequest = {
    includeInPayroll: boolean;
};

export type SectionFieldDefinitionRequest = {
    name: LabelRequest;
    type: SectionFieldType;
    order: number;
    mandatory: boolean;
    customListId?: number;
};

export type SectionDefinitionUpdateRequest = {
    name: LabelRequest;
    order: number;
    includeInPayroll: boolean;
    fields?: SectionFieldDefinitionRequest[];
};
