import { Label } from '@/domain/label/Label.model';
import { AnswerResult, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { renderGroupByWithFilters } from '@/domain/survey/Survey.service';
import { getLabelTranslation } from '@/utils/language.util';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Comment } from './Comment';
import { ScaleGroupBy } from './ScaleGroupBy';

type Props = {
    title: Label;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
};

export const ScaleResult: FC<Props> = ({ title, activeGroupByFilters, answer, groupBy }) => {
    const { t } = useTranslation();

    if (!answer?.details || !answer?.completions) {
        return <></>;
    }

    return (
        <Paper
            sx={{
                padding: 3,
                '& .singleGroup': {
                    display: 'flex',
                    flexGrow: 0,
                },
            }}
        >
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid item>
                    <Typography variant='body1bold'>{getLabelTranslation(title)}</Typography>
                </Grid>
                <Grid item>
                    <Typography component={'span'} variant='body1'>
                        {answer.completions?.ALL[0].answered} {t('survey_results.answered')} - {answer.completions?.ALL[0].skipped}{' '}
                        {t('survey_results.skipped')}
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={
                    groupBy !== SurveyResultFilterType.ALL
                        ? {
                              boxShadow: '0px 4px 4px rgb(0 0 0 / 10%)',
                              padding: '4px 0px',
                          }
                        : undefined
                }
            >
                <ScaleGroupBy question={answer.question} answerDetails={answer.details.ALL[0]} groupBy={SurveyResultFilterType.ALL} />
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid
                        sx={{
                            maxHeight: 300,
                            overflow: 'auto',
                        }}
                    >
                        {answer.details.ALL[0].comments?.map((comment, index: number) => <Comment key={index} text={comment.comment} user={comment.user} />)}
                    </Grid>
                </Grid>
            </Box>
            {groupBy !== SurveyResultFilterType.ALL &&
                answer.details[groupBy] &&
                renderGroupByWithFilters(activeGroupByFilters, answer.details[groupBy]).map((answerDetails, index) =>
                    answerDetails.items.length ? (
                        <div key={index}>
                            <ScaleGroupBy question={answer.question} answerDetails={answerDetails} groupBy={groupBy} />
                            <Grid container direction={'row'} justifyContent={'space-between'}>
                                <Grid
                                    sx={{
                                        maxHeight: 300,
                                        overflow: 'auto',
                                    }}
                                >
                                    {answerDetails.comments?.map((comment, i: number) => <Comment key={i} text={comment.comment} user={comment.user} />)}
                                </Grid>
                            </Grid>
                        </div>
                    ) : undefined,
                )}
        </Paper>
    );
};
