import { Footer } from '@/page/layout/Footer';
import { ReorderItem, ReorderModal } from '@/components/reorder-modal/ReorderModal';
import { RouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard';
import { displayFormRouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard.util';
import { Location } from '@/domain/location/Location.model';
import { useGetCalendars } from '@/hooks/calendar/Calendar.hook';
import { AreaDialog } from '@/page/setting/location/area-dialog/AreaDialog';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, Chip, FormControlLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { CancelCircleIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { Controller, FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FieldTime } from '@/components/form/field-time/FieldTime';

const DEFAULT_OPENING_TIME: LocalTime = '08:00';
const DEFAULT_CLOSING_TIME: LocalTime = '17:00';

export const LocationSettingForm: FC<{ location?: Location; onSave: (data: LocationSettingForm) => void }> = ({ location, onSave }) => {
    const { t } = useTranslation();
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
    const { data: calendars = [], isLoading: isLoadingCalendars } = useGetCalendars();

    const [isNewAreaDialogOpen, setIsNewAreaDialogOpen] = useState<boolean>(false);
    const [areaToEdit, setAreaToEdit] = useState<{ area: AreaSettingForm; index: number }>();

    const defaultAreas: Omit<AreaSettingForm, 'id'>[] =
        location?.areas?.map((a, index) => ({
            // save areaId to distinguish between new and existing areas
            areaId: a.id,
            name: a.name ?? '',
            color: a.color ?? '',
            order: a.order ?? index,
        })) ?? [];

    const defaultValues: Partial<LocationSettingForm> = {
        name: location?.name ?? '',
        street: location?.street ?? '',
        zipCode: location?.zipCode ?? '',
        city: location?.city ?? '',
        calendarId: location?.calendar?.id,
        openingTime: location?.openingTime ?? DEFAULT_OPENING_TIME,
        closingTime: location?.closingTime ?? DEFAULT_CLOSING_TIME,
    };

    const { register, control, handleSubmit, formState } = useForm<LocationSettingForm>({
        resolver: yupResolver(locationSettingFormSchema),
        defaultValues: {
            ...defaultValues,
            areas: defaultAreas,
        },
    });

    const {
        fields: areas,
        remove,
        update,
        append,
        replace,
    } = useFieldArray({
        control,
        name: 'areas',
    });

    const { errors } = formState;

    const handleAreaClick = (area: AreaSettingForm, index: number) => {
        setAreaToEdit({ area, index });
    };

    const handleAreaDialogClose = () => {
        setAreaToEdit(undefined);
        setIsNewAreaDialogOpen(false);
    };

    const handleAreaUpdate = (index: number, data: AreaSettingForm) => {
        update(index, data);
        handleAreaDialogClose();
    };

    const handleAreaCreate = (data: Omit<AreaSettingForm, 'id'>) => {
        // Append will generate id
        append(data as AreaSettingForm);
        handleAreaDialogClose();
    };

    const handleReorderSave = (reorderItems: ReorderItem[]) => {
        const newOrder = reorderItems.map<AreaSettingForm>(({ id, order }) => {
            const index = areas.findIndex(area => area.id === id);
            return { ...areas[index], order };
        });
        replace(newOrder);
        setIsOrderModalOpen(false);
    };

    const mapToReorderItem = (area: FieldArrayWithId<LocationSettingForm, 'areas'>): ReorderItem => ({
        // Use index as id because we id is optional in area
        id: area.id,
        name: area.name,
        order: area.order,
    });

    return (
        <>
            <ContentContainer>
                <RouteLeavingGuard when={displayFormRouteLeavingGuard(formState)} />
                <Stack component={Paper} p={3} gap={4} flex={1}>
                    <Typography variant='body1bold'>{t('locations_page.about')}</Typography>
                    <FormControlLabel
                        label={t('locations_page.location_name')}
                        control={<TextField error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                    />
                    <Typography variant='body1bold'>{t('locations_page.address')}</Typography>
                    <Stack direction='row' spacing={4}>
                        <FormControlLabel
                            label={t('locations_page.street')}
                            control={<TextField error={!!errors.street} helperText={errors.street?.message} {...register('street')} />}
                        />
                        <FormControlLabel
                            label={t('locations_page.zip_code')}
                            control={<TextField error={!!errors.zipCode} helperText={errors.zipCode?.message} {...register('zipCode')} />}
                        />
                    </Stack>
                    <FormControlLabel
                        label={t('locations_page.city')}
                        labelPlacement='top'
                        control={<TextField error={!!errors.city} helperText={errors.city?.message} {...register('city')} />}
                    />
                    <Typography variant='body1bold'>{t('locations_page.calendar')}</Typography>
                    <Controller
                        name='calendarId'
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                                options={calendars ?? []}
                                loading={isLoadingCalendars}
                                getOptionKey={option => option.id}
                                getOptionLabel={option => option.name}
                                value={calendars?.find(option => option.id === value) ?? getNull()}
                                onChange={(_, newValue) => {
                                    onChange(newValue?.id ?? getNull());
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            'aria-label': 'calendarId',
                                        }}
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        )}
                    />
                    <Stack direction='row' gap={1}>
                        <FormControlLabel
                            label={t('locations_page.opening_hours')}
                            labelPlacement='top'
                            control={<FieldTime name={'openingTime'} control={control} />}
                        />
                        <FormControlLabel
                            label={t('locations_page.closing_hours')}
                            labelPlacement='top'
                            control={<FieldTime name={'closingTime'} control={control} />}
                        />
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Typography variant='body1bold'>{t('planning_setting_page.areas')}</Typography>
                        <Button onClick={() => setIsNewAreaDialogOpen(true)}>{t('locations_page.add_area')}</Button>
                        {areas.length ? (
                            <Button
                                variant='text'
                                onClick={() => {
                                    setIsOrderModalOpen(true);
                                }}
                            >
                                {t('locations_page.reorder')}
                            </Button>
                        ) : undefined}
                    </Stack>
                    <Stack gap={1} direction='row' flexWrap='wrap'>
                        {areas.map((area, index) => (
                            <Chip
                                key={area.id}
                                variant='outlined'
                                label={area.name}
                                onDelete={() => remove(index)}
                                style={{
                                    borderColor: area.color,
                                    color: area.color,
                                }}
                                deleteIcon={<CancelCircleIcon size={20} style={{ color: area.color }} />}
                                onClick={() => handleAreaClick(area, index)}
                            />
                        ))}
                    </Stack>
                </Stack>
                {(areaToEdit || isNewAreaDialogOpen) && (
                    <AreaDialog
                        open={true}
                        area={areaToEdit?.area}
                        onClose={handleAreaDialogClose}
                        handleSave={data =>
                            areaToEdit
                                ? handleAreaUpdate(areaToEdit.index, {
                                      ...data,
                                      order: areaToEdit.area.order,
                                      id: areaToEdit.area.id,
                                      areaId: areaToEdit.area.areaId,
                                  })
                                : handleAreaCreate({ ...data, order: areas.length })
                        }
                    />
                )}
                {isOrderModalOpen && (
                    <ReorderModal
                        open={true}
                        onSave={handleReorderSave}
                        onClose={() => setIsOrderModalOpen(false)}
                        initialReorderItems={areas.map(mapToReorderItem)}
                    />
                )}
            </ContentContainer>
            <Footer>
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained'>
                    {t(location?.id ? 'general.update' : 'general.create')}
                </Button>
            </Footer>
        </>
    );
};

const areasSettingFormSchema = yup.object().shape({
    id: yup.string(),
    // Real area id
    areaId: yup.number(),
    name: yup.string().trim().required(),
    color: yup.string().trim().required(),
    order: yup.number().required(),
});

export type AreaSettingForm = yup.InferType<typeof areasSettingFormSchema>;

const locationSettingFormSchema = yup.object().shape({
    name: yup.string().trim().required(),
    street: yup.string().trim().required(),
    zipCode: yup.string().trim().required(),
    city: yup.string().trim().required(),
    calendarId: yup.number().required(),
    areas: yup.array().required().of(areasSettingFormSchema),
    openingTime: yup.string<LocalTime>().nullable().default(DEFAULT_OPENING_TIME),
    closingTime: yup.string<LocalTime>().nullable().default(DEFAULT_CLOSING_TIME),
});

export type LocationSettingForm = yup.InferType<typeof locationSettingFormSchema>;
