import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { Folder } from '@/domain/document/Document.model';
import { desktopVisible } from '@/theme/responsive';
import { getLabelTranslation } from '@/utils/language.util';

type Props = {
    folder: Folder;
    openFileStackDialog: () => void;
    onAddLinkClick: () => void;
    canManageDocument: boolean;
};

export const DocumentDetailHeader: FC<Props> = ({ folder, openFileStackDialog, onAddLinkClick, canManageDocument }) => {
    const { t } = useTranslation();

    return (
        <Stack direction='row' gap={1} flexWrap='wrap' justifyContent='space-between' alignItems='center'>
            <Typography variant='body1bold'>{getLabelTranslation(folder?.name)}</Typography>
            <Stack direction='row' alignItems='center' gap={1}>
                {canManageDocument && (
                    <Stack direction='row' gap={1} sx={desktopVisible} alignItems='center'>
                        <Typography color='textPrimary'>{t('folders.visible_to')}</Typography>
                        <StackedAvatars employeeAvatars={folder?.employeeAccess} />
                    </Stack>
                )}
                {canManageDocument && <Button onClick={openFileStackDialog}>{t('folders.upload')}</Button>}
                {canManageDocument && <Button onClick={onAddLinkClick}>{t('folders.add_link')}</Button>}
            </Stack>
        </Stack>
    );
};
