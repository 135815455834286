import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import * as yup from 'yup';

export const employeeAddressSectionSchema = yup
    .object()
    .shape({
        startDate: yup.string<LocalDate>().default(getNull()).test(getLocalDateTestConfig()).required(),
        addressLine1: yup.string().required(),
        addressLine2: yup.string(),
        region: yup.string(),
        city: yup.string().required(),
        postCode: yup.string().required(),
        country: yup.object().default(getNull()).required().shape({
            label: yup.string().required(),
            value: yup.string().required(),
        }),
    })
    .required();

export type EmployeeAddressSectionFormValues = yup.InferType<typeof employeeAddressSectionSchema>;
