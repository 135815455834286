import { CheckboxAutocompleteOption } from '@/components/autocomplete-wrapper/CheckboxAutocompleteOption';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FilePickerWrapper } from '@/components/file-picker-wrapper/FilePickerWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { RichTextEditor } from '@/components/rich-text-editor/RichTextEditor';
import { Announcement } from '@/domain/announcement/Announcement.model';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { AnnouncementFormValues, AnnouncementImageFormValues, getAnnouncementSchema } from '@/page/announcement/AnnouncementForm.schema';
import { AnnouncementImageBox } from '@/page/announcement/AnnouncementImageBox';
import { useGetLocations } from '@/page/setting/location/Location.hook';
import { getCurrentLocalDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, DialogActions, DialogContent, FormControlLabel, FormHelperText, TextField } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AnnouncementDialogProps = {
    open: boolean;
    defaultValue?: Announcement;
    onClose: () => void;
    onSubmit: SubmitHandler<AnnouncementFormValues>;
    isPending: boolean;
};
export const AnnouncementDialog: FC<AnnouncementDialogProps> = ({ open, defaultValue, onClose, onSubmit, isPending }) => {
    const { t } = useTranslation();

    const { data: departments, isLoading: isDepartmentsLoading } = useGetDepartments();
    const { data: locations, isLoading: isLocationsLoading } = useGetLocations();

    const handleCloseCreateAnnouncementDialog = () => {
        onClose();
    };

    const defaultImage: AnnouncementImageFormValues = defaultValue?.imageUrl
        ? {
              url: defaultValue?.imageUrl,
              filename: defaultValue?.title ?? '',
              key: undefined,
              mimetype: 'image/*',
          }
        : getNull();

    const { control, watch, setValue, handleSubmit } = useForm<AnnouncementFormValues>({
        resolver: yupResolver(getAnnouncementSchema()),
        defaultValues: {
            title: '',
            content: '',
            startDate: getCurrentLocalDate(),
            publishToAllDepartments: false,
            departments: [],
            publishToAllLocations: false,
            locations: [],
            image: undefined,
        },
        values: defaultValue
            ? {
                  title: defaultValue.title,
                  content: defaultValue.content,
                  startDate: defaultValue.startDate,
                  endDate: defaultValue.endDate,
                  publishToAllDepartments: defaultValue.departments.length === 0,
                  departments: defaultValue.departments,
                  publishToAllLocations: defaultValue.locations.length === 0,
                  locations: defaultValue.locations,
                  image: defaultImage,
              }
            : undefined,
    });

    const publishToAllDepartments = watch('publishToAllDepartments');
    const publishToAllLocations = watch('publishToAllLocations');
    const image = watch('image');

    return (
        <DialogWrapper onClose={handleCloseCreateAnnouncementDialog} open={open} header={t('announcement.create_dialog.title')} maxWidth={'sm'}>
            <DialogContent>
                <Stack gap={2}>
                    <FormControlLabel
                        label={t('announcement.create_dialog.title_label')}
                        labelPlacement='top'
                        sx={{ width: '100%' }}
                        control={
                            <Controller
                                name='title'
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField {...field} error={!!fieldState.error} helperText={fieldState.error?.message} autoFocus fullWidth />
                                )}
                            />
                        }
                    />
                    <Stack>
                        <Typography variant={'body1'}>{t('announcement.create_dialog.content_label')}</Typography>
                        <Controller
                            name={'content'}
                            control={control}
                            render={({ field, formState, fieldState }) => {
                                return (
                                    <RichTextEditor
                                        minHeight={'120px'}
                                        errorMessage={fieldState?.error?.message}
                                        onUpdate={field.onChange}
                                        defaultValue={formState?.defaultValues?.content ?? ''}
                                        name={'content'}
                                    />
                                );
                            }}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Controller
                            name='image'
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <>
                                        <FilePickerWrapper
                                            containerId='create-announcement-dialog'
                                            accept={'image/*'}
                                            maxFiles={1}
                                            filesMetadata={field.value ? [field.value] : []}
                                            onFileUploaded={fileToAdd => {
                                                field.onChange(fileToAdd);
                                            }}
                                            onFileRemoved={() => {
                                                field.onChange(getNull());
                                            }}
                                            onFileRenamed={fileToReplace => {
                                                field.onChange(fileToReplace);
                                            }}
                                            fetchDocumentUrl={undefined}
                                        />
                                        {!!fieldState.error?.message && (
                                            <FormHelperText sx={{ marginLeft: 2 }} error={!!fieldState.error?.message}>
                                                {fieldState.error?.message}
                                            </FormHelperText>
                                        )}
                                    </>
                                );
                            }}
                        />
                        {image?.url && <AnnouncementImageBox src={image.url} alt={image.filename} maxHeight={'300px'} />}
                    </Stack>
                    <Stack direction={'row'} alignItems={'flex-start'} gap={2}>
                        <FormControlLabel
                            label={t('announcement.create_dialog.startDate_label')}
                            control={<FieldLocalDate control={control} name='startDate' />}
                        />
                        <FormControlLabel label={t('announcement.create_dialog.endDate_label')} control={<FieldLocalDate control={control} name='endDate' />} />
                    </Stack>
                    <FormControlLabel
                        label={t('announcement.create_dialog.publishToAllDepartments')}
                        labelPlacement='end'
                        control={<FieldSwitch control={control} name='publishToAllDepartments' onChange={() => setValue('departments', [])} />}
                    />
                    {!publishToAllDepartments && (
                        <Stack>
                            <FormControlLabel
                                label={t('announcement.create_dialog.departments_label')}
                                control={
                                    <Controller
                                        name='departments'
                                        control={control}
                                        render={({ field: { value: selectedDepartments = [], onChange, ...restField }, fieldState }) => (
                                            <Autocomplete
                                                {...restField}
                                                multiple
                                                fullWidth
                                                disableCloseOnSelect
                                                value={selectedDepartments}
                                                options={departments ?? []}
                                                loading={isDepartmentsLoading}
                                                getOptionLabel={department => getLabelTranslation(department?.name)}
                                                onChange={(_, selectedDepartment) => {
                                                    onChange(selectedDepartment);
                                                }}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                getOptionKey={option => option.id}
                                                renderOption={(props, departmentOption, { selected }) => (
                                                    <CheckboxAutocompleteOption
                                                        {...props}
                                                        selected={selected}
                                                        label={getLabelTranslation(departmentOption?.name)}
                                                        key={departmentOption.id}
                                                    />
                                                )}
                                                renderInput={params => (
                                                    <TextField error={!!fieldState.error} helperText={fieldState.error?.message} {...params} />
                                                )}
                                            />
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    )}
                    <FormControlLabel
                        label={t('announcement.create_dialog.publishToAllLocations')}
                        labelPlacement='end'
                        control={<FieldSwitch control={control} name='publishToAllLocations' onChange={() => setValue('locations', [])} />}
                    />
                    {!publishToAllLocations && (
                        <Stack>
                            <FormControlLabel
                                label={t('announcement.create_dialog.locations_label')}
                                control={
                                    <Controller
                                        name='locations'
                                        control={control}
                                        render={({ field: { value: selectedLocations = [], onChange, ...restField }, fieldState }) => (
                                            <Autocomplete
                                                {...restField}
                                                multiple
                                                fullWidth
                                                disableCloseOnSelect
                                                value={selectedLocations}
                                                options={locations ?? []}
                                                loading={isLocationsLoading}
                                                getOptionLabel={location => location.name}
                                                onChange={(_, selectedLocation) => {
                                                    onChange(selectedLocation);
                                                }}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                getOptionKey={option => option.id}
                                                renderOption={(props, locationOption, { selected }) => (
                                                    <CheckboxAutocompleteOption
                                                        {...props}
                                                        selected={selected}
                                                        label={locationOption?.name}
                                                        key={locationOption.id}
                                                    />
                                                )}
                                                renderInput={params => (
                                                    <TextField error={!!fieldState.error} helperText={fieldState.error?.message} {...params} />
                                                )}
                                            />
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    )}
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleSubmit(onSubmit, console.error)()} fullWidth disabled={isPending}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
