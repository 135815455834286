import {
    AnswerItemResult,
    AnswerResult,
    AnswerResultDetails,
    SurveyAnswerCompletion,
    SurveyQuestionItem,
    SurveyResultFilterType,
} from '@/domain/survey/Survey.model';
import { COLORS_SCHEME, renderGroupByWithFilters } from '@/domain/survey/Survey.service';
import { getLabelTranslation } from '@/utils/language.util';
import { calculatePercentage } from '@/utils/math.util';
import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    questionItem: SurveyQuestionItem;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
};

export const ProgressBar: FC<Props> = ({ questionItem, activeGroupByFilters, answer, groupBy }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    if (!answer?.details || !answer?.completions) {
        return <></>;
    }

    const answered = answer.completions[SurveyResultFilterType.ALL][0]?.answered;
    const allItem = answer.details[SurveyResultFilterType.ALL].flatMap(a => a.items).find(a => a.item.id === questionItem.id);

    const getTotalAnsweredResults = (groupBy: SurveyAnswerCompletion[], key: string) => {
        return groupBy.find(a => a.key === key)?.answered ?? 0;
    };

    const renderChildProgressBar = (detail: AnswerResultDetails, item: AnswerItemResult | undefined, index: number) => {
        return (
            <Grid container direction={'row'} key={questionItem.id} alignItems={'center'} sx={{ mb: 1, mt: 2 }}>
                <Grid item>
                    <Typography variant='body2' sx={totalPercentStyles}>
                        {getLabelTranslation(detail.label)}
                    </Typography>
                </Grid>
                <Tooltip
                    placement='left'
                    title={
                        <>
                            {t('survey_results.total_answers')} {item?.count ?? ''} <br />
                            {getLabelTranslation(detail.label)}
                        </>
                    }
                >
                    <Grid xs item sx={progressBarStyles} className={`groups`} style={{ background: '#F8F8F7' }}>
                        <Typography sx={labelStyles} variant='body1'>
                            {`${item ? calculatePercentage(item.count, getTotalAnsweredResults(answer.completions[groupBy], detail.key)) : 0}%`}
                        </Typography>
                        <Box
                            style={{
                                width: `${item ? calculatePercentage(item.count, getTotalAnsweredResults(answer.completions[groupBy], detail.key)) : 0}%`,
                                background: COLORS_SCHEME[index < COLORS_SCHEME.length ? index : 0].color,
                            }}
                            sx={statusStyles}
                        />
                    </Grid>
                </Tooltip>
            </Grid>
        );
    };

    const progressBarStyles = {
        background: theme.palette.primary.dark,
        marginLeft: 0,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        borderRadius: 2,
        height: 31,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&.groups': {
            height: 16,
            padding: '0 12px',
            '& p': {
                fontSize: 13,
                color: '#fff',
                lineHeight: '16px',
            },
            '& .group': {
                display: 'none',
            },
        },
    };

    const statusStyles = {
        position: 'absolute',
        height: '100%',
        background: 'rgb(1 203 203 / 40%)',
        top: 0,
        left: 0,
        borderRadius: 2,
    };

    const labelStyles = {
        position: 'relative',
        zIndex: 1,
        color: '#000A1D',
        '& .groups': {
            display: 'none',
        },
    };

    const totalPercentStyles = {
        width: 160,
        textAlign: 'right',
        paddingRight: 1,
        boxSizing: 'border-box',
        // display: 'block',
        color: '#585869',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
    };
    return (
        <>
            <Tooltip title={allItem?.count ? `${t('survey_results.total_answers')}: ${allItem?.count}` : ''}>
                <Grid
                    container
                    direction={'row'}
                    key={questionItem.id}
                    alignItems={'center'}
                    sx={
                        groupBy !== SurveyResultFilterType.ALL
                            ? {
                                  boxShadow: '0px 4px 4px rgb(0 0 0 / 10%)',
                                  padding: '4px 0px',
                                  mb: 1,
                              }
                            : {
                                  mb: 1,
                              }
                    }
                >
                    <Grid item>
                        <Typography variant='body1bold' sx={totalPercentStyles}>
                            {groupBy === SurveyResultFilterType.ALL
                                ? `${calculatePercentage(allItem?.count ?? 0, answered)}%`
                                : `${t('survey_results.all_participants')}`}
                        </Typography>
                    </Grid>

                    <Grid xs item sx={progressBarStyles} style={{ background: '#F8F8F7' }}>
                        <Typography sx={labelStyles} variant='body1'>
                            {groupBy === SurveyResultFilterType.ALL ? (
                                `${getLabelTranslation(questionItem?.label)}`
                            ) : (
                                <>
                                    <b>{calculatePercentage(allItem?.count ?? 0, answered)}%</b> {getLabelTranslation(questionItem?.label)}
                                </>
                            )}
                        </Typography>
                        <Box
                            style={{
                                width: `${calculatePercentage(allItem?.count ?? 0, answered)}%`,
                                background: 'rgba(1, 205, 205, 0.5)',
                            }}
                            sx={statusStyles}
                        />
                    </Grid>
                </Grid>
            </Tooltip>
            {groupBy !== SurveyResultFilterType.ALL &&
                answer.details[groupBy] &&
                renderGroupByWithFilters(activeGroupByFilters, answer.details[groupBy])
                    .filter(item => !item.items.length || item.items.some(a => a.item.id === questionItem.id))
                    .map((detail, index) =>
                        detail.items.length
                            ? detail.items.filter(a => a.item.id === questionItem.id).map(item => renderChildProgressBar(detail, item, index))
                            : renderChildProgressBar(detail, undefined, index),
                    )}
        </>
    );
};
