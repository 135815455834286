import { Employment, EmploymentCreateReason, EmploymentsSearchRequest } from '@/domain/employment/Employment.model';
import { getEmployments } from '@/domain/employment/Employment.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetEmployments = (
    employmentSearchMutation?: EmploymentsSearchRequest,
    { enabled }: { enabled: boolean } = { enabled: true },
): UseQueryResult<Employment[]> => {
    const [employment, setEmployment] = useState<Employment[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployment = useCallback(
        async (employeeId?: number) => {
            if (!employeeId || !enabled) {
                return;
            }

            try {
                const employmentData = await getEmployments({ employeeId: employeeId });
                setEmployment(employmentData);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    useEffect(() => {
        fetchEmployment(employmentSearchMutation?.employeeId).catch(handleError);
    }, [fetchEmployment, employmentSearchMutation?.employeeId]);

    return {
        data: employment,
        setData: setEmployment,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployment(employmentSearchMutation?.employeeId),
    };
};

export const useGetContracts = (
    employmentSearchMutation?: EmploymentsSearchRequest,
    { enabled }: { enabled: boolean } = { enabled: true },
): UseQueryResult<Employment[]> => {
    const { data: employments, ...rest } = useGetEmployments(employmentSearchMutation, { enabled });

    // Contract are employments with employmentCreateReason NEW_EMPLOYEE or REHIRED
    const contractCreationReasonsList = [EmploymentCreateReason.NEW_EMPLOYEE, EmploymentCreateReason.REHIRED];
    const isContract = (employment: Employment) => {
        return employment.principal && employment.employmentCreateReason && contractCreationReasonsList.includes(employment.employmentCreateReason);
    };
    return {
        data: employments?.filter(isContract),
        ...rest,
    };
};
