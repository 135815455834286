import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAvailableContributorTypes } from '@/domain/review-template/ReviewTemplate.service';
import { InvitationEmailFormType, ReviewTemplateFormType } from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import { TranslatableRichTextEditor } from '@/components/translatable-rich-text-editor/TranslatableRichTextEditor';
import { getLocalizedErrorMessage, UserLanguage } from '@/utils/language.util';
import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { createDefaultLabel } from '@/domain/label/Label.service';

type InvitationEmailsContentFormProps = {
    translationLanguage: UserLanguage;
};

export const InvitationEmailsContentForm: FC<InvitationEmailsContentFormProps> = ({ translationLanguage }) => {
    const { watch } = useFormContext<ReviewTemplateFormType>();
    const reviewTemplateFormValues = watch();
    const availableContributorTypes = getAvailableContributorTypes(reviewTemplateFormValues);
    const showSelfInvitationEmail = availableContributorTypes.includes('SELF') && reviewTemplateFormValues.includePreparationStep;
    const showPeerInvitationEmail = availableContributorTypes.includes('PEER') && reviewTemplateFormValues.includePreparationStep;
    const showUpwardInvitationEmail = availableContributorTypes.includes('UPWARD') && reviewTemplateFormValues.includePreparationStep;

    return (
        <Stack gap={2}>
            <ManagerEmail translationLanguage={translationLanguage} />
            {showSelfInvitationEmail && <SelfReviewEmail translationLanguage={translationLanguage} />}
            {showPeerInvitationEmail && <PeerReviewEmail translationLanguage={translationLanguage} />}
            {showUpwardInvitationEmail && <UpwardFeedbackEmail translationLanguage={translationLanguage} />}
        </Stack>
    );
};

type ManagerEmailProps = {
    translationLanguage: UserLanguage;
};
const ManagerEmail: FC<ManagerEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={2} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.manager_email')}</Typography>
            <Controller
                render={({ field, fieldState: { error } }) => {
                    return (
                        <TranslatableLabelInput
                            translationLanguage={translationLanguage}
                            onChange={value => {
                                field.onChange(value);
                            }}
                            value={field.value}
                            sx={{ width: '800px' }}
                            error={!!error}
                            helperText={getLocalizedErrorMessage(error, translationLanguage)}
                            label={t('reviews_settings_page.review_template_form.subject')}
                            autoFocus
                            name={'managerSubject'}
                        />
                    );
                }}
                control={control}
                name={'managerSubject'}
            />

            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <Controller
                    name={'managerBody'}
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <TranslatableRichTextEditor
                                minHeight={'120px'}
                                onUpdate={field.onChange}
                                value={field.value ?? createDefaultLabel()}
                                translationLanguage={translationLanguage}
                                name={'managerBody'}
                                errorMessage={getLocalizedErrorMessage(fieldState.error, translationLanguage)}
                            />
                        );
                    }}
                />
            </Stack>
        </Stack>
    );
};

type SelfReviewEmailProps = {
    translationLanguage: UserLanguage;
};

const SelfReviewEmail: FC<SelfReviewEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();
    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.self_review_email')}</Typography>
            <Controller
                render={({ field, fieldState: { error } }) => {
                    return (
                        <TranslatableLabelInput
                            translationLanguage={translationLanguage}
                            onChange={value => {
                                field.onChange(value);
                            }}
                            value={field.value ?? createDefaultLabel()}
                            sx={{ width: '800px' }}
                            error={!!error}
                            helperText={getLocalizedErrorMessage(error, translationLanguage)}
                            label={t('reviews_settings_page.review_template_form.subject')}
                            autoFocus
                            name={'selfSubject'}
                        />
                    );
                }}
                control={control}
                name={'selfSubject'}
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <Controller
                    name={'selfBody'}
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <TranslatableRichTextEditor
                                minHeight={'120px'}
                                onUpdate={field.onChange}
                                value={field.value}
                                translationLanguage={translationLanguage}
                                name={'selfBody'}
                                errorMessage={getLocalizedErrorMessage(fieldState.error, translationLanguage)}
                            />
                        );
                    }}
                />
            </Stack>
        </Stack>
    );
};

type PeerReviewEmailProps = {
    translationLanguage: UserLanguage;
};

const PeerReviewEmail: FC<PeerReviewEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.peer_review_email')}</Typography>
            <Controller
                render={({ field, fieldState: { error } }) => {
                    return (
                        <TranslatableLabelInput
                            translationLanguage={translationLanguage}
                            onChange={value => {
                                field.onChange(value);
                            }}
                            value={field.value ?? createDefaultLabel()}
                            sx={{ width: '800px' }}
                            error={!!error}
                            helperText={getLocalizedErrorMessage(error, translationLanguage)}
                            label={t('reviews_settings_page.review_template_form.subject')}
                            autoFocus
                            name={'peerSubject'}
                        />
                    );
                }}
                control={control}
                name={'peerSubject'}
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <Controller
                    name={'peerBody'}
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <TranslatableRichTextEditor
                                minHeight={'120px'}
                                onUpdate={field.onChange}
                                value={field.value}
                                translationLanguage={translationLanguage}
                                name={'peerBody'}
                                errorMessage={getLocalizedErrorMessage(fieldState.error, translationLanguage)}
                            />
                        );
                    }}
                />
            </Stack>
        </Stack>
    );
};

type UpwardFeedbackEmailProps = {
    translationLanguage: UserLanguage;
};

const UpwardFeedbackEmail: FC<UpwardFeedbackEmailProps> = ({ translationLanguage }) => {
    const { control } = useFormContext<InvitationEmailFormType>();
    const { t } = useTranslation();

    return (
        <Stack p={2} gap={3} component={Paper}>
            <Typography variant={'body1bold'}>{t('reviews_settings_page.review_template_form.upward_feedback_email')}</Typography>
            <Controller
                render={({ field, fieldState: { error } }) => {
                    return (
                        <TranslatableLabelInput
                            translationLanguage={translationLanguage}
                            onChange={value => {
                                field.onChange(value);
                            }}
                            value={field.value ?? createDefaultLabel()}
                            sx={{ width: '800px' }}
                            error={!!error}
                            helperText={getLocalizedErrorMessage(error, translationLanguage)}
                            label={t('reviews_settings_page.review_template_form.subject')}
                            autoFocus
                            name={'upwardSubject'}
                        />
                    );
                }}
                control={control}
                name={'upwardSubject'}
            />
            <Stack width={'800px'}>
                <Typography variant={'body1'}>{t('reviews_settings_page.review_template_form.body')}</Typography>
                <Controller
                    name={'upwardBody'}
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <TranslatableRichTextEditor
                                minHeight={'120px'}
                                onUpdate={field.onChange}
                                value={field.value}
                                translationLanguage={translationLanguage}
                                name={'upwardBody'}
                                errorMessage={getLocalizedErrorMessage(fieldState.error, translationLanguage)}
                            />
                        );
                    }}
                />
            </Stack>
        </Stack>
    );
};
