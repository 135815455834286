import { JobFamily } from '@/domain/job-family/JobFamily.model';
import { useGetJobFamilies } from '@/hooks/job-family/JobFamily.hook';
import { getNull } from '@/utils/object.util';
import { Autocomplete, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const FieldSelectJobFamily: FC<{ name: string }> = ({ name }) => {
    const { data: jobFamilies = [], isLoading } = useGetJobFamilies();

    const { control } = useFormContext<Record<typeof name, JobFamily | null>>();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => (
                <Autocomplete
                    {...field}
                    // Fall back to null to avoid uncontrolled component error
                    value={field.value ?? getNull()}
                    onChange={(_, data) => onChange(data)}
                    options={jobFamilies}
                    loading={isLoading}
                    getOptionLabel={jobFamily => jobFamily?.name}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    fullWidth
                    renderInput={params => <TextField error={!!fieldState.error} helperText={fieldState.error?.message} {...params} />}
                />
            )}
        />
    );
};
