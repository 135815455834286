import { leaveTypePolicyApi } from '@/api/leave-type-policy/LeaveTypePolicy.api';
import { LeaveType, UserLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';

const getCurrentEmployeeLeaveTypePolicies = (): Promise<UserLeaveTypePolicy[]> => {
    return leaveTypePolicyApi.getCurrentEmployeeLeaveTypePolicies();
};

const getCurrentEmployeeWorkingLeaveTypePolicies = (): Promise<UserLeaveTypePolicy[]> => {
    return leaveTypePolicyApi.getCurrentEmployeeWorkingLeaveTypePolicies();
};

export const getCurrentEmployeeAllLeaveTypePolicies = async (): Promise<UserLeaveTypePolicy[]> => {
    const responsesData = await Promise.all([getCurrentEmployeeLeaveTypePolicies(), getCurrentEmployeeWorkingLeaveTypePolicies()]);
    return [...new Set(responsesData.flat())];
};

export const getEmployeeLeaveTypePolicies = (employeeId: number): Promise<UserLeaveTypePolicy[]> => {
    return leaveTypePolicyApi.getEmployeeLeaveTypePolicies(employeeId);
};

export const assignLeaveTypePolicy = (employeeId: number, employeeLeaveType: UserLeaveTypePolicy): Promise<UserLeaveTypePolicy[]> => {
    return leaveTypePolicyApi.assignLeaveTypePolicy(employeeId, employeeLeaveType);
};

export const batchAssignLeaveTypePolicy = (
    employeeIds: number[],
    leaveTypeId: number,
    leaveTypePoliceId: number,
    applyFrom: LocalDate,
): Promise<UserLeaveTypePolicy> => {
    return leaveTypePolicyApi.batchAssignLeaveTypePolicy(employeeIds, leaveTypeId, leaveTypePoliceId, applyFrom);
};

export const unassignLeaveTypePolicy = (employeeId: number, leaveType: LeaveType, endDate: LocalDate): Promise<UserLeaveTypePolicy[]> => {
    return leaveTypePolicyApi.unassignLeaveTypePolicy(employeeId, leaveType, endDate);
};

export const batchUnassignLeaveTypePolicy = (employeeIds: number[], leaveTypeId: number, endDate: LocalDate): Promise<UserLeaveTypePolicy> => {
    return leaveTypePolicyApi.batchUnassignLeaveTypePolicy(employeeIds, leaveTypeId, endDate);
};

const getEmployeeLeaveTypes = (userLeaveTypePolicies: UserLeaveTypePolicy[], employeeId: number) => {
    return userLeaveTypePolicies?.filter(userLeaveTypePolicy => {
        return userLeaveTypePolicy?.user?.id === employeeId;
    });
};

const getEmployeeLeaveTypeOfToday = (employeeLeaveTypePolicies: UserLeaveTypePolicy[], leaveTypeOfTodayEmployeeId: number) => {
    return employeeLeaveTypePolicies?.find(employeeLeaveType => {
        return employeeLeaveType?.leaveType?.id === leaveTypeOfTodayEmployeeId;
    });
};

export const getEmployeeLeaveTypeToDisplay = (userLeaveTypePolicies: UserLeaveTypePolicy[], employeeId: number, leaveTypeId: number): LeaveType | undefined => {
    const employeeLeaveTypePolicies = getEmployeeLeaveTypes(userLeaveTypePolicies, employeeId);
    const employeeLeaveTypePolicy = getEmployeeLeaveTypeOfToday(employeeLeaveTypePolicies, leaveTypeId);
    return employeeLeaveTypePolicy?.leaveType;
};
