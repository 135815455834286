import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { searchAreas } from '@/domain/area/Area.service';
import { EmployeeFilterType, searchEmployees } from '@/domain/employee/Employee.service';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { getLocations } from '@/domain/location/Location.service';
import { TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';
import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';

export const useAllTimesheetTableFilters = (): { filters: (SelectFilter | AsyncSelectFilter)[] } => {
    const { t } = useTranslation();
    const defaultTimesheetTypeOptions = [TimesheetType.TIMESHEET_ADJUSTMENT, TimesheetType.TIMESHEET, TimesheetType.TIMESHEET_PAYMENT].map(type => ({
        label: t(`timesheets.all_page.timesheet_types.enum_${type as string}`),
        value: type,
    }));
    const filters: (SelectFilter | AsyncSelectFilter)[] = [
        {
            filterName: 'Type',
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLeaveTypes().then(leaveTypes => {
                    return [
                        ...defaultTimesheetTypeOptions,
                        ...leaveTypes.map(leaveType => ({
                            label: leaveType.title,
                            value: leaveType.id,
                        })),
                    ];
                });
            },
            key: 'leaveTimesheet.leaveTypeId',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getDepartments().then(departments => {
                    return departments?.map(department => ({
                        label: getLabelTranslation(department.name),
                        value: department.id,
                    }));
                });
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'area.id',
            filterName: 'Area',
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const areas = await searchAreas({ locationIds: [] });
                return areas?.map(area => ({
                    label: area.name,
                    value: area.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'MANAGER_IDS' satisfies EmployeeFilterType,
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employees = await searchEmployees();
                return employees?.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
