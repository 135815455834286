import { getAppConfig } from '@/config/config';
import { Box, IconButton, IconButtonProps, Input, Stack, StackProps, useTheme } from '@mui/material';
import * as filestack from 'filestack-js';
import { PickerDisplayMode, PickerFileMetadata } from 'filestack-js';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './FilePickerWrapper.css';
import { Cancel01Icon, File01Icon, LinkSquare02Icon } from 'hugeicons-react';
import { ConfirmPopover } from '@/components/confirmation-popover/ConfirmPopover';

const config = getAppConfig();
const client = filestack.init(config.FILESTACK.API_KEY);

export type FileMetadata = Pick<PickerFileMetadata, 'filename' | 'key' | 'mimetype'> & {
    itemId?: number;
    url?: string;
};

export type FileStackWrapperProps = filestack.PickerOptions & {
    // this containerID is used to differentiate between multiple FilePickerWrappers
    containerId: string;
    filesMetadata: FileMetadata[];
    onFileUploaded: (fileMetadata: FileMetadata) => void;
    onFileRemoved: (fileMetadata: FileMetadata) => void;
    onFileRenamed: ((fileMetadata: FileMetadata) => void) | undefined;
    fetchDocumentUrl: ((itemId: number) => Promise<string>) | undefined;
    maxFiles?: number;
    accept?: string | string[];
    confirmOnRemove?: boolean;
} & StackProps;

export const FilePickerWrapper: FC<FileStackWrapperProps> = ({
    onCancel,
    onFileRenamed,
    onFileRemoved,
    onFileUploaded,
    filesMetadata,
    containerId,
    fetchDocumentUrl = () => Promise.resolve(''),
    maxFiles,
    accept,
    confirmOnRemove = false,
    ...rest
}) => {
    const { t } = useTranslation();

    const fileStackContainerId = 'fileStackContainer' + (containerId || '');
    const { createPickerInstance } = useFileStackInstance({
        container: fileStackContainerId,
        onFileUploadFinished: handleFileUploaded,
        onCancel: onCancel ?? (() => undefined),
        maxFiles,
        accept,
    });
    const theme = useTheme();

    const pickerInstance = useRef<Promise<void>>();
    if (!pickerInstance.current) {
        pickerInstance.current = createPickerInstance();
    }

    const mapPickerFileMetadata = (pickerFileMetadata: filestack.PickerFileMetadata): FileMetadata => {
        return {
            filename: pickerFileMetadata.filename,
            key: pickerFileMetadata.key,
            mimetype: pickerFileMetadata.mimetype,
            url: pickerFileMetadata.url,
        };
    };

    function handleFileUploaded(pickerFleMetadata: filestack.PickerFileMetadata) {
        const fileMetadata = mapPickerFileMetadata(pickerFleMetadata);
        onFileUploaded(fileMetadata);
    }

    const handleFileClicked = async ({ url, itemId }: FileMetadata) => {
        if (url) {
            window.open(url, '_blank');
            return;
        }
        if (!itemId) {
            return;
        }

        const documentUrl = await fetchDocumentUrl(itemId);
        if (!documentUrl) {
            return;
        }

        window.open(documentUrl, '_blank');
    };

    return (
        <Stack {...rest} gap={1}>
            <Box id={fileStackContainerId} />
            {filesMetadata?.map(fileMetadata => (
                <Box
                    key={fileMetadata?.itemId ?? fileMetadata.key ?? fileMetadata.url}
                    border={'0.5px solid'}
                    borderColor={theme.palette.grey[300]}
                    borderRadius={1}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <File01Icon
                            style={{
                                paddingLeft: 1.5,
                                paddingRight: 1.5,
                            }}
                        />

                        <Input
                            sx={{ border: 'none' }}
                            inputProps={{
                                style: {
                                    padding: 0,
                                },
                            }}
                            fullWidth
                            value={fileMetadata.filename}
                            readOnly={!onFileRenamed}
                            onChange={e => {
                                if (!onFileRenamed) {
                                    return;
                                }

                                onFileRenamed({
                                    ...fileMetadata,
                                    filename: e.target.value,
                                });
                            }}
                            disableUnderline={true}
                            endAdornment={
                                (fileMetadata.itemId ?? fileMetadata.url) && (
                                    <IconButton onClick={() => handleFileClicked(fileMetadata)}>
                                        <LinkSquare02Icon />
                                    </IconButton>
                                )
                            }
                        />
                        {confirmOnRemove ? (
                            <ConfirmPopover onConfirm={() => onFileRemoved(fileMetadata)} elevation={2} content={t('file_stack_wrapper.are_you_sure_delete')}>
                                <DeleteIconButton />
                            </ConfirmPopover>
                        ) : (
                            <DeleteIconButton onClick={() => onFileRemoved(fileMetadata)} />
                        )}
                    </Stack>
                </Box>
            ))}
        </Stack>
    );
};

type UseFileStackInstanceOptions = {
    container: string;
    onFileUploadFinished: (response: PickerFileMetadata) => void;
    onCancel: filestack.PickerUploadDoneCallback;
    maxFiles?: number;
    accept?: string | string[];
};

const useFileStackInstance = (
    options: UseFileStackInstanceOptions,
): {
    createPickerInstance: () => Promise<void>;
} => {
    const { container, onFileUploadFinished, onCancel, maxFiles, accept } = options;
    const { t } = useTranslation();
    const FROM_SOURCES = ['local_file_system'];
    const onFileSelected = (file: PickerFileMetadata) => {
        if (file.size > config.MAX_UPLOAD_FILE_SIZE) {
            throw new Error(t('file_stack_wrapper.file_too_big'));
        }
    };

    const getCustomText = () => ({
        'Drag and Drop, Copy and Paste Files': t('file_stack_wrapper.drag_and_drop'),
    });

    const createPickerInstance = async () => {
        await client
            .picker({
                onFileUploadFinished,
                accept: accept ?? config.ALLOWED_DOCUMENT_FORMATS,
                fromSources: FROM_SOURCES,
                displayMode: PickerDisplayMode.dropPane,
                dropPane: {
                    overlay: false,
                },
                container: container,
                onCancel,
                onFileSelected,
                customText: getCustomText(),
                maxFiles: maxFiles ?? config.MAX_UPLOAD_FILES,
            })
            .open();
    };

    return { createPickerInstance };
};

const DeleteIconButton = (props: IconButtonProps) => (
    <IconButton
        size='small'
        sx={{
            ml: 'auto',
        }}
        aria-label='delete-file'
        {...props}
    >
        <Cancel01Icon />
    </IconButton>
);
