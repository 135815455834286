import { Box, Divider, Fade, List, ListItemButton, ListItemText, Stack, SxProps, Theme, Toolbar } from '@mui/material';
import { FC, forwardRef, Fragment, useEffect } from 'react';

import { LogoRoger } from '@/components/logo-roger/LogoRoger';
import {
    canAccessOtherEmployeeReviews,
    canConfigureCompanySettings,
    canManageOtherEmployeeObjectives,
    canManageOtherEmployeeTimesheets,
    canManagePayroll,
    canSeeOtherEmployeeLeaves,
    canSeeSurveyResults,
    canViewCompanyDocuments,
    canViewEmployeesDirectory,
    canViewReports,
    canViewReviews,
    canViewShifts,
} from '@/domain/permission/Permission.service';
import { Page, pages } from '@/page/Pages';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';

import { useLocalStorage } from '@/hooks/Storage.hook';
import { useAppSelector } from '@/stores/store';
import {
    BankIcon as PayrollIcon,
    Calendar03Icon as LeavesIcon,
    CircleArrowLeft02Icon,
    Clock01Icon as TimesheetsIcon,
    DashboardSquare02Icon as PlanningIcon,
    DocumentValidationIcon,
    File01Icon as DocumentIcon,
    Home03Icon as HomeIcon,
    Layout3ColumnIcon as ReportIcon,
    Settings02Icon,
    StarIcon as ReviewsIcon,
    Target01Icon as ObjectivesIcon,
    UserIcon as ProfileIcon,
    UserMultiple02Icon as EmployeesIcon,
} from 'hugeicons-react';

export const Sidebar: FC = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const location = useLocation();

    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);

    const [expandedMenu, setExpandedMenu] = useLocalStorage('expandedMenu', false);

    // Sync expandedMenu state with redux
    useEffect(() => {
        dispatch({ type: UiActionType.EXPAND_MENU, expand: expandedMenu });
    }, [dispatch, expandedMenu]);

    if (!realm) {
        return undefined;
    }

    const list = currentEmployee?.id
        ? [
              { page: pages.home, icon: <HomeIcon />, label: t('sidebar.home'), canSee: true },
              { page: pages.myProfile, icon: <ProfileIcon />, label: t('sidebar.my_profile'), canSee: true },
              {
                  page: pages.people,
                  icon: <EmployeesIcon />,
                  label: t('sidebar.manage_people'),
                  canSee: canViewEmployeesDirectory(grantedPolicies, currentEmployee.id),
              },
              {
                  page: pages.manageLeaves,
                  icon: <LeavesIcon />,
                  label: t('sidebar.manage_leaves'),
                  canSee: canSeeOtherEmployeeLeaves(realm.realmFeatures, grantedPolicies, currentEmployee.id),
              },
              {
                  page: pages.planning,
                  // PlanningIcon size seams to be wrong, so we use size 23
                  icon: <PlanningIcon size={23} />,
                  label: t('sidebar.planning'),
                  canSee: canViewShifts(realm.realmFeatures, grantedPolicies),
              },
              {
                  page: pages.timesheets,
                  icon: <TimesheetsIcon />,
                  label: t('sidebar.timesheets'),
                  canSee: canManageOtherEmployeeTimesheets(realm.realmFeatures, grantedPolicies, currentEmployee.id),
              },
              {
                  page: pages.companyDocuments,
                  icon: <DocumentIcon />,
                  label: t('sidebar.company_documents'),
                  canSee: canViewCompanyDocuments(realm.realmFeatures, grantedPolicies),
              },
              {
                  page: pages.payroll,
                  icon: <PayrollIcon />,
                  label: t('sidebar.payroll'),
                  canSee: canManagePayroll(realm.realmFeatures, grantedPolicies, currentEmployee.id),
              },
              {
                  page: pages.surveyTemplates,
                  icon: <DocumentValidationIcon />,
                  label: t('sidebar.surveys'),
                  canSee: canSeeSurveyResults(realm.realmFeatures, grantedPolicies),
              },
              {
                  page: pages.reports,
                  icon: <ReportIcon />,
                  label: t('sidebar.reports'),
                  canSee: canViewReports(realm.realmFeatures, grantedPolicies),
              },
              {
                  page: pages.reviews,
                  icon: <ReviewsIcon />,
                  label: t('sidebar.reviews'),
                  canSee:
                      canAccessOtherEmployeeReviews(realm.realmFeatures, grantedPolicies, currentEmployee.id) ||
                      canViewReviews(realm.realmFeatures, grantedPolicies),
              },
              {
                  page: pages.objectives,
                  icon: <ObjectivesIcon />,
                  label: t('sidebar.objectives'),
                  canSee: canManageOtherEmployeeObjectives(realm.realmFeatures, grantedPolicies, currentEmployee.id),
              },
              {
                  page: pages.settings,
                  icon: <Settings02Icon />,
                  label: t('sidebar.company_settings'),
                  canSee: canConfigureCompanySettings(grantedPolicies),
              },
          ].filter(({ canSee }) => canSee)
        : [];

    const itemProps: SxProps<Theme> = {
        height: '36px',
        marginY: 0.25,
        paddingX: 0,
        borderRadius: ({ shape }) => `${shape.borderRadius}px`,
        '&:hover': ({ palette }) => ({
            backgroundColor: palette.primary.light,
            color: palette.primary.dark,
            fill: palette.primary.dark,
        }),
        '&.Mui-selected': ({ palette }) => ({
            backgroundColor: palette.primary.main,
            color: palette.common.white,
            fill: palette.common.white,
        }),
        '&.Mui-selected:hover, &.Mui-selected:focus': ({ palette }) => ({
            backgroundColor: palette.primary.main,
            color: palette.common.white,
            fill: palette.common.white,
        }),
        transition: { duration: '0.3s' },
    };

    const isSelectedMenu = (page: Page) => {
        if (pages.home === page && location.pathname === '/') {
            return true;
        }
        return (
            pages.home !== page &&
            (location.pathname.startsWith(page.root) ||
                // if we are on the profile page, we need to check if we are on the my profile or people page
                (pages.myProfile === page && /^\/profile\/\D/.test(location.pathname)) ||
                (pages.people === page && /^\/profile\/\d/.test(location.pathname)))
        );
    };

    const handleMenuToggle = () => {
        setExpandedMenu(!expandedMenu);
    };

    return (
        <>
            <Toolbar disableGutters sx={{ paddingLeft: 1 }}>
                <LogoRoger variant='light' logoOnly={!expandedMenu} />
            </Toolbar>
            <Stack flex='1' flexWrap='nowrap' px={1} justifyContent='space-between'>
                <List>
                    {list.map(({ page, icon, label }) => (
                        <Fragment key={page.pathname}>
                            {page === pages.settings && <Divider />}
                            <ListItemButton
                                component={Link}
                                to={page.pathname}
                                sx={itemProps}
                                dense
                                selected={isSelectedMenu(page)}
                                aria-label={label}
                                title={label}
                            >
                                <Stack direction='row' flex={1} gap={1} alignItems='center' p={1} flexWrap='nowrap'>
                                    <Box display='flex'>{icon}</Box>
                                    {expandedMenu && (
                                        <Fade
                                            in={expandedMenu}
                                            timeout={{
                                                enter: 300,
                                            }}
                                        >
                                            <ListItemText
                                                primaryTypographyProps={{ color: 'inherit' }}
                                                sx={{
                                                    marginY: 0,
                                                }}
                                            >
                                                {label}
                                            </ListItemText>
                                        </Fade>
                                    )}
                                </Stack>
                            </ListItemButton>
                        </Fragment>
                    ))}
                </List>

                <Stack mb={2} ml={1}>
                    <CircleArrowLeft02Icon
                        onClick={handleMenuToggle}
                        style={{
                            transform: expandedMenu ? 'rotate(0deg)' : 'rotate(-180deg)',
                            cursor: 'pointer',
                        }}
                    />
                </Stack>
            </Stack>
        </>
    );
};

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});
