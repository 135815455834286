import { ConfirmPopover } from '@/components/confirmation-popover/ConfirmPopover';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { IntegrationType, ThirdPartyPublicApiIntegrationScope } from '@/domain/third-party/ThirdParty.model';
import { ThirdPartyConnector } from '@/page/setting/third-party/third-party-setting-page/ThirdPartySettingPage';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type ThirdPartyConnectorDialogProps = DialogWrapperProps & {
    connector?: ThirdPartyConnector;
    onSave: (connector: ThirdPartyConnectorFormValues) => void;
    onGenerateApiKey?: () => void;
    publicApiOptionDisabled?: boolean;
};

export const ThirdPartyConnectorDialog: FC<ThirdPartyConnectorDialogProps> = ({
    connector,
    onSave,
    onGenerateApiKey = () => {},
    publicApiOptionDisabled = false,
    ...rest
}) => {
    const { t } = useTranslation();

    // This is to avoid to create two public api connectors
    const connectorTypeFallback = publicApiOptionDisabled ? undefined : 'PUBLIC_API';
    const defaultConnectorType = connector && 'integrationType' in connector ? connector.integrationType : connectorTypeFallback;
    const defaultScopes = connector && 'scopes' in connector ? connector.scopes : [];
    const defaultExpireAt = connector && 'scopes' in connector ? connector.expireAt : getNull();
    const defaultConfiguration = connector && 'configuration' in connector ? connector.configuration : '';

    const { control, handleSubmit, watch } = useForm<ThirdPartyConnectorFormValues>({
        resolver: yupResolver(thirdPartyConnectorSchema),
        defaultValues: {
            type: defaultConnectorType,
            scopes: defaultScopes,
            expireAt: defaultExpireAt,
            configuration: defaultConfiguration,
        },
    });

    const connectorTypesOptions = ['PUBLIC_API', ...Object.values(IntegrationType)] as const;

    const connectorScopesOptions = Object.values(ThirdPartyPublicApiIntegrationScope);

    const isExistingConnector = !!connector?.id;

    const connectorType = watch('type');
    const isPublicApiConnector = connectorType === 'PUBLIC_API';

    const isExistingPublicApiConnector = isPublicApiConnector && isExistingConnector;

    return (
        <DialogWrapper header={t('third_party.connectors.dialog_title')} {...rest}>
            <Stack component={DialogContent} gap={2}>
                <Controller
                    name='type'
                    control={control}
                    render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                        <FormControlLabel
                            label={t('third_party.connectors.type')}
                            control={
                                <Autocomplete
                                    fullWidth
                                    value={value ?? getNull()}
                                    options={connectorTypesOptions}
                                    getOptionDisabled={type => type === 'PUBLIC_API' && publicApiOptionDisabled}
                                    getOptionLabel={type => t('third_party_setting_page.connectors_type', { context: type, defaultValue: type })}
                                    onChange={(_, data) => onChange(data)}
                                    renderInput={params => <TextField {...params} error={!!error} helperText={error?.message} />}
                                    disableClearable
                                    // We don't want to allow type switching for existing connectors
                                    disabled={isExistingConnector}
                                    {...rest}
                                />
                            }
                        />
                    )}
                />

                {isPublicApiConnector && (
                    <>
                        <Controller
                            name='scopes'
                            control={control}
                            render={({ field: { value, onChange, ...rest }, fieldState: { error } }) => (
                                <FormControlLabel
                                    label={t('third_party.connectors.scopes')}
                                    control={
                                        <Autocomplete
                                            fullWidth
                                            size='small'
                                            multiple
                                            value={value}
                                            options={connectorScopesOptions}
                                            getOptionLabel={scope => t('third_party.connectors.scope_type', { context: scope, defaultValue: scope })}
                                            onChange={(_, data) => onChange(data)}
                                            renderInput={params => <TextField {...params} error={!!error} helperText={error?.message} />}
                                            limitTags={5}
                                            {...rest}
                                        />
                                    }
                                />
                            )}
                        />

                        <FormControlLabel label={t('third_party.connectors.expire_at')} control={<FieldLocalDate name='expireAt' control={control} />} />
                    </>
                )}

                {!isPublicApiConnector && (
                    <Controller
                        name='configuration'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControlLabel
                                label={t('third_party.connectors.configuration')}
                                control={
                                    <TextField
                                        placeholder={t('third_party.connectors.configuration_placeholder')}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        {...field}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                }
                            />
                        )}
                    />
                )}

                {isExistingPublicApiConnector && (
                    <ConfirmPopover onConfirm={onGenerateApiKey} elevation={2} content={t('third_party.connectors.confirm_to_generate_api_key')}>
                        <Button variant='text' sx={{ width: 'fit-content' }}>
                            {t('third_party.connectors.generate_api_key')}
                        </Button>
                    </ConfirmPopover>
                )}
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(onSave, console.error)}>{t('general.save')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const scopeSchema = yup.string().required().oneOf(Object.values(ThirdPartyPublicApiIntegrationScope));

const thirdPartyConnectorSchema = yup.object().shape({
    type: yup
        .string()
        .required()
        .oneOf(['PUBLIC_API', ...Object.values(IntegrationType)] as const),
    scopes: yup.array().of(scopeSchema).required(),
    expireAt: yup
        .string<LocalDate>()
        .test(getLocalDateTestConfig())
        .nullable()
        .default(getNull())
        .when('type', {
            is: 'PUBLIC_API',
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
    configuration: yup
        .string()
        .nullable()
        .default(getNull())
        .when('type', {
            is: 'PUBLIC_API',
            then: schema => schema,
            otherwise: schema => schema.required(),
        }),
});

export type ThirdPartyConnectorFormValues = yup.InferType<typeof thirdPartyConnectorSchema>;
