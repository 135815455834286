import { SectionField } from '@/components/section/types';
import { Gender, MaritalStatus } from '@/domain/employee/Employee.model';
import { getGenderTranslationKey, getMaritalStatusTranslationKey } from '@/domain/employee/Employee.service';
import { EmployeePersonalInfo } from '@/domain/employee/EmployeePersonalInfo.model';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { getCountry } from '@/utils/countries.util';
import { differenceInYears, getCurrentLocalDate } from '@/utils/datetime.util';

import { convertEnumToMap } from '@/utils/enum.util';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export const useEmployeePersonalInfoSectionFields = (personalInfo: EmployeePersonalInfo | undefined): SectionField[] => {
    const { t } = useTranslation();

    if (!personalInfo) {
        return [];
    }
    return [
        {
            formValueName: 'birthdate',
            title: t('employee.birthdate'),
            stringValue: personalInfo?.birthdate ? getAgeDisplay(personalInfo?.birthdate) : '',
            dateValue: personalInfo?.birthdate,
            type: SectionFieldType.BIRTHDAY,
            fieldType: 'EMPLOYEE_BIRTH_DATE',
        },
        {
            formValueName: 'gender',
            stringValue: personalInfo?.gender ? t(getGenderTranslationKey(), { context: personalInfo?.gender }) : undefined,
            value: personalInfo?.gender ?? '',
            title: t('employee.gender'),
            enumList: convertEnumToMap(Gender, key => t(getGenderTranslationKey(), { context: key })),
            type: SectionFieldType.ENUM_LIST_ITEM,
            fieldType: 'EMPLOYEE_GENDER',
        },
        {
            formValueName: 'maritalStatus',
            stringValue: personalInfo?.maritalStatus ? t(getMaritalStatusTranslationKey(), { context: personalInfo?.maritalStatus }) : undefined,
            value: personalInfo?.maritalStatus ?? '',
            title: t('employee.maritalStatus'),
            enumList: convertEnumToMap(MaritalStatus, key => t(getMaritalStatusTranslationKey(), { context: key })),
            type: SectionFieldType.ENUM_LIST_ITEM,
            fieldType: 'EMPLOYEE_MARITAL_STATUS',
        },
        {
            formValueName: 'maritalStatusSince',
            title: t('employee.maritalStatus_since'),
            dateValue: personalInfo?.maritalStatusSince,
            type: SectionFieldType.DATE,
            fieldType: 'EMPLOYEE_MARITAL_STATUS_SINCE',
        },
        {
            formValueName: 'nationality',
            title: t('employee.nationality'),
            countryValue: personalInfo?.nationality ? getCountry(personalInfo?.nationality) : undefined,
            type: SectionFieldType.COUNTRY,
            fieldType: 'EMPLOYEE_NATIONALITY',
        },
        {
            formValueName: 'avsNumber',
            title: t('employee.avs_number'),
            stringValue: personalInfo?.avsNumber ?? '',
            type: SectionFieldType.AVS_NUMBER,
            fieldType: 'EMPLOYEE_AVS',
        },
        {
            formValueName: 'personalPhoneNumber',
            title: t('employee.personal_phone_number'),
            stringValue: personalInfo?.personalPhoneNumber ?? '',
            type: SectionFieldType.PHONE_NUMBER,
            fieldType: 'EMPLOYEE_PERSONAL_PHONE_NUMBER',
        },
        {
            formValueName: 'personalEmail',
            title: t('employee.personal_email'),
            stringValue: personalInfo?.personalEmail ?? '',
            type: SectionFieldType.STRING,
            fieldType: 'EMPLOYEE_PERSONAL_EMAIL',
        },
    ];
};

export const getAgeDisplay = (birthdate: LocalDate): string => {
    return '(' + differenceInYears(getCurrentLocalDate(), birthdate) + ' ' + i18next.t('employee.years_old') + ')';
};
