import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionField } from '@/components/section/types';
import { Gender, MaritalStatus } from '@/domain/employee/Employee.model';
import { getGenderTranslationKey, getMaritalStatusTranslationKey } from '@/domain/employee/Employee.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { convertEnumToMap } from '@/utils/enum.util';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type PersonalInfosSectionProps = {
    baseProfileFormValues?: Partial<OnboardingProfileStepFormValues>;
};

export const PersonalInfosSection: FC<PersonalInfosSectionProps> = ({ baseProfileFormValues }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();

    const personalInfosFields: SectionField[] = [
        {
            formValueName: 'birthdate',
            title: t('employee.birthdate'),
            dateValue: baseProfileFormValues?.birthdate ?? undefined,
            type: SectionFieldType.DATE,
            required: false,
            fieldType: 'EMPLOYEE_BIRTH_DATE',
        },
        {
            formValueName: 'gender',
            stringValue: baseProfileFormValues?.gender ? t(getGenderTranslationKey(), { context: baseProfileFormValues?.gender }) : undefined,
            value: baseProfileFormValues?.gender ?? '',
            title: t('employee.gender'),
            enumList: convertEnumToMap(Gender, key => t(getGenderTranslationKey(), { context: key })),
            type: SectionFieldType.ENUM_LIST_ITEM,
            required: false,
            fieldType: 'EMPLOYEE_GENDER',
        },
        {
            formValueName: 'nationality',
            title: t('employee.nationality'),
            countryValue: baseProfileFormValues?.nationality ?? undefined,
            type: SectionFieldType.COUNTRY,
            required: false,
            fieldType: 'EMPLOYEE_NATIONALITY',
        },
        {
            formValueName: 'maritalStatus',
            stringValue: baseProfileFormValues?.maritalStatus
                ? t(getMaritalStatusTranslationKey(), { context: baseProfileFormValues?.maritalStatus })
                : undefined,
            value: baseProfileFormValues?.maritalStatus ?? '',
            title: t('employee.maritalStatus'),
            enumList: convertEnumToMap(MaritalStatus, key => t(getMaritalStatusTranslationKey(), { context: key })),
            type: SectionFieldType.ENUM_LIST_ITEM,
            required: false,
            fieldType: 'EMPLOYEE_MARITAL_STATUS',
        },
    ];

    const maritalStatus = watch('maritalStatus');
    if (maritalStatus) {
        personalInfosFields.push({
            formValueName: 'maritalStatusSince',
            title: t('employee.maritalStatus_since'),
            dateValue: baseProfileFormValues?.maritalStatusSince ?? undefined,
            type: SectionFieldType.DATE,
            required: false,
            fieldType: 'EMPLOYEE_MARITAL_STATUS_SINCE',
        });
    }

    // Keep avsNumber position in the array to avoid to have avsNumber field in the middle of the form
    // TODO : Find a better way to handle order of fields in the form
    personalInfosFields.push({
        formValueName: 'avsNumber',
        title: t('employee.avs_number'),
        value: baseProfileFormValues?.avsNumber ?? '',
        type: SectionFieldType.AVS_NUMBER,
        required: false,
        fieldType: 'EMPLOYEE_AVS',
    });

    return (
        <SectionContainer title={t('employee.sections.personal_info')}>
            {!!personalInfosFields?.length &&
                personalInfosFields.map(personalInfosField => {
                    return (
                        <SectionFieldContainer
                            key={personalInfosField.title}
                            formValueName={personalInfosField.formValueName ?? ''}
                            title={personalInfosField.title ?? ''}
                            required={personalInfosField.required}
                        >
                            {<EditableSectionFieldComponent field={personalInfosField} />}
                        </SectionFieldContainer>
                    );
                })}
        </SectionContainer>
    );
};
