import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReview, EmployeeReviewer, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import {
    cancelEmployeeReview,
    closeEmployeeReview,
    employeeSelfReviewDone,
    getEmployeeReviewContributorType,
    getEmployeeReviewNavigationLink,
    isEmployeeReviewCancelled,
    isEmployeeReviewClosed,
    isEmployeeReviewInputNeeded,
    isEmployeeReviewStarted,
    isEmployeeReviewSubmitted,
    isManagerReviewDone,
    isManagerReviewSubmitted,
    resetEmployeeReview,
    startEmployeeReviewDiscussion,
} from '@/domain/employee-review/EmployeeReview.service';
import { Employee } from '@/domain/employee/Employee.model';
import { isManagerReviewRequired } from '@/domain/review/Review.service';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { FormControlLabel, Stack, TextField } from '@mui/material';
import { Tick02Icon } from 'hugeicons-react';
import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmployeeReviewStartDiscussionDialog } from '../employee-review-start-discussion-dialog/EmployeeReviewStartDiscussionDialog';
import { MoreVerticalIcon } from '@/assets/icons/Icons';
import { EmployeeReviewSummaryStatusChip } from '@/page/review/employee-review-summary-status-chip/EmployeeReviewSummaryStatusChip';
import { EmployeeReviewUpdateReviewersDialog } from '@/page/review/employee-review-update-reviewers-dialog/EmployeeReviewUpdateReviewersDialog';
import { EmployeeReviewPreviewDialog } from '@/page/review/employee-review-preview-dialog/EmployeeReviewPreviewDialog';

type EmployeeReviewCycleListProps = {
    employeeReviews: EmployeeReview[];
    filterControls?: ReactElement;
    onUpdate: () => void;
    onUpdateReviewers: (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => void;
    agGridWrapper: ReturnType<typeof useAgGridWrapper<EmployeeReview>>;
    isSearchAvailable?: boolean;
    showReviewNameColumn?: boolean;
    showContextMenu?: boolean;
    disableSelection?: boolean;
};

// TODO: this component is probably redundant with EmployeeReviews.tsx and maybe also with EmployeeReviewTaskTable/EmployeeReviewProfileTable ?
// why we have so many components that are so similar?
export const EmployeeReviewCycleList: FC<EmployeeReviewCycleListProps> = ({
    isSearchAvailable = false,
    filterControls,
    employeeReviews,
    onUpdate,
    onUpdateReviewers,
    agGridWrapper,
    showReviewNameColumn = false,
    showContextMenu = false,
    disableSelection = false,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [employeeReview, setEmployeeReview] = useState<EmployeeReview>();
    const [openStartDiscussionDialog, setOpenStartDiscussionDialog] = useState<boolean>(false);

    const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);
    const [updateReviewersDialogOpen, setUpdateReviewersDialogOpen] = useState<boolean>(false);

    const [reviewToClose, setReviewToClose] = useState<EmployeeReview>();
    // TODO put this state in the dialog
    const [comment, setComment] = useState<string>('');
    const [activeEmployeeReview, setActiveEmployeeReview] = useState<EmployeeReview>();

    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);

    const hasPeers = employeeReviews?.some(review => review?.peerReviewers?.length > 0);
    const hasUpwards = employeeReviews?.some(review => review?.upwardReviewers?.length > 0);
    const reviewNameColumn: RogerColDef<EmployeeReview> = {
        field: 'review.name',
        headerName: t('reviews.employee_reviews_table.review_name'),
    };

    const getReviewersRatio = (reviewers: EmployeeReviewer[]) => {
        const totalReviewers = reviewers.length;
        const reviewedReviewers = reviewers.filter(reviewer => reviewer.reviewed).length;

        if (totalReviewers === 0) {
            return '';
        }
        return `${reviewedReviewers}/${totalReviewers}`;
    };

    const peerReviewsColumn: RogerColDef<EmployeeReview> = {
        field: 'peerReviewers',
        headerName: t('reviews.employee_reviews_table.peer_reviews'),
        valueGetter: ({ data }) => (data?.peerReviewers ? getReviewersRatio(data.peerReviewers) : ''),
    };

    const upwardReviewsColumn: RogerColDef<EmployeeReview> = {
        field: 'upwardReviewers',
        headerName: t('reviews.employee_reviews_table.upward_reviews'),
        valueGetter: ({ data }) => (data?.upwardReviewers ? getReviewersRatio(data.upwardReviewers) : ''),
    };

    const endDateColumn: RogerColDef<EmployeeReview> = {
        field: 'review.endDate',
        headerName: t('reviews.employee_reviews_table.end_date'),
        type: 'date',
    };

    const contextMenuRender = ({ data }: { data: EmployeeReview }) => {
        const menuItems = [
            {
                title: t('reviews.employee_review.preview_template'),
                onClick: () => {
                    setActiveEmployeeReview(data);
                    setPreviewDialogOpen(true);
                },
            },
            {
                title: t('reviews.employee_review.update_reviewers'),
                onClick: () => {
                    setActiveEmployeeReview(data);
                    setUpdateReviewersDialogOpen(true);
                },
            },
        ];

        if (!isEmployeeReviewCancelled(data.status) && !isEmployeeReviewClosed(data.status)) {
            menuItems.unshift({
                title: t('reviews.employee_review.close'),
                onClick: () => {
                    setReviewToClose(data);
                },
            });
        }

        if (isEmployeeReviewInputNeeded(data.status)) {
            menuItems.unshift({
                title: t('reviews.employee_review.cancel'),
                onClick: () => {
                    onCancelEmployeeReview(data.id);
                },
            });

            const includeManagerReview = employeeReview?.review?.contributorTypes?.includes('MANAGER');
            const isInputNeeded = employeeReview?.status === EmployeeReviewStatus.INPUT_NEEDED;
            const includePreparationStep = employeeReview?.review?.includePreparationStep;
            const everyFeedbackNeededDone =
                currentEmployee &&
                includePreparationStep &&
                employeeSelfReviewDone(employeeReview) &&
                ((includeManagerReview && isManagerReviewDone(employeeReview, currentEmployee)) || !includeManagerReview);

            const showStartDiscussion = isInputNeeded && (everyFeedbackNeededDone || !includePreparationStep);

            if (showStartDiscussion) {
                menuItems.unshift({
                    title: t('reviews.employee_review.start_discussion'),
                    onClick: () => {
                        onStartDiscussion(data);
                    },
                });
            }
        }

        if (isEmployeeReviewStarted(data.status) || isEmployeeReviewSubmitted(data.status)) {
            menuItems.unshift({
                title: t('reviews.employee_review.back_to_input_needed'),
                onClick: () => {
                    onResetEmployeeReview(data.id, EmployeeReviewStatus.INPUT_NEEDED);
                },
            });
        }

        if (isEmployeeReviewSubmitted(data.status)) {
            menuItems.unshift({
                title: t('reviews.employee_review.back_to_discussion_started'),
                onClick: () => {
                    onResetEmployeeReview(data.id, EmployeeReviewStatus.DISCUSSION_STARTED);
                },
            });
        }

        return <BasicMenu items={menuItems} endIcon={<MoreVerticalIcon />} />;
    };

    const contextMenuColumn: RogerColDef<EmployeeReview> = {
        type: 'actionMenu',
        cellRenderer: ({ data }) => (data ? contextMenuRender({ data }) : undefined),
    };

    const statusColumn: RogerColDef<EmployeeReview> = {
        field: 'status',
        headerName: t('reviews.employee_review.status'),
        cellRenderer: StatusCell,
    };

    const addReviewerColumns = (reviewerColumns: RogerColDef<EmployeeReview>[]) => {
        if (hasPeers) {
            reviewerColumns.push(peerReviewsColumn);
        }
        if (hasUpwards) {
            reviewerColumns.push(upwardReviewsColumn);
        }
        reviewerColumns.push(endDateColumn);
    };

    const renderDynamicColumns = (): RogerColDef<EmployeeReview>[] => {
        const reviewerColumns: RogerColDef<EmployeeReview>[] = [];
        addReviewerColumns(reviewerColumns);

        if (showReviewNameColumn) {
            reviewerColumns.push(reviewNameColumn);
        }
        reviewerColumns.push(statusColumn);
        if (showContextMenu) {
            reviewerColumns.push(contextMenuColumn);
        }
        return reviewerColumns;
    };

    const openDiscussionDialog = (employeeReview: EmployeeReview) => {
        setEmployeeReview(employeeReview);
        setOpenStartDiscussionDialog(true);
    };

    const shouldShowDiscussionDialog = (employeeReview: EmployeeReview, currentEmployee: Employee) => {
        const contributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);
        const isInInputNeededStatus = employeeReview.status === EmployeeReviewStatus.INPUT_NEEDED;
        const isContributorTypeManager = contributorType === 'MANAGER';

        if (!employeeReview.review) {
            throw new Error('Employee review review should not be undefined');
        }

        return (
            isContributorTypeManager &&
            isInInputNeededStatus &&
            !(isManagerReviewRequired(employeeReview.review) && !isManagerReviewSubmitted(employeeReview, currentEmployee))
        );
    };

    const handleRowClick = (employeeReview: EmployeeReview | undefined, currentEmployee: Employee) => {
        if (!employeeReview) {
            throw new Error('Employee review should not be undefined');
        }

        const link = getEmployeeReviewNavigationLink(employeeReview, currentEmployee);
        if (shouldShowDiscussionDialog(employeeReview, currentEmployee)) {
            openDiscussionDialog(employeeReview);
            return;
        }
        navigate(link);
    };

    const tableOptions: RogerColDef<EmployeeReview>[] = [
        {
            type: 'selection',
            hide: disableSelection,
        },
        {
            field: 'employee',
            headerName: '',
            type: 'avatar',
        },
        {
            field: 'employee.firstName',
            headerName: t('general.firstName'),
        },
        {
            field: 'employee.lastName',
            headerName: t('general.lastName'),
        },
        {
            headerName: t('reviews.employee_reviews_table.manager'),
            valueGetter: ({ data }) => data?.managers.flatMap(manager => manager.reviewer),
            type: 'stackedAvatars',
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },
        {
            field: 'managers',
            headerName: t('reviews.employee_reviews_table.reviewed_by_one_manager'),
            cellRenderer: ManagerReviewedCell,
            comparator: (managers: EmployeeReviewer[]) => (managers.some(manager => manager.reviewed) ? -1 : 1),
            cellClass: 'display-flex',
        },
        {
            field: 'selfReviewed',
            headerName: t('reviews.employee_reviews_table.self_review'),
            cellRenderer: SelfReviewedCell,
            comparator: (selfReviewed: boolean) => (selfReviewed ? -1 : 1),
            cellClass: 'display-flex',
        },
        ...renderDynamicColumns(),
    ];

    const onStartDiscussion = (employeeReview: EmployeeReview) => {
        startEmployeeReviewDiscussion(employeeReview.id)
            .then(() => {
                showSnackbar(t('reviews.review_summary.messages.discussion_started'), 'success');
                setOpenStartDiscussionDialog(false);
                navigate(`/reviews/${employeeReview.id}/manager-summary`);
            })
            .catch(handleError);
    };

    const onCancelEmployeeReview = (employeeReviewId: number) => {
        cancelEmployeeReview(employeeReviewId)
            .then(data => {
                const reviewIndex = employeeReviews?.findIndex(review => review.id === data.id);
                employeeReviews[reviewIndex] = data;
                onUpdate();
                showSnackbar(t('reviews.messages.employee_review_cancelled'), 'success');
            })
            .catch(handleError);
    };

    const onResetEmployeeReview = (employeeReviewId: number, status: EmployeeReviewStatus) => {
        resetEmployeeReview(employeeReviewId, status)
            .then(data => {
                const reviewIndex = employeeReviews?.findIndex(review => review.id === data.id);
                employeeReviews[reviewIndex] = data;
                onUpdate();
                showSnackbar(t('reviews.messages.employee_review_updated'), 'success');
            })
            .catch(handleError);
    };

    const onReviewersChange = (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => {
        onUpdateReviewers(request, employeeReviewId);
        setUpdateReviewersDialogOpen(false);
    };

    const handleReviewClose = async () => {
        try {
            if (!reviewToClose?.id) {
                throw new Error('Review to close should not be undefined');
            }

            await closeEmployeeReview(reviewToClose.id, comment);
            setReviewToClose(undefined);
            setComment('');

            const reviewIndex = employeeReviews?.findIndex(review => review.id === reviewToClose.id);

            employeeReviews[reviewIndex].status = EmployeeReviewStatus.CLOSED;
            onUpdate();
            showSnackbar(t('reviews.messages.employee_review_closed'), 'success');
        } catch {
            showSnackbar(t('reviews.manage_reviews.close_review_error'), 'error');
        }
    };

    return (
        <Stack flex={1} gap={2}>
            <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                <Stack>{filterControls}</Stack>
                {isSearchAvailable && <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} />}
            </Stack>
            <AgGridWrapper<EmployeeReview>
                rowData={employeeReviews}
                columnDefs={tableOptions}
                initRef={agGridWrapper.setGridRef}
                rowSelection={disableSelection ? undefined : 'multiple'}
                getRowId={params => params.data.id.toString()}
                onRowClicked={({ data, event }) => {
                    if (!event || !currentEmployee) {
                        return;
                    }

                    if (!event.defaultPrevented && data?.status !== EmployeeReviewStatus.CANCELLED) {
                        return handleRowClick(data, currentEmployee);
                    }
                }}
            />
            {activeEmployeeReview && (
                <EmployeeReviewPreviewDialog open={previewDialogOpen} activeReview={activeEmployeeReview} onClose={() => setPreviewDialogOpen(false)} />
            )}
            {updateReviewersDialogOpen && activeEmployeeReview && (
                <EmployeeReviewUpdateReviewersDialog
                    open={updateReviewersDialogOpen}
                    activeEmployeeReview={activeEmployeeReview}
                    onSave={onReviewersChange}
                    onClose={() => setUpdateReviewersDialogOpen(false)}
                />
            )}
            {employeeReview && openStartDiscussionDialog && (
                <EmployeeReviewStartDiscussionDialog
                    employeeReview={employeeReview}
                    onStartDiscussion={() => onStartDiscussion(employeeReview)}
                    onClose={() => setOpenStartDiscussionDialog(false)}
                />
            )}
            {reviewToClose && (
                <DialogContainer open onClose={() => setReviewToClose(undefined)} onSave={handleReviewClose} title={t('reviews.manage_reviews.close_review')}>
                    <Stack>
                        <FormControlLabel
                            label={t('general.comment')}
                            labelPlacement='top'
                            value={comment}
                            control={<TextField fullWidth onChange={event => setComment(event.target.value)} InputProps={{ multiline: true, minRows: 4 }} />}
                        />
                    </Stack>
                </DialogContainer>
            )}
        </Stack>
    );
};
const ManagerReviewedCell = ({ value }: { value: EmployeeReviewer[] }) => {
    const reviewed = value.some(manager => manager.reviewed);
    if (reviewed) {
        return <Tick02Icon />;
    }
};

const SelfReviewedCell = ({ value }: { value: boolean }) => {
    return value && <Tick02Icon />;
};

const StatusCell = ({ value }: { value: EmployeeReview['status'] }) => <EmployeeReviewSummaryStatusChip status={value} />;
