import { EditableStackSection } from '@/components/section/StackSectionComponent/EditableStackSection';
import { StackSection } from '@/components/section/StackSectionComponent/StackSection';
import { SectionActionButton } from '@/components/section/types';
import { FC, useState } from 'react';
import { getLabelTranslation } from '@/utils/language.util';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { EmployeeSection } from '@/domain/employee-section/EmployeeSection.model';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getSectionAction } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { useAppSelector } from '@/stores/store';
import { canManageEmployeeCustomSections, canManagePendingEmployeeCustomSections } from '@/domain/permission/Permission.service';
import { EmployeeProfileChangeRow } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { convertEmployeeSectionFieldsToSectionFields, getSectionActionButton } from '@/domain/employee/Employee.service';
import {
    CustomSectionRowFormValues,
    useCustomSectionRowSchema,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeCustomSectionRowDialog.hook';
import { EmployeeSectionRowFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeCustomSectionRowDialog';

export const EmployeeCustomStackSection: FC<{
    employeeSection: EmployeeSection;
    sectionDefinition: SectionDefinition;
    onSave(rowId?: number): (values: CustomSectionRowFormValues) => Promise<void>;
    currentSectionRow: EmployeeSectionRowFormValues;
    pendingRow: EmployeeProfileChangeRow;
}> = ({ employeeSection, sectionDefinition, onSave, currentSectionRow, pendingRow }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [editable, setEditable] = useState(false);

    const fields = convertEmployeeSectionFieldsToSectionFields(employeeSection.id, sectionDefinition?.fields, currentSectionRow?.fields);

    const pendingFields = convertEmployeeSectionFieldsToSectionFields(employeeSection.id, sectionDefinition?.fields, pendingRow?.fields);

    const { schema } = useCustomSectionRowSchema({ sectionDefinition });

    const hasPendingChanges = !!pendingRow?.fields;
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const canApprove = canManageEmployeeCustomSections(policies, employeeSection.employeeId, sectionDefinition.id);
    const canRequestApproval = canManagePendingEmployeeCustomSections(policies, employeeSection.employeeId, sectionDefinition.id);

    const action = getSectionAction({ canApprove, canRequestApproval, hasPendingChanges });

    const openMutationDialogButton: SectionActionButton = {
        title: t('general.edit'),
        onClick: () => {
            setEditable(true);
        },
    };

    const seePendingButton: SectionActionButton = {
        title: t('employee.sections.see_pending_changes'),
        onClick: () => {
            navigate('/people/employee-requests');
        },
    };

    const handleSave = (rowId?: number) => async (values: CustomSectionRowFormValues) => {
        await onSave(rowId)(values);
        setEditable(false);
    };

    const actionButton = getSectionActionButton(action, openMutationDialogButton, seePendingButton);

    if (editable) {
        return (
            <EditableStackSection
                sectionTitle={getLabelTranslation(sectionDefinition.name)}
                fields={action === 'EDIT_PENDING' ? pendingFields : fields}
                onSave={handleSave(action === 'EDIT' ? currentSectionRow?.id : pendingRow?.id)}
                onCancel={() => setEditable(false)}
                schema={schema}
            />
        );
    }

    return (
        <StackSection
            sectionTitle={getLabelTranslation(sectionDefinition.name)}
            fields={fields}
            actionButtons={actionButton ? [actionButton] : []}
            pendingFields={hasPendingChanges ? pendingFields : []}
        />
    );
};
