import { FileMetadata } from '@/components/file-picker-wrapper/FilePickerWrapper';
import { CreateAnnouncementMutation, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';
import { Department } from '@/domain/department/Department.model';
import { Location } from '@/domain/location/Location.model';
import { getLocalDateMinTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';

import { t } from 'i18next';
import * as yup from 'yup';

const imageSchema = yup
    .object<FileMetadata>()
    .shape({
        key: yup.string(),
        url: yup.string(),
        filename: yup.string().required(),
        itemId: yup.number(),
        mimetype: yup.string().required(),
    })
    .nullable()
    .default(undefined);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAnnouncementSchema = () => {
    return yup.object().shape({
        title: yup.string().required(),
        content: yup.string().required(),
        image: imageSchema,
        startDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        endDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .when('startDate', ([startDate], schema) =>
                schema.test(getLocalDateMinTestConfig(startDate, t('announcement.create_dialog.end_date_after_start_date'))),
            ),
        publishToAllDepartments: yup.boolean().required(),
        departments: yup
            .array<Department>()
            .required()
            .when('publishToAllDepartments', ([publishToAllDepartments], schema) => (publishToAllDepartments ? schema : schema.min(1))),
        publishToAllLocations: yup.boolean().required(),
        locations: yup
            .array<Location>()
            .required()
            .when('publishToAllLocations', ([publishToAllLocations], schema) => (publishToAllLocations ? schema : schema.min(1))),
    });
};

export type AnnouncementImageFormValues = yup.InferType<typeof imageSchema>;

export type AnnouncementFormValues = yup.InferType<ReturnType<typeof getAnnouncementSchema>>;

export const mapAnnouncementFormValuesToCreateMutation = (data: AnnouncementFormValues): CreateAnnouncementMutation => {
    const { image, departments, locations, publishToAllDepartments, publishToAllLocations, ...rest } = data;

    return {
        ...rest,
        image: image ?? undefined,
        departmentIds: publishToAllDepartments ? [] : departments.map(department => department.id),
        locationIds: publishToAllLocations ? [] : locations.map(location => location.id),
    };
};

export const mapAnnouncementFormValuesToEditMutation = (announcementId: number, data: AnnouncementFormValues): EditAnnouncementMutation => {
    const { image, departments, locations, publishToAllDepartments, publishToAllLocations, ...rest } = data;

    return {
        ...rest,
        id: announcementId,
        image: image ?? undefined,
        departmentIds: publishToAllDepartments ? [] : departments.map(department => department.id),
        locationIds: publishToAllLocations ? [] : locations.map(location => location.id),
    };
};
