import * as yup from 'yup';
import { ReviewArray, ReviewType } from '@/domain/review/Review.model';
import I18n from '@/i18n/i18n';
import { Label } from '@/domain/label/Label.model';
import { getLabelPropertyName, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import i18next from 'i18next';

export type ReviewTemplateFormType = AboutStepFormType &
    FormStepFormType &
    PostReviewEmployeeFeedbackStepFormType &
    PostReviewManagerFeedbackStepFormType &
    InvitationEmailFormType;
export type ReviewTemplateFormStepperType =
    | AboutStepFormType
    | FormStepFormType
    | PostReviewEmployeeFeedbackStepFormType
    | PostReviewManagerFeedbackStepFormType
    | InvitationEmailFormType;

export type InvitationEmailFormType = {
    managerSubject: Label;
    managerBody: Label;
    selfSubject?: Label;
    selfBody?: Label;
    peerSubject?: Label;
    peerBody?: Label;
    upwardSubject?: Label;
    upwardBody?: Label;
};
export type AboutStepFormType = {
    name: Label;
    reviewType: ReviewType;
    includePreparationStep: boolean;
    managerPreparationAccess: boolean;
    includeValidationStep: boolean;
    includePrivateManagerQuestions: boolean;
    includePrivateEmployeeQuestions: boolean;
    selfReview: boolean;
    managerPreparation: boolean;
    peerFeedback: boolean;
    upwardFeedback: boolean;
};
export type SectionBlockForm = {
    sectionName: Label;
    selfReview: boolean;
    managerPreparation: boolean;
    peerFeedback: boolean;
    upwardFeedback: boolean;
    blockType: 'SECTION';
    temporaryId: string;
    order: number;
};
export type QuestionBlockForm = {
    questionName: Label;
    selfReview: boolean;
    managerPreparation: boolean;
    peerFeedback: boolean;
    upwardFeedback: boolean;
    required: boolean;
    instructionEnabled: boolean;
    instruction: Label | undefined;
    reviewScaleEnabled: boolean;
    reviewRatingScaleId: number | null | undefined;
    blockType: 'QUESTION';
    temporaryId: string;
    order: number;
};
export type ObjectiveBlockForm = {
    selfReview: boolean;
    managerPreparation: boolean;
    peerFeedback: boolean;
    upwardFeedback: boolean;
    blockType: 'OBJECTIVES';
    temporaryId: string;
    order: number;
};
export type SkillsBlockForm = {
    selfReview: boolean;
    managerPreparation: boolean;
    peerFeedback: boolean;
    upwardFeedback: boolean;
    allSkillsRequired: boolean;
    minSkills: number | undefined;
    maxSkills: number | undefined;
    rangeSkillsRequired: boolean;
    blockType: 'SKILLS';
    temporaryId: string;
    order: number;
};
export type PrivateQuestionBlockForm = {
    questionName: Label;
    required: boolean;
    reviewScaleEnabled: boolean;
    instructionEnabled: boolean;
    instruction?: Label;
    reviewRatingScaleId?: number;
    blockType: 'PRIVATE_EMPLOYEE_QUESTION' | 'PRIVATE_MANAGER_QUESTION';
    temporaryId: string;
    order: number;
};
export type ReviewBlockForm = QuestionBlockForm | ObjectiveBlockForm | SkillsBlockForm | SectionBlockForm;
export type PostReviewManagerFeedbackStepFormType = {
    privateManagerQuestions: PrivateQuestionBlockForm[];
};
export type PostReviewEmployeeFeedbackStepFormType = {
    privateEmployeeQuestions: PrivateQuestionBlockForm[];
};
export type FormStepFormType = {
    reviewBlocksForm: ReviewBlockForm[];
};
type CommonBlock = {
    temporaryId: string;
    order: number;
    blockType: 'QUESTION' | 'OBJECTIVES' | 'SKILLS' | 'SECTION' | 'SKILL';
};
const sharedBlockSchema: yup.ObjectSchema<CommonBlock> = yup.object().shape({
    temporaryId: yup.string().required(),
    order: yup.number().required(),
    blockType: yup.string().trim().oneOf(['QUESTION', 'OBJECTIVES', 'SKILLS', 'SECTION', 'SKILL']).required(),
});

const getQuestionBlockFormSchema = (translationLanguage: UserLanguage): yup.ObjectSchema<QuestionBlockForm> => {
    return yup
        .object()
        .concat(sharedBlockSchema)
        .shape({
            blockType: yup.string().trim().oneOf(['QUESTION']).required(),
            questionName: getLabelFormSchema(translationLanguage),
            selfReview: yup.boolean().default(false),
            managerPreparation: yup.boolean().default(false),
            peerFeedback: yup.boolean().required(),
            upwardFeedback: yup.boolean().required(),
            required: yup.boolean().required(),
            instructionEnabled: yup.boolean().required().default(false),
            instruction: getLabelFormSchema(),
            reviewScaleEnabled: yup.boolean().required().default(false),
            reviewRatingScaleId: yup
                .number()
                .when(['reviewScaleEnabled'], {
                    is: true,
                    then: schema => schema.required(),
                })
                .default(undefined),
        });
};
const objectiveBlockFormSchema: yup.ObjectSchema<ObjectiveBlockForm> = yup
    .object()
    .concat(sharedBlockSchema)
    .shape({
        blockType: yup.string().trim().oneOf(['OBJECTIVES']).required(),
        reviewScaleEnabled: yup.boolean().required().default(false),
        selfReview: yup.boolean().required(),
        managerPreparation: yup.boolean().required(),
        peerFeedback: yup.boolean().required(),
        upwardFeedback: yup.boolean().required(),
    });
const skillsBlockFormSchema: yup.ObjectSchema<SkillsBlockForm> = yup
    .object()
    .concat(sharedBlockSchema)
    .shape({
        blockType: yup.string().trim().oneOf(['SKILLS']).required(),
        selfReview: yup.boolean().required(),
        managerPreparation: yup.boolean().required(),
        peerFeedback: yup.boolean().required(),
        upwardFeedback: yup.boolean().required(),
        allSkillsRequired: yup.boolean().required(),
        rangeSkillsRequired: yup.boolean().required(),
        minSkills: yup.number().when(['rangeSkillsRequired', 'allSkillsRequired'], {
            is: (rangeSkillsRequired: boolean, allSkillsRequired: boolean) => rangeSkillsRequired && !allSkillsRequired,
            then: schema =>
                schema
                    .min(1)
                    .max(6)
                    .required()
                    .test(
                        'minSkillsShouldBeLessThanMaxSkills',
                        I18n.t('reviews_settings_page.review_template_form.error_min_skills_should_be_less_than_max_skills'),
                        function (value) {
                            return value <= this.parent.maxSkills || !this.parent.maxSkills;
                        },
                    ),
        }),
        maxSkills: yup.number().when(['rangeSkillsRequired', 'allSkillsRequired'], {
            is: (rangeSkillsRequired: boolean, allSkillsRequired: boolean) => rangeSkillsRequired && !allSkillsRequired,
            then: schema => schema.min(1).max(6).required(),
        }),
    });

const getSectionBlockFormSchema = (translationLanguage: UserLanguage): yup.ObjectSchema<SectionBlockForm> => {
    return yup
        .object()
        .concat(sharedBlockSchema)
        .shape({
            blockType: yup.string().trim().oneOf(['SECTION']).required(),
            sectionName: getLabelFormSchema(translationLanguage),
            selfReview: yup.boolean().required(),
            managerPreparation: yup.boolean().required(),
            peerFeedback: yup.boolean().required(),
            upwardFeedback: yup.boolean().required(),
        });
};

export const getAboutStepSchema = (translationLanguage: UserLanguage): yup.ObjectSchema<AboutStepFormType> => {
    return yup
        .object()
        .shape({
            name: getLabelFormSchema(translationLanguage),
            reviewType: yup.string<ReviewType>().oneOf(ReviewArray).nullable().required(),
            managerPreparationAccess: yup.boolean().required(),
            includePreparationStep: yup.boolean().required(),
            includeValidationStep: yup.boolean().required(),
            includePrivateManagerQuestions: yup.boolean().required(),
            includePrivateEmployeeQuestions: yup.boolean().required(),
            selfReview: yup.boolean().required(),
            managerPreparation: yup.boolean().required(),
            peerFeedback: yup.boolean().required(),
            upwardFeedback: yup.boolean().required(),
        })
        .test({
            name: 'atLeastOneRequired',
            test: function (value) {
                const atLeastOneIsChecked = value?.selfReview || value?.managerPreparation || value?.peerFeedback || value?.upwardFeedback;
                const includePreparationStepChecked = value?.includePreparationStep;
                if (atLeastOneIsChecked || !includePreparationStepChecked) {
                    return true;
                }
                return this.createError({
                    message: I18n.t('reviews_settings_page.review_template_form.error_at_least_one_workflow_step'),
                    path: 'includePreparationStep',
                });
            },
        });
};

const getBlockSchema = (translationLanguage: UserLanguage): yup.Lazy<ReviewBlockForm> => {
    return yup.lazy((item: { blockType: string }) => {
        const { blockType } = item;

        switch (blockType) {
            case 'QUESTION':
                return getQuestionBlockFormSchema(translationLanguage) as unknown as yup.Lazy<ReviewBlockForm>;
            case 'OBJECTIVES':
                return objectiveBlockFormSchema as unknown as yup.Lazy<ReviewBlockForm>;
            case 'SKILLS':
                return skillsBlockFormSchema as unknown as yup.Lazy<ReviewBlockForm>;
            case 'SECTION':
                return getSectionBlockFormSchema(translationLanguage) as unknown as yup.Lazy<ReviewBlockForm>;
        }
        // todo find a way to fix this
        return getQuestionBlockFormSchema(translationLanguage) as unknown as yup.Lazy<ReviewBlockForm>;
    });
};

export const getFormStepSchema = (translationLanguage: UserLanguage): yup.ObjectSchema<FormStepFormType> => {
    return yup.object().shape({
        reviewBlocksForm: yup
            .array()
            .required()
            .of(getBlockSchema(translationLanguage))
            .min(1, I18n.t('reviews_settings_page.review_template_form.error_at_least_one_block'))
            .test({
                name: 'At least one question or objective or skill is checked for contributors if the preparation step of the contributor is required',
                test: function (value, context) {
                    const selfReviewRequired = context?.parent?.selfReview && context?.parent?.includePreparationStep;
                    const managerPreparationRequired = context?.parent?.managerPreparation && context?.parent?.includePreparationStep;
                    const peerFeedbackRequired = context?.parent?.peerFeedback && context?.parent?.includePreparationStep;
                    const upwardFeedbackRequired = context?.parent?.upwardFeedback && context?.parent?.includePreparationStep;

                    const noContributorPreparationRequired =
                        !selfReviewRequired && !managerPreparationRequired && !peerFeedbackRequired && !upwardFeedbackRequired;
                    if (noContributorPreparationRequired) {
                        return true;
                    }

                    const atLeastOneActionCheckedForSelf = (value as ReviewBlockForm[]).some(item => item?.selfReview && item?.blockType !== 'SECTION');

                    if (selfReviewRequired && !atLeastOneActionCheckedForSelf) {
                        return this.createError({
                            message: I18n.t('reviews_settings_page.review_template_form.error_at_least_one_question_or_objective_or_skill_is_checked_for_self'),
                            path: 'reviewBlocksForm',
                        });
                    }

                    const atLeastOneActionCheckedForManager = (value as ReviewBlockForm[]).some(
                        item => item?.managerPreparation && item?.blockType !== 'SECTION',
                    );

                    if (managerPreparationRequired && !atLeastOneActionCheckedForManager) {
                        return this.createError({
                            message: I18n.t(
                                'reviews_settings_page.review_template_form.error_at_least_one_question_or_objective_or_skill_is_checked_for_manager',
                            ),
                            path: 'reviewBlocksForm',
                        });
                    }

                    const atLeastOneActionCheckedForPeer = (value as ReviewBlockForm[]).some(item => item?.peerFeedback && item?.blockType !== 'SECTION');

                    if (peerFeedbackRequired && !atLeastOneActionCheckedForPeer) {
                        return this.createError({
                            message: I18n.t('reviews_settings_page.review_template_form.error_at_least_one_question_or_objective_or_skill_is_checked_for_peer'),
                            path: 'reviewBlocksForm',
                        });
                    }

                    const atLeastOneActionCheckedForUpward = (value as ReviewBlockForm[]).some(item => item?.upwardFeedback && item?.blockType !== 'SECTION');

                    if (upwardFeedbackRequired && !atLeastOneActionCheckedForUpward) {
                        return this.createError({
                            message: I18n.t(
                                'reviews_settings_page.review_template_form.error_at_least_one_question_or_objective_or_skill_is_checked_for_upward',
                            ),
                            path: 'reviewBlocksForm',
                        });
                    }

                    return true;
                },
            }),
    });
};

const getPrivateQuestionSchema = (translationLanguage: UserLanguage): yup.ObjectSchema<PrivateQuestionBlockForm> => {
    return yup.object().shape({
        questionName: getLabelFormSchema(translationLanguage),
        required: yup.boolean().required(),
        reviewScaleEnabled: yup.boolean().required().default(false),
        reviewRatingScaleId: yup.number().when(['reviewScaleEnabled'], {
            is: true,
            then: schema => schema.required(),
        }),
        instructionEnabled: yup.boolean().required().default(false),
        instruction: getLabelFormSchema(),
        temporaryId: yup.string().trim().required(),
        order: yup.number().required(),
        blockType: yup
            .string()
            .required()
            .oneOf(['PRIVATE_EMPLOYEE_QUESTION', 'PRIVATE_MANAGER_QUESTION'] as const),
    });
};

export const getPrivateManagerFeedbackSchema = (translationLanguage: UserLanguage): yup.ObjectSchema<PostReviewManagerFeedbackStepFormType> => {
    return yup.object().shape({
        privateManagerQuestions: yup.array().of(getPrivateQuestionSchema(translationLanguage)).required(),
    });
};

export const getPrivateEmployeeFeedbackSchema = (translationLanguage: UserLanguage): yup.ObjectSchema<PostReviewEmployeeFeedbackStepFormType> => {
    return yup.object().shape({
        privateEmployeeQuestions: yup.array().of(getPrivateQuestionSchema(translationLanguage)).required(),
    });
};

const isGeneralLabelEmailValid = (value: Label, includePreparationStep: boolean, reviewAsked: boolean) => {
    if (includePreparationStep && reviewAsked) {
        return !!value?.translationDe || !!value?.translationEn || !!value?.translationFr || !!value?.translationIt;
    }
    return true;
};

const isDefaultLabelEmailValid = (value: Label, includePreparationStep: boolean, reviewAsked: boolean) => {
    const realmLanguage = getLabelPropertyName(getRealmLanguage());
    const realmValue = value?.[realmLanguage];
    if (includePreparationStep && reviewAsked) {
        return !!realmValue;
    }
    return true;
};

export const getInvitationEmailSchema = (): yup.ObjectSchema<InvitationEmailFormType> => {
    return yup.object().shape({
        managerSubject: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                return !!value?.translationDe || !!value?.translationEn || !!value?.translationFr || !!value?.translationIt;
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                const realmLanguage = getLabelPropertyName(getRealmLanguage());
                const realmValue = value?.[realmLanguage];
                return !!realmValue;
            }),
        managerBody: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                return isGeneralLabelEmailValid(value, true, true);
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                return isDefaultLabelEmailValid(value, true, true);
            }),
        selfSubject: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const selfReview = this.from?.[1].value.selfReview;
                return isGeneralLabelEmailValid(value, includePreparationStep, selfReview);
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const selfReview = this.from?.[1].value.selfReview;
                return isDefaultLabelEmailValid(value, includePreparationStep, selfReview);
            }),
        selfBody: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const selfReview = this.from?.[1].value.selfReview;
                return isGeneralLabelEmailValid(value, includePreparationStep, selfReview);
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const selfReview = this.from?.[1].value.selfReview;
                return isDefaultLabelEmailValid(value, includePreparationStep, selfReview);
            }),
        peerSubject: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const peerReview = this.from?.[1].value.peerFeedback;
                return isGeneralLabelEmailValid(value, includePreparationStep, peerReview);
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const peerReview = this.from?.[1].value.peerFeedback;
                return isDefaultLabelEmailValid(value, includePreparationStep, peerReview);
            }),
        peerBody: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const selfReview = this.from?.[1].value.peerFeedback;
                return isGeneralLabelEmailValid(value, includePreparationStep, selfReview);
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const selfReview = this.from?.[1].value.peerFeedback;
                return isDefaultLabelEmailValid(value, includePreparationStep, selfReview);
            }),
        upwardSubject: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const upwardReview = this.from?.[1].value.upwardFeedback;
                return isGeneralLabelEmailValid(value, includePreparationStep, upwardReview);
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const upwardReview = this.from?.[1].value.upwardFeedback;
                return isDefaultLabelEmailValid(value, includePreparationStep, upwardReview);
            }),
        upwardBody: getLabelFormSchema()
            .test('is-required', i18next.t('general.validations.required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const upwardReview = this.from?.[1].value.upwardFeedback;
                return isGeneralLabelEmailValid(value, includePreparationStep, upwardReview);
            })
            .test('is-default-required', i18next.t('label.validations.default_required'), function (value) {
                const includePreparationStep = this.from?.[1].value.includePreparationStep;
                const upwardReview = this.from?.[1].value.upwardFeedback;
                return isDefaultLabelEmailValid(value, includePreparationStep, upwardReview);
            }),
    });
};
