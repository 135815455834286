import { Box, Chip, Stack, Typography } from '@mui/material';
import { TranslatableLabelTypography } from '@/components/translatable-label-typography/TranslatableLabelTypography';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { FC } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { DragDropHorizontalIcon } from 'hugeicons-react';

type SectionFieldDefinitionDraggableProps = {
    sectionField: SectionFieldDefinition;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
    onFieldUpdate: (field: SectionFieldDefinition) => void;
    onFieldDelete: (field: SectionFieldDefinition) => void;
};

export const SectionFieldDefinitionDraggable: FC<SectionFieldDefinitionDraggableProps> = ({
    sectionField,
    provided,
    snapshot,
    onFieldDelete = () => {},
    onFieldUpdate = () => {},
}) => {
    const { t } = useTranslation();

    if (!sectionField) {
        return undefined;
    }

    return (
        <Stack
            key={sectionField.id}
            direction='row'
            gap={2}
            alignItems='center'
            justifyContent='space-between'
            {...(snapshot.isDragging
                ? {
                      bgcolor: ({ palette }) => palette.common.white,
                      borderRadius: 1,
                      boxShadow: 1,
                  }
                : {})}
        >
            <Box display='contents' {...provided.dragHandleProps} alignSelf='center'>
                <DragDropHorizontalIcon />
            </Box>
            <Box flex='1'>
                <Stack direction='row' gap={1} alignItems='center'>
                    <TranslatableLabelTypography variant='body2bold' noWrap label={sectionField?.name} />
                    {sectionField.mandatory && <Chip label={t('employee_fields_page.table_columns.mandatory')} size='small' />}
                </Stack>

                <Typography variant='body2' noWrap>
                    {t(`employee_fields_page.${sectionField.type}`)}
                </Typography>
            </Box>

            <EmployeeFieldMoreButton
                onEditClicked={() => onFieldUpdate(sectionField)}
                onDeleteClicked={() => onFieldDelete(sectionField)}
                cancelEnabled={false}
            />
        </Stack>
    );
};
