import { FC } from 'react';

import category1 from '@/assets/icons/SurveyCategories/icon1.svg';
import category2 from '@/assets/icons/SurveyCategories/icon2.svg';
import category3 from '@/assets/icons/SurveyCategories/icon3.svg';
import category4 from '@/assets/icons/SurveyCategories/icon4.svg';
import category5 from '@/assets/icons/SurveyCategories/icon5.svg';
import { AnswerResult, getSurveyCategoryTranslationKey, SurveyQuestionCategory, SurveyResultFilterType, SurveyResults } from '@/domain/survey/Survey.model';
import { Box, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';

type Props = {
    categories: AnswerResult[];
    setCategory: (category: SurveyQuestionCategory | undefined) => void;
    activeCategory: SurveyQuestionCategory | undefined;
    results: SurveyResults;
    groupBy: SurveyResultFilterType;
};

export const SurveyCategories: FC<Props> = ({ results, categories, setCategory, activeCategory }) => {
    const { t } = useTranslation();

    const showCategoryIcon = (category: SurveyQuestionCategory | undefined) => {
        switch (category) {
            case SurveyQuestionCategory.FULFILLING_ROLE:
                return category1;
            case SurveyQuestionCategory.PERSONAL_GROWTH:
                return category2;
            case SurveyQuestionCategory.STRONG_LEADERSHIP:
                return category3;
            case SurveyQuestionCategory.POSITIVE_RELATIONSHIPS:
                return category4;
            case SurveyQuestionCategory.MISSION_VALUES:
                return category5;
            case SurveyQuestionCategory.HEALTH_WELL_BEING:
                // TODO: add missing icons
                return category5;
            default:
                break;
        }
    };

    const sortByType = (x: AnswerResult, y: AnswerResult) => {
        if (!x.question.category || !y.question.category) {
            return 0;
        }
        // TODO use better compare function
        if (x.question.category < y.question.category) {
            return -1;
        }
        if (x.question.category > y.question.category) {
            return 1;
        }
        return 0;
    };
    return (
        <Grid
            container
            direction={'row'}
            sx={{
                padding: 38,
                paddingTop: 1,
                paddingBottom: 2,
                textAlign: 'center',
            }}
            spacing={2}
            justifyContent={'center'}
        >
            {categories
                ? [...categories].sort(sortByType).map(category => (
                      <Grid
                          key={category.question.id}
                          item
                          sx={{
                              display: 'flex',
                              paddingTop: 0,
                              marginBottom: 1,
                          }}
                      >
                          <Card
                              className={activeCategory === category.question.category ? 'active' : ''}
                              variant='outlined'
                              sx={theme => ({
                                  width: 144,
                                  cursor: 'pointer',
                                  '&.active': {
                                      background: theme.palette.primary.main,
                                      '& .MuiTypography-root': {
                                          color: '#fff',
                                      },
                                  },
                                  '& .MuiCardContent-root': {
                                      paddingBottom: theme.spacing(2),
                                  },
                              })}
                          >
                              <CardContent onClick={() => setCategory(category.question.category)}>
                                  <Box
                                      sx={theme => ({
                                          background: theme.palette.background.default,
                                          width: 40,
                                          margin: 'auto',
                                          height: 40,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          borderRadius: '50%',
                                          marginBottom: 1,
                                      })}
                                  >
                                      <img
                                          src={showCategoryIcon(category.question.category)}
                                          alt={'Icon'}
                                          style={{
                                              display: 'block',
                                              margin: 'auto',
                                          }}
                                      />
                                  </Box>
                                  <Typography variant='body1bold'>
                                      {results?.categories['ALL'][0]?.categoryAverageScore?.[
                                          // TODO fix domaine type
                                          category.question.category ?? ('' as SurveyQuestionCategory)
                                      ]?.toFixed(1)}
                                  </Typography>
                                  <Typography variant='body1'>{t(getSurveyCategoryTranslationKey(category.question.category))}</Typography>
                              </CardContent>
                          </Card>
                      </Grid>
                  ))
                : undefined}
        </Grid>
    );
};
