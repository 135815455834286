import { useTranslation } from 'react-i18next';
import { SectionField } from '@/components/section/types';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { FC, useEffect } from 'react';
import { SectionContainer } from '../../SectionContainer';
import { SectionFieldContainer } from '../../SectionFieldContainer';
import { useFormContext } from 'react-hook-form';
import { CONTRACT_PROBATION_PERIOD_MONTHS, ContractType } from '@/domain/employment/Employment.model';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import { addMonths, isValidDate, subDaysAndFormat } from '@/utils/datetime.util';

export const ContractSection: FC = () => {
    const { t } = useTranslation();

    const { watch, getFieldState, setValue } = useFormContext<OnboardingProfileStepFormValues>();

    const mapContractType = () => {
        const contractTypeMap = new Map<string, string>();
        Object.values(ContractType).forEach(contractType => {
            contractTypeMap.set(contractType, t('employee.employment.contract_type', { context: contractType }));
        });
        return contractTypeMap;
    };

    const contractSectionFields: (Omit<SectionField, 'formValueName' | 'title'> & {
        formValueName: string;
        title: string;
    })[] = [
        {
            formValueName: 'contractStartDate',
            title: t('employee.employment.start_date'),
            type: SectionFieldType.DATE,
            required: true,
            fieldType: 'CONTRACT_START_DATE',
        },
        {
            formValueName: 'probationEndDate',
            title: t('employee.employment.probation_end_date'),
            type: SectionFieldType.DATE,
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_PROBATION_END_DATE',
        },
        {
            formValueName: 'contractType',
            title: t('employee.employment.contract_type_title'),
            type: SectionFieldType.ENUM_LIST_ITEM,
            required: true,
            enumList: mapContractType(),
            fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_TYPE',
        },
    ];
    const contractType = watch('contractType');
    const contractStartDate = watch('contractStartDate');

    // When the contract start date is changed, the probation end date is automatically set to 3 months after the contract start date
    // unless the probation end date has been changed manually
    useEffect(() => {
        if (isValidDate(contractStartDate) && !getFieldState('probationEndDate').isDirty) {
            // The probation end date not included the last day of the probation period
            const probationEndDate = subDaysAndFormat(addMonths(contractStartDate, CONTRACT_PROBATION_PERIOD_MONTHS), 1);
            if (isValidDate(probationEndDate)) {
                setValue('probationEndDate', probationEndDate);
            }
        }
    }, [contractStartDate, getFieldState, setValue]);

    if (contractType && contractType !== ContractType.PERMANENT) {
        contractSectionFields.push({
            formValueName: 'contractEndDate',
            title: t('employee.employment.contract_end_date'),
            type: SectionFieldType.DATE,
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_END_DATE',
        });
    }

    return (
        <SectionContainer title={t('employee.sections.contract')}>
            {!!contractSectionFields?.length &&
                contractSectionFields.map(contractSectionField => {
                    return (
                        <SectionFieldContainer
                            key={contractSectionField.title}
                            formValueName={contractSectionField.formValueName}
                            title={contractSectionField.title}
                            required={contractSectionField.required}
                        >
                            <EditableSectionFieldComponent field={contractSectionField} />
                        </SectionFieldContainer>
                    );
                })}
        </SectionContainer>
    );
};
