import { Employee } from '@/domain/employee/Employee.model';

import { Job } from '@/domain/job/Job.model';
import { Department } from '@/domain/department/Department.model';
import { Location } from '@/domain/location/Location.model';
import { CostCenterAssignment, CostCenterAssignmentMutation } from '@/domain/cost-center/CostCenter.model';

import { JobFamily } from '@/domain/job-family/JobFamily.model';

export enum TerminationType {
    DISMISSED = 'DISMISSED',
    RESIGNED = 'RESIGNED',
    END_OF_CONTRACT = 'END_OF_CONTRACT',
    MUTUAL = 'MUTUAL',
    RETIRED = 'RETIRED',
    OTHER = 'OTHER',
}

export enum TerminationReason {
    DISMISSED_REDUNDANT = 'DISMISSED_REDUNDANT',
    DISMISSED_UNDERPERFORMED = 'DISMISSED_UNDERPERFORMED',
    DISMISSED_DISCIPLINARY = 'DISMISSED_DISCIPLINARY',
    OTHER = 'OTHER',
    RESIGNED_COMPENSATION = 'RESIGNED_COMPENSATION',
    RESIGNED_CAREER = 'RESIGNED_CAREER',
    RESIGNED_LOCATION = 'RESIGNED_LOCATION',
    RESIGNED_OTHER = 'RESIGNED_OTHER',
    PASSED_AWAY = 'PASSED_AWAY',
    FURLOUGH = 'FURLOUGH',
    CULTURAL = 'CULTURAL',
    END_OF_CONTRACT = 'END_OF_CONTRACT',
    MUTUAL = 'MUTUAL',
    RETIREMENT = 'RETIREMENT',
    INTERNAL_CHANGE = 'INTERNAL_CHANGE',
    END_OF_APPRENTICESHIP = 'END_OF_APPRENTICESHIP',
}

export enum ContractType {
    PERMANENT = 'PERMANENT',
    TEMPORARY = 'TEMPORARY',
    APPRENTICE = 'APPRENTICE',
    INTERNSHIP = 'INTERNSHIP',
    UNPAID_INTERNSHIP = 'UNPAID_INTERNSHIP',
    CONTRACTOR = 'CONTRACTOR',
}

export enum EmploymentCreateReason {
    NEW_EMPLOYEE = 'NEW_EMPLOYEE',
    REHIRED = 'REHIRED',
    PROMOTION = 'PROMOTION',
    LATERAL_MOVE = 'LATERAL_MOVE',
    NEW_MANAGER = 'NEW_MANAGER',
    NEW_ENTITY = 'NEW_ENTITY',
    OTHER = 'OTHER',
}

type EmploymentCostCenter = CostCenterAssignment;

export type Employment = {
    id: number;
    employeeId: number;
    startDate: LocalDate;
    contractType: ContractType;
    endDate?: LocalDate;
    probationEndDate?: LocalDate;
    terminationNoticeDate?: LocalDate;
    terminationLastDayAtWork?: LocalDate;
    terminationType?: TerminationType;
    terminationReason?: TerminationReason;
    terminationComment?: string;
    location: Location;
    job: Job;
    jobFamily?: JobFamily;
    department: Department;
    managers: Employee[];
    employmentCreateReason?: EmploymentCreateReason;
    employmentCostCenters: EmploymentCostCenter[];
    principal: boolean;
};

export type EmploymentsSearchRequest = {
    employeeId: number;
};

export type EmploymentCreationMutation = {
    employeeId: number;
    contractType: string;
    employmentCreateReason: string;
    startDate: LocalDate;
    endDate?: LocalDate;
    items: EmploymentItemCreationMutation[];
};

type EmploymentItemCreationMutation = {
    principal: boolean;
    locationId: number;
    jobId: number;
    jobFamilyId?: number;
    departmentId: number;
    managerIds: number[];
    employmentCostCenters: CostCenterAssignmentMutation[];
};

type EmploymentItemUpdateMutation = EmploymentItemCreationMutation & {
    id?: number;
};

export type EmploymentUpdateMutation = Overwrite<
    Omit<EmploymentCreationMutation, 'employeeId'>,
    {
        employmentCreateReason?: string;
        items: EmploymentItemUpdateMutation[];
    }
> & {
    probationEndDate?: LocalDate;
};

export type ModifyEmploymentTerminationMutation = {
    endDate: LocalDate;
    terminationNoticeDate?: LocalDate;
    terminationLastDayAtWork?: LocalDate;
    terminationType: TerminationType;
    terminationReason: TerminationReason;
    terminationComment?: string;
};
export const CONTRACT_PROBATION_PERIOD_MONTHS = 3;

export enum EmploymentStatus {
    HIRED = 'HIRED',
    EMPLOYED = 'EMPLOYED',
    ON_LONG_LEAVE = 'ON_LONG_LEAVE',
    TERMINATED = 'TERMINATED',
}

export const EMPLOYEE_STATUS_TYPES = ['APPROVED', 'PENDING', 'CANCELLED'];
