import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { mapWorkingDays } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.service';
import { Employee } from '@/domain/employee/Employee.model';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { OnboardingMutation } from '@/domain/onboarding/Onboarding.model';
import { submitOnboardingForm } from '@/domain/onboarding/Onboarding.service';
import { PlanningPosition } from '@/domain/planning-position/PlanningPosition.model';
import { searchPlanningPosition } from '@/domain/planning-position/PlanningPosition.service';
import { Planning } from '@/domain/planning/Planning.model';
import { searchPlannings } from '@/domain/planning/Planning.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { ReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { searchReviewTemplates } from '@/domain/review-template/ReviewTemplate.service';
import { NUMBER_DAYS_BEFORE_EOT_REVIEW } from '@/domain/review/Review.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { searchWeeklyWorkingTimes } from '@/domain/weekly-working-time/WeeklyWorkingTime.service';
import { useGetCalendars } from '@/hooks/calendar/Calendar.hook';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { handleError } from '@/utils/api.util';
import { subDaysAndFormat } from '@/utils/datetime.util';
import { Box, CircularProgress, Stack, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OnboardingEmployeeInformationForm, OnboardingProfileStepFormValues } from './on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import { InviteForm, InviteFormValues } from './on-boarding-form/InviteForm/InviteForm';
import { LeavesForm, LeaveTypeFormValues } from './on-boarding-form/LeavesForm/LeavesForm';
import { OnBoardingHeader } from './on-boarding-form/OnBoardingHeader';
import { PlanningForm, PlanningFormValues } from './on-boarding-form/PlanningForm/PlanningForm';
import { ReviewsForm, ReviewsFormValues } from './on-boarding-form/ReviewsForm/ReviewsForm';

export const OnBoardingPage: FC = () => {
    const { palette } = useTheme();

    const [baseProfileFormValues, setBaseProfileFormValues] = useState<Partial<OnboardingProfileStepFormValues>>({});
    const [leavesFormValues, setLeavesFormValues] = useState<LeaveTypeFormValues>();
    const [planningFormValues, setPlanningFormValues] = useState<PlanningFormValues>();
    const [reviewFormValues, setReviewFormValues] = useState<ReviewsFormValues>();

    const scrollRef = useRef<HTMLDivElement>();
    const [isActive, handleBackStep, handleNextStep, backButtonAllowed] = useStep();
    const [weeklyWorkingTimes, setWeeklyWorkingTimes] = useState<WeeklyWorkingTime[]>([]);

    const [leaveTypes, setLeaveTypes] = useState<LeaveType[]>([]);
    const [plannings, setPlannings] = useState<Planning[]>([]);
    const [planningPositions, setPlanningPositions] = useState<PlanningPosition[]>([]);
    const [reviewTemplates, setReviewTemplates] = useState<ReviewTemplate[]>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmittingTheForm, setIsSubmittingTheForm] = useState<boolean>(false);

    const { data: calendars = [], isLoading: isLoadingCalendars } = useGetCalendars();

    const canConfigureLeaves = useRealmFeatureEnabled(RealmFeaturesType.LEAVES);
    const canConfigurePlanning = useRealmFeatureEnabled(RealmFeaturesType.PLANNING);
    const canConfigureReviews = useRealmFeatureEnabled(RealmFeaturesType.REVIEWS);

    type PromiseTypes = [
        Promise<WeeklyWorkingTime[]>,
        Promise<LeaveType[]> | undefined,
        Promise<ReviewTemplate[]> | undefined,
        Promise<Employee[]> | undefined,
        Promise<Planning[]> | undefined,
        Promise<PlanningPosition[]> | undefined,
    ];
    const requests = useMemo<PromiseTypes>(
        () => [
            searchWeeklyWorkingTimes(),
            canConfigureLeaves ? getLeaveTypes() : undefined,
            canConfigureReviews ? searchReviewTemplates({ reviewType: 'ONBOARDING' }) : undefined,
            canConfigureReviews ? searchEmployees() : undefined,
            canConfigurePlanning ? searchPlannings() : undefined,
            canConfigurePlanning ? searchPlanningPosition() : undefined,
        ],
        [canConfigureLeaves, canConfigurePlanning, canConfigureReviews],
    );

    const scrollIntoView = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const fetchData = useCallback(async () => {
        const [weeklyWorkingTimesResponse, leaveTypesResponse, reviewTemplatesResponse, employeesResponse, planningsResponse, planningPositionsResponse] =
            await Promise.allSettled<PromiseTypes>(requests);

        if (weeklyWorkingTimesResponse.status !== 'fulfilled') {
            handleError(weeklyWorkingTimesResponse.reason);
            return;
        }

        setWeeklyWorkingTimes(weeklyWorkingTimesResponse.value);

        if (leaveTypesResponse?.status === 'fulfilled') {
            setLeaveTypes(
                leaveTypesResponse?.value?.filter(leaveType => {
                    return !!leaveType.policies?.length;
                }) ?? [],
            );
        }

        if (reviewTemplatesResponse?.status === 'fulfilled') {
            setReviewTemplates(reviewTemplatesResponse?.value ?? []);
        }

        if (employeesResponse?.status === 'fulfilled') {
            setEmployees(employeesResponse?.value ?? []);
        }

        if (planningsResponse?.status === 'fulfilled') {
            setPlannings(planningsResponse?.value ?? []);
        }

        if (planningPositionsResponse?.status === 'fulfilled') {
            setPlanningPositions(planningPositionsResponse?.value ?? []);
        }
    }, [requests]);

    // We need to fetch all the data before the user can start the onboarding process
    useEffect(() => {
        setIsLoading(true);

        fetchData()
            // We don't want to display an error message
            // if the user is not allowed to configure leaves, planning or reviews
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
    }, [fetchData]);

    const navigate = useNavigate();

    const onNextStep = () => {
        handleNextStep();
        scrollIntoView();
    };

    const onBackStep = () => {
        handleBackStep();
        scrollIntoView();
    };

    const onSubmitBaseProfileForm: SubmitHandler<OnboardingProfileStepFormValues> = baseProfileFormValuesData => {
        setBaseProfileFormValues(baseProfileFormValuesData);
        onNextStep();
    };

    const onSubmitLeavesForm: SubmitHandler<LeaveTypeFormValues> = leavesFormValuesData => {
        setLeavesFormValues(leavesFormValuesData);
        onNextStep();
    };

    const onSubmitPlanningForm: SubmitHandler<PlanningFormValues> = planningFormValuesData => {
        setPlanningFormValues(planningFormValuesData);
        onNextStep();
    };
    const onSubmitReviewForm: SubmitHandler<ReviewsFormValues> = reviewFormValuesData => {
        setReviewFormValues(reviewFormValuesData);
        onNextStep();
    };

    const onSubmitInviteForm: SubmitHandler<InviteFormValues> = inviteFormValuesData => {
        // At this point, we have all the data we need to create the employee
        const otherFormValues = {
            baseProfileFormValues: baseProfileFormValues as OnboardingProfileStepFormValues,
            leavesFormValues: leavesFormValues as LeaveTypeFormValues,
            planningFormValues: planningFormValues as PlanningFormValues,
            reviewFormValues: reviewFormValues as ReviewsFormValues,
            weeklyWorkingTimes,
        };

        const request = formatToOnboardingMutation(inviteFormValuesData, otherFormValues);

        onSubmitOnBoardingForm(request);
    };

    const onSubmitOnBoardingForm = async (request: OnboardingMutation) => {
        setIsSubmittingTheForm(true);
        try {
            const data = await submitOnboardingForm(request);
            navigate(`/profile/${data.id}/personal-info`);
            // TODO - this is a hack, we should refresh the employee data instead of refreshing the page
            // we can't refresh the employee data because the employee data is loaded in the parent component
            // and we don't want to duplicate the logic here
            // change this when we will use redux toolkit or react-query
            // We refresh the page to display the new avatar
            // https://rogerhr.atlassian.net/browse/RP-2014
            navigate(0);
        } catch (error) {
            handleError(error);
        }
        setIsSubmittingTheForm(false);
    };

    const steps = [
        {
            headerKey: 'information',
            formName: 'base-profile-form',
            step: (
                <OnboardingEmployeeInformationForm
                    weeklyWorkingTimes={weeklyWorkingTimes}
                    calendars={calendars}
                    onSubmitBaseProfileForm={onSubmitBaseProfileForm}
                    baseProfileFormValues={baseProfileFormValues}
                />
            ),
        },
    ];

    if (leaveTypes?.length) {
        steps.push({
            headerKey: 'leaves',
            formName: 'leaves-form',
            step: <LeavesForm onSubmitLeavesForm={onSubmitLeavesForm} leavesFormValues={leavesFormValues} leaveTypes={leaveTypes} />,
        });
    }

    if (plannings?.length) {
        steps.push({
            headerKey: 'planning',
            formName: 'planning-form',
            step: <PlanningForm onSubmitForm={onSubmitPlanningForm} plannings={plannings} planningPositions={planningPositions} />,
        });
    }

    if (reviewTemplates?.length) {
        steps.push({
            headerKey: 'reviews',
            formName: 'review-form',
            step: (
                <ReviewsForm
                    onSubmitForm={onSubmitReviewForm}
                    templates={reviewTemplates}
                    employees={employees}
                    defaultValues={{
                        reviewName: baseProfileFormValues?.displayName ?? '',
                        reviewTemplate: undefined,
                        reviewNotificationDate: baseProfileFormValues?.probationEndDate
                            ? subDaysAndFormat(baseProfileFormValues.probationEndDate, NUMBER_DAYS_BEFORE_EOT_REVIEW)
                            : undefined,
                        reviewEndDate: baseProfileFormValues?.probationEndDate ?? undefined,
                        // Pre fill the review manager with the first manager
                        reviewManager: baseProfileFormValues?.managers?.length
                            ? {
                                  value: baseProfileFormValues?.managers?.[0].id,
                                  label: baseProfileFormValues?.managers?.[0].displayName,
                              }
                            : undefined,
                    }}
                />
            ),
        });
    }

    steps.push({
        headerKey: 'access',
        formName: 'invite-form',
        step: <InviteForm onSubmitInviteForm={onSubmitInviteForm} />,
    });

    return (
        <>
            {isLoading && isLoadingCalendars && (
                <Stack justifyContent='center' alignItems='center' flex={1}>
                    <CircularProgress />
                </Stack>
            )}
            {!isLoading && (
                <Stack gap={2}>
                    <Box ref={scrollRef} position={'sticky'} top={0} zIndex={100} paddingBottom={0} bgcolor={palette.background.default}>
                        <OnBoardingHeader
                            handleBackStep={onBackStep}
                            isActive={isActive}
                            backButtonAllowed={backButtonAllowed}
                            steps={steps}
                            isSubmittingTheForm={isSubmittingTheForm}
                        />
                    </Box>
                    <Stack>
                        {steps.map((step, index) => (
                            <Box key={step.formName} display={isActive(index) ? 'block' : 'none'}>
                                {isActive(index) && step.step}
                            </Box>
                        ))}
                    </Stack>
                </Stack>
            )}
        </>
    );
};

/**
 * Merge all the data from the different forms to create the request to send to the API
 * @param inviteFormValuesData
 * @param otherValues
 */
const formatToOnboardingMutation = (
    inviteFormValuesData: InviteFormValues,
    otherValues: {
        baseProfileFormValues: OnboardingProfileStepFormValues;
        leavesFormValues: LeaveTypeFormValues;
        planningFormValues: PlanningFormValues;
        reviewFormValues: ReviewsFormValues;
        weeklyWorkingTimes: WeeklyWorkingTime[];
    },
): OnboardingMutation => {
    const { baseProfileFormValues, leavesFormValues, planningFormValues, reviewFormValues, weeklyWorkingTimes } = otherValues;
    const selectedWeeklyWorkingTime = weeklyWorkingTimes.find(w => w.id === baseProfileFormValues.weeklyWorkingTimeId);

    return {
        ...formatBaseProfile(baseProfileFormValues, selectedWeeklyWorkingTime),
        leaveTypeAssignments: leavesFormValues?.leaveTypeAssignments,
        sendInvitation: inviteFormValuesData?.sendInvitation,
        loginMethodId: inviteFormValuesData?.loginMethod?.id,
        language: inviteFormValuesData.language,
        ...formatPlanning(planningFormValues),
        ...formatReviews(reviewFormValues),
    };
};

const formatBaseProfile = (baseProfileFormValues: OnboardingProfileStepFormValues, selectedWeeklyWorkingTime: WeeklyWorkingTime | undefined) => ({
    employeeCode: baseProfileFormValues.employeeCode,
    contractStartDate: baseProfileFormValues.contractStartDate,
    probationEndDate: baseProfileFormValues.probationEndDate ?? undefined,
    firstName: baseProfileFormValues.firstName,
    contractEndDate: baseProfileFormValues?.contractEndDate ?? undefined,
    contractType: baseProfileFormValues.contractType,
    lastName: baseProfileFormValues.lastName,
    maidenName: baseProfileFormValues.maidenName ?? undefined,
    email: baseProfileFormValues.email.toLowerCase(),
    gender: baseProfileFormValues.gender ?? undefined,
    phoneNumber: baseProfileFormValues?.phoneNumber && baseProfileFormValues?.phoneNumber !== '' ? baseProfileFormValues?.phoneNumber : undefined,
    birthdate: baseProfileFormValues.birthdate ?? undefined,
    nationality: baseProfileFormValues?.nationality?.value,
    maritalStatus: baseProfileFormValues?.maritalStatus ?? undefined,
    maritalStatusSince: baseProfileFormValues.maritalStatusSince ?? undefined,
    departmentId: baseProfileFormValues?.department?.id,
    jobId: baseProfileFormValues?.job?.id,
    jobFamilyId: baseProfileFormValues?.jobFamily?.id,
    locationId: baseProfileFormValues?.location?.id,
    managerIds: baseProfileFormValues?.managers.map(manager => manager.id),
    employmentCostCenters: baseProfileFormValues?.employmentCostCenters?.map(costCenter => ({
        costCenterId: costCenter.costCenter.id,
        percentage: costCenter.percentage,
    })),
    calendarId: baseProfileFormValues.calendarId,
    workingPatternType: baseProfileFormValues?.type,
    rate: baseProfileFormValues?.type === EmployeeWorkingPatternType.RATE ? baseProfileFormValues?.rate : undefined,
    weeklyWorkingTimeId: getWeeklyWorkingTimeId(baseProfileFormValues),
    workingPatternId: baseProfileFormValues?.type === EmployeeWorkingPatternType.TEMPLATE ? baseProfileFormValues?.workingPatternTemplate?.id : undefined,
    workingDays:
        baseProfileFormValues?.type === EmployeeWorkingPatternType.FIXED
            ? mapWorkingDays(
                  baseProfileFormValues?.workingDays.morningWorkingDays,
                  baseProfileFormValues?.workingDays.afternoonWorkingDays,
                  selectedWeeklyWorkingTime,
              )
            : undefined,
    timesheetSettingId: baseProfileFormValues?.timesheetSettingId,
});

const formatPlanning = (planningFormValues: PlanningFormValues | undefined) => {
    const { managerPlannings, memberPlannings, planningPosition } = planningFormValues ?? {};
    return {
        planningPositionId: planningPosition?.value,
        managerPlannings: managerPlannings?.map(planning => planning.value) ?? [],
        memberPlannings: memberPlannings?.map(planning => planning.value) ?? [],
    };
};

const formatReviews = (reviewFormValues: ReviewsFormValues | undefined) => ({
    reviews: reviewFormValues?.organizeMeeting
        ? (reviewFormValues?.reviews ?? [])
              .filter(review => !!review?.reviewTemplate?.value)
              .map(review => ({
                  name: review?.reviewName,
                  reviewTemplateId: review.reviewTemplate.value,
                  managerIds: review?.reviewManager?.value ? [review?.reviewManager?.value] : [],
                  startDate: review?.reviewNotificationDate,
                  endDate: review?.reviewEndDate,
              }))
        : [],
});

const getWeeklyWorkingTimeId = (baseProfileFormValues: OnboardingProfileStepFormValues) => {
    return baseProfileFormValues?.type === EmployeeWorkingPatternType.FIXED || baseProfileFormValues?.type === EmployeeWorkingPatternType.RATE
        ? baseProfileFormValues?.weeklyWorkingTimeId
        : undefined;
};

const useStep = (): [(step: number) => boolean, () => void, () => void, () => boolean] => {
    const [activeStep, setActiveStep] = useState(0);

    const handleBackStep = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleNextStep = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const isActive = (step: number) => {
        return activeStep === step;
    };

    const backButtonAllowed = () => {
        return activeStep > 0;
    };

    return [isActive, handleBackStep, handleNextStep, backButtonAllowed];
};
