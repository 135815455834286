import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { LeaveRequestAttachmentCreationRequest } from '@/domain/leave-request-attachment/LeaveRequestAttachment.model';
import { createLeaveRequestAttachment, getLeaveRequestAttachmentUrl } from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';
import { LeaveCreationMutation, LeaveRequest, LeaveRequestPreview, LeaveRequestStatus, LeaveUpdateMutation } from '@/domain/leave-request/LeaveRequest.model';
import {
    approvePendingLeaveRequest,
    convertLeavesMinutesToUnit,
    createLeaveRequest,
    editLeaveRequest,
    editPendingLeaveRequest,
    getEndPeriodChoices,
    getLeaveRequestPeriodAsString,
    getStartPeriodChoices,
    isOverlappingExceedsLimitInDays,
    setDayPeriodTime,
    setStartTimeAndEndTime,
    shiftAPISearchRequest,
    shouldFetchEmployeeShifts,
} from '@/domain/leave-request/LeaveRequest.service';
import { LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { AllowanceType, LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { removeNullsFromLeaveType } from '@/domain/leave-type/LeaveType.service';
import { canApproveRejectLeaveRequests } from '@/domain/permission/Permission.service';
import { Shift, ShiftReleaseRequest, ShiftSearchRequest, ShiftStatus } from '@/domain/shift/Shift.model';
import { useGetLeaveTypeHistories } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { ConfirmNegativeBalanceDialog } from '@/page/leave/confirm-negative-balance-dialog/ConfirmNegativeBalanceDialog';
import { CommonLeaveTypeDetails } from '@/page/leave/leave-request-dialog/CommonLeaveTypeDetails';
import { DailyLeaveTypeDetails } from '@/page/leave/leave-request-dialog/DailyLeaveTypeDetails';
import {
    getLeaveRemainingTimePercentage,
    handleSearchConflicting,
    handleSearchOverlapping,
    handleSearchPreview,
    hasConflicts,
} from '@/page/leave/leave-request-dialog/LeaveRequestDialog.util';
import { SelectLeaveType } from '@/page/leave/leave-request-dialog/LeaveRequestDialogLeaveTypeStep';
import { leaveRequestFormSchema, LeaveRequestFormValues } from '@/page/leave/leave-request-dialog/LeaveRequestForm.schema';
import { useLeaveRequestAttachments } from '@/page/leave/leave-request-dialog/UseLeaveRequestAttachments';
import { useGetLeaveRequestById } from '@/page/leave/leave-request/LeaveRequests.hook';
import { LeavesConflictsDialog } from '@/page/leave/leaves-conflicts-dialog/LeavesConflictsDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { formatToLocalDate, getCurrentLocalDate, getTimeFormatFromDate, getTodayDate, isBeforeDate, isSameDate, setTime, toDate } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogProps,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HourlyLeaveTypeDetails } from './HourlyLeaveTypeDetails';

import { getEmployeeShifts, shiftRelease } from '@/domain/shift/Shift.service';
import { ArrowLeft01Icon } from 'hugeicons-react';

export type LeaveRequestDialogProps = Omit<DialogProps, 'onClose'> & {
    leaveRequestId?: number;
    open: boolean;
    employeeId: number;
    onSave: () => void;
    onClose: () => void;
    defaultLeaveRequest?: Partial<LeaveRequestFormValues>;
};

export const LeaveRequestDialog: FC<LeaveRequestDialogProps> = props => {
    const { leaveRequestId, employeeId, ...restProps } = props;

    const currentEmployeeId = useAppSelector(state => state.currentEmployee.employee?.id);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const {
        data: leaveRequest,
        isLoading: isLoadingLeaveRequest,
        isError: isErrorLeaveRequest,
        error: leaveRequestError,
    } = useGetLeaveRequestById(leaveRequestId);
    const {
        data: leaveTypeHistories,
        isLoading: isLoadingLeaveTypes,
        isError: isErrorLeaveTypes,
        error: leaveTypesError,
    } = useGetLeaveTypeHistories(employeeId);

    if (!leaveTypeHistories || !currentEmployeeId) {
        return undefined;
    }

    return (
        <StateHandler
            isLoading={isLoadingLeaveRequest || isLoadingLeaveTypes}
            isError={isErrorLeaveRequest || isErrorLeaveTypes}
            error={leaveRequestError || leaveTypesError}
        >
            <LeaveRequestDialogWrapper
                employeeId={employeeId}
                leaveRequest={leaveRequest}
                policies={policies}
                leaveTypeHistories={leaveTypeHistories}
                currentEmployeeId={currentEmployeeId}
                {...restProps}
            />
        </StateHandler>
    );
};

const LeaveRequestDialogWrapper: FC<
    Omit<LeaveRequestDialogProps, 'leaveRequestId'> & {
        leaveRequest: LeaveRequest | undefined;
        policies: EmployeePolicy[];
        leaveTypeHistories: LeaveTypeHistory[];
        currentEmployeeId: number;
    }
> = ({
    open,
    employeeId,
    onClose,
    onSave,
    leaveRequest,
    leaveTypeHistories,
    policies,
    currentEmployeeId,
    defaultLeaveRequest: defaultLeaveRequestFormValues,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    const canApproveLeaveRequests = canApproveRejectLeaveRequests(policies, employeeId);

    //this values will be used to compare some fields to not send request to the BE when there is no need for it
    const [leaveTypeSelected, setLeaveTypeSelected] = useState<LeaveType | undefined>(leaveRequest?.leaveType ?? undefined);
    //this value is used to display the overlapping request details and hide the form
    const [displayOverlappingRequestDetails, setDisplayOverlappingRequestDetails] = useState<boolean>(false);

    const makeItDisplayOverlappingRequestDetails = () => {
        setDisplayOverlappingRequestDetails(true);
    };

    const leaveTypesWithComputedData = leaveTypeHistories
        // Display only leave types that are not available in self service
        // when the connected user is not the selected employee and doesn't have the access to approve
        ?.filter(history => history.leaveType?.availableInSelfService || employeeId !== currentEmployeeId || canApproveLeaveRequests);

    const isEdit = !!leaveRequest;

    const dialogHeader = () => {
        return (
            <Stack direction='row' justifyContent='space-between'>
                {displayOverlappingRequestDetails && (
                    <Button
                        variant='text'
                        startIcon={<ArrowLeft01Icon />}
                        onClick={() => {
                            setDisplayOverlappingRequestDetails(false);
                        }}
                    >
                        {t('general.back')}
                    </Button>
                )}
                {!displayOverlappingRequestDetails && (
                    <>
                        {!isEdit && <Typography variant='h2bold'>{t('request_leave_dialog.title')}</Typography>}
                        {isEdit && <Typography variant='h2bold'>{t('request_leave_dialog.title_edit')}</Typography>}
                    </>
                )}
            </Stack>
        );
    };

    return (
        <DialogWrapper open={open} fullScreen={fullScreen} onClose={onClose} header={dialogHeader()}>
            {!leaveTypeSelected && (
                <DialogContent sx={{ marginBottom: '25px' }}>
                    <SelectLeaveType
                        leaveTypeHistories={leaveTypesWithComputedData}
                        onLeaveTypeSelected={selectedLeaveType => {
                            setLeaveTypeSelected(removeNullsFromLeaveType(selectedLeaveType));
                        }}
                    />
                </DialogContent>
            )}

            {leaveTypeSelected && (
                <LeaveRequestDialogForm
                    defaultLeaveRequest={leaveRequest}
                    leaveTypeHistories={leaveTypeHistories}
                    currentEmployeeId={currentEmployeeId}
                    employeeId={employeeId}
                    defaultLeaveType={leaveTypeSelected}
                    onSave={onSave}
                    defaultLeaveRequestFormValues={defaultLeaveRequestFormValues}
                    canApproveLeaveRequests={canApproveLeaveRequests}
                    makeItDisplayOverlappingRequestDetails={makeItDisplayOverlappingRequestDetails}
                    displayOverlappingRequestDetails={displayOverlappingRequestDetails}
                    policies={policies}
                />
            )}
        </DialogWrapper>
    );
};

type LeaveRequestDialogFormProps = {
    defaultLeaveRequest: LeaveRequest | undefined;
    leaveTypeHistories: LeaveTypeHistory[];
    currentEmployeeId: number;
    employeeId: number;
    defaultLeaveType: LeaveType;
    onSave: () => void;
    defaultLeaveRequestFormValues?: Partial<LeaveRequestFormValues>;
    canApproveLeaveRequests: boolean;
    makeItDisplayOverlappingRequestDetails: () => void;
    displayOverlappingRequestDetails: boolean;
    policies: EmployeePolicy[];
};

type LeaveRequestAmounts = {
    remainingAmountInDays?: number;
    availableAmountInDays?: number;
    remainingAmountInMinutes?: number;
    availableAmountInMinutes?: number;
};

export const LeaveRequestDialogForm: FC<LeaveRequestDialogFormProps> = ({
    defaultLeaveRequest,
    defaultLeaveType,
    leaveTypeHistories,
    currentEmployeeId,
    employeeId,
    onSave,
    defaultLeaveRequestFormValues,
    canApproveLeaveRequests,
    makeItDisplayOverlappingRequestDetails,
    displayOverlappingRequestDetails,
    policies,
}) => {
    const { t } = useTranslation();

    const [leaveRequestAmounts, setLeaveRequestAmounts] = useState<LeaveRequestAmounts>();
    const [previewResult, setPreviewResult] = useState<LeaveRequestPreview>();
    const [overlappingRequests, setOverlappingRequests] = useState<LeaveRequest[]>([]);
    const [conflictingRequests, setConflictingRequests] = useState<LeaveRequest[]>([]);
    const [isLeaveConflictsDialogOpen, setIsLeaveConflictsDialogOpen] = useState<boolean>(false);
    const [leaveRequestWithNegativeBalance, setLeaveRequestWithNegativeBalance] = useState<LeaveRequestFormValues>();
    const [conflictingShifts, setConflictingShifts] = useState<Shift[]>();
    const [shiftReleaseRequest, setShiftReleaseRequest] = useState<ShiftReleaseRequest>();
    const [isFetchingInformation, setIsFetchingInformation] = useState<boolean>(false);

    const isEdit = !!defaultLeaveRequest;

    const schema = leaveRequestFormSchema;

    const formMethods = useForm<LeaveRequestFormValues, undefined, LeaveRequestFormValues>({
        resolver: yupResolver(schema),
        defaultValues: getDefaultLeaveRequestFormValues(defaultLeaveRequest, defaultLeaveType, defaultLeaveRequestFormValues),
    });

    const { handleSubmit, control, watch, setValue, getValues } = formMethods;
    const { filesMetadata, handleFileRemoved, handleFileUploaded } = useLeaveRequestAttachments(defaultLeaveRequest?.id);

    const leaveTypesWithComputedData = leaveTypeHistories
        // Display only leave types that are not available in self service
        // when the connected user is not the selected employee and doesn't have the access to approve
        ?.filter(history => history.leaveType?.availableInSelfService || employeeId !== currentEmployeeId || canApproveLeaveRequests);

    const getLeaveTypesWithSameUnitType = (unit: UnitType) =>
        [...(leaveTypesWithComputedData ?? [])]
            .sort((a, b) => {
                return a.leaveType.order - b.leaveType.order;
            })
            .filter(history => history.leaveType.unitType === unit)
            .map(history => history.leaveType);

    const saveUploadedAttachments = (leaveRequestId: number) => {
        if (!isEdit && !!filesMetadata?.length) {
            filesMetadata.forEach(leaveRequestAttachment => {
                if (!leaveRequestAttachment) {
                    return;
                }
                const leaveRequestAttachmentCreationRequest: LeaveRequestAttachmentCreationRequest = {
                    leaveRequestId,
                    name: leaveRequestAttachment.filename,
                    mimeType: leaveRequestAttachment.mimetype,
                    s3Key: leaveRequestAttachment.key ?? '',
                };
                createLeaveRequestAttachment(leaveRequestAttachmentCreationRequest).catch(handleError);
            });
        }
    };

    const onLeaveRequestCreated = (leaveRequestId: number, isMultipleDays: boolean) => {
        showSnackbar(t('request_leave_dialog.messages.leave_request_created'), 'success');
        if (!isMultipleDays) {
            saveUploadedAttachments(leaveRequestId);
        }
        onSave();
    };
    const onLeaveRequestApproveError = (error: unknown) => {
        showSnackbar(t('request_leave_dialog.messages.could_not_approve_leave_request'), 'error');
        console.error(error);
    };
    const onLeaveRequestCreationError = (error: unknown) => {
        showSnackbar(t('request_leave_dialog.messages.could_not_create_leave_request'), 'error');
        console.error(error);
    };
    const onLeaveRequestUpdateError = (error: unknown) => {
        console.error(error);
        showSnackbar(t('request_leave_dialog.messages.could_not_update_leave_request'), 'error');
    };

    const createLeave = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        try {
            const leavePercentage = getLeaveRemainingTimePercentage(
                conflictingRequests,
                leaveRequestFormValue.leaveType.unitType,
                leaveRequestFormValue.leavePercentage,
            );
            const request: LeaveCreationMutation = mapLeaveRequestFormValuesToLeaveCreationMutation(leaveRequestFormValue, employeeId, leavePercentage);
            const leaveRequests = await createLeaveRequest(request);
            if (leaveRequestFormValue.isApproveAutomatically) {
                const approvalPromises = leaveRequests.map(async leaveRequest => {
                    try {
                        const approvedLeaveRequest = await approvePendingLeaveRequest(leaveRequest.id);
                        onLeaveRequestCreated(approvedLeaveRequest.id, leaveRequestFormValue.isMultipleDays);
                    } catch (error) {
                        onLeaveRequestApproveError(error);
                    }
                });
                await Promise.all(approvalPromises);
                onSave();
            } else if (leaveRequests?.length > 0) {
                onLeaveRequestCreated(leaveRequests[0].id, leaveRequestFormValue.isMultipleDays);
            }
        } catch (error) {
            onLeaveRequestCreationError(error);
        }
    };

    const onSaveLeaveRequest = async (isApproveAutomatically: boolean, leaveRequestId: number) => {
        if (isApproveAutomatically) {
            try {
                await approvePendingLeaveRequest(leaveRequestId);
                onSave();
            } catch (error) {
                onLeaveRequestUpdateError(error);
            }
        } else {
            onSave();
        }
    };

    const updateLeaveRequest = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        try {
            const leavePercentage = getLeaveRemainingTimePercentage(
                conflictingRequests,
                leaveRequestFormValue.leaveType.unitType,
                leaveRequestFormValue.leavePercentage,
            );
            const request: LeaveUpdateMutation = mapLeaveRequestFormValuesToLeaveUpdateMutation(leaveRequestFormValue, leavePercentage);
            if (!isEdit) {
                return; // display an error message
            }

            await editLeaveRequest(defaultLeaveRequest.id, request);
            await onSaveLeaveRequest(leaveRequestFormValue.isApproveAutomatically, defaultLeaveRequest.id);
        } catch (error) {
            onLeaveRequestUpdateError(error);
        }
    };

    const updatePendingLeaveRequest = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        const leavePercentage = getLeaveRemainingTimePercentage(
            conflictingRequests,
            leaveRequestFormValue.leaveType.unitType,
            leaveRequestFormValue.leavePercentage,
        );
        const request: LeaveUpdateMutation = mapLeaveRequestFormValuesToLeaveUpdateMutation(leaveRequestFormValue, leavePercentage);
        if (!isEdit) {
            return; // display an error message
        }
        try {
            await editPendingLeaveRequest(defaultLeaveRequest.id, request);
            await onSaveLeaveRequest(leaveRequestFormValue.isApproveAutomatically, defaultLeaveRequest.id);
        } catch (error) {
            onLeaveRequestUpdateError(error);
        }
    };

    const handleSave = (leaveRequestFormValue: LeaveRequestFormValues) => {
        if (!isEdit) {
            createLeave(leaveRequestFormValue);
        } else if (defaultLeaveRequest && defaultLeaveRequest.requestStatus !== 'PENDING') {
            updateLeaveRequest(leaveRequestFormValue);
        } else {
            updatePendingLeaveRequest(leaveRequestFormValue);
        }
    };

    const onSubmit = (leaveRequestFormValue: LeaveRequestFormValues) => {
        if (leaveRequestFormValue.isApproveAutomatically) {
            const balance = convertLeavesMinutesToUnit({
                input:
                    (leaveRequestFormValue.leaveType?.displayUnitType === UnitType.DAYS
                        ? leaveRequestAmounts?.remainingAmountInDays
                        : leaveRequestAmounts?.remainingAmountInMinutes) ?? 0,
                outputUnit: leaveRequestFormValue.leaveType?.displayUnitType,
                roundingType: leaveRequestFormValue.leaveType?.roundingType,
            });
            if (balance < 0 && leaveRequestFormValue.leaveType?.allowanceType !== AllowanceType.UNLIMITED) {
                setLeaveRequestWithNegativeBalance(leaveRequestFormValue);
                return;
            }
            checkConflictsLeave(leaveRequestFormValue);
        } else {
            handleSave(leaveRequestFormValue);
        }
    };

    const isFormValid = useCallback(() => {
        if (!previewResult) {
            return false;
        }

        // rule base on remainingAmountInMinutes
        if (!leaveRequestAmounts?.remainingAmountInDays && leaveRequestAmounts?.remainingAmountInDays !== 0) {
            return false;
        }

        const leaveRequest = getValues();

        // rule base on unitType
        const isDayUnit = leaveRequest.leaveType.unitType === UnitType.DAYS;

        if (isDayUnit) {
            if (!isFormLeaveTypeDaysValid(leaveRequest) || hasConflicts(conflictingRequests)) {
                return false;
            }
        } else if (!isFormLeaveTypeHoursValid(leaveRequest)) {
            return false;
        }

        return !(
            leaveRequest?.leaveType?.maxDurationInMinutes &&
            previewResult?.previews?.some(preview => (leaveRequest?.leaveType?.maxDurationInMinutes ?? 0) < (preview.amountInMinutes ?? 0))
        );
    }, [conflictingRequests, getValues, previewResult, leaveRequestAmounts?.remainingAmountInDays]);

    const showApproveAutomatically = canApproveLeaveRequests && isFormValid() && !!getValues('endDate');

    const leaveType = watch('leaveType');
    const isLeaveTypeDay = leaveType.unitType === UnitType.DAYS;

    const leaveTypesBasedOnUnitType = getLeaveTypesWithSameUnitType(leaveType.unitType);

    const onLeaveTypeChange = (leaveType: LeaveRequestFormValues['leaveType']) => {
        const newValue = leaveTypesBasedOnUnitType.find(lt => lt.id === leaveType.id);
        if (!newValue) {
            return;
        }
        const getNewEndDate = (leaveActivityType: LeaveActivityType, endDate: Nullable<LocalDate>, startDate: Nullable<LocalDate>): Nullable<LocalDate> => {
            if (leaveActivityType === LeaveActivityType.MEDICAL) {
                return getNull() as unknown as LocalDate;
            }
            return endDate ?? startDate;
        };
        const endDate = getNewEndDate(newValue.leaveActivityType, getValues('endDate'), getValues('startDate'));
        setValue('endDate', endDate ?? getNull());
    };

    const fetchLeaveRequestDetails = useCallback(
        async (formValues: LeaveRequestFormValues) => {
            // if we change the leave type, we want to switch to create mode (and calculate the available amount accordingly)
            const isNotTypeChanged = isEdit && formValues.leaveType?.id === defaultLeaveRequest?.leaveType?.id;

            setIsFetchingInformation(true);

            try {
                let filteredConflictingRequests: LeaveRequest[] = [];
                let filteredOverlappingRequests: LeaveRequest[] = [];

                if (formValues.isMultipleDays && formValues.dates.length === 0) {
                    //if its multiple days and no dates are selected, reset all the values because we do not have the data to search
                    setOverlappingRequests([]);
                    setConflictingRequests([]);
                    setPreviewResult(undefined);
                    setLeaveRequestAmounts(prevState => ({
                        ...prevState,
                        remainingAmountInDays: prevState?.availableAmountInDays,
                        remainingAmountInMinutes: prevState?.availableAmountInMinutes,
                    }));
                    return;
                }

                // If start date and end date are the same, reset end time period to make sure they are the same
                if (formValues.startDate && formValues.endDate && isSameDate(formValues.startDate, formValues.endDate)) {
                    formValues.endTimePeriod = formValues.startTimePeriod;
                }

                const isMedicalLeaveType = formValues.leaveType?.leaveActivityType === LeaveActivityType.MEDICAL;
                const shouldCheckForOverlapping = !(isMedicalLeaveType && formValues.endDate == undefined) || formValues.isMultipleDays;
                if (
                    formValues?.startDate &&
                    formValues?.endDate &&
                    !isOverlappingExceedsLimitInDays(toDate(formValues.startDate), toDate(formValues.endDate)) &&
                    shouldCheckForOverlapping
                ) {
                    const overlapping = await handleSearchOverlapping(formValues, employeeId);
                    filteredOverlappingRequests = overlapping?.filter(lr => lr.id !== defaultLeaveRequest?.id);
                    setOverlappingRequests(filteredOverlappingRequests);
                } else {
                    setOverlappingRequests([]);
                }

                if (shouldCheckForOverlapping) {
                    const conflicting = await handleSearchConflicting(formValues, employeeId);
                    filteredConflictingRequests = conflicting?.filter(lr => lr.id !== defaultLeaveRequest?.id);
                    setConflictingRequests(filteredConflictingRequests);
                } else {
                    setConflictingRequests([]);
                }

                const leaveRequestPreview = await handleSearchPreview(formValues, filteredConflictingRequests, employeeId, formValues.leavePercentage);

                setPreviewResult(leaveRequestPreview);
                const { totalAmountInDays: previewAmountInDays, totalAmountInMinutes: previewAmountInMinutes } = leaveRequestPreview ?? {};
                const availableAmountInDays = calculateAvailableAmountInDays(
                    defaultLeaveRequest,
                    leaveTypeHistories,
                    formValues.leaveType?.id,
                    isNotTypeChanged,
                );

                const availableAmountInMinutes = calculateAvailableAmountInMinutes(
                    defaultLeaveRequest,
                    leaveTypeHistories,
                    formValues.leaveType?.id,
                    isNotTypeChanged,
                );

                setLeaveRequestAmounts({
                    remainingAmountInDays: availableAmountInDays - previewAmountInDays,
                    availableAmountInDays,
                    remainingAmountInMinutes: availableAmountInMinutes - previewAmountInMinutes,
                    availableAmountInMinutes,
                });
            } catch (error) {
                console.error(error);
                handleError(error);
            } finally {
                setIsFetchingInformation(false);
            }
        },
        [employeeId, isEdit, defaultLeaveRequest, leaveTypeHistories],
    );

    const validateStartAndEndDate = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string) => {
            if (fieldName === 'startDate') {
                const newEndDate = getNewEndDateOnStartDateChange(toDate(formValues.endDate), toDate(formValues.startDate));
                formValues['endDate'] = formatToLocalDate(newEndDate) ?? getNull();
                setValue('endDate', formatToLocalDate(newEndDate) ?? getNull());
            }

            if ((fieldName === 'startDate' || fieldName === 'endDate') && formValues.startDate && formValues.endDate) {
                if (
                    !formValues.startTimePeriod ||
                    !getStartPeriodChoices(toDate(formValues.startDate), toDate(formValues.endDate)).includes(formValues.startTimePeriod)
                ) {
                    setValue('startTimePeriod', DayPeriod.ALL_DAY);
                    formValues['startTimePeriod'] = DayPeriod.ALL_DAY;
                }
                if (
                    !formValues.endTimePeriod ||
                    !getEndPeriodChoices(toDate(formValues.startDate), toDate(formValues.endDate)).includes(formValues.endTimePeriod)
                ) {
                    setValue('endTimePeriod', DayPeriod.ALL_DAY);
                    formValues['endTimePeriod'] = DayPeriod.ALL_DAY;
                }
            }

            if (
                (fieldName === 'startDate' || fieldName === 'endDate' || fieldName === 'startTimePeriod') &&
                formValues.startDate &&
                formValues.endDate &&
                isSameDate(formValues.startDate, formValues.endDate)
            ) {
                setValue('endTimePeriod', formValues.startTimePeriod);
                formValues['endTimePeriod'] = formValues.startTimePeriod;
            }
        },
        [setValue],
    );

    const validateLeaveType = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string, endDate?: Date) => {
            if (fieldName === 'leaveType' && formValues.leaveType?.leaveActivityType === LeaveActivityType.MEDICAL) {
                //we have to set the field to null for it to actually change the value
                setValue('endDate', formatToLocalDate(endDate) ?? getNull());
                formValues['endDate'] = formatToLocalDate(endDate) ?? getNull();
                setLeaveRequestAmounts(prevState => ({
                    ...prevState,
                    remainingAmountInDays: 0,
                }));
            }
        },
        [setValue],
    );

    const validateMultipleDays = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string) => {
            if (fieldName === 'isMultipleDays') {
                const dates = formValues.isMultipleDays ? [getCurrentLocalDate()] : [];
                setValue('dates', dates);
                formValues['dates'] = dates;
            }
        },
        [setValue],
    );

    const validateAndCorrectCurrentFormValues = useCallback(
        (formValues: LeaveRequestFormValues, fieldName: string, endDate?: Date): LeaveRequestFormValues => {
            validateStartAndEndDate(formValues, fieldName);

            validateLeaveType(formValues, fieldName, endDate);

            validateMultipleDays(formValues, fieldName);

            return formValues;
        },
        [validateLeaveType, validateMultipleDays, validateStartAndEndDate],
    );

    //TODO: maybe we can improve this with a debounce to see later
    //this useEffect is called on every change from the form
    useEffect(() => {
        //on first render call the fetchLeaveRequestDetails, so we can have the initial values
        fetchLeaveRequestDetails(watch());
        //after the first render, we will only call the fetchLeaveRequestDetails only when the form values change
        const subscription = watch((value, { name, type }) => {
            if (name === 'comment' || name === 'isApproveAutomatically') {
                return;
            }
            if (type === 'change' && name) {
                const formValuesValidated = validateAndCorrectCurrentFormValues(value as LeaveRequestFormValues, name, toDate(defaultLeaveRequest?.endDate));
                fetchLeaveRequestDetails(formValuesValidated);
            }
        });
        return () => subscription.unsubscribe();
    }, [fetchLeaveRequestDetails, validateAndCorrectCurrentFormValues, watch, defaultLeaveRequest]);

    const checkConflictsLeave = async (leaveRequestFormValue: LeaveRequestFormValues) => {
        const conflicts = await checkConflictsLeaveRequestFormValues(policies, employeeId, leaveRequestFormValue);
        if (conflicts) {
            setConflictingShifts(conflicts.shifts);
            setIsLeaveConflictsDialogOpen(true);
            setShiftReleaseRequest(conflicts.shiftReleaseRequest);
        } else {
            handleSave(leaveRequestFormValue);
        }
    };

    const leaveRequestFormValues = watch();
    const displayApproveAutomaticallyCheckbox =
        (defaultLeaveRequest && hasRequestStatus(defaultLeaveRequest.requestStatus) && showApproveAutomatically) ||
        (!defaultLeaveRequest && showApproveAutomatically);

    const commonLeaveTypeDetails = (
        <CommonLeaveTypeDetails
            leaveType={leaveType}
            filesMetadata={filesMetadata}
            onFileUploaded={handleFileUploaded}
            onFileRenamed={undefined}
            onFileRemoved={handleFileRemoved}
            fetchDocumentUrl={(itemId: number) => getLeaveRequestAttachmentUrl(itemId, 'ATTACHMENT').then(res => res)}
            amountInMinutes={previewResult?.totalAmountInMinutes ?? 0}
            containerId={'leave-request-dialog-' + defaultLeaveRequest?.id}
        />
    );

    const renderOverlappingRequestDetails = () => {
        if (!overlappingRequests?.length) {
            return <></>;
        }

        return (
            <Stack direction='column' spacing={1} alignItems='flex-start' pb={2}>
                <Typography variant='body2'>{t('leaves_page.whos_out')}</Typography>
                {overlappingRequests.map(lr => {
                    return (
                        <EmployeeAvatarWithDetails key={lr?.id} employee={lr?.employee}>
                            <Typography> {getLeaveRequestPeriodAsString(lr, lr.leaveType)}</Typography>
                        </EmployeeAvatarWithDetails>
                    );
                })}
            </Stack>
        );
    };

    return (
        <>
            <FormProvider {...formMethods}>
                <Stack spacing={2} component={DialogContent}>
                    {!displayOverlappingRequestDetails && (
                        <Stack spacing={2}>
                            <Controller
                                name={'leaveType'}
                                control={control}
                                render={({ field: { onChange, value, ...field } }) => (
                                    <FormControlLabel
                                        label={t('request_leave_dialog.leave_type')}
                                        labelPlacement='top'
                                        style={{ width: '100%' }}
                                        control={
                                            <Autocomplete
                                                {...field}
                                                options={leaveTypesBasedOnUnitType}
                                                value={value}
                                                renderInput={params => <TextField {...params} />}
                                                getOptionLabel={option => option.title}
                                                isOptionEqualToValue={(option, anotherOption) => option.id === anotherOption.id}
                                                fullWidth={true}
                                                disableClearable={true}
                                                onChange={(_, selectedLeaveType) => {
                                                    if (!selectedLeaveType) {
                                                        return;
                                                    }
                                                    onLeaveTypeChange(selectedLeaveType);
                                                    onChange(removeNullsFromLeaveType(selectedLeaveType));
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                            {isLeaveTypeDay ? (
                                <DailyLeaveTypeDetails
                                    previewResult={previewResult}
                                    isEdit={isEdit}
                                    overlappingRequests={overlappingRequests}
                                    conflictingRequests={conflictingRequests}
                                    remainingAmountInDays={leaveRequestAmounts?.remainingAmountInDays}
                                    availableAmountInDays={leaveRequestAmounts?.availableAmountInDays}
                                    remainingAmountInMinutes={leaveRequestAmounts?.remainingAmountInMinutes}
                                    availableAmountInMinutes={leaveRequestAmounts?.availableAmountInMinutes}
                                    displayOverlappingRequestDetails={() => {
                                        makeItDisplayOverlappingRequestDetails();
                                    }}
                                >
                                    {commonLeaveTypeDetails}
                                </DailyLeaveTypeDetails>
                            ) : (
                                <HourlyLeaveTypeDetails
                                    previewResult={previewResult}
                                    isEdit={isEdit}
                                    remainingAmountInMinutes={leaveRequestAmounts?.remainingAmountInMinutes}
                                    availableAmountInMinutes={leaveRequestAmounts?.availableAmountInMinutes}
                                >
                                    {commonLeaveTypeDetails}
                                </HourlyLeaveTypeDetails>
                            )}
                        </Stack>
                    )}
                    {displayOverlappingRequestDetails && renderOverlappingRequestDetails()}
                </Stack>
                <DialogActions>
                    {!displayOverlappingRequestDetails && (
                        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={1}>
                            {displayApproveAutomaticallyCheckbox && (
                                <Controller
                                    name={'isApproveAutomatically'}
                                    control={control}
                                    render={({ field: { value, ...restField } }) => (
                                        <FormControlLabel
                                            label={t('request_leave_dialog.automatically_approve_request')}
                                            labelPlacement='end'
                                            control={<Checkbox checked={value} {...restField} size='small' aria-label={'isApproveAutomatically'} />}
                                        />
                                    )}
                                />
                            )}
                            <Button
                                disabled={isFetchingInformation || !isFormValid()}
                                variant='contained'
                                color='primary'
                                size='medium'
                                onClick={handleSubmit(onSubmit, console.error)}
                            >
                                {t('general.save')}
                            </Button>
                        </Stack>
                    )}
                </DialogActions>
            </FormProvider>
            {isLeaveConflictsDialogOpen && (
                <LeavesConflictsDialog
                    open={isLeaveConflictsDialogOpen}
                    onClose={() => setIsLeaveConflictsDialogOpen(false)}
                    onSave={() => {
                        if (!shiftReleaseRequest) {
                            return;
                        }
                        shiftRelease(shiftReleaseRequest).then(() => {
                            handleSave(leaveRequestFormValues);

                            setIsLeaveConflictsDialogOpen(false);
                        });
                    }}
                    saveAndKeepConflicts={() => {
                        setIsLeaveConflictsDialogOpen(false);

                        handleSave(leaveRequestFormValues);
                    }}
                    shifts={conflictingShifts ?? []}
                />
            )}
            {leaveRequestWithNegativeBalance && (
                <ConfirmNegativeBalanceDialog
                    onSave={() => {
                        setLeaveRequestWithNegativeBalance(undefined);
                        checkConflictsLeave(leaveRequestFormValues);
                    }}
                    onClose={() => setLeaveRequestWithNegativeBalance(undefined)}
                    leaveType={leaveRequestWithNegativeBalance.leaveType}
                    availableAmountInDays={leaveRequestAmounts?.remainingAmountInDays}
                    availableAmountInMinutes={leaveRequestAmounts?.remainingAmountInMinutes}
                />
            )}
        </>
    );
};

const getDefaultLeaveRequestFormValues = (
    leaveRequest: LeaveRequest | undefined,
    leaveType: LeaveType,
    defaultLeaveRequestFormValues: Partial<LeaveRequestFormValues> | undefined,
): LeaveRequestFormValues => {
    return {
        ...{
            leaveType: leaveRequest?.leaveType ? removeNullsFromLeaveType(leaveRequest?.leaveType) : leaveType,
            dates: [],
            startTimePeriod: leaveRequest?.startTimePeriod ?? DayPeriod.ALL_DAY,
            endTimePeriod: leaveRequest?.endTimePeriod ?? DayPeriod.ALL_DAY,
            startDate: leaveRequest?.startDate ?? getCurrentLocalDate(),
            endDate: leaveRequest ? (leaveRequest.endDate ?? getNull()) : getCurrentLocalDate(),
            startTimeInMinutes: leaveRequest?.startTimeInMinutes ?? 0,
            endTimeInMinutes: leaveRequest?.endTimeInMinutes ?? 0,
            comment: leaveRequest?.comment ?? '',
            leavePercentage: leaveRequest?.leavePercentage ?? 100,
            isMultipleDays: false,
            isApproveAutomatically: false,
        },
        ...defaultLeaveRequestFormValues,
    };
};

const mapLeaveRequestFormValuesToLeaveCreationMutation = (
    formValues: LeaveRequestFormValues,
    employeeId: number,
    leavePercentage: number,
): LeaveCreationMutation => {
    const mutation = mapLeaveRequestFormValuesToLeaveUpdateMutation(formValues, leavePercentage);
    return {
        ...mutation,
        employeeId: employeeId,
        dates: formValues.isMultipleDays ? formValues.dates : [],
    };
};

const mapLeaveRequestFormValuesToLeaveUpdateMutation = (formValues: LeaveRequestFormValues, leavePercentage: number): LeaveUpdateMutation => {
    return {
        leaveTypeId: formValues.leaveType.id,
        //for the creation in case of being multiple days, the BE will prioritize the dates over the startDate and endDate and it is a mandatory field in the update so this case will probably not happen
        startDate: formValues.startDate ?? getCurrentLocalDate(),
        endDate: formValues.endDate ?? undefined,
        startTimeInMinutes: formValues.startTimeInMinutes,
        endTimeInMinutes: formValues.endTimeInMinutes,
        comment: formValues.comment,
        startTimePeriod: formValues.startTimePeriod,
        endTimePeriod: formValues.endTimePeriod,
        leavePercentage: leavePercentage,
    };
};

const hasRequestStatus = (status: LeaveRequestStatus): boolean => {
    return status && (status === 'PENDING' || status === 'APPROVED');
};

const calculateAvailableAmountInDays = (
    updatedLeave: LeaveRequest | undefined,
    leaveTypeHistories: LeaveTypeHistory[],
    leaveTypeId: number,
    isEdit: boolean,
): number => {
    const history = leaveTypeHistories.find(history => history.leaveType?.id === leaveTypeId);
    if (isEdit) {
        return (history?.availableAmountInDays ?? 0) + (updatedLeave?.usedAmountInDays ?? 0);
    } else {
        return history?.availableAmountInDays ?? 0;
    }
};

const calculateAvailableAmountInMinutes = (
    updatedLeave: LeaveRequest | undefined,
    leaveTypeHistories: LeaveTypeHistory[],
    leaveTypeId: number,
    isEdit: boolean,
): number => {
    const history = leaveTypeHistories.find(history => history.leaveType?.id === leaveTypeId);
    if (isEdit) {
        return (history?.availableAmountInMinutes ?? 0) + (updatedLeave?.usedAmountInMinutes ?? 0);
    } else {
        return history?.availableAmountInMinutes ?? 0;
    }
};

function isEndDateCanBeEmpty(formValues: LeaveRequestFormValues): boolean {
    return formValues.leaveType.leaveActivityType === LeaveActivityType.MEDICAL;
}

const isFormLeaveTypeDaysValid = (formValues: LeaveRequestFormValues) => {
    if (!isEndDateCanBeEmpty(formValues) && !formValues.endDate) {
        return false;
    }
    if (!formValues.startTimePeriod) {
        return false;
    }
    return formValues.endTimePeriod;
};

const isFormLeaveTypeHoursValid = (formValues: LeaveRequestFormValues) => {
    if (formValues.startTimeInMinutes === undefined) {
        //this value can be midnight = 0
        return false;
    }
    if (formValues.endTimeInMinutes === undefined) {
        //this value can be midnight = 0
        return false;
    }
    return formValues.startTimeInMinutes < formValues.endTimeInMinutes;
};

const checkConflictsLeaveRequestFormValues = async (
    policies: EmployeePolicy[],
    employeeId: number,
    leaveRequestFormValues: LeaveRequestFormValues,
): Promise<
    | {
          shifts: Shift[];
          shiftReleaseRequest: ShiftReleaseRequest;
      }
    | undefined
> => {
    if (shouldFetchEmployeeShifts(leaveRequestFormValues.leaveType, toDate(leaveRequestFormValues?.endDate), policies)) {
        const shiftStatuses = [ShiftStatus.SHIFT_DRAFT, ShiftStatus.SHIFT_PUBLISHED];
        const shiftSearchRequest = createShiftSearchRequestFromLeaveRequestFormValues(employeeId, shiftStatuses, leaveRequestFormValues);
        try {
            const response = await getEmployeeShifts(shiftSearchRequest);
            if (response[0]?.shifts?.length) {
                const shiftReleaseRequest: ShiftReleaseRequest = {
                    rangeDates: shiftSearchRequest.rangeDates,
                    employeeId: (shiftSearchRequest.employeeIds ?? [])[0],
                };
                const shifts = response.map(shift => shift.shifts).flat();
                return { shifts: shifts, shiftReleaseRequest: shiftReleaseRequest };
            } else {
                return undefined;
            }
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }
    return undefined;
};

const createShiftSearchRequestFromLeaveRequestFormValues = (
    employeeId: number,
    planningShiftStatus: ShiftStatus[],
    leaveRequestFormValues: LeaveRequestFormValues,
): ShiftSearchRequest => {
    const { startDate: newStartDate, endDate: newEndDate } = calculateStartEndDateForLeaveRequestFormValues(leaveRequestFormValues);
    if (leaveRequestFormValues.isMultipleDays) {
        return shiftAPISearchRequestWithMultipleDates(newStartDate, newEndDate, leaveRequestFormValues, employeeId, planningShiftStatus);
    } else {
        return shiftAPISearchRequest(newStartDate, newEndDate, employeeId, planningShiftStatus);
    }
};

const shiftAPISearchRequestWithMultipleDates = (
    startDate: Date,
    endDate: Date,
    leaveRequestFormValues: LeaveRequestFormValues,
    employeeId: number,
    statuses?: ShiftStatus[],
): ShiftSearchRequest => {
    const rangeDates = leaveRequestFormValues.dates.map(date => {
        const startDateTime = setTime(date, getTimeFormatFromDate(startDate));
        const endDateTime = setTime(date, getTimeFormatFromDate(endDate));

        if (leaveRequestFormValues.leaveType.unitType === UnitType.DAYS && leaveRequestFormValues.endTimePeriod === DayPeriod.ALL_DAY) {
            endDateTime.setDate(endDateTime.getDate());
        }

        return { start: startDateTime, end: endDateTime };
    });
    return {
        rangeDates: rangeDates,
        employeeIds: [employeeId],
        statuses: statuses ?? [],
    };
};

const calculateStartEndDateForLeaveRequestFormValues = (
    leaveRequestFormValues: LeaveRequestFormValues,
): {
    startDate: Date;
    endDate: Date;
} => {
    if (!leaveRequestFormValues.startDate || !leaveRequestFormValues.endDate) {
        return {
            startDate: toDate(leaveRequestFormValues.startDate) ?? getTodayDate(),
            endDate: toDate(leaveRequestFormValues.endDate) ?? getTodayDate(),
        };
    }
    const startDate = toDate(leaveRequestFormValues.startDate);
    const endDate = toDate(leaveRequestFormValues.endDate);

    if (leaveRequestFormValues.leaveType.unitType === UnitType.HOURS) {
        setStartTimeAndEndTime(startDate, endDate, leaveRequestFormValues.startTimeInMinutes ?? 0, leaveRequestFormValues.endTimeInMinutes ?? 0);
    } else if (leaveRequestFormValues.leaveType.unitType === UnitType.DAYS) {
        setDayPeriodTime(startDate, endDate, leaveRequestFormValues.startTimePeriod, leaveRequestFormValues.endTimePeriod);
    }

    return { startDate, endDate };
};

const getNewEndDateOnStartDateChange = (endDate: Date | undefined, startDate: Date | undefined): Date | undefined => {
    if (endDate && startDate && isBeforeDate(endDate, startDate)) {
        return startDate;
    }
    return endDate;
};
