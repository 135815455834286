import { Footer } from '@/page/layout/Footer';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, FormControlLabel, FormLabel, Link, Paper, Stack, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FC, useState } from 'react';
import { useGetJob } from '@/hooks/job/Job.hook';
import { Job, JobCreateMutation, JobUpdateMutation } from '@/domain/job/Job.model';
import { getLocalizedErrorMessage, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { Label } from '@/domain/label/Label.model';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel, isEmptyLabel } from '@/domain/label/Label.service';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { useGetJobFamilies } from '@/hooks/job-family/JobFamily.hook';
import { JobFamily } from '@/domain/job-family/JobFamily.model';
import { TranslatableRichTextEditor } from '@/components/translatable-rich-text-editor/TranslatableRichTextEditor';
import { createJob, deleteJob, updateJob } from '@/domain/job/Job.service';
import { handleError } from '@/utils/api.util';
import { Copy01Icon } from 'hugeicons-react';
import { showSnackbar } from '@/utils/snackbar.util';
import { useAppSelector } from '@/stores/store';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { getNull } from '@/utils/object.util';

export const JobSettingPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const jobId = Number(params.jobId) || undefined;

    const { data: job, isError: isJobError, isLoading: isJobLoading, error: jobError } = useGetJob(jobId);
    const { data: jobFamilies = [], isError: isJobFamilyError, isLoading: isJobFamilyLoading, error: jobFamilyError } = useGetJobFamilies();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const handleSave = async (request: JobSettingForm) => {
        const mutation = mapJobSettingFormToMutation(request);
        if (job?.id) {
            await onUpdate(job.id, mutation);
        } else {
            await onSave(mutation);
        }
    };

    const onUpdate = async (jobId: number, mutation: JobUpdateMutation) => {
        try {
            await updateJob(jobId, mutation);
            showSnackbar(t('job_settings_page.job_save_success'), 'success');
            navigate('/settings/organization/jobs');
        } catch (error) {
            handleError(error);
        }
    };

    const onSave = async (mutation: JobCreateMutation) => {
        try {
            await createJob(mutation);
            showSnackbar(t('job_settings_page.job_save_success'), 'success');
            navigate('/settings/organization/jobs');
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = async (jobId: number) => {
        try {
            await deleteJob(jobId);
            navigate('/settings/organization/jobs');
        } catch (e) {
            handleError(e);
        }
    };

    const hasJobFamiliesFeature = hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.JOB_FAMILIES);

    return (
        <StateHandler isLoading={isJobLoading || isJobFamilyLoading} isError={isJobError || isJobFamilyError} error={jobError || jobFamilyError}>
            <JobSettingForm job={job} jobFamilies={jobFamilies} onSave={handleSave} hasJobFamiliesFeature={hasJobFamiliesFeature} onDelete={handleDelete} />
        </StateHandler>
    );
};

const JobSettingForm: FC<{
    job?: Job;
    jobFamilies: JobFamily[];
    onSave: (data: JobSettingForm) => void;
    hasJobFamiliesFeature: boolean;
    onDelete: (jobId: number) => void;
}> = ({ job, jobFamilies, onSave, hasJobFamiliesFeature, onDelete }) => {
    const { t } = useTranslation();
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());
    const isEdit = !!job;

    const { control, handleSubmit, getValues, setValue } = useForm<JobSettingForm>({
        resolver: yupResolver(jobFormSchema(translationLanguage)),
        defaultValues: {
            name: job?.name || createDefaultLabel(),
            displayNameMen: job?.displayNameMen || createDefaultLabel(),
            displayNameWomen: job?.displayNameWomen || createDefaultLabel(),
            jobFamilyId: job?.jobFamily?.id ?? getNull(),
            purpose: job?.purpose || createDefaultLabel(),
            responsibility: job?.responsibility || createDefaultLabel(),
        },
    });

    const handleCopyName = () => {
        const name = getValues('name');
        setValue('displayNameMen', name);
        setValue('displayNameWomen', name);
    };

    return (
        <>
            <ContentContainer component={Paper} flex={1} gap={2} p={3} pb={100} mb={2}>
                <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                    <Typography variant='h1'>{t(isEdit ? 'job_settings_page.edit_job' : 'job_settings_page.new_job')}</Typography>
                    <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                </Stack>
                <Stack gap={2}>
                    <Controller
                        control={control}
                        name='name'
                        render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                            <TranslatableLabelInput
                                fullWidth
                                translationLanguage={translationLanguage}
                                onChange={onChange}
                                value={value}
                                sx={{ width: '300px' }}
                                label={t('job_settings_page.name')}
                                error={!!error}
                                helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                {...restField}
                            />
                        )}
                    />
                    <Stack direction={'row'} gap={2}>
                        <Controller
                            control={control}
                            name='displayNameMen'
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                <TranslatableLabelInput
                                    fullWidth
                                    sx={{ width: '300px' }}
                                    translationLanguage={translationLanguage}
                                    onChange={onChange}
                                    value={value}
                                    label={t('job_settings_page.display_name_men')}
                                    error={!!error}
                                    helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                    {...restField}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='displayNameWomen'
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                <TranslatableLabelInput
                                    fullWidth
                                    sx={{ width: '300px' }}
                                    translationLanguage={translationLanguage}
                                    onChange={onChange}
                                    value={value}
                                    label={t('job_settings_page.display_name_women')}
                                    error={!!error}
                                    helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                    {...restField}
                                />
                            )}
                        />
                        <Link
                            component='button'
                            color='primary'
                            onClick={handleCopyName}
                            sx={{
                                paddingTop: '15px',
                                display: 'flex',
                                gap: 0.5,
                            }}
                            alignItems={'center'}
                        >
                            <Copy01Icon /> <p>{t('job_settings_page.copy_name')}</p>
                        </Link>
                    </Stack>

                    {hasJobFamiliesFeature && (
                        <Controller
                            name={`jobFamilyId`}
                            control={control}
                            render={({ field: { onChange, value, ...restField }, fieldState }) => (
                                <FormControlLabel
                                    label={t('job_settings_page.function')}
                                    labelPlacement='top'
                                    sx={{ width: '300px' }}
                                    control={
                                        <Autocomplete
                                            fullWidth
                                            onChange={(_, newValue) => onChange(newValue?.id ?? getNull())}
                                            options={jobFamilies}
                                            value={jobFamilies.find(option => option.id === value) ?? getNull()}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={jobFamily => jobFamily.name}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message ? t('general.validations.required') : undefined}
                                                />
                                            )}
                                            {...restField}
                                        />
                                    }
                                />
                            )}
                        />
                    )}

                    <Controller
                        control={control}
                        name='purpose'
                        render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                            <TranslatableLabelInput
                                fullWidth
                                translationLanguage={translationLanguage}
                                onChange={onChange}
                                value={value}
                                label={t('job_settings_page.purpose')}
                                error={!!error}
                                helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                {...restField}
                            />
                        )}
                    />

                    <Controller
                        name={'responsibility'}
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                            <Stack gap={0}>
                                <FormLabel>{t('job_settings_page.responsibility')}</FormLabel>
                                <TranslatableRichTextEditor
                                    {...field}
                                    sx={{ width: '100%' }}
                                    errorMessage={getLocalizedErrorMessage(error, translationLanguage)}
                                    translationLanguage={translationLanguage}
                                    onUpdate={onChange}
                                    name={'responsibility'}
                                />
                            </Stack>
                        )}
                    />
                </Stack>
            </ContentContainer>
            <Footer>
                {isEdit && (
                    <Button color='error' variant={'contained'} aria-label={'delete_job'} onClick={() => onDelete(job.id)}>
                        {t('general.delete')}
                    </Button>
                )}
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained'>
                    {t(isEdit ? 'general.update' : 'general.create')}
                </Button>
            </Footer>
        </>
    );
};

const jobFormSchema = (translationLanguage: UserLanguage) => {
    return yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
        displayNameMen: getLabelFormSchema(translationLanguage),
        displayNameWomen: getLabelFormSchema(translationLanguage),
        jobFamilyId: yup.number().optional().nullable(),
        purpose: getLabelFormSchema(),
        responsibility: getLabelFormSchema(),
    });
};

export type JobSettingForm = {
    name: Label;
    displayNameMen: Label;
    displayNameWomen: Label;
    jobFamilyId?: Nullable<number>;
    purpose: Label;
    responsibility: Label;
};

const mapJobSettingFormToMutation = (form: JobSettingForm): JobUpdateMutation | JobCreateMutation => {
    return {
        name: form.name,
        //TODO: implement a way to change the order of the jobs ?
        order: 0,
        displayNameMen: form.displayNameMen,
        displayNameWomen: form.displayNameWomen,
        jobFamilyId: form.jobFamilyId ?? undefined,
        purpose: isEmptyLabel(form.purpose) ? undefined : form.purpose,
        responsibility: isEmptyLabel(form.responsibility) ? undefined : form.responsibility,
    };
};
