import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Label } from '@/domain/label/Label.model';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel, isEmptyLabel } from '@/domain/label/Label.service';
import { ReviewRatingScale, ReviewRatingScaleCreationMutation, ReviewRatingScaleUpdateMutation } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { createReviewRatingScale, deleteReviewRatingScale, updateReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.service';
import { useGetReviewRatingScale } from '@/hooks/review-rating-scale/ReviewRatingScale.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { handleError } from '@/utils/api.util';
import { getLocalizedErrorMessage, getRealmLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Delete02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

export const ReviewRatingScaleSettingFormPage: FC = () => {
    const params = useParams();
    const ratingId = params.scaleId === 'new' ? undefined : Number(params.scaleId) || undefined;

    const { data: reviewRatingScale, isLoading: isReviewRatingScalesLoading, isError: isReviewRatingScalesError, error } = useGetReviewRatingScale(ratingId);

    return (
        <StateHandler isLoading={isReviewRatingScalesLoading} isError={isReviewRatingScalesError} error={error}>
            <ReviewRatingScaleSettingForm reviewRatingScale={reviewRatingScale} ratingId={ratingId} />
        </StateHandler>
    );
};

export interface ReviewRatingScaleForm {
    name: Label;
    items: ReviewRatingScaleItem[];
}

type ReviewRatingScaleItem = {
    label: Label;
    description: Label;
    commentRequired: boolean;
};

type ReviewRatingScaleSettingFormProps = {
    reviewRatingScale: ReviewRatingScale | undefined;
    ratingId: number | undefined;
};

export const ReviewRatingScaleSettingForm: FC<ReviewRatingScaleSettingFormProps> = ({ reviewRatingScale, ratingId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mode = ratingId ? 'edit' : 'create';
    const MAX_ITEMS = 6;
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());

    const getInitialValues = (reviewRatingScale: ReviewRatingScale | undefined): ReviewRatingScaleForm => {
        if (!reviewRatingScale) {
            return {
                name: createDefaultLabel(),
                items: [
                    {
                        label: createDefaultLabel(),
                        description: createDefaultLabel(),
                        commentRequired: false,
                    },
                    {
                        label: createDefaultLabel(),
                        description: createDefaultLabel(),
                        commentRequired: false,
                    },
                ],
            };
        }
        return {
            name: reviewRatingScale.name,
            items: reviewRatingScale.items.map(item => {
                return {
                    label: item.label,
                    description: item.description && !isEmptyLabel(item.description) ? item.description : createDefaultLabel(),
                    commentRequired: item.commentRequired,
                };
            }),
        };
    };

    const itemSchema = yup.object().shape({
        label: getLabelFormSchema(translationLanguage),
        description: getLabelFormSchema(),
        commentRequired: yup.boolean().required(),
    });
    const schema = yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
        items: yup.array().of(itemSchema).required(),
    });

    const defaultValues = getInitialValues(reviewRatingScale);

    const { handleSubmit, watch, control } = useForm<ReviewRatingScaleForm>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const items: ReviewRatingScaleItem[] = watch('items');
    const maxScore = items?.length;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    });

    const mapFormToMutation = (reviewRatingScaleForm: ReviewRatingScaleForm): ReviewRatingScaleCreationMutation | ReviewRatingScaleUpdateMutation => {
        return {
            name: reviewRatingScaleForm.name,
            items: reviewRatingScaleForm.items.map((item, index) => {
                return {
                    label: item.label,
                    description: isEmptyLabel(item.description) ? undefined : item.description,
                    score: index + 1,
                    commentRequired: item.commentRequired,
                };
            }),
        };
    };

    const mapFormToCreationMutation = (reviewRatingScaleForm: ReviewRatingScaleForm): ReviewRatingScaleCreationMutation =>
        mapFormToMutation(reviewRatingScaleForm);

    const mapFormToEditionMutation = (reviewRatingScaleForm: ReviewRatingScaleForm): ReviewRatingScaleUpdateMutation =>
        mapFormToMutation(reviewRatingScaleForm);

    async function onSaveEdition(reviewRatingScaleForm: ReviewRatingScaleForm) {
        if (!ratingId) {
            return;
        }
        const reviewRatingScaleEditionMutation = mapFormToEditionMutation(reviewRatingScaleForm);

        try {
            await updateReviewRatingScale(ratingId, reviewRatingScaleEditionMutation);
            showSnackbar(t('reviews_settings_page.messages.rating_scale_updated'), 'success');
            navigate('/settings/reviews/scales');
        } catch (error) {
            handleError(error);
        }
    }

    async function onSaveCreation(reviewRatingScaleForm: ReviewRatingScaleForm) {
        const reviewRatingScaleCreationMutation = mapFormToCreationMutation(reviewRatingScaleForm);
        try {
            await createReviewRatingScale(reviewRatingScaleCreationMutation);
            showSnackbar(t('reviews_settings_page.messages.rating_scale_added'), 'success');
            navigate('/settings/reviews/scales');
        } catch (error) {
            handleError(error);
        }
    }

    const onSave = (reviewRatingScaleForm: ReviewRatingScaleForm) => {
        if (mode === 'create') {
            onSaveCreation(reviewRatingScaleForm).catch(handleError);
        } else if (mode === 'edit') {
            onSaveEdition(reviewRatingScaleForm).catch(handleError);
        }
    };

    const handleDelete = async () => {
        if (!ratingId) {
            return;
        }
        try {
            await deleteReviewRatingScale(ratingId);
            showSnackbar(t('reviews_settings_page.messages.rating_scale_deleted'), 'success');
            navigate('/settings/reviews/scales');
        } catch (error) {
            handleError(error);
        }
    };

    const canDeleteScore = (items: ReviewRatingScaleItem[]) => {
        return items.length > 2;
    };

    const canAddScore = (items: ReviewRatingScaleItem[]) => {
        return items.length < MAX_ITEMS;
    };

    const getFooterActions = (): FooterActionsProps['actions'] => {
        const footerActions: FooterActionsProps['actions'] = [];

        if (mode === 'edit') {
            footerActions.push({
                name: 'delete',
                children: t('general.delete'),
                variant: 'contained',
                color: 'error',
                onClick: () => {
                    handleDelete().catch(handleError);
                },
            });
        }
        footerActions.push({
            name: 'save',
            children: t(mode === 'edit' ? 'general.update' : 'general.create'),
            variant: 'contained',
            onClick: () => {
                handleSubmit(onSave, console.error)();
            },
        });
        return footerActions;
    };

    const footerActions = getFooterActions();

    return (
        <>
            <ContentContainer>
                <Stack gap={2}>
                    <Stack gap={2} direction='column' component={Paper} p={2}>
                        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                            <Typography variant='h1'>{t('reviews_settings_page.rating_scale_form.rating_scale')}</Typography>
                            <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                        </Stack>

                        <Controller
                            control={control}
                            name='name'
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                <TranslatableLabelInput
                                    fullWidth
                                    translationLanguage={translationLanguage}
                                    onChange={onChange}
                                    value={value}
                                    sx={{ width: '550px' }}
                                    label={t('reviews_settings_page.rating_scale_form.scale_name')}
                                    error={!!error}
                                    helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                    {...restField}
                                />
                            )}
                        />
                    </Stack>
                    <Stack gap={2}>
                        {fields.map((item, index) => {
                            return (
                                <Stack key={item?.id} component={Paper} p={2} pt={1}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Stack gap={0.5} direction={'row'} alignItems={'center'}>
                                            <Typography variant='body2bold'>{`(${index + 1})`}</Typography>
                                            {index === 0 && <Typography variant='body2bold'>{t('reviews_settings_page.rating_scale_form.worst')}</Typography>}
                                            {index === maxScore - 1 && (
                                                <Typography variant='body2bold'>{t('reviews_settings_page.rating_scale_form.best')}</Typography>
                                            )}
                                        </Stack>

                                        <IconButton
                                            disabled={!canDeleteScore(items)}
                                            size='medium'
                                            onClick={() => {
                                                remove(index);
                                            }}
                                        >
                                            <Delete02Icon />
                                        </IconButton>
                                    </Stack>

                                    <Stack gap={1}>
                                        <Controller
                                            control={control}
                                            name={`items.${index}.label`}
                                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                                <TranslatableLabelInput
                                                    fullWidth
                                                    translationLanguage={translationLanguage}
                                                    onChange={onChange}
                                                    value={value}
                                                    sx={{ width: '550px' }}
                                                    label={t('reviews_settings_page.rating_scale_form.title')}
                                                    error={!!error}
                                                    helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                                    {...restField}
                                                />
                                            )}
                                        />

                                        <Controller
                                            control={control}
                                            name={`items.${index}.description`}
                                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                                <TranslatableLabelInput
                                                    fullWidth
                                                    translationLanguage={translationLanguage}
                                                    onChange={onChange}
                                                    value={value}
                                                    sx={{ width: '550px' }}
                                                    label={t('reviews_settings_page.rating_scale_form.description')}
                                                    multiline
                                                    rows={3}
                                                    error={!!error}
                                                    helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                                    {...restField}
                                                />
                                            )}
                                        />

                                        <FormControlLabel
                                            label={t('reviews_settings_page.rating_scale_form.required_comment')}
                                            labelPlacement='end'
                                            control={<FieldSwitch name={`items.${index}.commentRequired`} control={control} />}
                                        />
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Button
                        variant='contained'
                        disabled={!canAddScore(items)}
                        onClick={() =>
                            append({
                                label: createDefaultLabel(),
                                description: createDefaultLabel(),
                                commentRequired: false,
                            })
                        }
                        sx={{ alignSelf: 'flex-start' }}
                    >
                        {t('reviews_settings_page.rating_scale_form.add_rating_score')}
                    </Button>
                </Stack>
            </ContentContainer>

            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </>
    );
};
