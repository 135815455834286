import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Button, FormControlLabel, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { FC, ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getRequestStatusTranslationKey } from '@/domain/leave-request/LeaveRequest.service';
import {
    ApproveTimesheetPaymentMutation,
    DeclineTimesheetPaymentMutation,
    TimesheetPayment,
    TimesheetPaymentSearchRequest,
} from '@/domain/timesheet-payment/TimesheetPayment.model';
import { approveTimesheetPayments, cancelTimesheetPayments, declineTimesheetPayments } from '@/domain/timesheet-payment/TimesheetPayment.service';
import { TimesheetsRequestStatus } from '@/domain/timesheet/Timesheet.model';
import { useTimesheetPaymentsSearch } from '@/hooks/timesheet/Timesheet.hook';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate, getCurrentLocalDate } from '@/utils/datetime.util';

import { Tick01Icon } from 'hugeicons-react';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';

/*
TODO: the due filter was removed until we have a better date picker, at the moment is mandatory to pick the 2 filters
If we will not use this filter anymore we can remove it from the page (just delete all the code comments related to it)

For more information on why we removed the filter, please check the following PR-3564
 */
export const PayrollTimesheetPaymentsPage: FC = () => {
    const { t } = useTranslation();

    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
    const [paymentDueDate, setPaymentDueDate] = useState<LocalDate>(getCurrentLocalDate());
    const agGridWrapper = useAgGridWrapper<TimesheetPayment>();

    const { dateRange, dateRangeViewType, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'payroll-timesheet-payments-date-range',
    });

    const timesheetPaymentSearchRequest: TimesheetPaymentSearchRequest = useMemo(() => {
        return {
            requestStartDate: dateRange[0],
            requestEndDate: dateRange[1],
        };
    }, [dateRange]);

    const { data: timesheetPayments, isLoading, error, isError, refetch: refetchTimesheetPayments } = useTimesheetPaymentsSearch(timesheetPaymentSearchRequest);

    const onBtnExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
            columnKeys: columnDefs
                .map(columnDef => columnDef.field ?? columnDef.colId)
                // we want to remove the first column
                .slice(1, columnDefs.length)
                .filter(c => c !== undefined),
        });
    };

    const refreshAndClearSelection = () => {
        refetchTimesheetPayments().catch(handleError);
        setSelectedRows([]);
    };

    function onSelectionChanged() {
        const selectedRows = agGridWrapper.gridRef.current?.api?.getSelectedRows() ?? [];
        setSelectedRows(selectedRows.map(row => row.id));
    }

    const declinePayments = async () => {
        const declineTimesheetPaymentsRequest: DeclineTimesheetPaymentMutation = {
            paymentIds: selectedRows,
        };
        try {
            await declineTimesheetPayments(declineTimesheetPaymentsRequest);
            refreshAndClearSelection();
        } catch (error) {
            handleError(error);
        }
    };

    const onClose = () => {
        setIsApproveModalOpen(false);
    };

    const onApproveTimesheetPayments = async () => {
        const approveTimesheetPaymentsRequest: ApproveTimesheetPaymentMutation = {
            paymentIds: selectedRows,
            paymentDueDate: paymentDueDate,
        };
        try {
            await approveTimesheetPayments(approveTimesheetPaymentsRequest);
            setIsApproveModalOpen(false);
            refreshAndClearSelection();
        } catch (error) {
            handleError(error);
        }
    };

    if (!timesheetPayments) {
        return <></>;
    }

    const menuRenderer = (data: ICellRendererParams<TimesheetPayment>) => {
        const handleOnCancel = async (id: number) => {
            const approveRequest = {
                paymentIds: [id],
            };
            try {
                await cancelTimesheetPayments(approveRequest);
                refreshAndClearSelection();
            } catch (error) {
                handleError(error);
            }
        };

        const handleOnDecline = async (id: number) => {
            const declineTimesheetPaymentsRequest: DeclineTimesheetPaymentMutation = {
                paymentIds: [id],
            };
            try {
                await declineTimesheetPayments(declineTimesheetPaymentsRequest);
                refreshAndClearSelection();
            } catch (error) {
                handleError(error);
            }
        };

        return (
            <EmployeeFieldMoreButton
                onEditClicked={() => {}}
                onDeleteClicked={() => {}}
                onApproveClicked={() => {
                    if (data.data?.id) {
                        setIsApproveModalOpen(true);
                        setSelectedRows([data.data.id]);
                    }
                }}
                onDeclineClicked={async () => {
                    if (data.data?.id) {
                        handleOnDecline(data.data.id);
                    }
                }}
                onCancelClicked={() => {
                    if (data.data?.id) {
                        handleOnCancel(data.data.id);
                    }
                }}
                approveEnabled={true}
                declineEnabled={true}
                onAddClicked={() => {}}
                isAddEnabled={false}
                deleteEnabled={false}
                disabled={false}
                cancelEnabled={true}
                editDisabled={true}
            />
        );
    };

    const columnDefs: RogerColDef<TimesheetPayment>[] = [
        {
            type: 'selection',
        },
        {
            field: 'employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            headerName: t('timesheets.payments_page.table_headers.first_name'),
            field: 'employee.firstName',
        },
        {
            headerName: t('timesheets.payments_page.table_headers.last_name'),
            field: 'employee.lastName',
        },
        {
            headerName: t('timesheets.payments_page.table_headers.duration'),
            field: 'amountInMinutes',
            type: 'minutesToHours',
        },
        {
            headerName: t('timesheets.payments_page.table_headers.request_date'),
            field: 'requestDate',
            type: 'date',
        },
        {
            headerName: t('timesheets.payments_page.table_headers.comment'),
            field: 'comment',
        },
        {
            headerName: t('timesheets.payments_page.table_headers.payment_date'),
            field: 'paymentDueDate',
            type: 'date',
        },
        {
            field: 'status',
            headerName: t('timesheets.payments_page.table_headers.status'),
            cellRenderer: StatusChips,
            valueFormatter: ({ value }) => t(getRequestStatusTranslationKey(value)),
        },
        {
            headerName: t('timesheets.payments_page.table_headers.requested_by'),
            field: 'createdBy.displayName',
        },
        {
            field: 'employee.employeeCode',
            headerName: t('payroll.id'),
            hide: true,
        },
        {
            type: 'actionMenu',
            cellRenderer: menuRenderer,
        },
    ];
    return (
        <Stack direction='column' gap={2} flex={1}>
            <Stack spacing={3} alignItems='center' direction='row' justifyContent='space-between' component={Paper} p={1}>
                <DateRangePicker dates={dateRange} onDatesChanged={onDateRangeChange} defaultViewType={dateRangeViewType} availableViews={['MONTH', 'RANGE']} />

                <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} onBtnExport={onBtnExport} />
            </Stack>
            <StateHandler error={error} isError={isError} isLoading={isLoading}>
                <Stack direction={'row'} alignItems={'center'} gap={1} minHeight={30} component={Paper} p={1}>
                    <Typography variant='body2' color='textPrimary' align='left' minWidth={80}>
                        {t('timesheets.selected')}: {selectedRows?.length > 0 ? selectedRows.length : 0}
                    </Typography>
                    {!!selectedRows?.length && (
                        <Stack direction='row' gap={1}>
                            <Button
                                size='small'
                                onClick={() => {
                                    setIsApproveModalOpen(true);
                                }}
                            >
                                {t('timesheets.approve_payment')}
                            </Button>
                            <Button size='small' onClick={() => declinePayments()}>
                                {t('timesheets.decline_payment')}
                            </Button>
                        </Stack>
                    )}
                </Stack>

                <Stack component={Paper} flex={1}>
                    <AgGridWrapper<TimesheetPayment>
                        rowData={timesheetPayments}
                        initRef={agGridWrapper.setGridRef}
                        onSelectionChanged={onSelectionChanged}
                        rowSelection='multiple'
                        getRowId={params => params.data.id.toString()}
                        columnDefs={columnDefs}
                    />
                </Stack>
            </StateHandler>

            <DialogContainer
                saveButtonText={t('general.approve')}
                title={t('timesheets.approve_payment')}
                open={isApproveModalOpen}
                onClose={onClose}
                onSave={onApproveTimesheetPayments}
                cancelEnabled={false}
            >
                <Stack gap={1}>
                    {!!selectedRows?.length && (
                        <Stack direction='row' gap={0.5}>
                            <Tick01Icon />
                            <Typography variant='body1bold'>{t('timesheets.payment_will_be_approved', { count: selectedRows.length })}</Typography>
                        </Stack>
                    )}
                    <FormControlLabel
                        label={t('timesheets.expected_payment_date')}
                        labelPlacement='top'
                        style={{ width: '100%' }}
                        control={
                            <DatePickerWrapper
                                value={paymentDueDate}
                                onChange={newStart => {
                                    if (newStart) {
                                        setPaymentDueDate(newStart);
                                    }
                                }}
                            />
                        }
                    />
                </Stack>
            </DialogContainer>
        </Stack>
    );
};

const StatusChips = (params: ICellRendererParams<TimesheetPayment>): ReactElement => {
    const { t } = useTranslation();
    const { status, paymentDueDate } = params.data ?? {};
    if (!status) {
        return <></>;
    }

    if (status === TimesheetsRequestStatus.APPROVED && paymentDueDate) {
        const approvedStatusText = t('timesheets.status_chip_payments_due_date', { dueDate: formatInDefaultDate(paymentDueDate) });
        return (
            <Tooltip title={approvedStatusText}>
                <RequestStatusChip status={status} />
            </Tooltip>
        );
    }

    return <RequestStatusChip status={status} />;
};
