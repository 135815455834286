import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, ButtonProps, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { cloneElement, FC, MouseEvent, PropsWithChildren, ReactElement, ReactNode, useState } from 'react';

import { useTranslation } from 'react-i18next';

type ConfirmDialogProps = {
    // Possible improvement: Make the children type more generic to allow any type of children and improve mouse event type
    children?: ReactElement<HTMLButtonElement>;
    onConfirm(): void;
    confirmButtonProps?: ButtonProps;
    title?: ReactNode;
    content?: ReactNode;
} & Omit<
    DialogWrapperProps,
    'open' | 'onClose'
    // If open/onClose is defined that means that the dialog is controlled
> & {
        open?: boolean;
        onClose?(): void;
    };

export const ConfirmDialog: FC<PropsWithChildren<ConfirmDialogProps>> = ({
    children,
    title,
    content,
    onConfirm,
    open = false,
    confirmButtonProps,
    onClose,
    ...rest
}) => {
    const { t } = useTranslation();

    // Possible improvement: Create a custom hook to handle the open state and children cloning
    // We should consider to do this if we have more components that need this behavior
    const [internalOpen, setInternalOpen] = useState(open);

    // Children should be always defined, but we need to handle the case when it's not until we provide a component to handle confirmation from menu items
    const childrenWithOnOpen = children
        ? cloneElement(children, {
              onClick: (e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setInternalOpen(true);
              },
          })
        : undefined;

    const handleInternalClose = () => setInternalOpen(false);

    // If not controlled, we should close the dialog when the confirm button is clicked
    const handleClose = onClose ? onClose : handleInternalClose;
    const isOpen = onClose ? open : internalOpen;

    const displayHeading = !title ? t('confirmation_dialog.are_you_sure') : title;

    return (
        <>
            {/* Render the cloned children with the onClick event handler */}
            {childrenWithOnOpen}

            <DialogWrapper open={isOpen} onClose={handleClose} {...rest} header={displayHeading}>
                {/* We are using DialogContentText to ensure that the content is styled correctly */}
                <DialogContent>{typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}</DialogContent>
                <DialogActions>
                    <Button
                        children={t('general.yes')}
                        fullWidth
                        onClick={() => {
                            onConfirm();

                            if (!onClose) {
                                handleInternalClose();
                            }
                        }}
                        {...confirmButtonProps}
                    />
                </DialogActions>
            </DialogWrapper>
        </>
    );
};
