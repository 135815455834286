import { EditableStackSection } from '@/components/section/StackSectionComponent/EditableStackSection';
import { StackSection } from '@/components/section/StackSectionComponent/StackSection';
import { SectionLoading } from '@/components/section/SectionLoading';
import { SectionField } from '@/components/section/types';
import { Employee, EmployeeBasicInfoUpdateMutation } from '@/domain/employee/Employee.model';
import { canManageEmployees } from '@/domain/permission/Permission.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { useAppSelector } from '@/stores/store';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { handleError } from '@/utils/api.util';
import { updateEmployeeBasicInfo } from '@/domain/employee/Employee.service';

type Props = {
    employee: Employee;
    onUpdateEmployeeBasicInfo: () => void;
};

export const EmployeeBasicInfoSection: FC<Props> = ({ employee, onUpdateEmployeeBasicInfo }) => {
    const { t } = useTranslation();
    const [editable, setEditable] = useState(false);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);

    // TODO : Improve dynamic form validation type
    const schema = yup.object().shape({
        firstName: yup.string().trim().required(),
        lastName: yup.string().trim().required(),
        displayName: yup.string().trim(),
        phoneNumber: yup.string().trim(),
        // TODO: phone number validation is disabled at the moment for some reason, check why and remove if not needed or enable it
        // .matches(new RegExp(PHONE_NUMBER_REGEX_PATTERN), { message: t('general.validations.not_type_phone_number'), excludeEmptyString: true }),
        employeeCode: yup.string().trim(),
    });

    // Check if the display name is changed to hide it if it is generated by the BE when we are in edit mode
    const beGeneratedDisplayName = realm?.reverseDisplayName ? `${employee.lastName} ${employee.firstName}` : `${employee.firstName} ${employee.lastName}`;
    const isDisplayNameChanged = () => {
        return !editable || employee.displayName !== beGeneratedDisplayName;
    };

    const fields: SectionField[] = [
        {
            formValueName: 'firstName',
            title: t('employee.first_name'),
            stringValue: employee.firstName,
            type: SectionFieldType.STRING,
            required: true,
            fieldType: 'EMPLOYEE_FIRSTNAME',
        },
        {
            formValueName: 'lastName',
            title: t('employee.last_name'),
            stringValue: employee.lastName,
            type: SectionFieldType.STRING,
            required: true,
            fieldType: 'EMPLOYEE_LASTNAME',
        },
        {
            formValueName: 'maidenName',
            title: t('employee.maiden_name'),
            stringValue: employee.maidenName,
            type: SectionFieldType.STRING,
            required: false,
            fieldType: 'EMPLOYEE_MAIDEN_NAME',
        },
        {
            formValueName: 'displayName',
            title: t('employee.display_name'),
            stringValue: isDisplayNameChanged() ? employee.displayName : '',
            type: SectionFieldType.STRING,
            required: false,
            fieldType: 'EMPLOYEE_DISPLAY_NAME',
        },
        {
            formValueName: 'email',
            title: t('employee.email'),
            stringValue: employee.email,
            type: SectionFieldType.STRING,
            // Email is editable only if the user has permission
            disabled: true,
            fieldType: 'EMPLOYEE_EMAIL',
        },
        {
            formValueName: 'phoneNumber',
            title: t('employee.phone_number'),
            stringValue: employee.phoneNumber,
            type: SectionFieldType.PHONE_NUMBER,
            required: false,
            fieldType: 'EMPLOYEE_PHONE_NUMBER',
        },
        {
            formValueName: 'employeeCode',
            title: t('employee.employee_code'),
            stringValue: employee.employeeCode,
            type: SectionFieldType.STRING,
            required: false,
            fieldType: 'EMPLOYEE_CODE',
        },
    ];

    const actionButtonEdit = {
        title: t('general.edit'),
        onClick: () => {
            setEditable(true);
        },
    };

    const mapEmployeeBasicInfoUpdateRequest = (formValues: Record<string, string>): EmployeeBasicInfoUpdateMutation => {
        return {
            firstName: formValues['firstName'],
            lastName: formValues['lastName'],
            maidenName: formValues['maidenName'],
            displayName: formValues['displayName'],
            email: formValues['email'],
            phoneNumber: formValues['phoneNumber'],
            employeeCode: formValues['employeeCode'],
        };
    };

    const onSave = async (formValues: Record<string, string>) => {
        const employeePersonalInfoUpdateRequest = mapEmployeeBasicInfoUpdateRequest(formValues);

        try {
            await updateEmployeeBasicInfo(employee.id, employeePersonalInfoUpdateRequest);
            onUpdateEmployeeBasicInfo();
            setEditable(false);
        } catch (e) {
            handleError(e);
        }
    };

    if (!employee) {
        return <SectionLoading sectionTitle={'employee.sections.basic-info'} />;
    }

    const getActionButtons = () => {
        if (canManageEmployees(policies, employee.id)) {
            return [actionButtonEdit];
        }
        return [];
    };

    return editable ? (
        <EditableStackSection
            sectionTitle={t('employee.sections.basic_info')}
            fields={fields}
            onSave={onSave}
            schema={schema}
            onCancel={() => setEditable(false)}
        />
    ) : (
        <StackSection sectionTitle={t('employee.sections.basic_info')} fields={fields} actionButtons={getActionButtons()} />
    );
};
