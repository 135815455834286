import { LeaveBalanceSearchRequest, LeaveTypeHistory, UserLeaveTypeBalance } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { leaveTypeHistoryApi } from '@/api/leave-type-history/LeaveTypeHistory.api';
import { LeaveActivityType } from '@/domain/leave-type/LeaveType.model';

export function getLeaveTypeHistories(
    userId: number,
    filters: {
        leaveActivityTypes?: LeaveActivityType[];
        endDate?: LocalDate;
    },
): Promise<LeaveTypeHistory[]> {
    return leaveTypeHistoryApi.getLeaveTypeHistories(userId, filters);
}

export function getLeaveTypeHistory(userId: number, userLeaveTypeId: number): Promise<LeaveTypeHistory> {
    return leaveTypeHistoryApi.getLeaveTypeHistory(userId, userLeaveTypeId);
}

export function getLeaveBalance(leaveTypeId: number, searchRequest: LeaveBalanceSearchRequest): Promise<UserLeaveTypeBalance[]> {
    return leaveTypeHistoryApi.getLeaveBalance(leaveTypeId, searchRequest);
}
