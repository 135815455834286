import { AdditionalActionPaper } from '@/components/autocomplete-wrapper/AdditionalActionPaper';
import { FlatTreeNode, useTreeAutoComplete } from '@/components/autocomplete-wrapper/TreeAutoComplete/useTreeAutoComplete';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Department } from '@/domain/department/Department.model';
import { mapDepartmentsToTreeNodes } from '@/domain/department/Department.utils';
import { Employee } from '@/domain/employee/Employee.model';
import { Location } from '@/domain/location/Location.model';
import { getLocations } from '@/domain/location/Location.service';
import { hasConfigureOrganizationPolicy } from '@/domain/permission/Permission.service';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetJobs } from '@/hooks/job/Job.hook';
import { DepartmentsTree } from '@/page/setting/organization/department/department-tree/DepartmentsTree';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, getRealmLanguage } from '@/utils/language.util';
import { defaultToNull, getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { JobsManagement } from '@/page/setting/job/JobsManagement';
import { EmploymentStatus } from '@/domain/employment/Employment.model';

const useLocations = () => {
    const [locations, setLocations] = useState<Location[]>();

    useEffect(() => {
        getLocations()
            .then(locations => {
                setLocations(locations);
            })
            .catch(error => {
                showSnackbar(error.response?.data?.message, 'error');
            });
    }, []);

    return locations;
};

export const LocationAutocomplete: FC<{ name: string }> = ({ name }) => {
    const { t } = useTranslation();
    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.location')}
                {'*'}
            </Typography>
            <LocationAutocompleteController name={name} />
        </Stack>
    );
};

export const LocationAutocompleteController: FC<{ name: string }> = ({ name }) => {
    const locations = useLocations();
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => (
                <Autocomplete
                    {...field}
                    onChange={(_, data) => onChange(data)}
                    getOptionLabel={location => location?.name}
                    options={locations ?? []}
                    getOptionDisabled={location => location.id}
                    value={defaultToNull(field.value)}
                    isOptionEqualToValue={isOptionEqualToValue}
                    fullWidth
                    renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                />
            )}
        />
    );
};

export const DepartmentAutocomplete: FC<{ onDepartmentChange?: (d: Department | null) => void; name: string }> = props => {
    const { t } = useTranslation();

    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.department')}
                {'*'}
            </Typography>
            <DepartmentAutocompleteController {...props} />
        </Stack>
    );
};

export const DepartmentAutocompleteController: FC<{
    onDepartmentChange?: (d: Department | null) => void;
    name: string;
}> = ({ onDepartmentChange, name }) => {
    const { t } = useTranslation();
    const { data: departments = [], refetch, isLoading } = useGetDepartments();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const { control, formState } = useFormContext<Record<typeof name, FlatTreeNode | null>>();

    const [openDepartmentCreationModal, setOpenDepartmentCreationModal] = useState<boolean>(false);
    const onCloseDepartmentCreationModal = () => {
        setOpenDepartmentCreationModal(false);
        refetch().catch(handleError);
    };
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());

    const departmentOptions = mapDepartmentsToTreeNodes(departments, translationLanguage);
    const defaultValue = formState.defaultValues?.[name] ? departmentOptions.find(d => d.id === formState.defaultValues?.[name]?.id) : undefined;
    const { autocompleteProps, textFieldInputProps } = useTreeAutoComplete({
        options: departmentOptions,
        defaultValue,
    });

    const getPaperComponent = (param: HTMLAttributes<HTMLElement>) => (
        <AdditionalActionPaper
            {...param}
            displayAdditionalAction={hasConfigureOrganizationPolicy(policies)}
            additionalActionLabel={t('general.manage_list')}
            onAdditionalActionClick={() => setOpenDepartmentCreationModal(true)}
        />
    );

    return (
        <Stack flex={1}>
            <Controller
                name={name}
                render={({ field: { onChange, ...field }, fieldState }) => {
                    return (
                        <Autocomplete
                            {...autocompleteProps}
                            {...field}
                            loading={isLoading}
                            PaperComponent={getPaperComponent}
                            onChange={(e, data, reason) => {
                                autocompleteProps.onChange(e, data, reason);
                                onChange(data);
                                if (onDepartmentChange) {
                                    const departmentOption = data ? departments.find(d => d.id === data.id) : undefined;
                                    onDepartmentChange(departmentOption ?? getNull());
                                }
                            }}
                            fullWidth
                            renderInput={params => (
                                <TextField
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    {...params}
                                    {...field}
                                    inputProps={{
                                        ...params.inputProps,
                                        'aria-label': 'department',
                                    }}
                                    InputProps={{ ...params.InputProps, ...textFieldInputProps }}
                                />
                            )}
                        />
                    );
                }}
                control={control}
            />

            {openDepartmentCreationModal && (
                <DialogContainer
                    maxWidth={'md'}
                    open={true}
                    onSave={onCloseDepartmentCreationModal}
                    onClose={onCloseDepartmentCreationModal}
                    customHeader={
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
                            <Typography variant='body1bold'>{t('employee.employment.department')}</Typography>
                            <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                        </Stack>
                    }
                >
                    <DepartmentsTree translationLanguage={translationLanguage} />
                </DialogContainer>
            )}
        </Stack>
    );
};

export const JobsAutocomplete: FC<{ name: string }> = props => {
    const { t } = useTranslation();

    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.job')}
                {'*'}
            </Typography>
            <JobsAutocompleteController {...props} />
        </Stack>
    );
};

export const JobsAutocompleteController: FC<{ name: string }> = ({ name }) => {
    const { data: jobs, refetch } = useGetJobs();
    const { control } = useFormContext();
    const [openJobCreationModal, setOpenJobCreationModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());
    const onCloseJobCreationModal = () => {
        setOpenJobCreationModal(false);
        refetch().catch(handleError);
    };

    const getPaperComponent = (param: HTMLAttributes<HTMLElement>) => (
        <AdditionalActionPaper
            {...param}
            displayAdditionalAction={hasConfigureOrganizationPolicy(policies)}
            additionalActionLabel={t('general.manage_list')}
            onAdditionalActionClick={() => setOpenJobCreationModal(true)}
        />
    );

    return (
        <Stack flex={1}>
            <Controller
                name={name}
                render={({ field: { onChange, ...field }, fieldState }) => (
                    <Autocomplete
                        {...field}
                        PaperComponent={getPaperComponent}
                        onChange={(_, data) => onChange(data)}
                        getOptionLabel={department => getLabelTranslation(department?.name)}
                        options={jobs ?? []}
                        value={defaultToNull(field.value)}
                        isOptionEqualToValue={isOptionEqualToValue}
                        fullWidth
                        renderInput={params => <TextField error={!!fieldState.error} helperText={fieldState.error?.message} {...params} />}
                    />
                )}
                control={control}
            />
            {openJobCreationModal && (
                <DialogContainer
                    maxWidth={'md'}
                    open={true}
                    onSave={onCloseJobCreationModal}
                    onClose={onCloseJobCreationModal}
                    customHeader={
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
                            <Typography variant='body1bold'>{t('employee.employment.job')}</Typography>
                            <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                        </Stack>
                    }
                >
                    <JobsManagement translationLanguage={translationLanguage} />
                </DialogContainer>
            )}
        </Stack>
    );
};

export const ManagerAutocomplete: FC<{ excludeEmployeeIds?: Employee['id'][]; name: string }> = props => {
    const { t } = useTranslation();

    return (
        <Stack direction='column'>
            <Typography variant='body1'>{t('employee.employment.manager')}</Typography>
            <ManagerAutocompleteController {...props} />
        </Stack>
    );
};

export const ManagerAutocompleteController: FC<{
    excludeEmployeeIds?: Employee['id'][];
    name: string;
}> = ({ excludeEmployeeIds = [], name }) => {
    const { data: employees } = useGetEmployees({ statuses: [EmploymentStatus.HIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.ON_LONG_LEAVE] });
    const { control } = useFormContext<Record<string, Employee[]>>();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, ...field }, fieldState }) => (
                <Autocomplete
                    multiple
                    onChange={(_, data) => onChange(data)}
                    getOptionLabel={employee => employee?.displayName}
                    getOptionKey={option => option.id}
                    options={employees?.filter(e => !excludeEmployeeIds.includes(e.id)) ?? []}
                    value={value ?? []}
                    isOptionEqualToValue={isOptionEqualToValue}
                    fullWidth
                    renderInput={params => (
                        <TextField
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            {...params}
                            {...field}
                            inputProps={{
                                ...params.inputProps,
                                'aria-label': 'managers',
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

const isOptionEqualToValue = <T extends { id: number }>(option: T, value: T) => {
    return option?.id === value?.id;
};
