import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { Employee } from '@/domain/employee/Employee.model';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, StackProps, Typography, useTheme } from '@mui/material';
import {
    employeeSelfReviewDone,
    hasManagerReviewers,
    hasPeerReviewers,
    hasUpwardReviewers,
    isEmployeeReviewCompleted,
    isEmployeeReviewEmployeeApproved,
    isEmployeeReviewSubmitted,
    isManagerHasPreparationAccess,
} from '@/domain/employee-review/EmployeeReview.service';
import {
    isEmployeeValidationRequired,
    isManagerReviewRequired,
    isPeerReviewAsked,
    isSelfReviewRequired,
    isUpwardReviewAsked,
} from '@/domain/review/Review.service';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { getAppConfig } from '@/config/config';
import { Calendar03Icon, Tick02Icon } from 'hugeicons-react';
import { formatDate } from '@/utils/datetime.util';

const config = getAppConfig();

type EmployeeReviewProgressProps = {
    employeeReview: EmployeeReview;
    currentEmployee: Employee;
} & StackProps;

export const EmployeeReviewProgress: FC<EmployeeReviewProgressProps> = ({ currentEmployee, employeeReview, ...rest }) => {
    const { t } = useTranslation();
    const { review } = employeeReview;
    const navigate = useNavigate();

    return (
        <Stack gap={1} {...rest}>
            {employeeReview.review && review && employeeReview.review.includePreparationStep && (
                <Stack>
                    <Stack flexGrow={1} flexBasis={0} direction={'row'} gap={1}>
                        <Typography variant='body1bold'>{t('reviews.employee_review.view_progress_modal.preparation_step')}</Typography>
                        {isManagerHasPreparationAccess(employeeReview, currentEmployee) && employeeSelfReviewDone(employeeReview) && (
                            <Link
                                onClick={() => navigate(`/reviews/${employeeReview.id}/manager-summary`)}
                                color={'primary'}
                                variant={'body2'}
                                sx={{
                                    cursor: 'pointer',
                                }}
                            >
                                {t('reviews.employee_review.view_progress_modal.redirect_review_feedback_link_label')}
                            </Link>
                        )}
                    </Stack>

                    {isSelfReviewRequired(review) && <SelfReviewTaskRow employeeReview={employeeReview} />}
                    {isManagerReviewRequired(review) && hasManagerReviewers(employeeReview) && <ManagerReviewTaskRow employeeReview={employeeReview} />}
                    {isPeerReviewAsked(review) && hasPeerReviewers(employeeReview) && <PeerReviewTaskRow employeeReview={employeeReview} />}
                    {isUpwardReviewAsked(review) && hasUpwardReviewers(employeeReview) && <UpwardReviewTaskRow employeeReview={employeeReview} />}
                </Stack>
            )}

            <Stack>
                <Typography variant='body1bold'>{t('reviews.employee_review.view_progress_modal.discussion_step')}</Typography>
                <DiscussionStepTaskRow employeeReview={employeeReview} />
            </Stack>
            {review && isEmployeeValidationRequired(review) && (
                <Stack>
                    <Typography variant='body1bold'>{t('reviews.employee_review.view_progress_modal.validation_step')}</Typography>
                    <ValidationStepTaskRow employeeReview={employeeReview} />
                </Stack>
            )}
        </Stack>
    );
};

type ValidationStepTaskRowProps = {
    employeeReview: EmployeeReview;
};

export const ValidationStepTaskRow: FC<ValidationStepTaskRowProps> = ({ employeeReview }) => {
    const { t } = useTranslation();
    const taskDeadlineDate = employeeReview.review?.endDate;
    const taskDone = isEmployeeReviewEmployeeApproved(employeeReview.status);
    const taskName = t('reviews.employee_review.view_progress_modal.validate_review_task');
    const employeesAssigned = [employeeReview.employee];
    return (
        <TaskRow taskDone={taskDone} taskName={taskName} employeesAssigned={employeesAssigned}>
            <Typography variant='body2' flexGrow={1} flexBasis={0}>
                {taskName}
            </Typography>
            {taskDeadlineDate && <TaskDeadlineDate taskDeadlineDate={taskDeadlineDate} taskName={taskName} />}
        </TaskRow>
    );
};

type DiscussionStepTaskRowProps = {
    employeeReview: EmployeeReview;
};

export const DiscussionStepTaskRow: FC<DiscussionStepTaskRowProps> = ({ employeeReview }) => {
    const getManagersAndEmployee = (employeeReview: EmployeeReview): Employee[] => {
        return [employeeReview.employee, ...employeeReview.managers.map(manager => manager.reviewer)];
    };

    const isEmployeeReviewHasBeenSubmitted = (employeeReview: EmployeeReview): boolean => {
        return isEmployeeReviewSubmitted(employeeReview.status) || isEmployeeReviewCompleted(employeeReview) || !!employeeReview.discussionSubmittedAt;
    };

    const { t } = useTranslation();
    const taskDone = isEmployeeReviewHasBeenSubmitted(employeeReview);
    const taskName = t('reviews.employee_review.view_progress_modal.discuss_and_submit_review_task');
    const employeesAssigned = getManagersAndEmployee(employeeReview);
    return (
        <TaskRow taskDone={taskDone} taskName={taskName} employeesAssigned={employeesAssigned}>
            <Typography variant='body2' flexGrow={1} flexBasis={0}>
                {taskName}
            </Typography>
        </TaskRow>
    );
};

type SelfReviewTaskRowProps = {
    employeeReview: EmployeeReview;
};

export const SelfReviewTaskRow: FC<SelfReviewTaskRowProps> = ({ employeeReview }) => {
    const { t } = useTranslation();
    const taskDone = employeeReview.selfReviewed;
    const taskName = t('reviews.employee_review.view_progress_modal.write_self_review_task');
    const taskDeadlineDate = employeeReview.review?.feedbackDeadlineDate;
    const employeesAssigned = [employeeReview.employee];

    return (
        <TaskRow taskDone={taskDone} taskName={taskName} employeesAssigned={employeesAssigned}>
            <Typography variant='body2' flexGrow={1} flexBasis={0}>
                {taskName}
            </Typography>

            {taskDeadlineDate && <TaskDeadlineDate taskDeadlineDate={taskDeadlineDate} taskName={taskName} />}
        </TaskRow>
    );
};

type PeerReviewTaskRowProps = {
    employeeReview: EmployeeReview;
};

export const PeerReviewTaskRow: FC<PeerReviewTaskRowProps> = ({ employeeReview }) => {
    const { t } = useTranslation();
    const taskDone = employeeReview.peerReviewers.every(peerReviewer => peerReviewer.reviewed);
    const taskName = t('reviews.employee_review.view_progress_modal.write_peer_review_task');
    const taskDeadlineDate = employeeReview.review?.feedbackDeadlineDate;
    const employeesAssigned = employeeReview.peerReviewers.map(peerReviewer => peerReviewer.reviewer);
    return (
        <TaskRow taskDone={taskDone} taskName={taskName} employeesAssigned={employeesAssigned}>
            <Typography variant='body2' flexGrow={1} flexBasis={0}>
                {taskName}
            </Typography>
            {taskDeadlineDate && <TaskDeadlineDate taskDeadlineDate={taskDeadlineDate} taskName={taskName} />}
        </TaskRow>
    );
};

type ManagerReviewTaskRowProps = {
    employeeReview: EmployeeReview;
};

export const ManagerReviewTaskRow: FC<PropsWithChildren<ManagerReviewTaskRowProps>> = ({ employeeReview }) => {
    const { t } = useTranslation();
    const taskDone = employeeReview.managers.every(manager => manager.reviewed);
    const taskName = t('reviews.employee_review.view_progress_modal.write_manager_review_task');
    const taskDeadlineDate = employeeReview.review?.feedbackDeadlineDate;
    const employeesAssigned = employeeReview.managers.map(manager => manager.reviewer);

    return (
        <TaskRow taskDone={taskDone} taskName={taskName} employeesAssigned={employeesAssigned}>
            <Typography variant='body2' flexGrow={1} flexBasis={0}>
                {taskName}
            </Typography>
            {taskDeadlineDate && <TaskDeadlineDate taskDeadlineDate={taskDeadlineDate} taskName={taskName} />}
        </TaskRow>
    );
};

type UpwardReviewTaskRowProps = {
    employeeReview: EmployeeReview;
};

export const UpwardReviewTaskRow: FC<UpwardReviewTaskRowProps> = ({ employeeReview }) => {
    const { t } = useTranslation();
    const taskDone = employeeReview.upwardReviewers.every(upwardReviewer => upwardReviewer.reviewed);
    const taskName = t('reviews.employee_review.view_progress_modal.write_upward_review_task');
    const taskDeadlineDate = employeeReview.review?.feedbackDeadlineDate;
    const employeesAssigned = employeeReview.upwardReviewers.map(upwardReviewer => upwardReviewer.reviewer);
    return (
        <TaskRow taskDone={taskDone} taskName={taskName} employeesAssigned={employeesAssigned}>
            <Typography variant='body2' flexGrow={1} flexBasis={0}>
                {taskName}
            </Typography>
            {taskDeadlineDate && <TaskDeadlineDate taskDeadlineDate={taskDeadlineDate} taskName={taskName} />}
        </TaskRow>
    );
};

type TaskRowProps = {
    taskDone: boolean;
    taskName: string;
    employeesAssigned: Employee[];
};
export const TaskRow: FC<PropsWithChildren<TaskRowProps>> = ({ taskDone, taskName, employeesAssigned, children }) => {
    return (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
            <TaskCheckmarkIcon taskDone={taskDone} taskName={taskName} />
            <Stack flex={1} direction={'row'} alignItems={'center'}>
                {children}
            </Stack>
            <StackedAvatars id={taskName + '_stacked_avatars'} employeeAvatars={employeesAssigned} sx={{ marginLeft: 'auto', width: '50px' }} />
        </Stack>
    );
};

type TaskCheckmarkIconProps = {
    taskDone: boolean;
    taskName: string;
};

export const TaskCheckmarkIcon: FC<TaskCheckmarkIconProps> = ({ taskDone, taskName }) => {
    const theme = useTheme();
    return (
        <Tick02Icon
            name={taskName + '_icon'}
            color={theme.palette.primary.main}
            style={{
                visibility: taskDone ? 'visible' : 'hidden',
                marginRight: 'auto',
            }}
        />
    );
};

type TaskDeadlineDateProps = {
    taskDeadlineDate: LocalDate;
    taskName: string;
};

export const TaskDeadlineDate: FC<TaskDeadlineDateProps> = ({ taskDeadlineDate, taskName }) => {
    return (
        <Stack direction={'row'} alignItems={'center'} flexGrow={1} gap={1} flexBasis={0}>
            <Calendar03Icon />
            {taskDeadlineDate && (
                <Typography variant='body2' id={taskName + '_date'}>
                    {formatDate(taskDeadlineDate, config.DEFAULT_DAY_MONTH_FORMAT)}
                </Typography>
            )}
        </Stack>
    );
};
