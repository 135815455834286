import { CalendarDayType, getCalendarDayTypeTranslationKey } from '@/domain/calendar/Calendar.model';
import { Employee } from '@/domain/employee/Employee.model';
import { DailyTimesheetReport, DayTimesheet, TimesheetsRequestStatus, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { formatDurationInHours, getCurrentLocalDate, MONTHS, toDate } from '@/utils/datetime.util';

import { ExcelCell, ExcelRow, ExcelStyle, RowSpanParams } from '@ag-grid-community/core';
import { Theme } from '@mui/material';
import i18next from 'i18next';
import { TimesheetRow } from './TimesheetsHistory';

export const getNumberOfRowByDate = (params: RowSpanParams<TimesheetRow>): number => {
    return params.data?.day?.timesheets?.length ?? 1;
};

export const getExcelStyles = (theme: Theme): ExcelStyle[] => [
    {
        // This id is defined by default
        id: 'header',
        font: {
            bold: true,
        },
        alignment: {
            vertical: 'Center',
            horizontal: 'Center',
        },
        interior: {
            color: theme.palette.grey[300],
            pattern: 'Solid',
        },
        borders: {
            borderTop: {
                weight: 1,
                color: theme.palette.grey[300],
            },
            borderBottom: {
                weight: 1,
                color: theme.palette.grey[300],
            },
        },
    },
    {
        id: 'title',
        font: {
            bold: true,
        },
        alignment: {
            vertical: 'Center',
        },
        interior: {
            color: theme.palette.grey[300],
            pattern: 'Solid',
        },
    },
    {
        id: 'greyBackground',
        font: {
            bold: true,
        },
        interior: {
            color: theme.palette.grey[200],
            pattern: 'Solid',
        },
        borders: {
            borderBottom: {
                weight: 2,
            },
        },
    },
    {
        id: 'wrapText',

        alignment: {
            wrapText: true,
        },
    },
    {
        id: 'bold',
        font: {
            bold: true,
        },
    },
    {
        id: 'center',
        alignment: {
            vertical: 'Center',
            horizontal: 'Center',
        },
    },
    {
        id: 'left',
        alignment: {
            vertical: 'Center',
            horizontal: 'Left',
        },
    },
    {
        id: 'verticalCenter',
        alignment: {
            vertical: 'Center',
        },
    },
    {
        id: 'non-working-day',
        interior: {
            color: theme.palette.grey[200],
            pattern: 'Solid',
        },
    },
    // The ExcelStyle id cell is applied to every cell that is not a header, and it's useful if you need a style to be applied to all cells.
    {
        id: 'cell',
        alignment: {
            vertical: 'Center',
            horizontal: 'Center',
            wrapText: true,
        },
        font: {
            outline: true,
        },
        borders: {
            borderTop: {
                weight: 1,
                color: theme.palette.grey[300],
            },
            borderBottom: {
                weight: 1,
                color: theme.palette.grey[300],
            },
        },
    },
];

export const getPrependRows = ({
    startDate,
    endDate,
    employee,
    totalWorkedTime,
    totalLeave,
    totalContract,
    totalPayment,
    totalAdjustment,
    overtimeBalance,
    columnsCount,
}: {
    startDate: LocalDate;
    endDate: LocalDate;
    employee: Employee;
    totalWorkedTime: number;
    totalLeave: number;
    totalContract: number;
    totalPayment: number;
    totalAdjustment: number;
    overtimeBalance: number;
    columnsCount: number;
}): ExcelRow[] => [
    ...getEmptyRows(3, columnsCount),
    {
        cells: [
            {
                data: {
                    value: i18next.t('timesheets.report.title', {
                        startDate: toDate(startDate),
                        endDate: toDate(endDate),
                    }),
                    type: 'String',
                },
                mergeAcross: columnsCount - 1,
                styleId: 'title',
            },
        ],
    },
    {
        cells: [
            {
                mergeAcross: columnsCount - 1,
            },
        ],
    },
    {
        cells: [
            {
                data: {
                    value: i18next.t('timesheets.report.employee'),
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: employee.displayName,
                    type: 'String',
                },
                styleId: ['wrapText', 'left'],
            },
            {},
            {
                data: {
                    value: i18next.t('timesheets.report.worked_time'),
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: formatDurationInHours(totalWorkedTime ?? 0),
                    type: 'String',
                },
                styleId: ['center'],
            },
        ],
    },
    {
        cells: [
            {},
            {},
            {},
            {
                data: {
                    value: i18next.t('timesheets.report.leaves'),
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: formatDurationInHours(totalLeave ?? 0),
                    type: 'String',
                },
                styleId: ['center'],
            },
        ],
    },
    {
        cells: [
            {},
            {},
            {},
            {
                data: {
                    value: i18next.t('timesheets.report.contract_due'),
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: formatDurationInHours(totalContract ?? 0),
                    type: 'String',
                },
                styleId: ['center'],
            },
        ],
    },
    {
        cells: [
            {
                data: {
                    value: i18next.t('timesheets.report.export_date'),
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: getCurrentLocalDate(),
                    type: 'DateTime',
                },
                styleId: ['left'],
            },
        ],
    },
    {
        cells: [
            {},
            {},
            {},
            {
                data: {
                    value: overtimeBalance ? i18next.t('timesheets.report.overtime_balance') : '',
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: overtimeBalance ? formatDurationInHours(overtimeBalance) : '',
                    type: 'Number',
                },
                styleId: ['center'],
            },
        ],
    },
    {
        cells: [
            {},
            {},
            {},
            {
                data: {
                    value: totalAdjustment ? i18next.t('timesheets.report.adjustments') : '',
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: totalAdjustment ? formatDurationInHours(totalAdjustment) : '',
                    type: 'Number',
                },
                styleId: ['center'],
            },
        ],
    },
    {
        cells: [
            {},
            {},
            {},
            {
                data: {
                    value: totalPayment ? i18next.t('timesheets.report.payments') : '',
                    type: 'String',
                },
                styleId: ['bold', 'wrapText', 'left'],
            },
            {
                data: {
                    value: totalPayment ? formatDurationInHours(totalPayment) : '',
                    type: 'Number',
                },
                styleId: ['center'],
            },
        ],
    },
    ...getEmptyRows(1, columnsCount),
];

const getEmptyRows = (rowsCount: number, columns: number): ExcelRow[] => {
    const emptyCell: ExcelCell = {};

    const emptyRow: ExcelRow = {
        cells: Array.from({ length: columns }, () => emptyCell),
    };

    return Array.from({ length: rowsCount }, () => emptyRow);
};
const emptyCell: ExcelCell = {};
export const getAppendRows = ({ columnsCount }: { columnsCount: number }): ExcelRow[] => {
    const finalEmptyCells = Array.from(
        {
            length: columnsCount - 6, // 6 is the number of cells before the last signature cell (2 for the employee, 2 for the manager, 2 for the empty cells)
        },
        () => emptyCell,
    );

    const signaturesRow: ExcelRow = {
        cells: [
            {
                data: {
                    value: i18next.t('timesheets.report.employee_sign'),
                    type: 'String',
                },
                mergeAcross: 1,
                styleId: ['greyBackground', 'verticalCenter'],
            },
            emptyCell,
            emptyCell,
            {
                data: { value: i18next.t('timesheets.report.manager_sign'), type: 'String' },
                mergeAcross: 1,
                styleId: ['greyBackground', 'verticalCenter'],
            },
            ...finalEmptyCells,
        ],
    };

    return [...getEmptyRows(2, columnsCount), signaturesRow];
};

export const getAreaWithComment = (timesheetRow: TimesheetRow): string => {
    if (timesheetRow.timesheet.type === TimesheetType.PUBLIC_HOLIDAY) {
        return i18next.t(getCalendarDayTypeTranslationKey(CalendarDayType.HOLIDAY));
    }
    if (timesheetRow.timesheet.area?.name && timesheetRow.timesheet.comment) {
        return timesheetRow.timesheet.area?.name + ' · ' + timesheetRow.timesheet.comment;
    }
    if (timesheetRow.timesheet.area?.name) {
        return timesheetRow.timesheet.area?.name;
    }
    return timesheetRow.timesheet.comment;
};

export const convertDailyTimesheetReportToTimesheetRows = (report: DailyTimesheetReport): TimesheetRow[] => {
    return report?.dayTimesheets?.reduce((acc, day) => {
        const timesheets = day?.timesheets ?? [];
        const plannedTimesheets = day?.plannedTimesheets ?? [];
        const nonWorkingType = day?.nonWorkingType;

        // Combine timesheets and plannedTimesheets into a single array, preserving their order
        const combinedTimesheets = timesheets.map((timesheet, index) => ({
            timesheet,
            plannedTimesheet: plannedTimesheets[index] || undefined, // Include plannedTimesheet or undefined if it doesn't exist
        }));

        // Create rows based on combinedTimesheets
        const row: TimesheetRow[] = combinedTimesheets.map(({ timesheet, plannedTimesheet }, index) => {
            // In a day we can have multiple timesheets of the same type, so we need to group them
            const timesheetsGroupedByType = timesheets?.filter(t => t.type === timesheet.type);

            // to span the cells by type, only the first timesheet of the group type will have the span value
            let isFirstGroup = true;
            if (timesheetsGroupedByType?.length > 1) {
                isFirstGroup = !!timesheetsGroupedByType[0].id && timesheetsGroupedByType[0].id === timesheet.id;
            }

            const timesheetsGroupedByTypeRowSpan = isFirstGroup ? timesheetsGroupedByType?.length : 0;
            const isThereTimesheetsPending = timesheetsGroupedByType?.some(
                t => timesheet.type === TimesheetType.TIMESHEET && t.status === TimesheetsRequestStatus.PENDING,
            );
            let status = isFirstGroup || timesheet.type !== TimesheetType.TIMESHEET ? timesheet.status : undefined;
            // in case there is a pending timesheet in the middle of approved timesheets, we need to set the status to pending
            status = isFirstGroup && timesheet.type === TimesheetType.TIMESHEET && isThereTimesheetsPending ? TimesheetsRequestStatus.PENDING : status;

            return {
                timesheet,
                plannedTimesheet,
                // Todo fix type
                day: index === 0 ? day : (undefined as unknown as DayTimesheet),
                status: status as unknown as TimesheetsRequestStatus,
                timesheetsGroupedByType,
                timesheetsGroupedByTypeRowSpan,
                nonWorkingType: nonWorkingType,
            } satisfies TimesheetRow;
        });

        return [...acc, ...row];
    }, [] as TimesheetRow[]);
};

export const activeMonth = (month: MONTHS): number => {
    switch (month) {
        case MONTHS.JANUARY:
            return 0;
        case MONTHS.FEBRUARY:
            return 1;
        case MONTHS.MARCH:
            return 2;
        case MONTHS.APRIL:
            return 3;
        case MONTHS.MAY:
            return 4;
        case MONTHS.JUNE:
            return 5;
        case MONTHS.JULY:
            return 6;
        case MONTHS.AUGUST:
            return 7;
        case MONTHS.SEPTEMBER:
            return 8;
        case MONTHS.OCTOBER:
            return 9;
        case MONTHS.NOVEMBER:
            return 10;
        case MONTHS.DECEMBER:
            return 11;
        default:
            return 0;
    }
};
