import { FC, useState } from 'react';

import { Button, Stack } from '@mui/material';
import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { showSnackbar } from '@/utils/snackbar.util';
import { ReviewStatusChip } from './ReviewStatusChip';

import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { closeReview, deleteReview, isReviewClosed, isReviewDraft, isReviewStarted, reopenReview, updateReview } from '@/domain/review/Review.service';
import { Review, ReviewUpdateMutation } from '@/domain/review/Review.model';
import { useGetReviews } from '@/hooks/review/Review.hook';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { UpdateReviewDialog } from '@/page/review/reviews-management/UpdateReviewDialog';
import { ManageReviewsContextMenu } from '@/page/review/reviews-management/ManageReviewsContextMenu';

export const ManageReviewsCyclePage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [updateReviewDialogOpen, setUpdateReviewDialogOpen] = useState<boolean>(false);
    const [activeReviewData, setActiveReviewData] = useState<Review>();
    const [activeReviewId, setActiveReviewId] = useState<number>();

    const {
        data: reviews = [],
        isError: isReviewsError,
        isLoading: isLoadingReviews,
        refetch: refetchReviews,
        error: reviewsError,
    } = useGetReviews({ reviewTypes: ['CYCLE'] });

    const handleRowClick = (review: Review) => {
        if (isReviewDraft(review)) {
            navigate(`/reviews/manage-reviews/${review.id}/setup`);
        } else {
            navigate(`/reviews/manage-reviews/${review.id}`);
        }
    };

    const onDeleteReview = (review: Review): void => {
        if (isReviewDraft(review)) {
            deleteReview(review.id)
                .then(() => {
                    refetchReviews().catch(handleError);
                    showSnackbar(t('reviews.messages.review_deleted'), 'success');
                })
                .catch(handleError);
        }
    };

    const onCloseReview = (review: Review): void => {
        if (isReviewStarted(review)) {
            closeReview(review.id)
                .then(() => {
                    refetchReviews().catch(handleError);
                })
                .catch(handleError);
        }
    };

    const onReopenReview = (review: Review): void => {
        if (isReviewClosed(review)) {
            reopenReview(review.id)
                .then(() => {
                    refetchReviews().catch(handleError);
                })
                .catch(handleError);
        }
    };

    const getEmployeeReviews = (employeeReviews: EmployeeReview[]) => {
        return employeeReviews.map(review => review.employee);
    };

    const onCloseUpdateReviewDialog = () => {
        setUpdateReviewDialogOpen(false);
        setActiveReviewData(undefined);
        refetchReviews().catch(handleError);
    };

    const onSaveReview = (id: number, data: ReviewUpdateMutation) => {
        updateReview(id, data)
            .then(() => {
                showSnackbar(t('reviews.messages.review_updated'), 'success');
            })
            .catch(handleError)
            .finally(() => {
                onCloseUpdateReviewDialog();
            });
    };

    const contextMenuRender = (params: ICellRendererParams<Review>) => {
        const review = params.data;
        if (!review) {
            return <></>;
        }

        return (
            <ManageReviewsContextMenu
                onUpdateReview={() => {
                    if (!isReviewDraft(review)) {
                        setActiveReviewData(review);
                        setActiveReviewId(review.id);
                        setUpdateReviewDialogOpen(true);
                    } else {
                        handleRowClick(review);
                    }
                }}
                reviewStatus={review.reviewStatus}
                onDelete={() => onDeleteReview(review)}
                onClose={() => onCloseReview(review)}
                onReopen={() => onReopenReview(review)}
            />
        );
    };

    const tableOptions: RogerColDef<Review>[] = [
        {
            field: 'reviewStatus',
            headerName: t('reviews.manage_reviews.status'),
            cellRenderer: ReviewStatusCell,
        },
        {
            field: 'name',
            headerName: t('reviews.manage_reviews.cycle'),
        },
        {
            field: 'endDate',
            headerName: t('reviews.manage_reviews.end_date'),
            type: 'date',
        },
        {
            field: 'feedbackDeadlineDate',
            headerName: t('reviews.manage_reviews.feedback_deadline_date'),
            type: 'date',
        },
        {
            field: 'employeeReviews',
            headerName: t('reviews.manage_reviews.reviewed_employees'),
            valueGetter: ({ data }) => getEmployeeReviews(data?.employeeReviews ?? []),
            type: 'stackedAvatars',
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },

        {
            field: 'owners',
            headerName: t('reviews.manage_reviews.owners'),
            type: 'stackedAvatars',
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },
        {
            type: 'actionMenu',
            cellRenderer: contextMenuRender,
        },
    ];

    const agGridWrapper = useAgGridWrapper<Review>();

    return (
        <StateHandler isLoading={isLoadingReviews} isError={isReviewsError} error={reviewsError}>
            <Stack flex={1} gap={2}>
                <Stack direction='row' justifyContent='flex-end' alignItems='flex-end' gap={1}>
                    <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} />
                    <Button component={Link} to='/reviews/manage-reviews/new/setup'>
                        {t('reviews.create_review')}
                    </Button>
                </Stack>
                <AgGridWrapper<Review>
                    rowData={reviews}
                    columnDefs={tableOptions}
                    initRef={agGridWrapper.setGridRef}
                    onRowClicked={({ data, event }) => {
                        if (!event?.defaultPrevented && data) {
                            return handleRowClick(data);
                        }
                    }}
                />
                {updateReviewDialogOpen && activeReviewId && activeReviewData && (
                    <UpdateReviewDialog
                        open={true}
                        handleSave={(id, dataForm) =>
                            onSaveReview(id, {
                                ...activeReviewData,
                                ...dataForm,
                            })
                        }
                        id={activeReviewId}
                        closeDialog={onCloseUpdateReviewDialog}
                        activeReviewData={activeReviewData}
                    />
                )}
            </Stack>
        </StateHandler>
    );
};

const ReviewStatusCell = ({ value }: { value: Review['reviewStatus'] }) => <ReviewStatusChip status={value} />;
