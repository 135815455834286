import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

import { API_BASE_URL, client } from '@/api/common';
import { EmployeeSectionFieldDocumentDTO } from '@/api/employee-section/EmployeeSection.api';
import { SectionDefinitionDTO } from '@/api/section-definition/SectionDefinition.api';
import { Label } from '@/domain/label/Label.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import {
    AvailableReportField,
    AvailableReportGroupedField,
    Report,
    ReportCategory,
    ReportCreateMutation,
    ReportEmployeeRow,
    ReportFieldDefinition,
    ReportFieldDefinitionMutation,
    ReportFilter,
    ReportFilterMutation,
    ReportFilterRule,
    ReportGroupBy,
    ReportPreview,
    ReportType,
    ReportUpdateMutation,
    ReportUpdateViewerMutation,
} from '@/domain/report/Report.model';
import { AxiosResponse } from 'axios';
import { DocumentDTO } from '@/api/document/Document.api';

const getReportsByCategoryURL = (category: ReportCategory): string => {
    switch (category) {
        case 'REPORT_EMPLOYEE': // Category
            return API_BASE_URL + `/reports?category=EMPLOYEES`;
        case 'REPORT_LEAVE': // Category
            return API_BASE_URL + `/reports?category=LEAVES`;
        case 'REPORT_TIMESHEET': // Category
            return API_BASE_URL + `/reports?category=TIMESHEETS`;
        case 'REPORT_REVIEW': // Category
            return API_BASE_URL + `/reports?category=REVIEWS`;
        case 'REPORT_OBJECTIVE':
            return API_BASE_URL + `/reports?category=OBJECTIVES`;
    }

    throw Error('Unknown report category');
};

const getReportUrl = (reportType: ReportType): string => {
    switch (reportType) {
        case 'EMPLOYEE_PERSONAL_REPORT':
            return API_BASE_URL + `/employee/reports`;
        case 'EMPLOYEE_SECTION_REPORT':
            return API_BASE_URL + `/employee/section/reports`;
        case 'EMPLOYEE_ADDRESS_REPORT':
            return API_BASE_URL + `/employee/address/reports`;
        case 'EMPLOYEE_DOCUMENT_REPORT':
            return API_BASE_URL + `/employee/document/reports`;
        case 'EMPLOYMENT_REPORT':
            return API_BASE_URL + `/employment/reports`;
        case 'EMPLOYEE_ROLE_REPORT':
            return API_BASE_URL + `/employee/role/reports`;
        case 'WORKING_PATTERN_REPORT':
            return API_BASE_URL + `/employee/working-pattern/reports`;
        case 'LEAVE_TYPE_POLICY_REPORT':
            return API_BASE_URL + `/employee/leave-type-policy/reports`;
        case 'LEAVE_CORRECTION_REPORT':
            return API_BASE_URL + `/leave-correction/reports`;
        case 'TIMESHEET_ADJUSTMENT_REPORT':
            return API_BASE_URL + `/timesheet-adjustment/reports`;
        case 'TIMESHEET_PAYMENT_REPORT':
            return API_BASE_URL + `/timesheet-payment/reports`;
        case 'REVIEW_FEEDBACK_REPORT':
            return API_BASE_URL + `/employee-review-feedback/reports`;
        case 'OBJECTIVES_REPORT':
            return API_BASE_URL + `/objective/reports`;
        default:
            throw Error('Unknown report type');
    }
};

// BETWEEN is a custom rule available only in FE to manage date range
type ReportFilterRuleDTO = Exclude<ReportFilterRule, ReportFilterRule.BETWEEN>;
type ReportFilterDTO = Omit<ReportFilter, 'rule'> & { rule: ReportFilterRuleDTO };
export type ReportDTO = Omit<Report, 'filters'> & {
    filters: ReportFilterDTO[];
};
export type ReportFieldDefinitionDTO = ReportFieldDefinition;
export type ReportCreationRequestDTO = Omit<ReportCreateMutation, 'historyRange'> & {
    historyStartDate: string;
    historyEndDate: string;
    groupByFields: ReportGroupBy[];
};

export type ReportFieldDefinitionRequestDTO = ReportFieldDefinitionMutation;

type ReportFilterRequestDTO = Omit<ReportFilterMutation, 'rule'> & { rule: ReportFilterRuleDTO };
export type ReportUpdateRequestDTO = Omit<ReportUpdateMutation, 'reportType' | 'id' | 'historyRange' | 'filters'> & {
    groupByFields: ReportGroupBy[];
    historyStartDate: string;
    historyEndDate: string;
    filters: ReportFilterRequestDTO[];
};
export type ReportPreviewRequestDTO = {
    filters?: ReportFilterRequestDTO[];
    fieldDefinitions?: ReportFieldDefinitionRequestDTO[];
    sectionDefinitionId?: number;
};
export type ReportEmployeeRowDTO = {
    fields: {
        fieldDefinition: ReportFieldDefinitionDTO;
        stringValue: string;
        numberValue: number;
        dateValue: LocalDate;
        labelValue: Label;
        customListItemReferences: { id: number; label: Label }[];
        // Check if we can remove this
        documentValue: DocumentDTO;
        sectionFieldDocumentValues: EmployeeSectionFieldDocumentDTO[];
        employeeValues: EmployeeAvatar[];
        updatedAt: string;
    }[];
    employeeId: number;
};
export type AvailableReportFieldDTO = AvailableReportField;
export type AvailableReportGroupedFieldDTO = {
    type: string;
    sectionDefinition: SectionDefinitionDTO | undefined;
    fields: AvailableReportFieldDTO[];
};

type ReportMutationType<T> = T extends { reportType: ReportType } ? ReportCreationRequestDTO : ReportUpdateRequestDTO;

const convertFilterMutationToRequestDTO = (filters: ReportFilterMutation[]): ReportFilterRequestDTO[] => {
    return filters
        ?.map(filter => {
            if (filter.rule !== ReportFilterRule.BETWEEN) {
                return filter as ReportFilterRequestDTO;
            }

            const greaterThanFilter: ReportFilterRequestDTO = {
                ...filter,
                rule: ReportFilterRule.GREATER_THAN_EQUALS,
                values: [{ dateValue: filter.values[0].dateValue }],
            };
            const lowerThanFilter: ReportFilterRequestDTO = {
                ...filter,
                rule: ReportFilterRule.LOWER_THAN_EQUALS,
                values: [{ dateValue: filter.values[1].dateValue }],
            };
            return [greaterThanFilter, lowerThanFilter];
        })
        .flat();
};

const convertReportMutationToRequestDTO = <T extends ReportCreateMutation | Omit<ReportUpdateMutation, 'id' | 'reportType'>>(
    mutation: T,
): ReportMutationType<T> => {
    const { historyRange, filters, ...restMutation } = mutation;

    const convertedMutation = {
        reportType: 'reportType' in mutation ? mutation.reportType : undefined,
        filters: convertFilterMutationToRequestDTO(filters),
        ...restMutation,
        historyStartDate: restMutation.includeHistory && historyRange?.[0] ? historyRange[0] : undefined,
        historyEndDate: restMutation.includeHistory && historyRange?.[1] ? historyRange[1] : undefined,
    };
    return convertedMutation as ReportMutationType<T>;
};

const createReport = async (request: ReportCreateMutation): Promise<Report> => {
    const reportUrl = getReportUrl(request.reportType);
    const payload = convertReportMutationToRequestDTO(request);
    const { data } = await client.post<ReportDTO, AxiosResponse<ReportDTO>, ReportCreationRequestDTO>(reportUrl, payload);
    return convertReportDTO(data);
};

const searchReports = async (category: ReportCategory): Promise<Report[]> => {
    try {
        const reportUrl = getReportsByCategoryURL(category);
        const { data: reports } = await client.get<ReportDTO[]>(reportUrl);
        return reports.map(convertReportDTO);
    } catch (error) {
        return Promise.reject(error);
    }
};

const convertReportFilterDTO = (filters: ReportFilterDTO[]): ReportFilter[] => {
    return filters.reduce<ReportFilter[]>((acc, filter) => {
        const existing = acc.find(f => f.fieldType === filter.fieldType);
        const withoutExistingFilter = acc.filter(f => f.fieldType !== filter.fieldType);

        // Merge filters to get only one with the BETWEEN rule
        if (existing && filter.rule === ReportFilterRule.GREATER_THAN_EQUALS) {
            const rangeFilter: ReportFilter = {
                ...existing,
                rule: ReportFilterRule.BETWEEN,
                values: [filter.values[0], existing.values[0]],
            };
            return [...withoutExistingFilter, rangeFilter];
        }

        if (existing && filter.rule === ReportFilterRule.LOWER_THAN_EQUALS) {
            const rangeFilter = {
                ...existing,
                rule: ReportFilterRule.BETWEEN,
                values: [existing.values[0], filter.values[0]],
            };
            return [...withoutExistingFilter, rangeFilter];
        }

        // new filter or existing filter but not a range
        return [...acc, filter];
    }, []);
};

const convertReportDTO = (report: ReportDTO): Report => {
    return {
        ...report,
        filters: convertReportFilterDTO(report.filters),
    };
};

const getReport = async (reportType: ReportType, reportId: number): Promise<Report> => {
    const reportUrl = getReportUrl(reportType) + `/${reportId}`;
    const { data: report } = await client.get<ReportDTO>(reportUrl);
    return convertReportDTO(report);
};

const updateReportTitle = async (reportId: number, reportType: ReportType, title: Label): Promise<void> => {
    const reportUrl = getReportUrl(reportType) + `/${reportId}/title`;
    await client.patch(reportUrl, { title });
};

const deleteReport = async (reportId: number, reportType: ReportType): Promise<void> => {
    const reportUrl = getReportUrl(reportType) + `/${reportId}`;
    await client.delete(reportUrl);
};

const updateReport = async ({ reportType, id: reportId, ...request }: ReportUpdateMutation): Promise<void> => {
    const reportUrl = getReportUrl(reportType) + `/${reportId}`;
    const payload = convertReportMutationToRequestDTO(request);
    await client.put(reportUrl, payload satisfies ReportUpdateRequestDTO);
};

type ReportPatchViewerDTO = {
    viewerIds: number[];
};

const updateReportViewers = async ({ reportType, id: reportId, viewers }: ReportUpdateViewerMutation): Promise<void> => {
    try {
        const reportUrl = getReportUrl(reportType) + `/${reportId}/viewers`;
        return client.patch(reportUrl, { viewerIds: viewers.map(e => e.id) } satisfies ReportPatchViewerDTO);
    } catch (error) {
        return Promise.reject(error);
    }
};

const convertReportRowDTO = (row: ReportEmployeeRowDTO): ReportEmployeeRow => {
    return {
        ...row,
        fields: row.fields.map(field => ({
            ...field,
            updatedAt: convertUTCIsoStringToDate(field.updatedAt),
        })),
    };
};

const getReportEmployeesPreview = async (
    { filters, fieldDefinitions, reportType, ...rest }: ReportPreview,
    { signal }: { signal?: AbortSignal } = {},
): Promise<ReportEmployeeRow[]> => {
    const { data = [] } = await client.post<ReportEmployeeRowDTO[], AxiosResponse<ReportEmployeeRowDTO[]>, ReportPreviewRequestDTO>(
        `${getReportUrl(reportType)}/search`,
        {
            ...rest,
            filters: convertFilterMutationToRequestDTO(filters) ?? [],
            fieldDefinitions: fieldDefinitions ?? [],
        },
        {
            signal,
        },
    );
    return data.map(convertReportRowDTO);
};

const getReportEmployeeRows = async (id: number, reportType: ReportType): Promise<ReportEmployeeRow[]> => {
    const { data } = await client.get<ReportEmployeeRowDTO[]>(`${getReportUrl(reportType)}/${id}/rows`);
    return data.map(convertReportRowDTO);
};

function getAvailableReportGroupedFields(reportType: ReportType, sectionDefinitionId: number | undefined): Promise<AvailableReportGroupedField[]> {
    let url = `${getReportUrl(reportType)}/available-fields`;
    if (reportType === 'EMPLOYEE_SECTION_REPORT') {
        url = `${getReportUrl(reportType)}/${sectionDefinitionId}/available-fields`;
    }
    return client.get<AvailableReportGroupedFieldDTO[]>(url).then(response => {
        return response.data.map(({ type, sectionDefinition, fields }) => ({
            sectionDefinition,
            fields,
            type,
            // compute unique id based on type and sectionDefinition.id
            id: type + '_' + (sectionDefinition?.id ?? 1),
        }));
    });
}

export const reportApi = {
    createReport,
    searchReports,
    getReport,
    updateReportTitle,
    deleteReport,
    updateReport,
    getReportEmployeesPreview,
    getReportEmployeeRows,
    getAvailableReportGroupedFields,
    updateReportViewers,
};
