import { Gender, MaritalStatus } from '@/domain/employee/Employee.model';
import { getCurrentLocalDate, getLocalDateMaxTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import { isValidAvs } from '@/utils/validator.util';
import i18next from 'i18next';
import * as yup from 'yup';

// we disable this rule because the return type of the schema should be inferred using yup.InferType
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPersonalInfoSectionSchema = () => {
    return yup.object().shape({
        birthdate: yup
            .string<LocalDate>()
            .nullable()
            .test(getLocalDateTestConfig())
            .test(getLocalDateMaxTestConfig(getCurrentLocalDate(), i18next.t('employee.birthdate_future_error'))),
        gender: yup.string().oneOf(Object.values(Gender)).nullable(),
        maritalStatus: yup.string().oneOf(Object.values(MaritalStatus)).nullable(),
        maritalStatusSince: yup.string<LocalDate>().nullable().test(getLocalDateTestConfig()),
        nationality: yup
            .object()
            .default(getNull())
            .shape({
                label: yup.string().required(),
                value: yup.string().required(),
            })
            .nullable(),
        avsNumber: yup
            .string()
            .default(getNull())
            .nullable()
            .test({
                message: i18next.t('employee.avs_number_not_valid'),
                test: avsNumber => (avsNumber ? isValidAvs(avsNumber) : true),
            }),
        personalEmail: yup.string().email().nullable(),
        personalPhoneNumber: yup.string().trim(),
    });
};

export type PersonalInfoSectionFormValues = yup.InferType<ReturnType<typeof getPersonalInfoSectionSchema>>;
