import { SectionDefinition, SectionFieldType } from '@/domain/section-setting/Section.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { isNumberOrEmpty, MAX_NUMBER_VALUE } from '@/utils/math.util';
import { getNull } from '@/utils/object.util';
import { isValidIBAN } from 'ibantools';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ObjectShape } from 'yup';

export const useCustomSectionRowSchema = ({
    sectionDefinition,
}: {
    sectionDefinition: SectionDefinition;
}): {
    schema: yup.ObjectSchema<ObjectShape>;
} => {
    const { t } = useTranslation();

    const shape = sectionDefinition.fields.reduce((acc, curr) => {
        const sectionFieldDefinition = curr;

        let fieldSchema;

        switch (sectionFieldDefinition.type) {
            case SectionFieldType.STRING:
                fieldSchema = yup.string();
                break;
            case SectionFieldType.CUSTOM_MULTI_LIST_ITEM:
                fieldSchema = yup
                    .array()
                    .nullable()
                    .transform((_, val) => {
                        return val?.length ? val : undefined;
                    });
                break;
            case SectionFieldType.CUSTOM_LIST_ITEM:
                fieldSchema = yup
                    .number()
                    .nullable()
                    .transform((_, val) => {
                        if (val.length === 0) {
                            return undefined;
                        }
                        return val || val === 0 ? Number(val) : undefined;
                    });
                break;
            case SectionFieldType.NUMBER:
                fieldSchema = yup
                    .number()
                    .nullable()
                    .max(MAX_NUMBER_VALUE, t('general.validations.valid_number_max'))
                    .min(0, t('general.validations.valid_number_min'))
                    .transform((_, val) => {
                        return isNumberOrEmpty(val);
                    })
                    .typeError(t('general.validations.not_type_number'));
                break;
            case SectionFieldType.EMPLOYEE_REFERENCE:
                fieldSchema = yup
                    .number()
                    .nullable()
                    .transform((_, val) => {
                        return val || val === 0 ? Number(val) : undefined;
                    });
                break;
            case SectionFieldType.DATE:
                fieldSchema = yup.string<LocalDate>().nullable().test(getLocalDateTestConfig());
                break;
            case SectionFieldType.SECTION_FIELD_DOCUMENT:
                fieldSchema = yup
                    .array()
                    .of(
                        yup.object().nullable().default(getNull()).shape({
                            id: yup.number().nullable(),
                            itemId: yup.number().nullable(),
                            url: yup.string().nullable(),
                            key: yup.string().required(),
                            mimetype: yup.string().required(),
                        }),
                    )

                    .nullable()
                    .test({
                        message: t('general.validations.required'),
                        test: val => {
                            if (sectionFieldDefinition.mandatory) {
                                return !!val && val.length > 0;
                            }
                            return true;
                        },
                    });
                break;
            case SectionFieldType.COUNTRY:
                fieldSchema = yup
                    .object()
                    .shape({
                        label: yup.string(),
                        value: yup.string(),
                    })
                    .nullable()
                    .test({
                        message: t('general.validations.required'),
                        test: val => {
                            if (sectionFieldDefinition.mandatory) {
                                return !!val?.value;
                            }
                            return true;
                        },
                    });
                break;
            // TODO: phone number validation is disabled at the moment for some reason, check why and remove if not needed or enable it
            // case SectionFieldType.PHONE_NUMBER:
            //     fieldSchema = yup.string().nullable();
            //     .matches(new RegExp(PHONE_NUMBER_REGEX_PATTERN), {
            //         message: t('general.validations.not_type_phone_number'),
            //         excludeEmptyString: !sectionFieldDefinition.mandatory,
            //     });
            //     break;
            case SectionFieldType.IBAN_NUMBER:
                fieldSchema = yup
                    .string()
                    .nullable()
                    .transform((_, val: string) => (val ? val.replace(new RegExp('\\s', 'g'), '') : val))
                    .test({
                        message: t('general.validations.not_typ_iban_number'),
                        test: val => (!val ? !sectionFieldDefinition.mandatory : isValidIBAN(val)),
                    });
                break;

            default:
                fieldSchema = yup.string();
                break;
        }

        if (sectionFieldDefinition.mandatory) {
            fieldSchema = fieldSchema.required();
        }

        return { ...acc, [curr.id]: fieldSchema };
    }, {});

    const schema = yup.object().shape(shape);

    return { schema };
};

export type CustomSectionRowFormValues = yup.InferType<ReturnType<typeof useCustomSectionRowSchema>['schema']>;
