import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, FormControlLabel, Grid, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { CalendarTemplate } from '@/domain/calendar/Calendar.model';

type Props = {
    open: boolean;
    onClose: () => void;
    onSave: (data: { template: CalendarTemplate; name: string }) => void;
};

const getCalendarTemplateTranslationKeys = (templateName: CalendarTemplate): string => {
    switch (templateName) {
        case CalendarTemplate.GENEVA:
            return 'calendars_settings_page.templates.geneva';
        case CalendarTemplate.SCHAFFHAUSEN:
            return 'calendars_settings_page.templates.schaffhausen';
        case CalendarTemplate.VALAIS:
            return 'calendars_settings_page.templates.valais';
        case CalendarTemplate.URI:
            return 'calendars_settings_page.templates.uri';
        case CalendarTemplate.GRAUBUNDEN:
            return 'calendars_settings_page.templates.graubunden';
        case CalendarTemplate.NIDWALDEN:
            return 'calendars_settings_page.templates.nidwalden';
        case CalendarTemplate.ZURICH:
            return 'calendars_settings_page.templates.zurich';
        case CalendarTemplate.FRANCE:
            return 'calendars_settings_page.templates.france';
        case CalendarTemplate.TICINO:
            return 'calendars_settings_page.templates.ticino';
        case CalendarTemplate.SOLOTHURN:
            return 'calendars_settings_page.templates.solothurn';
        case CalendarTemplate.ST_GALLEN:
            return 'calendars_settings_page.templates.st_gallen';
        case CalendarTemplate.BASEL_LANDSHAFT:
            return 'calendars_settings_page.templates.basel_landshaft';
        case CalendarTemplate.BASEL_STADT:
            return 'calendars_settings_page.templates.basel_stadt';
        case CalendarTemplate.GLARUS:
            return 'calendars_settings_page.templates.glarus';
        case CalendarTemplate.APPENZEL_INNERRHAUDEN:
            return 'calendars_settings_page.templates.appenzel_innerrhauden';
        case CalendarTemplate.VAUD:
            return 'calendars_settings_page.templates.vaud';
        case CalendarTemplate.NEUCHATEL:
            return 'calendars_settings_page.templates.neuchatel';
        case CalendarTemplate.LUCERNE:
            return 'calendars_settings_page.templates.lucerne';
        case CalendarTemplate.BERN:
            return 'calendars_settings_page.templates.bern';
        case CalendarTemplate.FRIBOURG:
            return 'calendars_settings_page.templates.fribourg';
        case CalendarTemplate.AARGAU:
            return 'calendars_settings_page.templates.aargau';
        case CalendarTemplate.THURGAU:
            return 'calendars_settings_page.templates.thurgau';
        case CalendarTemplate.ZUG:
            return 'calendars_settings_page.templates.zug';
        case CalendarTemplate.JURA:
            return 'calendars_settings_page.templates.jura';
        case CalendarTemplate.OBWALDEN:
            return 'calendars_settings_page.templates.obwalden';
        case CalendarTemplate.SCHWYZ:
            return 'calendars_settings_page.templates.schwyz';
        case CalendarTemplate.APPENZEL_AUSSERRHAUDEN:
            return 'calendars_settings_page.templates.appenzel_ausserrhauden';
        default:
            return '';
    }
};

export const ImportCalendar: FC<Props> = ({ open, onClose, onSave }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().trim().required(),
        template: yup
            .string()
            .trim()
            .required()
            .oneOf(Object.keys(CalendarTemplate) as CalendarTemplate[]),
    });

    const { register, handleSubmit, control } = useForm<{ template: CalendarTemplate; name: string }>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            template: CalendarTemplate.GENEVA,
        },
    });

    return (
        <DialogContainer open={open} onClose={onClose} onSave={handleSubmit(onSave, console.error)} title={t('calendars_settings_page.import_calendar')}>
            <Grid container direction='column' spacing={2}>
                <Grid item>
                    <FormControlLabel
                        label={t('calendars_settings_page.calendar_name')}
                        style={{ width: '100%' }}
                        labelPlacement='top'
                        control={<TextField fullWidth {...register('name')} />}
                    />
                    <Grid item sx={{ mt: 1 }}>
                        <Controller
                            name='template'
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FormControlLabel
                                        label={t('calendars_settings_page.template_label')}
                                        labelPlacement='top'
                                        style={{ width: '100%' }}
                                        control={
                                            <Autocomplete
                                                fullWidth
                                                options={Object.keys(CalendarTemplate).map(k => k as CalendarTemplate)}
                                                style={{ width: '100%' }}
                                                onChange={(_, option) => field.onChange(option)}
                                                getOptionLabel={option => t(getCalendarTemplateTranslationKeys(option))}
                                                renderInput={params => <TextField {...params} />}
                                            />
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DialogContainer>
    );
};
