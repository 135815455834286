import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { DurationUnit } from '@/i18n/i18n';
import { MonthlyTimesheetReport, TimesheetSearch } from '@/domain/timesheet/Timesheet.model';
import { useGetEmployeeMonthlyTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { MissingCount } from '@/page/timesheet/missing-count/MissingCount';
import { getCurrentLocalDate, getEndOfYear, getStartOfYear } from '@/utils/datetime.util';

import { Paper, Stack } from '@mui/material';
import i18next from 'i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BalanceTimesheetFilter, useBalanceTimesheetPageFilters } from '@/hooks/timesheet/BalanceTimesheetPageFilters.hook';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey } from '@/components/filters-bar/FiltersBar.util';
import { Employment } from '@/domain/employment/Employment.model';
import { getLabelTranslation } from '@/utils/language.util';

export const TimesheetsBalancePage: FC = () => {
    // FILTERS
    const { filters: availableFilters } = useBalanceTimesheetPageFilters();

    const [filters, setFilters] = useFiltersStorage('timesheet-balance-filters', availableFilters);

    const mapFiltersToTimesheetSearchRequest = (filters: BalanceTimesheetFilter[]) => {
        //the filters will have an undefined date, and we need to always have an endDate (string Date or undefined)
        const filterEndDate = filters.find(filter => filter.key === 'endDate')?.value as LocalDate | undefined;
        const endDate = filterEndDate ? filterEndDate : getEndOfYear(getCurrentLocalDate());
        const search: TimesheetSearch = {
            startDate: getStartOfYear(endDate),
            endDate: endDate,
            locationIds: getFilterValueIdsByKey(filters, 'LOCATION_IDS'),
            departmentIds: getFilterValueIdsByKey(filters, 'DEPARTMENT_IDS'),
            jobIds: getFilterValueIdsByKey(filters, 'JOB_IDS'),
            managerIds: getFilterValueIdsByKey(filters, 'MANAGER_IDS'),
        };
        return search;
    };

    const {
        data: monthlyTimesheetReports = [],
        isLoading,
        isFetching = false,
        isError,
        error,
    } = useGetEmployeeMonthlyTimesheets({
        timesheetSearch: mapFiltersToTimesheetSearchRequest(filters),
        options: { enabled: !!filters.length },
    });

    const navigate = useNavigate();
    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<MonthlyTimesheetReport>();

    const columnDefs = getColumnDefs();

    const onBtnExport = () => {
        gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
            // we don't want to export the avatar column
            columnKeys: columnDefs
                .map(columnDef => columnDef.field ?? columnDef.colId)
                // we want to remove the first column
                .slice(1, columnDefs.length)
                .filter(c => c !== undefined),
        });
    };

    const goToEmployeeTimesheets = (employeeId: number) => navigate(`/profile/${employeeId}/timesheets`);

    return (
        <Stack gap={2} flex={1}>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <Stack flexGrow={1} gap={2}>
                    <Stack component={Paper} p={1} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <FiltersBar filters={filters} onFiltersChange={setFilters} />
                        <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
                    </Stack>
                    <StateHandler isLoading={isFetching} isError={false} error={error}>
                        <Stack component={Paper} flex={1}>
                            <AgGridWrapper<MonthlyTimesheetReport>
                                initRef={setGridRef}
                                onRowClicked={({ data }) => (data?.employee.id ? goToEmployeeTimesheets(data.employee.id) : undefined)}
                                rowData={monthlyTimesheetReports}
                                columnDefs={columnDefs}
                            />
                        </Stack>
                    </StateHandler>
                </Stack>
            </StateHandler>
        </Stack>
    );
};

const getColumnDefs = (): RogerColDef<MonthlyTimesheetReport>[] => [
    {
        field: 'employee',
        headerName: '',
        type: 'avatar',
    },
    {
        field: 'employee.email',
        headerName: 'Email',
        hide: true,
    },
    {
        field: 'employee.firstName',
        headerName: i18next.t('manage_people_page.table_headers.firstName'),
    },
    {
        field: 'employee.lastName',
        headerName: i18next.t('manage_people_page.table_headers.lastName'),
    },
    {
        field: 'employee.currentEmployments',
        headerName: i18next.t('timesheets.table_headers.job_title'),
        valueFormatter: ({ value }: { value: Employment[] }) => value?.flatMap(employment => getLabelTranslation(employment.job.name)).join(', '),
    },
    {
        field: 'employee.currentEmployments',
        headerName: i18next.t('timesheets.table_headers.main_location'),
        valueFormatter: ({ value }: { value: Employment[] }) => value?.flatMap(employment => employment.location.name).join(', '),
    },
    {
        field: 'employee.currentEmployments',
        headerName: i18next.t('timesheets.table_headers.manager'),
        valueGetter: ({ data }) => data?.employee.currentEmployments?.flatMap(employment => employment.managers),
        type: 'stackedAvatars',
    },
    {
        field: 'totalWorkedCount',
        headerName: i18next.t('timesheets.table_headers.totalWorkedCount'),
        type: 'minutesToHours',
    },
    {
        field: 'totalLeaveCount',
        headerName: i18next.t('timesheets.table_headers.totalLeaveCount'),
        valueGetter: data => ((data.data?.totalForecastedLeaveCount ?? 0) + (data.data?.totalForecastedUnpaidLeaveCount ?? 0)) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalContractCount',
        headerName: i18next.t('timesheets.table_headers.totalContractCount'),
        type: 'minutesToHours',
    },
    {
        field: 'previousCarryover',
        headerName: i18next.t('timesheets.table_headers.carryoverPreviousYear'),
        type: 'minutesToHours',
    },
    {
        field: 'totalForecastedDifference',
        headerName: i18next.t('timesheets.table_headers.totalDifference'),
        type: 'minutesToHours',
    },
    {
        field: 'totalForecastedCompensationCount',
        headerName: i18next.t('timesheets.table_headers.totalCompensation'),
        valueGetter: data => (data.data ? -(data.data.totalForecastedCompensationCount ?? 0) : 0) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalForecastedPaymentCount',
        headerName: i18next.t('timesheets.payments'),
        valueGetter: data => (data.data?.totalPaymentCount ? -data.data?.totalPaymentCount : 0) / 60,
        valueFormatter: params =>
            params.value
                ? i18next.t('duration.formatDuration', {
                      duration: params.value,
                      unit: DurationUnit.HOURS,
                  })
                : '-',
    },
    {
        field: 'totalForecastedAdjustmentCount',
        headerName: i18next.t('timesheets.adjustments'),
        type: 'minutesToHours',
    },
    {
        field: 'forecastedBalance',
        headerName: i18next.t('timesheets.table_headers.balance'),
        type: 'minutesToHours',
    },
    {
        headerName: i18next.t('timesheets.table_headers.missing'),
        field: 'missingCount',
        cellClass: ['display-flex'],
        cellRenderer: ({ data }) => <MissingCount missingCount={data?.missingCount} />,
    },
    {
        field: 'employee.employeeCode',
        headerName: i18next.t('payroll.id'),
        hide: true,
    },
];
