import { RouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { FolderMutation, FolderType } from '@/domain/document/Document.model';
import { createFolder, deleteFolder, updateFolder } from '@/domain/document/Document.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { displayFormRouteLeavingGuard } from '@/components/route-leaving-guard/RouteLeavingGuard.util';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { useGetFolderById } from '@/hooks/document/Document.hook';
import { getLocalizedErrorMessage, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { createDefaultLabel, isEmptyLabel } from '@/domain/label/Label.service';
import { getLabelFormSchema } from '@/domain/label/Label.schema';

const folderFormSchema = (translationLanguage: UserLanguage) => {
    return yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
        description: getLabelFormSchema(),
        folderType: yup
            .string()
            .required()
            .oneOf(['COMPANY', 'EMPLOYEE'] as FolderType[]),
    });
};

export const FolderSettingPage: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const defaultLanguage = getRealmLanguage();
    const [translationLanguage, setTranslationLanguage] = useState(defaultLanguage);

    const folderId = Number(params.folderId) || undefined;

    const { data: folder, isLoading, isError, error } = useGetFolderById(folderId);

    const { handleSubmit, control, formState } = useForm<FolderFormMutation>({
        resolver: yupResolver(folderFormSchema(defaultLanguage)),
        values: {
            name: folder?.name || createDefaultLabel(),
            description: folder?.description || createDefaultLabel(),
            folderType: folder?.folderType ?? 'COMPANY',
        },
    });

    const goToList = () => {
        navigate('/settings/documents/folders');
    };

    const handleSave = async (data: FolderFormMutation) => {
        const folderMutation = mapFolderFormToMutation(data);
        if (!folderId) {
            try {
                await createFolder(folderMutation);
                showSnackbar(t('folder_settings_page.folder_created'), 'success');
                goToList();
            } catch (error) {
                handleError(error);
            }
        } else {
            try {
                await updateFolder(folderId, folderMutation);
                showSnackbar(t('folder_settings_page.folder_updated'), 'success');
                goToList();
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleDelete = async (folderId: number) => {
        try {
            await deleteFolder(folderId);
            showSnackbar(t('folder_settings_page.folder_deleted'), 'success');
            goToList();
        } catch {
            showSnackbar(t('folder_settings_page.folder_cant_be_deleted'), 'warning');
        }
    };

    const getFooterActions = (): FooterActionsProps['actions'] => {
        const footerActions: FooterActionsProps['actions'] = [];

        if (folderId) {
            footerActions.push({
                name: 'delete',
                children: t('general.delete'),
                variant: 'contained',
                color: 'error',
                onClick: () => handleDelete(folderId),
            });
        }
        footerActions.push({
            name: 'save',
            children: t(folderId ? 'general.update' : 'general.create'),
            variant: 'contained',
            onClick: () => {
                handleSubmit(handleSave, console.error)();
            },
        });
        return footerActions;
    };

    const footerActions = getFooterActions();

    return (
        <>
            <RouteLeavingGuard when={displayFormRouteLeavingGuard(formState)} />

            <ContentContainer>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <Stack component={Paper} gap={2} flex={1} p={2}>
                        <Stack direction='row' justifyContent='space-between'>
                            <Typography variant='body1bold'>{t('folder_settings_page.about_folder')}</Typography>
                            <Stack direction='row' alignItems='center'>
                                <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                            </Stack>
                        </Stack>

                        <Controller
                            name={'name'}
                            control={control}
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                <TranslatableLabelInput
                                    label={t('folder_settings_page.table.folderName')}
                                    translationLanguage={translationLanguage}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                    {...restField}
                                />
                            )}
                        />

                        <Controller
                            name={'description'}
                            control={control}
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                                <TranslatableLabelInput
                                    label={t('folder_settings_page.table.folderDescription')}
                                    translationLanguage={translationLanguage}
                                    onChange={onChange}
                                    value={value}
                                    error={!!error}
                                    helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                    fullWidth
                                    InputProps={{ multiline: true, minRows: 4 }}
                                    {...restField}
                                />
                            )}
                        />

                        <Controller
                            name='folderType'
                            control={control}
                            render={({ field: { onChange, ...restField }, fieldState: { error } }) => (
                                <FormControlLabel
                                    label={t('folder_settings_page.table.folderType')}
                                    labelPlacement='top'
                                    disabled={!!folderId}
                                    control={
                                        <Autocomplete
                                            {...restField}
                                            fullWidth
                                            options={['COMPANY', 'EMPLOYEE']}
                                            onChange={(_, data) => onChange(data)}
                                            disableClearable
                                            getOptionLabel={option => t('folder_settings_page.folder_type', { context: option })}
                                            renderInput={params => <TextField {...params} error={!!error} helperText={error?.message} />}
                                        />
                                    }
                                />
                            )}
                        />
                    </Stack>
                </StateHandler>
            </ContentContainer>
            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </>
    );
};

export type FolderFormMutation = yup.InferType<ReturnType<typeof folderFormSchema>>;

const mapFolderFormToMutation = (form: FolderFormMutation): FolderMutation => {
    return {
        ...form,
        name: form.name,
        description: isEmptyLabel(form.description) ? undefined : form.description,
    };
};
