import {
    AsyncSelectFilter,
    DateFilter,
    DateRangeType,
    FilterCommonType,
    FilterType,
    SelectFilter,
    SelectFilterOption,
} from '@/components/filters-bar/FiltersBar';
import { EmployeeStatus, Gender, MaritalStatus } from '@/domain/employee/Employee.model';
import {
    getContractTypeTranslationKey,
    getGenderTranslationKey,
    getMaritalStatusTranslationKey,
    getUserEmploymentStatusTranslationKey,
    getUserStatusTranslationKey,
    searchEmployees,
} from '@/domain/employee/Employee.service';
import { ContractType, EmploymentCreateReason, EmploymentStatus, TerminationReason, TerminationType } from '@/domain/employment/Employment.model';
import { getTerminationTypeTranslationKey } from '@/domain/employment/Employment.service';
import { AllowanceType } from '@/domain/leave-type/LeaveType.model';
import {
    AvailableReportField,
    Report,
    REPORT_FIELD_VALUE_TYPES,
    ReportFieldType,
    ReportFieldValueType,
    ReportFilter,
    ReportFilterRule,
} from '@/domain/report/Report.model';
import { ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';
import { handleError } from '@/utils/api.util';
import { getCountryList } from '@/utils/countries.util';
import { convertEnumToOptions } from '@/utils/enum.util';
import { getLabelTranslation } from '@/utils/language.util';
import i18next from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';

import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { SectionFieldType, SectionRowStatus } from '@/domain/section-setting/Section.model';

import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { searchCostCenters } from '@/domain/cost-center/CostCenter.service';
import { getFolders } from '@/domain/document/Document.service';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { getLocations } from '@/domain/location/Location.service';
import { searchObjectiveCategories } from '@/domain/objective-category/ObjectiveCategory.service';
import { searchCompletionStatuses } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.service';
import { ReviewTemplateItemArray } from '@/domain/review-template/ReviewTemplate.model';
import { ContributorArray, FeedbackType } from '@/domain/review/Review.model';
import { searchRoles } from '@/domain/role/Role.service';
import { TIMESHEET_PAYMENT_STATUS } from '@/domain/timesheet/Timesheet.model';
import { WorkingPatternType } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';

import useDeepCompareEffect from 'use-deep-compare-effect';
import { getJobFamilies } from '@/domain/job-family/JobFamily.service';

export type UseReportFiltersProps = {
    availableFields: AvailableReportField[];
    defaultFilters: Report['filters'];
};
export const useReportFilters = (
    { availableFields, defaultFilters }: UseReportFiltersProps = {
        availableFields: [],
        defaultFilters: [],
    },
): [ReportFilterItemBar[], Dispatch<SetStateAction<ReportFilterItemBar[]>>] => {
    const [filters, setFilters] = useState<ReportFilterItemBar[]>([]);

    useDeepCompareEffect(() => {
        if (!availableFields?.length) {
            return;
        }
        let availableFieldsToFilterItemBar = availableFields.map(convertAvailableFieldToFilterItemBar);

        if (defaultFilters?.length) {
            // Set values from report in filters

            availableFieldsToFilterItemBar = availableFieldsToFilterItemBar.map(filter => {
                // Search the current filter in the report data
                const filterFromReport = defaultFilters.find(isFilterEquals(filter));
                if (filterFromReport) {
                    const values = getValuesFromReportFilter(filter.fieldFilterType, filterFromReport);

                    const filterWithValue = setValueInReportFilter(filter, values, filterFromReport.rule);
                    if (filterWithValue) {
                        return filterWithValue;
                    }
                }
                return filter;
            });
        }

        setFilters(availableFieldsToFilterItemBar);
    }, [availableFields, defaultFilters]);

    // This useEffect is used to fetch visible async filters and set the label in the filter value.
    // The ASYNC mode is changed into SYNC mode
    useDeepCompareEffect(() => {
        if (!availableFields?.length) {
            return;
        }

        const asyncFilters = getVisibleAsyncFilters(filters);

        if (asyncFilters.length) {
            // load async filters and set the label in the filter value from the options
            // filter value without label = [{value: 1, label: ''}, {value: 2, label: ''}]
            // + options = [{value: 1, label: 'IT'}, {value: 2, label: 'HR'}]
            // new filter value = [{value: 1, label: 'IT'}, {value: 2, label: 'HR'}]
            const promises = asyncFilters.map(async filter => {
                try {
                    const options = await filter.fetchOptions();

                    return convertAsyncFilter(filter, options);
                } catch (error) {
                    handleError(error);
                }
            });

            Promise.all(promises).then(updatedFilter => {
                setFilters(filters => mergeFilters(filters, updatedFilter));
            });
        }
    }, [availableFields?.length, filters]);

    return [filters, setFilters];
};

const convertAvailableFieldToFilterItemBar = (reportField: AvailableReportField): ReportFilterItemBar => {
    if (reportField.sectionFieldDefinition) {
        return convertCustomFieldToReportFilter(reportField);
    }

    const reportFilterType = getFilterTypeFromNonCustomField(reportField);

    const fieldType = reportField.fieldType;

    const commons: Pick<ReportFilterItemBar, 'fieldType' | 'fieldValueType' | 'fieldFilterType' | 'filterName' | 'hide' | 'defaultVisibility' | 'key'> = {
        key: fieldType,
        filterName: getFieldDefinitionTranslation({ fieldType }),
        fieldType: fieldType,
        hide: true,
        defaultVisibility: 'hidden',
        fieldValueType: reportField.valueType,
        fieldFilterType: reportField.filterType,
    };

    if (reportFilterType === 'date') {
        return {
            ...commons,
            type: reportFilterType,
            availableRules: ['MORE_THAN', 'WITHIN_THE_LAST', 'BETWEEN'],
        };
    }

    if (reportFilterType !== 'multi-select' && reportFilterType !== 'select') {
        return {
            ...commons,
            type: reportFilterType,
        };
    }

    return {
        ...commons,
        type: reportFilterType,
        rule: 'EQUALS',
        availableRules: ['EQUALS', 'NOT_EQUALS'],
        ...getSelectProps(reportField),
    };
};

const convertCustomFieldToReportFilter = ({ sectionFieldDefinition, filterType: fieldFilterType }: AvailableReportField): ReportFilterItemBar => {
    const filterType = getFilterTypeFromCustomField(sectionFieldDefinition.type);

    if (!isReportFieldValueType(sectionFieldDefinition.type)) {
        throw new Error(`Unhandled custom field type: ${sectionFieldDefinition.type}`);
    }

    const commons: FilterCommonType & Pick<ReportFilterItemBar, 'fieldType' | 'fieldValueType' | 'fieldFilterType' | 'sectionFieldDefinition' | 'key'> = {
        key: `EMPLOYEE_CUSTOM_FIELD_${sectionFieldDefinition.id}`,
        filterName: getLabelTranslation(sectionFieldDefinition.name),
        fieldType: 'EMPLOYEE_CUSTOM_FIELD',
        hide: true,
        defaultVisibility: 'hidden',
        sectionFieldDefinition: sectionFieldDefinition,
        fieldValueType: sectionFieldDefinition.type,
        fieldFilterType,
    };

    const getSelectOptions = (): SelectFilterOption[] => {
        if (sectionFieldDefinition.type === 'COUNTRY') {
            return getCountryList();
        }

        return (
            sectionFieldDefinition.customList?.items.map(item => ({
                label: getLabelTranslation(item.label),
                value: item.id,
            })) ?? []
        );
    };

    const options = getSelectOptions();

    switch (filterType) {
        case 'text':
        case 'number':
        case 'document':
            return {
                ...commons,
                // type number and document are not supported yet in the UI
                type: 'text',
            };
        case 'date':
            return {
                ...commons,
                type: filterType,
                availableRules: ['MORE_THAN', 'WITHIN_THE_LAST'],
            };
        case 'multi-select':
            return {
                ...commons,
                type: 'multi-select',
                options,
                selectMode: 'SYNC',
                rule: 'EQUALS',
                availableRules: ['EQUALS', 'NOT_EQUALS'],
            };
    }

    throw new Error(`Unhandled filterType: ${filterType}`);
};

const getFilterTypeFromNonCustomField = (reportField: AvailableReportField): FilterType['type'] => {
    // const currentFieldType = REPORT_FIELD_TYPES[reportFieldType];
    // Specific condition to override the default conversion
    // ex: if we want to use non multi-select for a specific enum
    if (isCountryFilter(reportField)) {
        return 'multi-select';
    }
    return getFilterType(reportField.filterType);
};

const getFilterTypeFromCustomField = (sectionFieldType: SectionFieldType): FilterType['type'] => {
    // Usually it's not possible to get a custom field from the api with this types
    if (sectionFieldType === 'BIRTHDAY' || sectionFieldType === 'DOT_STRING' || sectionFieldType === 'ENUM_LIST_ITEM') {
        return 'text';
    }
    return getFilterType(sectionFieldType);
};

/**
 *
 * @param filter state of the filter in the UI
 * @param filterValues store in the report
 * @param rule rule of the filter in the report
 * @returns filter with the value from the report
 */
const setValueInReportFilter = (filter: ReportFilterItemBar, filterValues: string[] | number[] | LocalDate[], rule?: ReportFilterRule): ReportFilterItemBar => {
    if (!filterValues?.length) {
        return filter;
    }

    switch (filter.type) {
        case 'select':
        case 'multi-select':
            if (filter.selectMode === 'ASYNC') {
                const values = filterValues.map(val => ({
                    // In async mode we don't have the options so we can't display the label without a call to the API
                    label: '',
                    value: val.toString(),
                }));
                return {
                    ...filter,
                    hide: !filterValues?.length,
                    value: values,
                    rule: getSelectFilterRule(rule),
                };
            } else {
                const selectedOptions = filter.options?.filter(option => filterValues.find(val => option.value.toString() === val.toString()));
                const values = filterValues?.length ? selectedOptions : [];

                return {
                    ...filter,
                    rule: getSelectFilterRule(rule),
                    value: values,
                    hide: !filterValues?.length,
                };
            }
        case 'date': {
            const dateType = getFilterDateType(rule);
            if (dateType === 'BETWEEN') {
                return {
                    ...filter,
                    value: filterValues as DateRangeType,
                    hide: !filterValues,
                    dateType,
                };
            }
            return {
                ...filter,
                value: filterValues[0] as LocalDate,
                hide: !filterValues,
                dateType,
            };
        }
        case 'text':
            return {
                ...filter,
                value: filterValues[0] as string,
                hide: !filterValues,
            };
        default:
            return filter;
    }
};

type CustomFieldType = Exclude<keyof typeof SectionFieldType, 'BIRTHDAY' | 'COUNTRY' | 'DOT_STRING' | 'ENUM_LIST_ITEM'>;
type FilterTypeUnion = ReportFieldValueType | CustomFieldType;

const getFilterType = (filterType: FilterTypeUnion): FilterType['type'] => {
    switch (filterType) {
        case 'NUMBER':
            return 'number';
        case 'STRING':
        case 'PHONE_NUMBER':
        case 'IBAN_NUMBER':
            return 'text';
        case 'REFERENCE':
        case 'EMPLOYEE_REFERENCE':
        case 'EMPLOYEE':
        case 'CUSTOM_LIST_ITEM':
        case 'CUSTOM_MULTI_LIST_ITEM':
        case 'ENUM':
        case 'LABEL':
            return 'multi-select';
        case 'DATE':
            return 'date';
        case 'DOCUMENT':
        case 'SECTION_FIELD_DOCUMENT':
            return 'document';
        case 'COUNTRY':
            return 'multi-select';
        default:
            throw new Error(`Unhandled filterType: ${filterType}`);
    }
};

const getFilterDateType = (rule?: ReportFilterRule): DateFilter['dateType'] => {
    switch (rule) {
        case ReportFilterRule.GREATER_THAN_EQUALS:
            return 'WITHIN_THE_LAST';
        case ReportFilterRule.LOWER_THAN_EQUALS:
            return 'MORE_THAN';
        case ReportFilterRule.BETWEEN:
            return 'BETWEEN';
        default:
            return undefined;
    }
};
const getSelectFilterRule = (rule?: ReportFilterRule): SelectFilter['rule'] => {
    switch (rule) {
        case ReportFilterRule.NOT_EQUALS:
        case ReportFilterRule.NOT_IN:
            return 'NOT_EQUALS';
        case ReportFilterRule.IN:
        case ReportFilterRule.EQUALS:
            return 'EQUALS';
    }
    return 'EQUALS';
};

const getValuesFromReportFilter = (filterType: ReportFieldValueType, filter: ReportFilter): string[] | number[] | LocalDate[] => {
    // Based on BE enum ReportFilterType
    switch (filterType) {
        case 'STRING':
        case 'ENUM':
            return filter.values?.map(v => v.stringValue).filter(value => value);
        case 'NUMBER':
            return filter.values?.map(v => v.numberValue).filter(value => value);
        case 'REFERENCE':
            return filter.values?.map(v => v.referenceId).filter(value => value);
        case 'DATE':
            return filter.values?.map(v => v.dateValue).filter(value => value);
        default:
            return [];
    }
};

const isCountryFilter = (filter: { fieldType: ReportFieldType }): boolean => {
    return (['EMPLOYEE_NATIONALITY', 'ADDRESS_COUNTRY', 'CURRENT_ADDRESS_COUNTRY'] satisfies ReportFieldType[] as ReportFieldType[]).includes(filter.fieldType);
};

/**
 * Check if the given type is a valid ReportFieldValueType
 * @param type
 * @returns true if the given type is a valid ReportFieldValueType
 */
export const isReportFieldValueType = (type: string): type is ReportFieldValueType => {
    return REPORT_FIELD_VALUE_TYPES.includes(type as ReportFieldValueType);
};

const getSelectProps = (
    filter: AvailableReportField,
): Pick<SelectFilter, 'selectMode' | 'options'> | Pick<AsyncSelectFilter, 'selectMode' | 'fetchOptions'> => {
    // override the default options for specific filterType
    if (isCountryFilter(filter)) {
        return {
            selectMode: 'SYNC',
            options: getCountryList(),
        };
    }

    if (filter.filterType === 'ENUM') {
        const filtersOfTypeEnum = {
            EMPLOYEE_STATUS: convertEnumToOptions(EmployeeStatus, key => i18next.t(getUserStatusTranslationKey(), { context: key })),
            EMPLOYEE_GENDER: convertEnumToOptions(Gender, key => i18next.t(getGenderTranslationKey(), { context: key })),
            EMPLOYEE_MARITAL_STATUS: convertEnumToOptions(MaritalStatus, key => i18next.t(getMaritalStatusTranslationKey(), { context: key })),
            CURRENT_EMPLOYMENT_STATUS: convertEnumToOptions(EmploymentStatus, key => i18next.t(getUserEmploymentStatusTranslationKey(), { context: key })),
            CURRENT_EMPLOYMENT_CONTRACT_TYPE: convertEnumToOptions(ContractType, key => i18next.t(getContractTypeTranslationKey(), { context: key })),
            CURRENT_EMPLOYMENT_TERMINATION_REASON: convertEnumToOptions(TerminationReason, key =>
                i18next.t('employee.employment.termination_reason.enum', { context: key }),
            ),
            CURRENT_EMPLOYMENT_TERMINATION_TYPE: convertEnumToOptions(TerminationType, key =>
                i18next.t(getTerminationTypeTranslationKey(key), { context: key }),
            ),
            CURRENT_EMPLOYMENT_CREATION_REASON: convertEnumToOptions(EmploymentCreateReason, key =>
                i18next.t('employee.employment.employment_create_reason', { context: key }),
            ),
            EMPLOYMENT_TERMINATION_REASON: convertEnumToOptions(TerminationReason, key =>
                i18next.t('employee.employment.termination_reason.enum', { context: key }),
            ),
            EMPLOYMENT_TERMINATION_TYPE: convertEnumToOptions(TerminationType, key => i18next.t(getTerminationTypeTranslationKey(key), { context: key })),
            EMPLOYMENT_CONTRACT_TYPE: convertEnumToOptions(ContractType, key => i18next.t(getContractTypeTranslationKey(), { context: key })),
            EMPLOYMENT_CREATION_REASON: convertEnumToOptions(EmploymentCreateReason, key =>
                i18next.t('employee.employment.employment_create_reason', { context: key }),
            ),
            //
            REVIEW_FEEDBACK_CONTRIBUTOR_TYPE: Object.keys(ContributorArray).map(key => ({
                label: i18next.t('reviews.enums.contributor_types.enum', { context: key }),
                value: key,
            })),
            REVIEW_FEEDBACK_TYPE: convertEnumToOptions(FeedbackType, key => i18next.t('reviews.enums.feedback_types.enum', { context: key })),
            REVIEW_FEEDBACK_ITEM_TYPE: Object.keys(ReviewTemplateItemArray).map(key => ({
                label: i18next.t('reviews.enums.item_types.enum', { context: key }),
                value: key,
            })),
            LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE_TYPE: convertEnumToOptions(AllowanceType, key =>
                i18next.t('leaves.leave_types.enums.allowance_types.enum', { context: key }),
            ),
            TIMESHEET_PAYMENT_STATUS: Object.keys(TIMESHEET_PAYMENT_STATUS).map(key => ({
                label: i18next.t('timesheets.enums.payments_status.enum', { context: key }),
                value: key,
            })),
            EMPLOYEE_SECTION_ROW_STATUS: convertEnumToOptions(SectionRowStatus, key => i18next.t('employee.sections.enums.status.enum', { context: key })),
            ADDRESS_STATUS: convertEnumToOptions(SectionRowStatus, key => i18next.t('employee.sections.enums.status.enum', { context: key })),
            CURRENT_WORKING_PATTERN_TYPE: convertEnumToOptions(WorkingPatternType, key => i18next.t('employee.work_pattern.type.enum', { context: key })),
            WORKING_PATTERN_TYPE: convertEnumToOptions(WorkingPatternType, key => i18next.t('employee.work_pattern.type.enum', { context: key })),
        } as const;

        if (filter.fieldType in filtersOfTypeEnum) {
            return {
                selectMode: 'SYNC',
                options: filtersOfTypeEnum[filter.fieldType as keyof typeof filtersOfTypeEnum],
            };
        }
    } else {
        const fetchEmployees = () =>
            searchEmployees().then(employees =>
                employees?.map(
                    employee =>
                        ({
                            label: employee.displayName,
                            value: employee.id,
                        }) as SelectFilterOption,
                ),
            );
        const filtersOfTypeReference = {
            EMPLOYEE: fetchEmployees,
            EMPLOYMENT_MANAGER: fetchEmployees,
            OBJECTIVE_ASSIGNEE: fetchEmployees,
            DOCUMENT_CREATED_BY: fetchEmployees,
            ADDRESS_UPDATED_BY: fetchEmployees,
            EMPLOYEE_SECTION_ROW_UPDATED_BY: fetchEmployees,
            EMPLOYMENT_SUBORDINATES: fetchEmployees,
            EMPLOYMENT_LOCATION: () =>
                getLocations().then(locations =>
                    locations?.map(
                        location =>
                            ({
                                label: location.name,
                                value: location.id,
                            }) as SelectFilterOption,
                    ),
                ),
            DOCUMENT_FOLDER_NAME: () =>
                getFolders().then(folders =>
                    folders?.map(
                        folder =>
                            ({
                                label: folder.name,
                                value: folder.id,
                            }) as SelectFilterOption,
                    ),
                ),
            EMPLOYEE_ROLE_TITLE: () =>
                searchRoles({}).then(roles =>
                    roles?.map(
                        role =>
                            ({
                                label: role.name,
                                value: role.id,
                            }) as SelectFilterOption,
                    ),
                ),
            EMPLOYMENT_DEPARTMENT: () =>
                getDepartments().then(departments =>
                    departments?.map(
                        department =>
                            ({
                                label: getLabelTranslation(department.name),
                                value: department.id,
                            }) as SelectFilterOption,
                    ),
                ),
            EMPLOYMENT_JOB: () =>
                getJobs().then(jobs =>
                    jobs?.map(
                        job =>
                            ({
                                label: getLabelTranslation(job.name),
                                value: job.id,
                            }) as SelectFilterOption,
                    ),
                ),
            EMPLOYMENT_JOB_FAMILY: () =>
                getJobFamilies().then(jobFamilies =>
                    jobFamilies?.map(
                        jobFamily =>
                            ({
                                label: jobFamily.name,
                                value: jobFamily.id,
                            }) as SelectFilterOption,
                    ),
                ),
            LEAVE_CORRECTION_LEAVE_TYPE_TITLE: () =>
                getLeaveTypes().then(leaveTypes =>
                    leaveTypes?.map(
                        leaveType =>
                            ({
                                label: leaveType.title,
                                value: leaveType.id,
                            }) as SelectFilterOption,
                    ),
                ),
            OBJECTIVE_CATEGORY: () =>
                searchObjectiveCategories({}).then(categories =>
                    categories?.map(
                        category =>
                            ({
                                label: getLabelTranslation(category.name),
                                value: category.id,
                            }) as SelectFilterOption,
                    ),
                ),
            OBJECTIVE_COMPLETION_STATUS: () =>
                searchCompletionStatuses().then(statuses =>
                    statuses?.map(
                        status =>
                            ({
                                label: status.name,
                                value: status.id,
                            }) as SelectFilterOption,
                    ),
                ),
            REVIEW_FEEDBACK_REVIEWER: fetchEmployees,
            EMPLOYMENT_COST_CENTERS: () =>
                searchCostCenters().then(costCenters =>
                    costCenters?.map(
                        costCenter =>
                            ({
                                label: costCenter.name,
                                value: costCenter.id,
                            }) as SelectFilterOption,
                    ),
                ),
        };

        // Utility type to remove prefix 'CURRENT_' from a string union type
        type RemovePrefix<T extends string, Prefix extends string> = T extends `${Prefix}${infer U}` ? U : T;

        const fieldType = filter.fieldType?.replace('CURRENT_', '') as RemovePrefix<ReportFieldType, 'CURRENT_'>;

        if (fieldType in filtersOfTypeReference) {
            return {
                selectMode: 'ASYNC',
                fetchOptions: filtersOfTypeReference[fieldType as keyof typeof filtersOfTypeReference],
            };
        }
    }
    throw new Error(`Impossible to get props for filter: ${filter.fieldType}`);
};

const isFilterEquals =
    (filter: ReportFilterItemBar) =>
    (f: ReportFilter): boolean =>
        // For non custom fields, we compare the field type
        f.fieldType === filter?.fieldType &&
        // For custom fields, we compare the section field definition id
        (!filter.sectionFieldDefinition?.id || f.fieldDefinition.id === filter.sectionFieldDefinition.id);

/**
 * Return visible async filters
 * ex: department, job, ...
 * @param filters
 */
const getVisibleAsyncFilters = (filters: ReportFilterItemBar[]): AsyncSelectFilter[] => {
    return filters.filter(filter => {
        return (
            (filter.type === 'multi-select' || filter.type === 'select') &&
            filter.selectMode === 'ASYNC' &&
            filter.hide === false &&
            // search value with empty label
            filter.value?.find(option => !option.label)
        );
    }) as AsyncSelectFilter[];
};

const convertAsyncFilter = (filter: AsyncSelectFilter, options: SelectFilterOption[]) => {
    return {
        ...(filter as ReportFilterItemBar),
        value: filter.value?.map(value => {
            const option = options.find(option => option.value?.toString() === value.value.toString());
            return option ?? value;
        }),
        options,
        selectMode: 'SYNC',
    } as ReportFilterItemBar;
};

const mergeFilters = (filters: ReportFilterItemBar[], updatedFilter: (ReportFilterItemBar | undefined)[]) =>
    filters.map(f => {
        const newFilter = updatedFilter.find(nf => nf?.fieldType === f.fieldType);
        return newFilter ?? f;
    });
