import { Label } from '@/domain/label/Label.model';
import { AnswerResult, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { getLabelTranslation } from '@/utils/language.util';
import { Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from './ProgressBar';

type Props = {
    title: Label;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
};

export const ChoiceResult: FC<Props> = ({ title, activeGroupByFilters, answer, groupBy }) => {
    const { t } = useTranslation();

    if (!answer?.details || !answer.completions) {
        return <></>;
    }
    return (
        <Paper sx={{ padding: 3 }}>
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid item>
                    <Typography variant='body1bold'>{getLabelTranslation(title)}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='body1'>
                        {answer.completions?.ALL[0].answered} {t('survey_results.answered')} - {answer.completions?.ALL[0].skipped}{' '}
                        {t('survey_results.skipped')}
                    </Typography>
                </Grid>
            </Grid>
            {answer.question?.items?.map(item => (
                <ProgressBar key={item.id} questionItem={item} answer={answer} groupBy={groupBy} activeGroupByFilters={activeGroupByFilters} />
            ))}
        </Paper>
    );
};
