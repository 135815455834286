import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { FileMetadata } from '@/components/file-picker-wrapper/FilePickerWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getAppConfig } from '@/config/config';
import { EmployeeDocumentCreationMutation, Folder, PreviewData } from '@/domain/document/Document.model';
import { Employee, EmployeeSearch } from '@/domain/employee/Employee.model';
import { buildEmployeeFilterPredicate } from '@/domain/employee/Employee.service';
import { useGetFoldersByType } from '@/hooks/document/Document.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { useCreateEmployeeDocuments } from '@/page/document/employee/EmployeeDocuments.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { MassDocumentImportDialog } from '@/page/setting/import/mass-document-import-dialog/MassDocumentImportDialog';
import { SelectResourceDialog } from '@/page/setting/import/mass-document-import-dialog/SelectResourceDialog';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { CellClassParams } from '@ag-grid-community/core';
import { Button, Link, Paper, Stack, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { getLabelTranslation } from '@/utils/language.util';

type Row = Omit<EmployeeDocumentCreationMutation, 's3Key' | 'folderId' | 'employeeId'> & {
    // Remove this when EmployeeDocumentCreationMutation is fixed, because it's not logic to have optional
    s3Key: string;
    folder: Folder | undefined;
    employee: Employee | undefined;
    isSetManually: boolean;
    documentTmpUrl?: string;
};

type ValidRow = {
    folder: NonNullable<Row['folder']>;
    employee: NonNullable<Row['employee']>;
} & Omit<Row, 'folder' | 'employee'>;

export const ImportDocumentsPage: FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

    // We have to load all the employees to do the matching with uploaded files
    const employeeSearch: EmployeeSearch = {
        statuses: [EmploymentStatus.EMPLOYED, EmploymentStatus.HIRED, EmploymentStatus.ON_LONG_LEAVE],
    };
    const { data: allEmployees = [], isLoading: isLoadingEmployees, isError: isErrorEmployees, error: errorEmployees } = useGetEmployees(employeeSearch);
    const {
        data: allEmployeesFolders = [],
        isLoading: isLoadingEmployeesFolder,
        isError: isErrorEmployeesFolders,
        error: errorEmployeesFolders,
    } = useGetFoldersByType('EMPLOYEE');

    const [documentsToImport, setDocumentsToImport] = useState<Row[]>([]);

    const [dialogToSetEmployeeIsOpen, setDialogToSetEmployeeIsOpen] = useState<boolean>(false);
    const [dialogToSetFolderIsOpen, setDialogToSetFolderIsOpen] = useState<boolean>(false);

    const [documentPreviewDialogOpen, setDocumentPreviewDialogOpen] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<PreviewData>();

    const agGridWrapper = useAgGridWrapper<Row>();

    const handleDocumentsAdded = ({
        documents,
        checkByName,
        checkByEmployeeCode,
    }: {
        documents: FileMetadata[];
        checkByName: boolean;
        checkByEmployeeCode: boolean;
    }) => {
        const documentWithEmployeeMatching = documents.map<Row>(({ filename, mimetype, key, url }) => {
            const predicate = buildEmployeeFilterPredicate(filename, {
                checkFirstName: checkByName,
                checkLastName: checkByName,
                checkEmployeeCode: checkByEmployeeCode,
            });
            const employee = allEmployees.find(predicate);

            const row: Row = {
                employee: employee,
                folder: undefined,
                name: filename,
                documentType: 'DOCUMENT',
                mimeType: mimetype,
                s3Key: key ?? '',
                documentTmpUrl: url,
                isSetManually: employee === undefined,
            };
            return row;
        });

        setDocumentsToImport(documents => [...documents, ...documentWithEmployeeMatching]);
    };
    const emptyCellColor = theme.palette.error.light;

    // set the background color of the cell based on the value
    const getCellBgColor = ({ value, data }: CellClassParams<Row>) => {
        if (!value) {
            return emptyCellColor;
        }
        if (data?.isSetManually) {
            // highlight the cell if the employee is set manually
            return theme.palette.warning.light;
        }
        // no background
        return '';
    };

    const documentNameCellRenderer = ({ value }: { value: string }) => (
        <Link component='button' color={theme.palette.primary.main} underline={'always'}>
            {value}
        </Link>
    );

    const columnsDef: RogerColDef<Row>[] = [
        {
            colId: 'selection',
            type: 'selection',
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: t('import_page.import_documents_page.matched_employee'),
            cellStyle: params => {
                return {
                    display: 'flex',
                    backgroundColor: getCellBgColor(params),
                };
            },
        },
        {
            field: 'folder.name',
            type: 'label',
            headerName: t('import_page.import_documents_page.matched_folder'),
            cellStyle: ({ value }) => ({
                backgroundColor: value ? '' : emptyCellColor,
            }),
        },
        {
            headerName: t('import_page.import_documents_page.document_name'),
            field: 'name',
            onCellClicked: ({ data }) => {
                if (!data) {
                    return;
                }
                const { name, documentType, mimeType, documentTmpUrl } = data;
                const config = getAppConfig();
                const isOffice = config.MIME_TYPES.MICROSOFT.some((type: string) => type === mimeType);
                const previewUrl = documentTmpUrl && isOffice ? `${config.OFFICE_PREVIEW_URL}${encodeURIComponent(documentTmpUrl)}` : documentTmpUrl;

                setPreviewData({
                    // id is set to 0 because the document it's not yet imported
                    document: {
                        id: 0,
                        name,
                        documentType,
                        mimeType,
                    },
                    url: previewUrl ?? '',
                });
                setDocumentPreviewDialogOpen(true);
            },
            cellRenderer: documentNameCellRenderer,
        },
    ];

    const {
        mutate: mutateEmployeeDocuments,
        isPending: isMutateEmployeeDocumentsPending,
        isError: isMutateEmployeeDocumentsError,
        error: mutateEmployeeDocumentsError,
    } = useCreateEmployeeDocuments();

    const handleImportDocumentsSubmit = async () => {
        try {
            const isValidMutation = (doc: Row): doc is ValidRow => !!doc.employee && !!doc.folder;
            const convertRowToMutation = ({ folder, employee, ...doc }: ValidRow): EmployeeDocumentCreationMutation => {
                return {
                    ...doc,
                    folderId: folder.id,
                    employeeId: employee.id,
                };
            };
            const payload = documentsToImport.filter(isValidMutation).map(convertRowToMutation);
            await mutateEmployeeDocuments(payload);
            showSnackbar(t('import_page.import_documents_page.employee_documents_created'), 'success');
            setDocumentsToImport([]);
        } catch (error) {
            handleError(error);
        }
    };

    const selectedRow = agGridWrapper?.selectedRows;

    const clearSelection = () => {
        agGridWrapper?.gridRef.current?.api.deselectAll();
    };

    const handleEmployeeSelected = (employee: Employee) => {
        setDocumentsToImport(documents => {
            return documents.map<Row>(doc => {
                if (findSelectedRow(doc)) {
                    return {
                        ...doc,
                        employee,
                        isSetManually: true,
                    };
                }
                return doc;
            });
        });
        clearSelection();
        setDialogToSetEmployeeIsOpen(false);
    };

    const findSelectedRow = (doc: Row) => selectedRow.find(d => d.s3Key === doc.s3Key);

    const handleFolderSelected = (folder: Folder) => {
        setDocumentsToImport(documents => {
            return documents.map<Row>(doc => {
                if (findSelectedRow(doc)) {
                    return {
                        ...doc,
                        folder,
                    };
                }
                return doc;
            });
        });
        clearSelection();
        setDialogToSetFolderIsOpen(false);
    };

    const handleDownloadClick = async (previewData: PreviewData) => {
        const file = await fetch(previewData.url);
        const blob = await file.blob();

        // force download to avoid just displaying the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = previewData.document.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const hasValidRows = documentsToImport.some(doc => doc.employee && doc.folder);

    const handleRemoveSelected = () => {
        setDocumentsToImport(documents => {
            return documents.filter(doc => !findSelectedRow(doc));
        });
        clearSelection();
    };

    const validToImportCount = documentsToImport.filter(doc => doc.employee && doc.folder).length;

    const invalidToImportCount = documentsToImport.length - validToImportCount;

    return (
        <>
            <ContentContainer>
                {/* Without employee this page can't work */}
                <StateHandler
                    isLoading={isLoadingEmployees || isLoadingEmployeesFolder || isMutateEmployeeDocumentsPending}
                    isError={isErrorEmployees || isErrorEmployeesFolders || isMutateEmployeeDocumentsError}
                    error={errorEmployees || errorEmployeesFolders || mutateEmployeeDocumentsError}
                >
                    <Stack gap={2} flex={1}>
                        <Stack direction='row' component={Paper} p={1} gap={1}>
                            <Button disabled={!selectedRow?.length} size='small' onClick={() => setDialogToSetEmployeeIsOpen(true)}>
                                {t('import_page.import_documents_page.set_employee')}
                            </Button>
                            {dialogToSetEmployeeIsOpen && (
                                <SelectResourceDialog
                                    title={t('import_page.import_documents_page.select_employee')}
                                    open={dialogToSetEmployeeIsOpen}
                                    options={allEmployees.map(employee => ({
                                        label: employee.displayName,
                                        value: employee,
                                    }))}
                                    onSelectValue={handleEmployeeSelected}
                                    onClose={() => setDialogToSetEmployeeIsOpen(false)}
                                    label={t('import_page.import_documents_page.set_employee_label')}
                                />
                            )}

                            <Button disabled={!selectedRow?.length} size='small' onClick={() => setDialogToSetFolderIsOpen(true)}>
                                {t('import_page.import_documents_page.set_folder')}
                            </Button>
                            {dialogToSetFolderIsOpen && (
                                <SelectResourceDialog
                                    title={t('import_page.import_documents_page.select_folder')}
                                    open={dialogToSetFolderIsOpen}
                                    options={allEmployeesFolders.map(folder => ({
                                        label: getLabelTranslation(folder.name),
                                        value: folder,
                                    }))}
                                    onSelectValue={handleFolderSelected}
                                    onClose={() => setDialogToSetFolderIsOpen(false)}
                                    label={t('import_page.import_documents_page.set_folder_label')}
                                />
                            )}

                            {/* Button to remove selected row */}
                            <Button disabled={!selectedRow?.length} size='small' onClick={handleRemoveSelected}>
                                {t('import_page.import_documents_page.remove_selected')}
                            </Button>
                        </Stack>
                        <Stack component={Paper} p={2} flex={1}>
                            <AgGridWrapper<Row>
                                initRef={agGridWrapper.setGridRef}
                                rowData={documentsToImport}
                                columnDefs={columnsDef}
                                rowSelection='multiple'
                                getRowId={({ data }) => data.s3Key}
                            />
                            {documentPreviewDialogOpen && !!previewData && (
                                <DocumentPreviewDialog
                                    onClose={() => setDocumentPreviewDialogOpen(false)}
                                    previewData={previewData}
                                    onDownloadClick={() => handleDownloadClick(previewData)}
                                />
                            )}
                        </Stack>
                    </Stack>
                </StateHandler>
                {dialogIsOpen && (
                    <MassDocumentImportDialog
                        open={true}
                        onSave={documents => {
                            handleDocumentsAdded(documents);
                            setDialogIsOpen(false);
                        }}
                        onClose={() => setDialogIsOpen(false)}
                    />
                )}
            </ContentContainer>
            <Footer>
                <Button variant='outlined' onClick={() => setDialogIsOpen(true)} disabled={isMutateEmployeeDocumentsPending}>
                    {t('import_page.import_documents_page.upload_again')}
                </Button>

                <ConfirmDialog
                    title={t('import_page.import_documents_page.confirm_import')}
                    content={
                        t('import_page.import_documents_page.confirm_import_content', {
                            count: validToImportCount,
                        }) +
                        ' ' +
                        t('import_page.import_documents_page.confirm_import_content_invalid', { count: invalidToImportCount })
                    }
                    onConfirm={handleImportDocumentsSubmit}
                >
                    <Button disabled={!hasValidRows || isMutateEmployeeDocumentsPending}>{t('import_page.import_documents_page.confirm_import')}</Button>
                </ConfirmDialog>
            </Footer>
        </>
    );
};
