import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { RadioLineGroup, TooltipFormControlLabel } from '@/components/radio-line-group/RadioLineGroup';
import { EmployeeReviewFeedbackSkillFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { EmployeeReviewStarsIcons } from '@/page/review/employee-review-stars-icons/EmployeeReviewStarsIcons';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Radio, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';

type EmployeeReviewSkillReviewDialogProps = {
    skillAndCategory: EmployeeReviewFeedbackSkillFormType;
    onConfirm: (skillAndCategory: EmployeeReviewFeedbackSkillFormType) => void;
    onClose: () => void;
    disabled: boolean;
};

export const EmployeeReviewSkillReviewDialog: FC<PropsWithChildren<EmployeeReviewSkillReviewDialogProps>> = ({
    skillAndCategory,
    onConfirm,
    onClose,
    disabled = false,
    children,
}) => {
    const { t } = useTranslation();
    const skillReviewDialogSchema = yup.object().shape({
        score: yup.number().required().default(undefined),
        comment: yup.string().default(''),
    });
    const { palette, breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));

    const defaultValues = {
        score: skillAndCategory.score ?? undefined,
        comment: skillAndCategory.comment ?? '',
    };

    type SkillFeedbackDialogFormType = yup.InferType<typeof skillReviewDialogSchema>;

    const { handleSubmit, control, watch } = useForm<SkillFeedbackDialogFormType>({
        resolver: yupResolver(skillReviewDialogSchema),
        defaultValues,
    });

    const onDialogSave = (values: SkillFeedbackDialogFormType, skillAndCategory: EmployeeReviewFeedbackSkillFormType) => {
        onConfirm({ ...skillAndCategory, ...values });
        onClose();
    };

    const onErase = () => {
        onConfirm({ ...skillAndCategory, score: undefined, comment: '' });
        onClose();
    };

    const getColorOfStarsIcon = (currentScoreSelected: number | undefined, scaleScore: number, disabled: boolean, error: boolean) => {
        if (error) {
            return palette.error.main;
        }

        if (!currentScoreSelected || currentScoreSelected !== scaleScore || disabled) {
            return palette.text.primary;
        }
        return palette.primary.main;
    };

    const skillLevelsOrderByScore = (skillAndCategory.skill.levels ?? []).sort((a, b) => a.score - b.score);

    return (
        <DialogWrapper onClose={onClose} open={true} header={t('reviews.write_feedback.skill_review_dialog_title')} maxWidth={'md'}>
            <DialogContent>
                <Stack pb={2}>
                    <Typography variant='body1bold'>{skillAndCategory.skill.name}</Typography>
                    <RichTextTypography variant='body1'>{skillAndCategory.skill.description}</RichTextTypography>
                </Stack>

                {children}

                <Stack gap={2}>
                    {skillLevelsOrderByScore && (
                        <Controller
                            name={`score`}
                            control={control}
                            render={({ field: { value, onChange }, formState: { errors } }) => (
                                <Stack>
                                    <RadioLineGroup value={value ?? getNull()} onChange={e => onChange(Number(e.target.value))} error={!!errors.score}>
                                        {skillLevelsOrderByScore.map(item => (
                                            <TooltipFormControlLabel
                                                key={item.id}
                                                value={item.score}
                                                control={<Radio disabled={disabled} />}
                                                label={
                                                    <Stack alignItems={'center'} direction={isTablet ? 'row' : 'column'} gap={1}>
                                                        <EmployeeReviewStarsIcons
                                                            score={item.score}
                                                            htmlColor={getColorOfStarsIcon(value, item.score, disabled, !!errors.score)}
                                                        />
                                                        <Typography
                                                            variant={'body1'}
                                                            color={errors.score ? 'error' : 'textPrimary'}
                                                            className={item.description ? 'rp-underline' : ''}
                                                        >
                                                            {item?.name}
                                                        </Typography>
                                                    </Stack>
                                                }
                                                description={item.description}
                                            />
                                        ))}
                                    </RadioLineGroup>
                                </Stack>
                            )}
                        />
                    )}

                    <Controller
                        name={`comment`}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                disabled={disabled}
                                {...field}
                                label={field.value ? '' : t('reviews.write_feedback.comment_label')}
                                fullWidth
                                multiline
                                minRows={3}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                {!disabled && !!watch('score') && (
                    <Button onClick={onErase} variant='contained' color={'error'} disabled={disabled}>
                        {t('reviews.write_feedback.erase')}
                    </Button>
                )}

                {!disabled && (
                    <Button onClick={handleSubmit(values => onDialogSave(values, skillAndCategory), console.error)} variant='contained'>
                        {t('general.confirm')}
                    </Button>
                )}
                {disabled && (
                    <Button onClick={onClose} variant='contained'>
                        {t('general.close')}
                    </Button>
                )}
            </DialogActions>
        </DialogWrapper>
    );
};
