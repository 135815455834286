import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { ContractType, Employment } from '@/domain/employment/Employment.model';
import { getTerminationTypeTranslationKey } from '@/domain/employment/Employment.service';
import { useGetContracts } from '@/hooks/employment/Employment.hook';
import { EmploymentDates } from '@/page/employee-profile/employee-profile-info/EmploymentSection/EmploymentsHistoryDialog';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { DialogContent, Stack } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ContractsHistoryDialog: FC<DialogWrapperProps & { employeeId: number }> = ({ employeeId, ...rest }) => {
    const { t } = useTranslation();

    const {
        data: contracts = [],
        isLoading: isContractsLoading,
        isError: isContractsError,
        error: contractsError,
    } = useGetContracts({ employeeId: employeeId });

    const tableHeight = getTableHeight({ rowsLength: contracts.length, disableFooter: true });

    return (
        <DialogWrapper header={t('employee.contract.history.title')} maxWidth='lg' {...rest}>
            <DialogContent sx={{ pb: 2 }}>
                <Stack width='100%' minHeight={100} height={tableHeight}>
                    <StateHandler isLoading={isContractsLoading} isError={isContractsError} error={contractsError} isEmpty={!contracts.length}>
                        <AgGridWrapper rowData={contracts} columnDefs={getContractColumnDefs()} compact statusBar={undefined} />
                    </StateHandler>
                </Stack>
            </DialogContent>
        </DialogWrapper>
    );
};

const getContractColumnDefs = (): RogerColDef<Employment>[] => {
    return [
        {
            field: 'startDate',
            headerName: t('employee.contract.history.effective_date'),
            valueFormatter: ({ value }: { value: Date }) => formatInDefaultDate(value),
            cellRenderer: ({ data }) => (data ? <EmploymentDates employment={data} /> : ''),
            cellClass: ['display-flex'],
        },
        {
            field: 'contractType',
            headerName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_CONTRACT_TYPE' }),
            valueFormatter: ({ value }: { value: ContractType }) => t('employee.employment.contract_type', { context: value }),
        },
        {
            field: 'probationEndDate',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_PROBATION_END_DATE' }),
            type: 'date',
        },
        {
            field: 'endDate',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_END_DATE' }),
            type: 'date',
        },
        {
            field: 'terminationNoticeDate',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE' }),
            type: 'date',
        },
        {
            field: 'terminationLastDayAtWork',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY' }),
            type: 'date',
        },
        {
            field: 'terminationType',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_TYPE' }),
            valueFormatter: ({ value }) => t(getTerminationTypeTranslationKey(value)),
        },
        {
            field: 'terminationReason',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_REASON' }),
            valueFormatter: ({ value }) => (value ? t('employee.employment.termination_reason.enum', { context: value }) : ''),
        },
        {
            field: 'terminationComment',
            headerName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_COMMENT' }),
        },
    ];
};
