import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Link, Paper, Stack, StackProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetEmployeeReviewAsContributor } from '@/hooks/employee-review/EmployeeReview.hook';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { ReviewEmptyStateIcon } from '@/components/empty-state/icons/ReviewEmptyStateIcon';
import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import {
    filterEmployeeReviewsByStatusAndEndDate,
    getCurrentContributorType,
    getOngoingReviews,
    getPastReviews,
} from '@/domain/employee-review/EmployeeReview.service';
import { useAppSelector } from '@/stores/store';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { Employee } from '@/domain/employee/Employee.model';
import { t } from 'i18next';
import { EmployeeReviewStatusButton } from '@/page/review/employee-review-status-button/EmployeeReviewStatusButton';
import { EmployeeReviewViewProgressDialog } from '@/page/review/employee-review-view-progress-dialog/EmployeeReviewViewProgressDialog';
import { ReviewStatus } from '@/domain/review/Review.model';
import { LoadingState } from '@/page/employee-profile/EmployeeProfileReviewsPage';

export const ReviewTasks: FC = () => {
    const { t } = useTranslation();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    if (!currentEmployee) {
        throw new Error('Current employee is not defined');
    }

    const {
        data: employeeReviews = [],
        isLoading,
        isError,
        error,
    } = useGetEmployeeReviewAsContributor({
        employeeReviewSearch: {
            reviewStatuses: [ReviewStatus.STARTED],
        },
    });

    const employeeReviewsFiltered = filterEmployeeReviewsByStatusAndEndDate(employeeReviews, currentEmployee);
    const ongoingEmployeeReviews = getOngoingReviews(employeeReviewsFiltered);
    const notOngoingEmployeeReviews = getPastReviews(employeeReviewsFiltered);

    const hasReviews = !!employeeReviews?.length;
    const hasOngoingReviews = !!ongoingEmployeeReviews?.length;
    const hasPastReviews = !!notOngoingEmployeeReviews?.length;

    const emptyState = <EmptyState icon={<ReviewEmptyStateIcon />} flex={1} title={t('reviews.employee_reviews.no_reviews')} />;

    return (
        <StateHandler
            isLoading={isLoading}
            loadingComponent={<LoadingState />}
            isEmpty={!hasReviews}
            isError={isError}
            emptyStateComponent={emptyState}
            error={error}
        >
            <Stack gap={2} flex={1}>
                {hasOngoingReviews && (
                    <EmployeeReviewTaskTable
                        flex={hasPastReviews ? 0 : 1}
                        employeeReviews={ongoingEmployeeReviews}
                        currentEmployee={currentEmployee}
                        tableTitle={t('reviews.employee_reviews.ongoing')}
                    />
                )}
                {hasPastReviews && (
                    <EmployeeReviewTaskTable
                        flex={1}
                        employeeReviews={notOngoingEmployeeReviews}
                        currentEmployee={currentEmployee}
                        tableTitle={t('reviews.employee_reviews.past')}
                    />
                )}
            </Stack>
        </StateHandler>
    );
};

type ReviewStatusBarWithContributorProps = {
    employeeReviews: EmployeeReview[];
    currentEmployee: Employee;
    tableTitle: string;
} & StackProps;

export const EmployeeReviewTaskTable: FC<ReviewStatusBarWithContributorProps> = ({ employeeReviews, currentEmployee, tableTitle, ...rest }) => {
    return (
        <Stack {...rest} gap={3} component={Paper} p={2.5} flexDirection={'column'}>
            <Typography variant='h1'>{tableTitle}</Typography>

            <Stack divider={<Divider />}>
                {employeeReviews?.map(employeeReview => (
                    <EmployeeReviewTaskRow key={employeeReview.id} employeeReview={employeeReview} currentEmployee={currentEmployee} />
                ))}
            </Stack>
        </Stack>
    );
};

type EmployeeReviewRowProps = {
    employeeReview: EmployeeReview;
    currentEmployee: Employee;
};

export const EmployeeReviewTaskRow: FC<EmployeeReviewRowProps> = ({ employeeReview, currentEmployee }) => {
    const [isViewProgressDialogOpen, setIsViewProgressDialogOpen] = useState(false);
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));

    return (
        <Stack key={employeeReview.id} direction={'row'} alignItems={'center'} justifyContent='space-between' py={1} gap={1}>
            <EmployeeAvatarWithDetails employee={employeeReview.employee} infoWidth={isMobile || isTablet ? 'fit-content' : '240px'}>
                <Typography variant='body2'>
                    {t('reviews.enums.contributor_types.enum', {
                        context: getCurrentContributorType(employeeReview, currentEmployee),
                    })}
                </Typography>
            </EmployeeAvatarWithDetails>
            <Stack
                alignItems={'flex-end'}
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent={'space-between'}
                width={'100%'}
                gap={1}
                sx={{ overflow: 'hidden' }}
            >
                <Stack flex={1} sx={{ overflow: 'hidden' }} alignItems={isMobile ? 'flex-end' : 'flex-start'} width={'100%'}>
                    {employeeReview?.review?.name && (
                        <Typography
                            variant='body1bold'
                            sx={{
                                alignSelf: isMobile ? 'end' : 'start',
                            }}
                        >
                            {employeeReview.review.name}
                        </Typography>
                    )}

                    <Link
                        onClick={() => setIsViewProgressDialogOpen(true)}
                        color={'primary'}
                        sx={{
                            cursor: 'pointer',
                            alignSelf: isMobile ? 'end' : 'start',
                        }}
                    >
                        {t('reviews.employee_review.view_progress_link_label')}
                    </Link>
                </Stack>
                <EmployeeReviewStatusButton
                    width={'260px'}
                    whiteSpace={'nowrap'}
                    justifyContent={'flex-end'}
                    employeeReview={employeeReview}
                    currentEmployee={currentEmployee}
                />
            </Stack>

            {isViewProgressDialogOpen && (
                <EmployeeReviewViewProgressDialog
                    onClose={() => setIsViewProgressDialogOpen(false)}
                    employeeReview={employeeReview}
                    currentEmployee={currentEmployee}
                />
            )}
        </Stack>
    );
};
