import { AnswerResultDetails, AnswerTextResult, AnswerWordCount } from '@/domain/survey/Survey.model';
import { getLabelTranslation, useUserLanguage } from '@/utils/language.util';
import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Comment } from './Comment';

type Props = {
    details: AnswerResultDetails;
    selectedWord?: AnswerWordCount;
};

export const TextAnswers: FC<Props> = ({ details, selectedWord = undefined }) => {
    const userLanguage = useUserLanguage();
    const [textAnswers, setTextAnswers] = useState<AnswerTextResult[]>([]);

    useEffect(() => {
        const filteredTextAnswers = details?.textAnswers?.filter(answer => !selectedWord || answer.text.toLowerCase().includes(selectedWord.value));
        setTextAnswers(filteredTextAnswers);
    }, [details, selectedWord]);

    if (!details) {
        return <></>;
    }

    return (
        <>
            {details.key !== 'ALL' && textAnswers?.length ? (
                <Typography variant='body1bold' mb={1} display={'block'}>
                    {getLabelTranslation(details.label, userLanguage)}
                </Typography>
            ) : undefined}

            {textAnswers?.map((answer, index: number) => <Comment key={index} text={answer.text} selectedWord={selectedWord?.value} user={answer.user} />)}
        </>
    );
};
