import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { LongLeave } from '@/domain/long-leave/LongLeave.model';
import { getLocalDateMinTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

type LongLeaveFormProps = {
    setLongLeaveDialogOpen: (open: boolean) => void;
    onSaveLongLeave: (longLeaveRequestForm: LongLeaveFormValues) => void;
    longLeaveDialogOpen: boolean;
    employeeId: number;
    currentLongLeave?: LongLeave;
};

export const LongLeaveDialog: FC<LongLeaveFormProps> = ({ setLongLeaveDialogOpen, onSaveLongLeave, employeeId, currentLongLeave, longLeaveDialogOpen }) => {
    const schema = getLongLeaveSchema();

    const formMethods = useForm<LongLeaveFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            employeeId: employeeId,
            startDate: currentLongLeave?.startDate,
            endDate: currentLongLeave?.endDate,
            comment: currentLongLeave?.comment ?? '',
        },
    });

    const { control, handleSubmit } = formMethods;

    const getLongLeaveDialogTitle = () => {
        if (currentLongLeave?.id) {
            return t('employee.employment.long_leave.modify_long_leave');
        }
        return t('employee.employment.long_leave.put_on_long_leave');
    };

    return (
        <DialogContainer
            open={longLeaveDialogOpen}
            onClose={() => setLongLeaveDialogOpen(false)}
            onSave={handleSubmit(longLeaveCreateRequest => onSaveLongLeave(longLeaveCreateRequest), console.error)}
            title={getLongLeaveDialogTitle()}
        >
            <FormProvider {...formMethods}>
                <Stack direction='column' spacing={2}>
                    <FormControlLabel label={t('general.start')} control={<FieldLocalDate control={control} name='startDate' />} />
                    <FormControlLabel label={t('general.end')} control={<FieldLocalDate control={control} name='endDate' />} />

                    <FormControlLabel
                        label={t('general.comment')}
                        control={
                            <Controller
                                name={'comment'}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        inputProps={{
                                            'aria-label': 'comment',
                                        }}
                                        error={!!fieldState.error?.message}
                                        helperText={fieldState.error?.message}
                                    />
                                )}
                            />
                        }
                    />
                </Stack>
            </FormProvider>
        </DialogContainer>
    );
};

const getLongLeaveSchema = () => {
    return yup.object().shape({
        employeeId: yup.number().required().default(getNull()),
        startDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
        endDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .when('startDate', ([startDate], schema) => schema.test(getLocalDateMinTestConfig(startDate)))
            .required(),
        comment: yup.string().required().default(''),
    });
};

export type LongLeaveFormValues = yup.InferType<ReturnType<typeof getLongLeaveSchema>>;
