import * as yup from 'yup';
import { hoursMinutesSchema } from '@/utils/datetime.schema';
import { getLocalDateTestConfig, getMinutesFromHoursMinutes, HoursMinutes } from '@/utils/datetime.util';

import i18next from 'i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPaymentFormSchema = () => {
    return yup
        .object()
        .shape({
            hoursMinutes: hoursMinutesSchema.required().test({
                message: i18next.t('general.validations.required'),
                test: (value: HoursMinutes) => getMinutesFromHoursMinutes(value) > 0,
            }),
            comment: yup.string().default(''),
            requestDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        })
        .required();
};

export type PaymentFormSchema = yup.InferType<ReturnType<typeof getPaymentFormSchema>>;
