import * as yup from 'yup';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import { LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { leaveTypeSchema } from '@/domain/leave-type/LeaveType.schema';
import { getNull } from '@/utils/object.util';

export const leaveRequestFormSchema = yup.object().shape({
    leaveType: leaveTypeSchema.required(),
    startDate: yup
        .string<LocalDate>()
        .test(getLocalDateTestConfig())
        .when('isMultipleDays', {
            is: true,
            then: schema => schema,
            otherwise: schema => schema.required(),
        }),
    endDate: yup
        .string<LocalDate>()
        .test(getLocalDateTestConfig())
        .default(getNull())
        .nullable()
        .when(['isMultipleDays', 'leaveType'], {
            is: (isMultipleDays: boolean, leaveType: LeaveType) => !isMultipleDays && leaveType.leaveActivityType !== LeaveActivityType.MEDICAL,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable(),
        }),
    dates: yup
        .array()
        .of(yup.string<LocalDate>().required())
        .required()
        .when('isMultipleDays', {
            is: true,
            then: schema => schema.min(1),
            otherwise: schema => schema,
        }),
    isMultipleDays: yup.boolean().required(),
    startTimePeriod: yup.mixed<DayPeriod>().required(),
    endTimePeriod: yup.mixed<DayPeriod>().required(),
    startTimeInMinutes: yup.number().when('leaveType', {
        is: (leaveType: LeaveType) => leaveType.unitType === UnitType.HOURS,
        then: schema => schema.required(),
        otherwise: schema => schema,
    }),
    endTimeInMinutes: yup.number().when('leaveType', {
        is: (leaveType: LeaveType) => leaveType.unitType === UnitType.HOURS,
        then: schema => schema.required(),
        otherwise: schema => schema,
    }),
    comment: yup.string().default(''),
    leavePercentage: yup.number().required().min(1).max(100),
    isApproveAutomatically: yup.boolean().required(),
});

export type LeaveRequestFormValues = yup.InferType<typeof leaveRequestFormSchema>;
