import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { LabelInputList, LabelListItemFormValues } from '@/components/label-input-list/LabelInputList';
import useDebounce from '@/hooks/Debounce.hook';
import { createJob, deleteJob, getJobs, updateJob } from '@/domain/job/Job.service';
import { UserLanguage } from '@/utils/language.util';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { t } from 'i18next';
import { Job } from '@/domain/job/Job.model';

const convertJobToItemFormValue = (job: Job) => {
    return {
        id: job.id,
        label: job.name,
        order: job.order,
    };
};

export type JobsManagementProps = {
    translationLanguage: UserLanguage;
};
export const JobsManagement: FC<PropsWithChildren<JobsManagementProps>> = ({ translationLanguage, children }) => {
    const debounce = useDebounce();

    const [list, setList] = useState<LabelListItemFormValues[]>([]);

    const fetchJobs = useCallback(async () => {
        const jobs = await getJobs();

        setList(jobs.map(convertJobToItemFormValue));
    }, []);

    // Fetch the job list
    useEffect(() => {
        fetchJobs().catch(handleError);
    }, [fetchJobs]);

    const handleJobAdd = async ({ label }: LabelListItemFormValues) => {
        try {
            const job = await createJob({ name: label, displayNameMen: label, displayNameWomen: label, order: 0 });
            // we add the new department at the top of the list
            // but when when the page will be reloaded the order will be managed by the api
            setList([convertJobToItemFormValue(job), ...list]);
        } catch {
            showSnackbar(t('settings_organization.jobs.add_error'), 'error');
        }
    };

    const handleLabelRemove = async (label: LabelListItemFormValues) => {
        if (!label.id) {
            return;
        }
        try {
            await deleteJob(label.id);
            setList(list.filter(item => item.id !== label.id));
        } catch (error) {
            handleError(error);
        }
    };

    const handleLabelUpdate = async ({ id, ...rest }: LabelListItemFormValues, error: unknown) => {
        if (!id) {
            return;
        }
        const index = list.findIndex(item => item.id === id);
        setList([...list.slice(0, index), { id, ...rest }, ...list.slice(index + 1)]);

        // If there is an error, we don't update the job
        if (error) {
            return;
        }

        debounce(async () => {
            try {
                await updateJob(id, {
                    name: rest.label,
                    displayNameMen: rest.label,
                    displayNameWomen: rest.label,
                    order: rest.order,
                });
            } catch (error) {
                handleError(error);
            }
        });
    };

    return (
        <Stack spacing={2}>
            {children}

            <LabelInputList
                list={list}
                onLabelAdd={handleJobAdd}
                onLabelRemove={handleLabelRemove}
                onLabelUpdate={handleLabelUpdate}
                translationLanguage={translationLanguage}
            />
        </Stack>
    );
};
