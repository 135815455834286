import { AgGridWrapper, AgGridWrapperColumnType, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { getCountryName } from '@/utils/countries.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { CircularProgress, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { SectionActionButton, SectionColumn, SectionRow } from '@/components/section/types';

type TableSectionProps = {
    sectionTitle: string;
    columns: SectionColumn[];
    rows: SectionRow[];
    actionButton?: SectionActionButton;
    actionMenuCellRenderer?: (params: ICellRendererParams) => ColDef['cellRenderer'];
};

export const TableSection: FC<TableSectionProps> = ({ sectionTitle, columns, rows, actionButton, actionMenuCellRenderer }) => {
    const { palette } = useTheme();
    const agGridWrapper = useAgGridWrapper<SectionRow>();
    const columnDefs: RogerColDef<SectionRow>[] = columns?.map(column => {
        const type = getType(column.type);
        return {
            sort: column.type === SectionFieldType.DATE ? 'desc' : undefined,
            headerName: column.title,
            type,
            valueGetter: ({ data }) => {
                if (!data?.fields?.length) {
                    return;
                }
                const field = data.fields.find(field => field.title === column.title);

                if (!field) {
                    return;
                }

                switch (field.type) {
                    case SectionFieldType.DATE:
                        return field.dateValue;
                    case SectionFieldType.NUMBER:
                        return field.numberValue?.toLocaleString();
                    case SectionFieldType.COUNTRY:
                        return field?.stringValue ? getCountryName(field.stringValue) : '';
                    case SectionFieldType.STRING:
                    case SectionFieldType.DOT_STRING:
                    case SectionFieldType.PHONE_NUMBER:
                    case SectionFieldType.ENUM_LIST_ITEM:
                    case SectionFieldType.IBAN_NUMBER:
                        return field.stringValue;
                    case SectionFieldType.CUSTOM_LIST_ITEM:
                        return field.customListItemReferences?.map(item => getLabelTranslation(item.label)).join();
                    case SectionFieldType.CUSTOM_MULTI_LIST_ITEM:
                        return field.customListItemReferences?.map(item => getLabelTranslation(item.label)).join(', ');
                    case SectionFieldType.SECTION_FIELD_DOCUMENT:
                        return field;
                    case SectionFieldType.EMPLOYEE_REFERENCE:
                        return field.employeeReferences;
                    case SectionFieldType.BIRTHDAY:
                    default:
                        return undefined;
                }
            },
        };
    });

    function getType(type: SectionFieldType): AgGridWrapperColumnType | undefined {
        switch (type) {
            case SectionFieldType.DATE:
                return 'date';
            case SectionFieldType.SECTION_FIELD_DOCUMENT:
                return 'sectionFieldDocuments';
            // This is a workaround
            case SectionFieldType.EMPLOYEE_REFERENCE:
                return 'stackedAvatars';
            default:
                return undefined;
        }
    }

    if (actionMenuCellRenderer) {
        columnDefs.push({
            type: 'actionMenu',
            cellRenderer: actionMenuCellRenderer,
        });
    }

    if (!columns) {
        return (
            <Stack justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Stack>
        );
    }

    // We compute the height of the table based on the number of rows
    const tableHeight = getTableHeight({ rowsLength: rows.length, disableFooter: true });

    return (
        <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Stack justifyContent='center' alignItems='flex-start' gap={1} pl={1} flex={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1} width='100%'>
                    <Typography variant='h1'>{sectionTitle}</Typography>
                    {actionButton && (
                        <Tooltip title={actionButton?.tooltipTitle}>
                            <span>
                                <Button variant='text' onClick={actionButton.onClick} disabled={actionButton?.disabled}>
                                    {actionButton.title}
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </Stack>
                {!!rows?.length && (
                    <Stack width='100%' minHeight={100} maxHeight={300} height={tableHeight}>
                        <AgGridWrapper<SectionRow>
                            initRef={agGridWrapper.setGridRef}
                            gridId={sectionTitle}
                            rowData={rows}
                            columnDefs={columnDefs}
                            statusBar={undefined}
                            getRowStyle={params => {
                                return params?.data?.isPending
                                    ? {
                                          fontStyle: 'italic',
                                          backgroundColor: palette.warning.light,
                                      }
                                    : undefined;
                            }}
                            // This is a fix to avoid column resizing when the data is updated
                            // When the dialog to add a row is opened, the table is rerendered and the columns are resized
                            // the best way is to avoid the rerendering of the table when the dialog is opened (or anything else changes)
                            // INFO : The condition on top of TableSection to use an other display for mobile is responsible for the rerendering
                            //  {!isSmallDevice ? (
                            // <TableSection
                            onRowDataUpdated={params => {
                                params.api.autoSizeAllColumns();
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </Paper>
    );
};
