import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { UnitType } from '@/domain/date/Date.model';
import { Employee } from '@/domain/employee/Employee.model';
import { Employment } from '@/domain/employment/Employment.model';
import { convertLeavesMinutesToUnit } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveBalanceSearchRequest, UserLeaveTypeBalance } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { AllowanceType, CarryoverType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { filterOutCompensationNotUnlimitedLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { isDifferentLifeCycleStartMonth } from '@/domain/leave/Leave.service';
import { canCorrectOtherEmployeeBalance } from '@/domain/permission/Permission.service';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { LeaveBalanceFilter, useLeaveBalancePageFilters } from '@/hooks/leave-type-history/LeaveBalancePageFilters.hook';
import { useGetLeaveBalance } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { AssignPolicyDialog } from '@/page/employee-profile/employee-profile-leave/Dialogs/AssignPolicyDialog';
import { UnassignPolicyDialog } from '@/page/employee-profile/employee-profile-leave/Dialogs/UnassignPolicyDialog';
import { useSearchTimesheetSettings } from '@/page/setting/time-management/TimesheetSettings.hook';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { useAppSelector } from '@/stores/store';
import { formatDate, getEndOfYear } from '@/utils/datetime.util';

import { getLabelTranslation } from '@/utils/language.util';
import { RowClickedEvent, SortChangedEvent } from '@ag-grid-community/core';
import { Button, ListItemButton, Paper, Stack, Theme, Typography, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import i18next from 'i18next';
import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const LeavesBalancePage: FC = () => {
    const { data: leaveTypes = [], isLoading: isLeaveTypesLoading, isError: isLeaveTypesError, error: leaveTypesError } = useGetLeaveTypes();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);

    const {
        data: timesheetSettings = [],
        isLoading: isTimesheetSettingsLoading,
        isError: isTimesheetSettingsError,
        error: timesheetSettingsError,
    } = useSearchTimesheetSettings();

    const leaveTypesFiltered = filterOutCompensationNotUnlimitedLeaveTypes(leaveTypes);
    const defaultLeaveType = leaveTypesFiltered?.[0];
    const defaultTimesheetSetting = timesheetSettings?.[0];

    const isLoading = isLeaveTypesLoading || isTimesheetSettingsLoading;
    const isError = isLeaveTypesError || isTimesheetSettingsError;
    const isEmpty = !leaveTypes?.length || !timesheetSettings?.length || !defaultLeaveType || !defaultTimesheetSetting;
    const error = leaveTypesError || timesheetSettingsError;

    if (!currentEmployee?.id) {
        return;
    }

    return (
        <StateHandler isLoading={isLoading} isError={isError} isEmpty={isEmpty} error={error}>
            <LeavesBalanceTable
                currentEmployeeId={currentEmployee.id}
                leaveTypes={leaveTypesFiltered}
                defaultLeaveType={defaultLeaveType}
                timesheetSettings={timesheetSettings}
                defaultTimesheetSetting={defaultTimesheetSetting}
            />
        </StateHandler>
    );
};

type LeavesBalanceTableProps = {
    leaveTypes: LeaveType[];
    defaultLeaveType: LeaveType;
    timesheetSettings: TimesheetSetting[];
    defaultTimesheetSetting: TimesheetSetting;
    currentEmployeeId: number;
};

const LeavesBalanceTable: FC<LeavesBalanceTableProps> = ({ leaveTypes, defaultLeaveType, timesheetSettings, defaultTimesheetSetting, currentEmployeeId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const handleLeaveTypeAction = Boolean(anchorEl);
    const leaveTypeActionsId = handleLeaveTypeAction ? 'leave-type-actions' : undefined;
    const dispatch = useDispatch();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const canCorrectSomeEmployeeBalance = canCorrectOtherEmployeeBalance(policies, currentEmployeeId);

    const haveDifferentCycleStartMonth = isDifferentLifeCycleStartMonth(timesheetSettings);

    // FILTERS
    const { filters: availableFilters } = useLeaveBalancePageFilters(leaveTypes, defaultLeaveType);

    //add the filter in case we have different cycle start month
    if (haveDifferentCycleStartMonth) {
        availableFilters.unshift({
            filterName: t('balance_page.filters.time_management_profile'),
            type: 'select',
            selectMode: 'SYNC',
            value: [{ label: defaultTimesheetSetting.name, value: defaultTimesheetSetting.id }],
            options: timesheetSettings?.map(timesheetSetting => ({
                label: timesheetSetting.name,
                value: timesheetSetting.id,
            })),
            key: 'timesheetSettingId',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
        });
    }

    const [filters, setFilters] = useFiltersStorage('manage-balance-leaves-filters', availableFilters);

    const mapFiltersToLeaveBalanceSearchRequest = (filters: LeaveBalanceFilter[]) => {
        const search: LeaveBalanceSearchRequest = {
            endDate: getCycleEndDate(filters),
            locationIds: getFilterValueIdsByKey(filters, 'locationIds'),
            departmentIds: getFilterValueIdsByKey(filters, 'departmentIds'),
            jobIds: getFilterValueIdsByKey(filters, 'jobIds'),
            managerIds: getFilterValueIdsByKey(filters, 'managerIds'),
            timesheetSettingId: haveDifferentCycleStartMonth ? getFilterValueIdsByKey(filters, 'timesheetSettingId')?.[0] : undefined,
        };
        return search;
    };

    const {
        data: leaveBalance = [],
        isFetching = true,
        isLoading,
        isError,
        error,
    } = useGetLeaveBalance({
        leaveTypeId: getLeaveTypeId(filters),
        searchRequest: mapFiltersToLeaveBalanceSearchRequest(filters),
        options: { enabled: !!filters.length },
    });

    const columnDefs = getColumnDefs(canCorrectSomeEmployeeBalance, useTheme(), getLeaveType(leaveTypes, filters, defaultLeaveType), getCycleEndDate(filters));

    const agGridWrapper = useAgGridWrapper<UserLeaveTypeBalance>();

    const onBtnExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            // We don't want to export hidden columns
            allColumns: false,
            columnKeys: columnDefs
                .map(columnDef => columnDef.field ?? columnDef.colId)
                // we want to remove the first and second columns
                .slice(2, columnDefs.length)
                .filter(c => c !== undefined),
        });
    };

    const handleRowClicked = (params: RowClickedEvent<UserLeaveTypeBalance>) => {
        if (!params?.event?.defaultPrevented && params.data) {
            navigate(`/profile/${params.data.employee.id}/leaves/history/${params.data.leaveType.id}`);
        }
    };

    const handleCloseLeaveTypeActionButton = () => {
        setAnchorEl(undefined);
    };

    const handleClickLeaveTypeButton = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onSortChange = (params: SortChangedEvent<UserLeaveTypeBalance>) => {
        const sortModel = params.api.getColumnState();
        localStorage.setItem('sortLeaves', JSON.stringify(sortModel));
    };

    const selectedRows = agGridWrapper?.selectedRows;

    return (
        <Stack gap={2} flex={1}>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <Stack
                    component={Paper}
                    p={{ xs: 2, sm: 1 }}
                    pb={{ xs: 0, sm: 1 }}
                    direction={'row'}
                    spacing={2}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <FiltersBar filters={filters} onFiltersChange={setFilters} />
                    <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
                </Stack>
                <Stack alignItems='center' direction='row' alignContent='space-between' gap={0.5}>
                    {canCorrectSomeEmployeeBalance && (
                        <Typography variant='body2' color='textPrimary' align='right'>
                            {t('manage_people_page.selected')}: {selectedRows?.length > 0 ? selectedRows.length : undefined}
                        </Typography>
                    )}
                    {selectedRows.length > 0 ? (
                        <Button variant='contained' size='small' color='primary' onClick={handleClickLeaveTypeButton}>
                            {t('manage_people_page.action')}
                        </Button>
                    ) : undefined}
                    <Popover
                        id={leaveTypeActionsId}
                        open={handleLeaveTypeAction}
                        anchorEl={anchorEl}
                        onClose={handleCloseLeaveTypeActionButton}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <List component='nav' aria-label='main mailbox folders'>
                            <ListItemButton
                                onClick={() => {
                                    if (!selectedRows) {
                                        return;
                                    }
                                    const rows = selectedRows;
                                    const employees: Employee[] = [];
                                    rows.forEach(row => {
                                        employees.push(row.employee);
                                    });
                                    dispatch({
                                        type: UiActionType.ASSIGN_LEAVE_TYPE_DIALOG_OPEN,
                                        userId: undefined,
                                        open: true,
                                        usersIdList: employees?.map(employee => employee.id) ?? [],
                                    });
                                }}
                            >
                                <ListItemText primary={t('balance_page.assign_leave_type')} />
                            </ListItemButton>
                            <ListItemButton
                                onClick={() => {
                                    if (!selectedRows) {
                                        return;
                                    }
                                    const rows = selectedRows;
                                    const employees: Employee[] = [];
                                    rows.forEach(row => {
                                        employees.push(row.employee);
                                    });
                                    dispatch({
                                        type: UiActionType.UNASSIGN_LEAVE_TYPE_DIALOG_OPEN,
                                        userId: undefined,
                                        open: true,
                                        usersIdList: employees?.map(employee => employee.id) ?? [],
                                    });
                                }}
                            >
                                <ListItemText primary={t('balance_page.unassign_leave_type')} />
                            </ListItemButton>
                        </List>
                    </Popover>
                </Stack>
                <Stack flex='1'>
                    <AgGridWrapper<UserLeaveTypeBalance>
                        rowData={leaveBalance}
                        rowSelection='multiple'
                        initRef={agGridWrapper.setGridRef}
                        onSortChanged={onSortChange}
                        onRowClicked={handleRowClicked}
                        columnDefs={columnDefs}
                        // This is useful to keep selection after front end filtering
                        getRowId={({ data }) => data.employee?.id?.toString()}
                    />
                </Stack>
                <AssignPolicyDialog
                    onPolicyAssigned={() => {
                        dispatch({ type: UiActionType.ASSIGN_LEAVE_TYPE_DIALOG_OPEN, open: false });
                    }}
                />
                <UnassignPolicyDialog
                    userLeaveTypePolicies={[]}
                    onPolicyUnassigned={() => {
                        // nothing to do
                    }}
                />
            </StateHandler>
        </Stack>
    );
};

const getCycleEndDate = (filters: LeaveBalanceFilter[]): LocalDate => {
    const filterEndDate = filters.find(filter => filter.key === 'endCycle')?.value as LocalDate | undefined;
    return filterEndDate ? filterEndDate : getEndOfYear();
};

const getLeaveTypeId = (filters: LeaveBalanceFilter[]) => {
    return getSelectFilterNumberValues(filters.find(filter => filter.key === 'leaveTypeId'))?.[0];
};

const getLeaveType = (leaveTypes: LeaveType[], filters: LeaveBalanceFilter[], defaultLeaveType: LeaveType) => {
    const leaveTypeId = getLeaveTypeId(filters);
    return leaveTypes.find(leaveType => leaveType.id === leaveTypeId) ?? defaultLeaveType;
};

const getColumnDefs = (
    canCorrectSomeEmployeeBalance: boolean,
    theme: Theme,
    selectedLeaveType: LeaveType,
    selectedCycleEndDate: LocalDate,
): RogerColDef<UserLeaveTypeBalance>[] => {
    return [
        {
            type: 'selection',
            hide: !canCorrectSomeEmployeeBalance,
        },
        {
            field: 'employee',
            headerName: '',
            type: 'avatar',
        },
        {
            field: 'employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            field: 'employee.firstName',
            headerName: i18next.t('manage_people_page.table_headers.firstName'),
        },
        {
            field: 'employee.lastName',
            headerName: i18next.t('manage_people_page.table_headers.lastName'),
        },
        {
            field: 'employee.currentEmployments',
            headerName: i18next.t('balance_page.jobTitle'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => getLabelTranslation(employment.job.name)).join(', '),
        },
        {
            field: 'employee.currentEmployments',
            headerName: i18next.t('balance_page.location'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => employment.location.name).join(', '),
        },
        {
            field: 'employee.currentEmployments',
            headerName: i18next.t('balance_page.department'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => getLabelTranslation(employment.department.name)).join(', '),
        },
        {
            field: 'employee.currentEmployments',
            headerName: i18next.t('balance_page.manager'),
            valueGetter: ({ data }) => data?.employee.currentEmployments?.flatMap(employment => employment.managers),
            type: 'stackedAvatars',
        },
        {
            field: 'cycleHistory.remainingBalanceInMinutes',
            headerName: i18next.t('balance_page.current_balance', { date: formatDate(selectedCycleEndDate, 'dd.MM') }),
            cellStyle: ({ value }) => {
                return value < 0 ? { color: theme.palette.error.main } : undefined;
            },
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                return convertLeavesMinutesToUnit({
                    input:
                        data.leaveType.displayUnitType === UnitType.DAYS
                            ? data.cycleHistory.remainingBalanceInDays
                            : data.cycleHistory.remainingBalanceInMinutes,
                    outputUnit: data.leaveType.displayUnitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
        },
        {
            field: 'cycleHistory.grantedAmountInMinutes',
            headerName: i18next.t('balance_page.grant'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                return convertLeavesMinutesToUnit({
                    input:
                        data.leaveType.displayUnitType === UnitType.DAYS
                            ? data.cycleHistory.grantedAmountInDays - data.cycleHistory.carryoverAmountInDays
                            : data.cycleHistory.grantedAmountInMinutes - data.cycleHistory.carryoverAmountInMinutes,
                    outputUnit: data.leaveType.displayUnitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
            hide: selectedLeaveType?.allowanceType === AllowanceType.UNLIMITED,
        },
        {
            field: 'cycleHistory.carryoverAmountInMinutes',
            headerName: i18next.t('balance_page.carryover'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                return convertLeavesMinutesToUnit({
                    input:
                        data.leaveType.displayUnitType === UnitType.DAYS ? data.cycleHistory.carryoverAmountInDays : data.cycleHistory.carryoverAmountInMinutes,
                    outputUnit: data.leaveType.displayUnitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
            hide: selectedLeaveType?.carryoverType === CarryoverType.YEARLY_NO_CARRYOVER || selectedLeaveType?.allowanceType === AllowanceType.UNLIMITED,
        },
        {
            field: 'cycleHistory.takenAmountInMinutes',
            headerName: i18next.t('balance_page.taken_days'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }

                const amount = data.leaveType.displayUnitType === UnitType.DAYS ? data.cycleHistory.takenAmountInDays : data.cycleHistory.takenAmountInMinutes;
                const leaveType = data.leaveType;
                return convertLeavesMinutesToUnit({
                    input: leaveType.allowanceType === AllowanceType.UNLIMITED ? amount : -amount,
                    outputUnit: leaveType.displayUnitType,
                    roundingType: leaveType.roundingType,
                });
            },
        },
        {
            field: 'cycleHistory.plannedAmountInMinutes',
            headerName: i18next.t('balance_page.planned'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                const amount =
                    data.leaveType.displayUnitType === UnitType.DAYS ? data.cycleHistory.plannedAmountInDays : data.cycleHistory.plannedAmountInMinutes;
                const leaveType = data.leaveType;
                return convertLeavesMinutesToUnit({
                    input: leaveType.allowanceType === AllowanceType.UNLIMITED ? amount : -amount,
                    outputUnit: leaveType.displayUnitType,
                    roundingType: leaveType.roundingType,
                });
            },
        },
        {
            field: 'cycleHistory.adjustedAmountInMinutes',
            headerName: i18next.t('balance_page.adjustments'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                return convertLeavesMinutesToUnit({
                    input:
                        data.leaveType.displayUnitType === UnitType.DAYS ? data.cycleHistory.adjustedAmountInDays : data.cycleHistory.adjustedAmountInMinutes,
                    outputUnit: data.leaveType.displayUnitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
            hide: selectedLeaveType?.allowanceType === AllowanceType.UNLIMITED,
        },
        {
            field: 'employee.employeeCode',
            headerName: i18next.t('employee.employeeCode'),
            hide: true,
        },
    ];
};
