import { IconButton, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { MoreVerticalIcon } from '@/assets/icons/Icons';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

type Props = {
    onEditClicked?: () => void;
    onDeleteClicked?: () => void;
    onApproveClicked?: () => void;
    onDeclineClicked?: () => void;
    onCancelClicked?: () => void;
    onAddClicked?: () => void;
    disabled?: boolean;
    deleteEnabled?: boolean;
    isAddEnabled?: boolean;
    approveEnabled?: boolean;
    declineEnabled?: boolean;
    editDisabled?: boolean;
    cancelEnabled?: boolean;
};

// TODO: Improve code quality of the EmployeeFieldMoreButton component by cleaning and refactoring, particularly these multiple boolean code smell props.
// TODO: showMoreButton add condition for editDisabled and rethink about renaming this to editEnabled
export const EmployeeFieldMoreButton: FC<Props> = ({
    onEditClicked = () => {},
    onAddClicked = () => {},
    onDeleteClicked = () => {},
    onApproveClicked = () => {},
    onDeclineClicked = () => {},
    onCancelClicked = () => {},
    disabled = false,
    deleteEnabled = true,
    isAddEnabled = false,
    approveEnabled = false,
    declineEnabled = false,
    editDisabled = false,
    cancelEnabled = true,
}) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const showMoreButton = (deleteEnabled || isAddEnabled || approveEnabled || declineEnabled || cancelEnabled) && !disabled;

    return (
        showMoreButton && (
            <>
                <Menu
                    open={popupVisible}
                    id='user-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    onClose={(event: Event) => {
                        event.stopPropagation();
                        setPopupVisible(false);
                    }}
                    anchorOrigin={{ vertical: 30, horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {isAddEnabled && (
                        <MenuItem
                            dense={true}
                            onClick={event => {
                                event.stopPropagation();
                                setPopupVisible(false);
                                onAddClicked();
                            }}
                        >
                            {t('general.add')}
                        </MenuItem>
                    )}
                    {!editDisabled && (
                        <MenuItem
                            dense={true}
                            onClick={event => {
                                event.stopPropagation();
                                setPopupVisible(false);
                                onEditClicked();
                            }}
                            disabled={editDisabled}
                        >
                            {t('general.edit')}
                        </MenuItem>
                    )}

                    {approveEnabled && (
                        <MenuItem
                            dense={true}
                            onClick={event => {
                                event.stopPropagation();
                                setPopupVisible(false);
                                onApproveClicked();
                            }}
                        >
                            {t('general.approve')}
                        </MenuItem>
                    )}
                    {declineEnabled && (
                        <MenuItem
                            dense={true}
                            onClick={event => {
                                event.stopPropagation();
                                setPopupVisible(false);
                                onDeclineClicked();
                            }}
                        >
                            {t('general.decline')}
                        </MenuItem>
                    )}

                    {cancelEnabled && (
                        <MenuItem
                            dense={true}
                            onClick={event => {
                                event.stopPropagation();
                                setPopupVisible(false);
                                onCancelClicked();
                            }}
                        >
                            {t('general.cancel')}
                        </MenuItem>
                    )}
                    {deleteEnabled && (
                        <MenuItem
                            dense={true}
                            onClick={event => {
                                event.stopPropagation();
                                setPopupVisible(false);
                                onDeleteClicked();
                            }}
                        >
                            {t('general.delete')}
                        </MenuItem>
                    )}
                </Menu>
                <IconButton
                    sx={isMobile ? { padding: 0 } : undefined}
                    size='small'
                    disabled={disabled}
                    onClick={event => {
                        setAnchorEl(event.currentTarget);
                        event.preventDefault();
                        event.stopPropagation();
                        setPopupVisible(true);
                    }}
                    role='button'
                    aria-label='more'
                >
                    <MoreVerticalIcon />
                </IconButton>
            </>
        )
    );
};
