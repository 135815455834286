import { DatePickerProps, DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { getAppConfig } from '@/config/config';

import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';

type DatePickerRootProps = Pick<DatePickerProps, 'disabled' | 'minDate' | 'maxDate' | 'mobileOnly' | 'sx' | 'onChange'>;

type LocalDatePickerRootProps = Omit<DatePickerRootProps, 'onChange'> & {
    onChange?: (date: LocalDate | null) => void;
};

export type FieldLocalDateProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    datePickerProps?: RemoveFromType<RemoveFromType<DatePickerProps, LocalDatePickerRootProps>, ControllerRenderProps>;
} & LocalDatePickerRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldLocalDate = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldLocalDateProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, mobileOnly, minDate, maxDate, sx, onChange, ...controllerProps } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <DatePickerWrapper
                    timezone={getAppConfig().DEFAULT_TIMEZONE}
                    disabled={disabled}
                    slotProps={{
                        textField: {
                            error: !!error,
                            helperText: error?.message,
                        },
                    }}
                    mobileOnly={mobileOnly}
                    minDate={minDate}
                    maxDate={maxDate}
                    sx={sx}
                    {...field}
                    value={field.value}
                    onChange={localDate => {
                        field.onChange(localDate);
                        onChange?.(localDate);
                    }}
                />
            )}
        />
    );
};
