import { LeaveTypeSettingForm } from '@/page/setting/time-management/leave-type-setting-form/LeaveTypeSettingForm';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { getLeaveTypeById } from '@/domain/leave-type/LeaveType.service';

export const LeaveTypeSettingPage: FC = () => {
    const { leaveTypeId } = useParams();
    const mode = leaveTypeId !== 'new' ? 'edit' : 'create';

    const [leaveType, setLeaveType] = useState<LeaveType>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();
    const isNotFound = mode === 'edit' && !leaveType;

    useEffect(() => {
        if (mode === 'edit') {
            setIsLoading(true);
            getLeaveTypeById(Number(leaveTypeId))
                .then(data => setLeaveType(data))
                .catch(e => setError(e))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [leaveTypeId, mode]);

    return (
        <StateHandler isLoading={isLoading} isError={!!error} isEmpty={isNotFound} error={error}>
            <LeaveTypeSettingForm leaveType={leaveType} />
        </StateHandler>
    );
};
