import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionActionButton, SectionField } from '@/components/section/types';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getLabelTranslation } from '@/utils/language.util';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { EmployeeSectionRow } from '@/domain/employee-section/EmployeeSection.model';
import { convertEmployeeSectionFieldsToSectionFields } from '@/domain/employee/Employee.service';
import {
    CustomSectionRowFormValues,
    useCustomSectionRowSchema,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeCustomSectionRowDialog.hook';

export type EmployeeSectionRowFormValues = Omit<EmployeeSectionRow, 'id'> & {
    id?: number;
};

export const EmployeeCustomSectionRowDialog: FC<{
    sectionId: number;
    sectionDefinition: SectionDefinition;
    onSave(values: CustomSectionRowFormValues): void;
    actionButton?: SectionActionButton;
    currentSectionRow: EmployeeSectionRowFormValues;
    onClose(): void;
}> = ({ sectionId, sectionDefinition, onSave, onClose, currentSectionRow }) => {
    const fields = convertEmployeeSectionFieldsToSectionFields(sectionId, sectionDefinition.fields, currentSectionRow?.fields);

    const { schema } = useCustomSectionRowSchema({ sectionDefinition });

    const formMethods = useForm<CustomSectionRowFormValues>({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const handleSave = () => {
        handleSubmit(values => onSave(values), console.error)();
    };

    return (
        <DialogContainer title={getLabelTranslation(sectionDefinition.name)} open onSave={handleSave} onClose={onClose}>
            <Stack gap={1.5}>
                {fields?.map((field: SectionField) => (
                    <Stack key={field.title} gap={0.5}>
                        <Typography noWrap>
                            {field.title}
                            {field.required ? '*' : ''}
                        </Typography>
                        <FormProvider {...formMethods}>
                            <EditableSectionFieldComponent field={field} />
                        </FormProvider>
                    </Stack>
                ))}
            </Stack>
        </DialogContainer>
    );
};
