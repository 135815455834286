import { API_BASE_URL, client } from '@/api/common';
import { EmployeeProfileChange, EmployeeProfileChangeRow, EmployeeProfileChangeSearch } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { AxiosResponse } from 'axios';
import { EmployeeSectionDTO, EmployeeSectionFieldDTO } from '../employee-section/EmployeeSection.api';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

type EmployeeProfileChangeRequestDTO = {
    sectionDefinitionIds?: number[];
    employeeIds?: number[];
};

type EmployeeProfileChangeDTO = {
    employee: EmployeeAvatar;
    employeeSection: EmployeeSectionDTO;
    pendingRows: EmployeeProfileChangeRowDTO[];
};

type EmployeeProfileChangeRowDTO = {
    id: number;
    createdBy: EmployeeAvatar;
    fields: EmployeeSectionFieldDTO[];
    updatedAt: string;
};

const mapEmployeePendingRowDTO = (row: EmployeeProfileChangeRowDTO): EmployeeProfileChangeRow => {
    return {
        ...row,
        updatedAt: convertUTCIsoStringToDate(row.updatedAt),
    };
};

const mapEmployeeProfileChangeDTO = (change: EmployeeProfileChangeDTO): EmployeeProfileChange => {
    return {
        ...change,
        pendingRows: change.pendingRows?.map(mapEmployeePendingRowDTO),
    };
};

const getEmployeeProfilePendingChanges = (employeeId: number): Promise<EmployeeProfileChange[]> => {
    return client
        .get<EmployeeProfileChangeDTO[], AxiosResponse<EmployeeProfileChangeDTO[]>>(API_BASE_URL + `/employee/pending-changes/${employeeId}`)
        .then(response => response.data?.map(mapEmployeeProfileChangeDTO));
};

const searchEmployeeProfilePendingChanges = (request: EmployeeProfileChangeSearch = {}): Promise<EmployeeProfileChange[]> => {
    return client
        .post<
            EmployeeProfileChangeDTO[],
            AxiosResponse<EmployeeProfileChangeDTO[]>,
            EmployeeProfileChangeRequestDTO
        >(API_BASE_URL + `/employee/pending-changes/search`, request)
        .then(response => response.data?.map(mapEmployeeProfileChangeDTO));
};

export const employeePendingChangeAPI = {
    searchEmployeeProfilePendingChanges,
    getEmployeeProfilePendingChanges,
};
