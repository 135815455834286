import {
    DatePicker as MuiDatePicker,
    DatePickerProps as MuiDatePickerProps,
    DateValidationError,
    LocalizationProvider,
    MobileDatePicker as MuiMobileDatePicker,
    PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { forwardRef } from 'react';

import { formatToLocalDate, isValid, MAX_DATE, MIN_DATE, toDate } from '@/utils/datetime.util';

import { getLocale, getUserLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { Calendar03Icon } from 'hugeicons-react';

export type DatePickerProps = Overwrite<
    MuiDatePickerProps<Date>,
    {
        value?: LocalDate;
        onChange?: (value: LocalDate | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
        minDate?: LocalDate;
        maxDate?: LocalDate;
    }
> & {
    mobileOnly?: boolean;
    name?: string;
};

const defaultProps: DatePickerProps = {
    format: 'dd.MM.yyyy',
    closeOnSelect: true,
    minDate: MIN_DATE,
    maxDate: MAX_DATE,
};

const defaultSlotProps: DatePickerProps['slotProps'] = {
    toolbar: {
        hidden: true,
    },
    actionBar: {
        actions: [],
    },
    dialog: {
        maxWidth: false,
        fullWidth: false,
        // all dialogs are displayed in the top of the screen on mobile
        // but we want the date picker to be centered
        sx: {
            '& .MuiDialog-container': {
                alignItems: 'center',
            },
        },
    },
};

export const DatePickerWrapper = forwardRef<HTMLDivElement, DatePickerProps>(function DatePicker(
    { mobileOnly = false, value, slotProps, slots, onChange, minDate, maxDate, ...rest },
    ref,
) {
    const userLanguage = getUserLanguage();
    const locale = getLocale(userLanguage);

    const currentValue = toDate(value) ?? getNull();

    const muiDatePickerProps: MuiDatePickerProps<Date> = {
        ...defaultProps,
        value: currentValue,
        minDate: toDate(minDate) ?? toDate(defaultProps.minDate),
        maxDate: toDate(maxDate) ?? toDate(defaultProps.maxDate),
        slots: {
            openPickerIcon: Calendar03Icon,
            ...slots,
        },
        slotProps: {
            ...defaultSlotProps,
            ...slotProps,
            textField: {
                inputRef: ref,
                fullWidth: true,
                ...slotProps?.textField,
                inputProps: {
                    'aria-label': rest.name,
                },
            },
        },
        onChange: (date, context) => {
            // case when the user clears the date
            if (!date) {
                onChange?.(getNull(), context);
                return;
            }

            // case invalid date
            // we just check if is a valid date, we let the form handle valid range (> 1900)
            if (!isValid(date)) {
                // workaround to simulate invalid date as LocalDate
                // we need this case to let the field displays what the user typed
                // otherwise, the field will be cleared when the user change the year by typing 0 for example
                onChange?.('an-invalid-date' as LocalDate, context);
                return;
            }

            const formattedDate = formatToLocalDate(date);
            onChange?.(formattedDate, context);
        },
        ...rest,
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            {mobileOnly ? <MuiMobileDatePicker {...muiDatePickerProps} /> : <MuiDatePicker {...muiDatePickerProps} />}
        </LocalizationProvider>
    );
});
