import { FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ObjectiveCompletionStatus } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.model';

type CompletionStatusesSettingsDialogProps = {
    handleSave: (name: string) => void;
    closeDialog: () => void;
    activeCompletionStatus: Omit<ObjectiveCompletionStatus, 'id'>;
};

export const ScaleDialog: FC<CompletionStatusesSettingsDialogProps> = ({ handleSave, closeDialog, activeCompletionStatus }) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        name: yup.string().trim().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: activeCompletionStatus?.name ?? '',
        },
    });

    const onSave = () => {
        const { name } = getValues();
        handleSave(name);
    };
    return (
        <DialogContainer
            open={true}
            onClose={closeDialog}
            onSave={handleSubmit(onSave, console.error)}
            title={activeCompletionStatus ? t('planning_setting_page.edit_position') : t('planning_setting_page.add_position')}
        >
            <Stack gap={2}>
                <FormControlLabel
                    label={t('planning_setting_page.position_name')}
                    labelPlacement='top'
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                />
            </Stack>
        </DialogContainer>
    );
};
