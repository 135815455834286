import {
    Objective,
    ObjectiveCloseMutation,
    ObjectiveCreateMutation,
    ObjectiveSearch,
    ObjectiveStatus,
    ObjectiveStatusCreateMutation,
    ObjectiveStatusUpdate,
    ObjectiveUpdateMutation,
} from '@/domain/objective/Objective.model';
import { objectiveApi } from '@/api/objective/Objective.api';
import { getCurrentLocalDate, isAfterDate } from '@/utils/datetime.util';

export const getObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.getObjective(objectiveId);
};

export const updateObjective = (objectiveId: number, mutation: ObjectiveUpdateMutation): Promise<Objective> => {
    return objectiveApi.updateObjective(objectiveId, mutation);
};

export const deleteObjective = (objectiveId: number): Promise<void> => {
    return objectiveApi.deleteObjective(objectiveId);
};

export const closeObjective = (objectiveId: number, request: ObjectiveCloseMutation): Promise<Objective> => {
    return objectiveApi.closeObjective(objectiveId, request);
};

export const reopenObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.reopenObjective(objectiveId);
};

export const archiveObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.archiveObjective(objectiveId);
};

export const unarchiveObjective = (objectiveId: number): Promise<Objective> => {
    return objectiveApi.unarchiveObjective(objectiveId);
};

export const createObjective = (mutation: ObjectiveCreateMutation): Promise<Objective[]> => {
    return objectiveApi.createObjective(mutation);
};

export const searchObjectives = (request: ObjectiveSearch): Promise<Objective[]> => {
    return objectiveApi.searchObjectives(request);
};

export const createObjectiveStatus = (objectiveId: number, request: ObjectiveStatusCreateMutation): Promise<ObjectiveStatusUpdate> => {
    return objectiveApi.createObjectiveStatus(objectiveId, request);
};

export const updateObjectiveStatus = (
    objectiveId: number,
    objectiveStatusId: number,
    request: ObjectiveStatusCreateMutation,
): Promise<ObjectiveStatusUpdate> => {
    return objectiveApi.updateObjectiveStatus(objectiveId, objectiveStatusId, request);
};

export const deleteObjectiveStatus = (objectiveId: number, objectiveStatusId: number): Promise<void> => {
    return objectiveApi.deleteObjectiveStatus(objectiveId, objectiveStatusId);
};

export const getDueLabelKey = (dueDate: LocalDate): string => {
    if (isAfterDate(dueDate, getCurrentLocalDate())) {
        return 'objectives.overdue';
    } else {
        return 'objectives.due';
    }
};
export const getObjectiveStatusTranslationKey = (objectiveStatus: ObjectiveStatus): string => {
    switch (objectiveStatus) {
        case ObjectiveStatus.DELAYED:
            return 'objectives.status.off_track';
        case ObjectiveStatus.AT_RISK:
            return 'objectives.status.at_risk';
        case ObjectiveStatus.ON_TRACK:
            return 'objectives.status.on_track';
        default:
            return '';
    }
};

export const getParentsObjective = (objective: Objective): Objective[] => {
    const collectParents = (currentObjective: Objective | undefined, parents: Objective[] = []): Objective[] => {
        if (!currentObjective?.parent) {
            return parents;
        }
        return collectParents(currentObjective.parent, [currentObjective.parent, ...parents]);
    };

    return collectParents(objective);
};
