import { DailyTimesheetReport, Timesheet, TimesheetAction, TimesheetsRequestStatus, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { getDurationFromTime, isBeforeTime, isSameTime } from '@/utils/datetime.util';

export const MAX_TIME_PER_DAY_IN_MINUTES = 1080;
export const MAX_TIME_BETWEEN_TIMESHEETS = 480;
export const TIMESHEET_DEFAULT_DURATION = 60;
export const SHOW_BREAK_DURATION_BETWEEN_TIMESHEETS_FROM_X_MINUTES = 1;

export const getOriginalTimesheets = (dailyTimesheetReports: DailyTimesheetReport[]): Timesheet[] => {
    return (
        dailyTimesheetReports?.[0]?.dayTimesheets[0]?.timesheets.filter(
            timesheet =>
                timesheet.type === TimesheetType.TIMESHEET ||
                timesheet.type === TimesheetType.SHIFT_TIMESHEET ||
                timesheet.type === TimesheetType.MISSING ||
                timesheet.type === TimesheetType.AUTOFILL,
        ) ?? []
    );
};

export const hasAtLeastOneTimesheetType = (timesheets: Timesheet[]): boolean => {
    return timesheets.some(timesheet => {
        return timesheet.type === TimesheetType.TIMESHEET;
    });
};

export const isForceCreation = (mode: TimesheetAction, hasExistingTimesheetsInCurrentDate: boolean): boolean =>
    (mode === TimesheetAction.CREATE && !hasExistingTimesheetsInCurrentDate) ||
    mode === TimesheetAction.SHIFT_TIMESHEET ||
    mode === TimesheetAction.AUTO_FILL_TIMESHEET;

export const getDialogTitleKey = (mode: TimesheetAction, hasExistingTimesheetsInCurrentDate: boolean): string => {
    if (mode === TimesheetAction.MISSING_TIMESHEET) {
        return 'timesheets.complete_missing_entries';
    }
    if (hasExistingTimesheetsInCurrentDate) {
        return 'timesheets.update_timesheets';
    }
    return 'timesheets.create_timesheets';
};
//To validate if we have approved timesheets in the day to check for deletion
export const hasApprovedTimesheets = (timesheets: Timesheet[]): boolean => {
    return timesheets.some(timesheet => {
        return timesheet.status === TimesheetsRequestStatus.APPROVED;
    });
};

/**
 * Return the difference in minutes between two times taking the overnight into account
 * @param startTime represents the start time
 * @param endTime represents the end time
 *  @returns difference in minutes
 */
export const diffTimesInMinutesWithOvernight = (startTime: LocalTime, endTime: LocalTime): number => {
    if (isSameTime(startTime, endTime)) {
        return 0;
    }
    const isStartBeforeEnd = isBeforeTime(startTime, endTime);
    const totalMinutesInDay = 24 * 60;
    return isStartBeforeEnd
        ? getDurationFromTime(endTime) - getDurationFromTime(startTime)
        : getDurationFromTime(endTime) + (totalMinutesInDay - getDurationFromTime(startTime));
};
