import { getCurrentLocalDate, getTimeFormatFromDate, setTime } from '@/utils/datetime.util';
import { getLocale, getUserLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { LocalizationProvider as MuiLocalizationProvider, TimeField as MuiTimeField, TimeFieldProps, TimeValidationError } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { FC, forwardRef, PropsWithChildren } from 'react';

export type TimeFieldWrapperProps = Overwrite<
    TimeFieldProps<Date>,
    {
        value?: Nullable<LocalTime>;
        onChange?: (value: LocalTime | null, context: FieldChangeHandlerContext<TimeValidationError>) => void;
    }
>;
export const TimeFieldWrapper = forwardRef<HTMLDivElement, TimeFieldWrapperProps>((props, ref) => {
    const { value, onChange, ...rest } = props;
    const dateTime = value ? setTime(getCurrentLocalDate(), value) : value;

    return (
        <LocalizationProvider>
            <MuiTimeField
                ref={ref}
                ampm={false}
                value={dateTime}
                {...(onChange
                    ? {
                          onChange: (date, context) => {
                              onChange(date ? getTimeFormatFromDate(date) : getNull(), context);
                          },
                      }
                    : {})}
                {...rest}
            />
        </LocalizationProvider>
    );
});

export type DateTimeFieldWrapperProps = TimeFieldProps<Date>;
export const DateTimeFieldWrapper = forwardRef<HTMLDivElement, DateTimeFieldWrapperProps>((props, ref) => {
    return (
        <LocalizationProvider>
            <MuiTimeField ref={ref} ampm={false} {...props} />
        </LocalizationProvider>
    );
});

const LocalizationProvider: FC<PropsWithChildren> = props => {
    const userLanguage = getUserLanguage();
    return <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(userLanguage)} {...props} />;
};
