import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { AsyncSelectFilter, FiltersBar, FilterType, SelectFilterOption } from '@/components/filters-bar/FiltersBar';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getDepartments } from '@/domain/department/Department.service';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { getLocations } from '@/domain/location/Location.service';
import { EmployeePayrollProfileChange, PayrollSection } from '@/domain/payroll/Payroll.model';
import { useGetPayrollProfileChanges } from '@/hooks/payroll/Payroll.hook';
import { PayrollProfileChangesDialog } from '@/page/payroll/profile-changes-dialog/PayrollProfileChangesDialog';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Stack, TextField } from '@mui/material';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';

export const PayrollProfileChangesPage: FC = () => {
    const { t } = useTranslation();

    const [currentRow, setCurrentRow] = useState<EmployeePayrollProfileChange>();

    const agGridWrapper = useAgGridWrapper<EmployeePayrollProfileChange>();

    const availableFilters = getAvailableFilters();
    const [filters, setFilters] = useFiltersStorage('payroll-changes-filters', availableFilters);

    const getFilter = (key: string) => filters.find(filter => filter.key === key);

    const getSelectFilterValue = (key: string) => (getFilter(key) as AsyncSelectFilter)?.value?.flatMap((option: SelectFilterOption) => Number(option.value));

    const { dateRange, dateRangeViewType, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'payroll-changes-date-range',
    });

    const search = {
        startDate: dateRange[0],
        endDate: dateRange[1],
        departmentIds: getSelectFilterValue('departmentIds') ?? [],
        employeeIds: getSelectFilterValue('employeeIds') ?? [],
        locationIds: getSelectFilterValue('locationIds') ?? [],
    };
    const { data: changes = [], isLoading, isError, error } = useGetPayrollProfileChanges(search);

    const columnDefs = getColumnDefs();

    return (
        <Stack flex={1} gap={2}>
            <Stack component={Paper} direction='row' gap={1} alignItems='center' justifyContent='space-between' p={1}>
                <Stack direction='row' alignItems='flex-start' gap={1} flexWrap={'wrap'}>
                    <DateRangePicker
                        dates={dateRange}
                        onDatesChanged={onDateRangeChange}
                        defaultViewType={dateRangeViewType}
                        availableViews={['MONTH', 'RANGE']}
                    />
                    <FiltersBar
                        filters={filters}
                        onFiltersChange={filters => {
                            setFilters(filters);
                        }}
                        readOnly={true}
                        flex={1}
                    />
                </Stack>

                <TextField onChange={agGridWrapper?.quickFilter} placeholder={t('general.search')} />
            </Stack>
            <StateHandler isLoading={isLoading} isError={isError} isEmpty={!changes?.length} error={error}>
                <Stack flex={1}>
                    <AgGridWrapper
                        initRef={agGridWrapper?.setGridRef}
                        gridId={'payroll-profile-changes'}
                        columnDefs={columnDefs}
                        rowData={changes}
                        compact
                        onRowClicked={({ data }) => setCurrentRow(data)}
                        getRowId={({ data }) => data.employee.id.toString()}
                    />
                </Stack>
                {currentRow && (
                    <PayrollProfileChangesDialog
                        open={true}
                        employee={currentRow.employee}
                        sections={currentRow.sections}
                        onClose={() => setCurrentRow(undefined)}
                    />
                )}
            </StateHandler>
        </Stack>
    );
};

const getColumnDefs = (): RogerColDef<EmployeePayrollProfileChange>[] => {
    return [
        {
            field: 'employee',
            type: 'employee',
            sort: 'asc',
        },
        {
            field: 'sections',
            headerName: i18next.t('payroll_profile_changes_page.table_headers.sections'),
            valueFormatter: ({ value }: { value: PayrollSection[] }) => value.map(section => getLabelTranslation(section.sectionDefinition.name)).join(', '),
        },
    ];
};

const getAvailableFilters = (): FilterType[] => {
    return [
        {
            key: 'departmentIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'locationIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'employeeIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employees = await searchEmployees();
                return employees?.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ];
};
