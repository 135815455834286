import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import {
    PayrollFieldChangeValue,
    PayrollSection,
    PayrollSectionDefinition,
    PayrollSectionFieldDefinition,
    PayrollSectionRow,
    PayrollSectionValues,
} from '@/domain/payroll/Payroll.model';
import { FieldDefinition, SectionFieldValue, SectionType } from '@/domain/section-setting/Section.model';
import { isSameFieldDefinition } from '@/domain/section-setting/Section.service';
import { isAfterDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ColDef } from '@ag-grid-community/core';
import { DialogContent, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PayrollProfileChangesDialogProps = Omit<DialogWrapperProps, 'header'> & {
    employee: EmployeeAvatarDTO;
    sections: PayrollSection[];
    onClose: () => void;
};

export const PayrollProfileChangesDialog: FC<PayrollProfileChangesDialogProps> = ({ employee, sections, ...rest }) => {
    return (
        <DialogWrapper header={employee.displayName} {...rest} fullScreen>
            <DialogContent>
                <Stack gap={2} flex={1}>
                    {sections.map(section => (
                        <Section key={section.sectionDefinition.id} section={section} />
                    ))}
                </Stack>
            </DialogContent>
        </DialogWrapper>
    );
};
type SectionProps = {
    section: PayrollSection;
};

const Section: FC<SectionProps> = ({ section }) => {
    const { sectionDefinition, values } = section;

    if (!values?.length) {
        return;
    }

    const isTable = [SectionType.CUSTOM_MULTI_ROW, SectionType.ADDRESS, SectionType.EMPLOYMENT, SectionType.WORK_PATTERN].includes(sectionDefinition.type);

    return (
        <Stack pb={2} gap={1.5}>
            <SectionHeader section={section} />

            {isTable ? <TableSection section={section} /> : <SimpleSection section={section} />}
        </Stack>
    );
};

const SectionHeader = ({ section }: SectionProps) => {
    const { sectionDefinition, values } = section;
    const {
        currentValue: { updatedAt, updatedBy },
    } = getLastUpdatedRow(values);
    const { t } = useTranslation();
    return (
        <Stack>
            <Typography variant='h2'>{getLabelTranslation(sectionDefinition.name)}</Typography>
            <Stack direction='row' alignItems='center' gap={0.5}>
                <Typography variant='body1'>{t('payroll_profile_changes_dialog.updated_by')}</Typography>
                {updatedBy ? (
                    <EmployeeAvatarWithDetails
                        employee={updatedBy}
                        avatarProps={{
                            size: 'small',
                        }}
                        gap={0.5}
                    />
                ) : (
                    <Typography variant='body1'>{t('payroll_profile_changes_dialog.updated_by_public_api')}</Typography>
                )}
                <Typography variant='body1'>{t('payroll_profile_changes_dialog.updated_at', { date: updatedAt })}</Typography>
            </Stack>
        </Stack>
    );
};

const getLastUpdatedRow = (rows: PayrollSection['values']): PayrollSection['values'][0] => {
    return rows.reduce((lastUpdatedRow, currentRow) => {
        return isAfterDate(currentRow.currentValue.updatedAt, lastUpdatedRow.currentValue.updatedAt) ? currentRow : lastUpdatedRow;
    }, rows[0]);
};

const SimpleSection = ({ section }: SectionProps) => {
    const { sectionDefinition, values } = section;
    if (!values?.length) {
        return;
    }
    const { previousValue, currentValue } = values[0];

    const rowData = convertToSimpleSectionRow(sectionDefinition, previousValue, currentValue);

    const tableHeight = getTableHeight({ rowsLength: rowData.length, disableFooter: true });

    const columnDefs = getSimpleSectionColumnDefs();
    return (
        <Stack width='100%' minHeight={100} maxHeight={300} height={tableHeight}>
            <AgGridWrapper gridId={sectionDefinition.id.toString()} columnDefs={columnDefs} rowData={rowData} compact statusBar={undefined} disableAutoSize />
        </Stack>
    );
};

const convertToSimpleSectionRow = (
    sectionDefinition: PayrollSectionDefinition,
    previousValue: PayrollSectionRow | undefined,
    currentValue: PayrollSectionRow,
) =>
    sectionDefinition.fields.map(fieldDefinition => {
        const previousField = previousValue?.fields.find(({ sectionFieldDefinition }) => isSameFieldDefinition(sectionFieldDefinition, fieldDefinition));
        const currentField = currentValue.fields.find(({ sectionFieldDefinition }) => isSameFieldDefinition(sectionFieldDefinition, fieldDefinition));

        return {
            fieldDefinition,
            previousField,
            currentField,
        };
    });

const TableSection = ({ section }: SectionProps) => {
    const { sectionDefinition, values } = section;

    const columnDefs = getTableSectionColumnDefs(sectionDefinition.fields);

    const rowData: Record<string, SectionFieldValue>[] = convertToTableSectionRows(values);
    const tableHeight = getTableHeight({ rowsLength: rowData.length, disableFooter: true });
    return (
        <Stack width='100%' minHeight={100} maxHeight={300} height={tableHeight}>
            <AgGridWrapper gridId={sectionDefinition.id.toString()} columnDefs={columnDefs} rowData={rowData} compact statusBar={undefined} disableAutoSize />
        </Stack>
    );
};

const convertToTableSectionRows = (rows: PayrollSectionValues): Record<string, SectionFieldValue>[] => {
    return rows.map(({ currentValue }) => {
        return currentValue.fields.reduce(
            (row, field) => {
                row[getFieldDefinitionTranslation(field.sectionFieldDefinition)] = field;
                return row;
            },
            {} as Record<string, SectionFieldValue>,
        );
    });
};

const getSimpleSectionColumnDefs = (): ColDef<{
    fieldDefinition: FieldDefinition;
    previousField?: PayrollFieldChangeValue;
    currentField?: PayrollFieldChangeValue;
}>[] => {
    return [
        {
            field: 'fieldDefinition',
            headerName: i18next.t('payroll_profile_changes_dialog.field_name'),
            type: 'fieldDefinition',
            flex: 1,
        },
        {
            field: 'previousField',
            headerName: i18next.t('payroll_profile_changes_dialog.old_value'),
            type: 'fieldValue',
            flex: 1.5,
        },
        {
            field: 'currentField',
            headerName: i18next.t('payroll_profile_changes_dialog.new_value'),
            type: 'fieldValue',
            flex: 1.5,
        },
    ];
};

const getTableSectionColumnDefs = (sectionFieldDefinition: PayrollSectionFieldDefinition[]): ColDef<Record<string, SectionFieldValue>>[] =>
    sectionFieldDefinition.map(fieldDefinition => ({
        field: getFieldDefinitionTranslation(fieldDefinition),
        headerName: getFieldDefinitionTranslation(fieldDefinition),
        type: 'fieldValue',
    }));
