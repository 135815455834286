import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { Role } from '@/domain/role/Role.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    open: boolean;
    handleSave: (data: RoleFormValues, id: number | undefined) => void;
    closeDialog: () => void;
    activeRole: Role | undefined;
};

export type RoleFormValues = {
    name: string;
};

const schema = yup.object().shape({
    name: yup.string().trim().required(),
});
export const RoleSettingsDialog: FC<Props> = ({ open, handleSave, closeDialog, activeRole }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<RoleFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: activeRole?.name ?? '',
        },
    });

    const onSave = (data: RoleFormValues) => {
        handleSave(data, activeRole?.id);
    };
    const onClose = () => {
        closeDialog();
    };
    return (
        <DialogContainer
            open={open}
            onClose={onClose}
            onSave={handleSubmit(onSave, console.error)}
            title={activeRole ? t('role_setting_page.creation_dialog_title') : t('role_setting_page.edition_dialog_title')}
        >
            <Stack>
                <Controller
                    name='name'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            label={t('role_setting_page.role_name_input_label')}
                            labelPlacement='top'
                            control={<TextField fullWidth {...field} error={!!error} helperText={error?.message} />}
                        />
                    )}
                />
            </Stack>
        </DialogContainer>
    );
};
