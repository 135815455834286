import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { InputNumber } from '@/components/form/field-number/InputNumber';
import { TimesheetPaymentCreateMutation, TimesheetPaymentUpdateMutation } from '@/domain/timesheet-payment/TimesheetPayment.model';
import { createTimesheetPayment, updateTimesheetPayment } from '@/domain/timesheet-payment/TimesheetPayment.service';
import { Timesheet } from '@/domain/timesheet/Timesheet.model';
import { getPaymentFormSchema, PaymentFormSchema } from '@/page/employee-timesheet/payment-dialog/AddPaymentForm.schema';
import { handleError } from '@/utils/api.util';
import { formatToLocalDate, getHoursMinutesFromMinutes, getMinutesFromHoursMinutes, getTodayDate, HoursMinutes } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, capitalize, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AddPaymentDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    paymentData?: Timesheet;
    employeeId: number;
};

export const AddPaymentDialog: FC<AddPaymentDialogProps> = ({ open, onClose, onSave, paymentData, employeeId }) => {
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    const { control, watch, handleSubmit } = useForm<PaymentFormSchema>({
        resolver: yupResolver(getPaymentFormSchema()),
        defaultValues: {
            hoursMinutes: getHoursMinutesFromTotalMinutes(paymentData),
            requestDate: formatToLocalDate(paymentData?.startAt ?? getTodayDate()),
            comment: paymentData?.comment ?? '',
        },
    });

    const hoursMinutes = watch('hoursMinutes');

    const handleSave = async (data: PaymentFormSchema) => {
        if (paymentData?.paymentId) {
            const mutation: TimesheetPaymentUpdateMutation = {
                requestDate: data.requestDate,
                comment: data.comment,
                amountInMinutes: getMinutesFromHoursMinutes(data.hoursMinutes),
            };
            await handlePaymentEdit(mutation, paymentData.paymentId);
        } else {
            const mutation: TimesheetPaymentCreateMutation = {
                requestDate: data.requestDate,
                comment: data.comment,
                amountInMinutes: getMinutesFromHoursMinutes(data.hoursMinutes),
                employeeId: employeeId,
            };
            await handlePaymentCreate(mutation);
        }
    };

    const handlePaymentCreate = async (mutation: TimesheetPaymentCreateMutation) => {
        try {
            await createTimesheetPayment(mutation);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const handlePaymentEdit = async (mutation: TimesheetPaymentUpdateMutation, paymentId: number) => {
        try {
            await updateTimesheetPayment(mutation, paymentId);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <DialogWrapper open={open} onClose={handleClose} header={t('timesheets.add_adjustment')}>
            <Stack component={DialogContent} gap={2}>
                <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
                    <Controller
                        name={'hoursMinutes'}
                        control={control}
                        render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                            <>
                                <Stack flexGrow={1} width={'100%'}>
                                    <FormControlLabel
                                        label={capitalize(t('domain.unit_type.hours'))}
                                        labelPlacement='top'
                                        sx={{ flex: 1 }}
                                        control={
                                            <InputNumber
                                                {...restField}
                                                value={value.hours}
                                                onChange={hours => {
                                                    handleHoursChange(hours ?? 0, hoursMinutes, onChange);
                                                }}
                                                precision={0}
                                                fullWidth
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        }
                                    />
                                </Stack>
                                <Stack flexGrow={1} width={'100%'}>
                                    <FormControlLabel
                                        label={capitalize(t('domain.unit_type.minutes'))}
                                        labelPlacement='top'
                                        sx={{ flex: 1 }}
                                        control={
                                            <InputNumber
                                                {...restField}
                                                value={value.minutes}
                                                onChange={minutes => {
                                                    handleMinutesChange(minutes ?? 0, hoursMinutes, onChange);
                                                }}
                                                precision={0}
                                                fullWidth
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        }
                                    />
                                </Stack>
                            </>
                        )}
                    />
                </Stack>
                <FormControlLabel label={t('add_overtime_payment_dialog.effective_date')} control={<FieldLocalDate control={control} name={'requestDate'} />} />

                <Controller
                    name={'comment'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            label={t('add_overtime_payment_dialog.comment')}
                            control={
                                <TextField
                                    {...field}
                                    fullWidth
                                    InputProps={{ multiline: true, minRows: 2 }}
                                    placeholder={t('request_overtime_dialog.comment_placeholder')}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            }
                        />
                    )}
                />
            </Stack>
            <DialogActions>
                <Button onClick={() => handleSubmit(handleSave, console.error)()} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getHoursMinutesFromTotalMinutes = (paymentTimesheetData?: Timesheet) => {
    return paymentTimesheetData?.paymentCount
        ? getHoursMinutesFromMinutes(Math.abs(paymentTimesheetData?.paymentCount))
        : {
              hours: 0,
              minutes: 0,
          };
};

const handleHoursChange = (hours: number, hoursMinutes: HoursMinutes, onHoursMinutesChange: (data: HoursMinutes) => void) => {
    const newHoursMinutes = {
        ...hoursMinutes,
        hours,
    };
    onHoursMinutesChange(newHoursMinutes);
};

const handleMinutesChange = (minutes: number, hoursMinutes: HoursMinutes, onHoursMinutesChange: (data: HoursMinutes) => void) => {
    // extract the supplement hours if user set minutes greater than 60
    const suppHours = Math.trunc(minutes / 60);
    const restMinutes = minutes % 60;
    const newHoursMinutes = {
        hours: hoursMinutes.hours + suppHours,
        minutes: restMinutes,
    };
    onHoursMinutesChange(newHoursMinutes);
};
