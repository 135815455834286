import { ColorDot } from '@/components/color-dot/ColorDot';
import { EmployeeSectionDocumentLink } from '@/components/section/EmployeeSectionDocumentLink/EmployeeSectionDocumentLink';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { SectionField } from '@/components/section/types';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { DocumentContentDisposition } from '@/domain/document/Document.model';
import { getEmployeeSectionFieldDocumentUrl } from '@/domain/employee-section/EmployeeSection.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { formatInDefaultDate } from '@/utils/datetime.util';

import { getLabelTranslation } from '@/utils/language.util';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

export type SectionFieldComponentProps = {
    field: SectionField;
};

export const SectionFieldComponent: FC<SectionFieldComponentProps> = ({ field }) => {
    switch (field.type) {
        case SectionFieldType.EMPLOYEE_REFERENCE:
            return <StackedAvatars employeeAvatars={field[getFieldValueProperty(field.type)] ?? []} />;
        case SectionFieldType.CUSTOM_LIST_ITEM: {
            const label = field[getFieldValueProperty(field.type)]?.[0]?.label;
            return <TextField value={getLabelTranslation(label)} />;
        }
        case SectionFieldType.CUSTOM_MULTI_LIST_ITEM:
            return <TextField value={field[getFieldValueProperty(field.type)]?.map(item => getLabelTranslation(item.label)).join(', ')} />;

        case SectionFieldType.DATE:
            return <DateField value={field[getFieldValueProperty(field.type)]} />;
        case SectionFieldType.BIRTHDAY:
            return <BirthdayField age={field.stringValue} birthdayDate={field[getFieldValueProperty(field.type)]} />;
        case SectionFieldType.NUMBER:
            return <TextField value={field[getFieldValueProperty(field.type)]?.toLocaleString()} />;
        case SectionFieldType.STRING:
        case SectionFieldType.PHONE_NUMBER:
        case SectionFieldType.ENUM_LIST_ITEM:
        case SectionFieldType.IBAN_NUMBER:
        case SectionFieldType.AVS_NUMBER:
            return <TextField value={field[getFieldValueProperty(field.type)]} />;
        case SectionFieldType.DOT_STRING:
            return <DotTextField value={field[getFieldValueProperty(field.type)]} dotColor={field.dotColor} />;
        case SectionFieldType.COUNTRY:
            return <TextField value={field[getFieldValueProperty(field.type)]?.label ?? ''} />;
        case SectionFieldType.SECTION_FIELD_DOCUMENT:
            return <DocumentField field={field} />;
        default:
            return undefined;
    }
};

type DateFieldProps = {
    value: Nullable<LocalDate>;
};

const DateField: FC<DateFieldProps> = ({ value }) => {
    return <Typography>{value ? formatInDefaultDate(value) : ''}</Typography>;
};

type TextFieldProps = {
    value: string | undefined;
};

const TextField: FC<TextFieldProps> = ({ value }) => {
    return <Typography>{value ?? ''}</Typography>;
};

type DotTextFieldProps = {
    value: string | undefined;
    dotColor: string | undefined;
};
const DotTextField: FC<DotTextFieldProps> = ({ value, dotColor }) => {
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <ColorDot color={dotColor} />
            <TextField value={value} />
        </Stack>
    );
};

type BirthdayFieldProps = {
    birthdayDate: Nullable<LocalDate>;
    age: string | undefined;
};
const BirthdayField: FC<BirthdayFieldProps> = ({ birthdayDate, age }) => {
    return (
        <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
            <DateField value={birthdayDate} />
            <TextField value={age} />
        </Stack>
    );
};

export interface DocumentFieldProps {
    field: SectionField;
}

export const DocumentField: FC<DocumentFieldProps> = ({ field }) => {
    return (
        <Stack gap={1} direction={'row'} flexWrap={'wrap'}>
            {field.documents?.map(document => {
                return (
                    <EmployeeSectionDocumentLink
                        document={document}
                        onDownload={(contentDisposition: DocumentContentDisposition) => getEmployeeSectionFieldDocumentUrl(document.id, contentDisposition)}
                        key={document.id}
                    />
                );
            })}
        </Stack>
    );
};
