import { Autocomplete, FormControlLabel, TextField } from '@mui/material';
import { formatInDefaultDate, isValidDate } from '@/utils/datetime.util';

import { CheckboxAutocompleteOption } from '@/components/autocomplete-wrapper/CheckboxAutocompleteOption';
import { Controller, useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { EmployeeTimesheetFormValues } from '@/page/timesheet/timesheet-dialog/TimesheetDialog.schema';
import { TimesheetAction } from '@/domain/timesheet/Timesheet.model';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { defaultToNull, getNull } from '@/utils/object.util';
import { useTranslation } from 'react-i18next';

type MissingDatesFieldProps = {
    missingEntriesDates: LocalDate[];
};
export const MissingDatesField: FC<MissingDatesFieldProps> = ({ missingEntriesDates }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();

    return (
        <Controller
            name={`missingDates`}
            control={control}
            render={({ field: { value, onChange, ...restField } }) => (
                <Autocomplete
                    onChange={(_, value) => {
                        onChange(value);
                    }}
                    value={value}
                    multiple
                    options={missingEntriesDates}
                    fullWidth
                    renderInput={params => <TextField {...params} />}
                    getOptionLabel={option => formatInDefaultDate(option)}
                    renderOption={(props, option, { selected }) => (
                        <CheckboxAutocompleteOption {...props} selected={selected} label={formatInDefaultDate(option)} />
                    )}
                    {...restField}
                />
            )}
        />
    );
};

type ReferenceDateFieldProps = {
    mode: TimesheetAction;
    onChange: (date: LocalDate) => void;
};
export const ReferenceDateField: FC<ReferenceDateFieldProps> = ({ mode, onChange }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();

    return (
        <FieldLocalDate
            name='referenceDate'
            control={control}
            disabled={mode === TimesheetAction.EDIT || mode === TimesheetAction.SHIFT_TIMESHEET}
            onChange={date => {
                if (!isValidDate(date)) {
                    return;
                }
                onChange(date);
            }}
            mobileOnly
        />
    );
};

type AreaFieldProps = {
    timesheetIndex: number;
    areas: { id: number; name: string }[];
};
export const AreaField: FC<AreaFieldProps> = ({ timesheetIndex, areas }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();
    const { t } = useTranslation();

    return (
        <Controller
            name={`timesheets.${timesheetIndex}.area`}
            control={control}
            render={({ field: { onChange, value, ...restField } }) => (
                <FormControlLabel
                    label={''}
                    labelPlacement='top'
                    sx={{ width: '100%' }}
                    control={
                        <Autocomplete
                            {...restField}
                            id='area-select'
                            value={value ?? getNull()}
                            options={areas.map(area => ({
                                id: area.id,
                                name: area.name,
                            }))}
                            disabled={false}
                            disableClearable={false}
                            disableListWrap={true}
                            fullWidth
                            getOptionLabel={option => option.name}
                            onChange={(_, newValue) => {
                                onChange(defaultToNull(newValue));
                            }}
                            renderInput={params => <TextField {...params} placeholder={t('planning.area')} />}
                            isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue?.id}
                        />
                    }
                />
            )}
        />
    );
};

type CommentFieldProps = {
    timesheetIndex: number;
};
export const CommentField: FC<CommentFieldProps> = ({ timesheetIndex }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();
    const { t } = useTranslation();

    return (
        <Controller
            name={`timesheets.${timesheetIndex}.comment`}
            control={control}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error ? t('timesheets.mandatory_comment') : ''}
                    InputProps={{ multiline: true, minRows: 1 }}
                    placeholder={t('general.comment')}
                />
            )}
        />
    );
};
