import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionField } from '@/components/section/types';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { TerminationReason, TerminationType } from '@/domain/employment/Employment.model';
import { getTerminationTypeTranslationKey } from '@/domain/employment/Employment.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';

export const TerminationStepForm: FC = () => {
    const { t } = useTranslation();

    const mapTerminationType = (): Map<string, string> => {
        const terminationTypeMap = new Map<string, string>();
        Object.values(TerminationType).forEach(terminationType => {
            terminationTypeMap.set(terminationType, t(getTerminationTypeTranslationKey(terminationType)));
        });
        return terminationTypeMap;
    };

    const mapTerminationReason = (): Map<string, string> => {
        const terminationReasonMap = new Map<string, string>();
        Object.values(TerminationReason).forEach(terminationReason => {
            terminationReasonMap.set(terminationReason, t('employee.employment.termination_reason.enum', { context: terminationReason }));
        });
        return terminationReasonMap;
    };

    const sectionFields: (Omit<SectionField, 'formValueName' | 'title'> & {
        formValueName: string;
        title: string;
    })[] = [
        {
            formValueName: 'terminationNoticeDate',
            title: t('employee.employment.termination.notice_date'),
            type: SectionFieldType.DATE,
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE',
        },
        {
            formValueName: 'terminationLastDayAtWork',
            title: t('employee.employment.termination.last_day_at_work'),
            type: SectionFieldType.DATE,
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY',
        },
        {
            formValueName: 'endDate',
            title: t('employee.employment.termination.termination_date'),
            type: SectionFieldType.DATE,
            required: true,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY',
        },
        {
            formValueName: 'terminationType',
            enumList: mapTerminationType(),
            title: t('employee.employment.termination_type.termination_type'),
            type: SectionFieldType.ENUM_LIST_ITEM,
            required: true,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_TYPE',
        },
        {
            formValueName: 'terminationReason',
            enumList: mapTerminationReason(),
            title: t('employee.employment.termination_reason.termination_reason'),
            type: SectionFieldType.ENUM_LIST_ITEM,
            required: true,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_REASON',
        },
        {
            formValueName: 'terminationComment',
            title: t('general.comment'),
            type: SectionFieldType.STRING,
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_COMMENT',
        },
    ];

    return (
        <SectionContainer title={t('employee.employment.termination.dates_reason')}>
            {!!sectionFields?.length &&
                sectionFields.map(sectionField => {
                    return (
                        <SectionFieldContainer
                            key={sectionField.title}
                            formValueName={sectionField.formValueName}
                            title={sectionField.title}
                            required={sectionField.required}
                        >
                            {<EditableSectionFieldComponent field={sectionField} />}
                        </SectionFieldContainer>
                    );
                })}
        </SectionContainer>
    );
};
