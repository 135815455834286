import { ColorDot } from '@/components/color-dot/ColorDot';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { isEmployeeReviewInputNeeded } from '@/domain/employee-review/EmployeeReview.service';
import { ContributorType } from '@/domain/review/Review.model';
import { isReviewClosed } from '@/domain/review/Review.service';
import { EmployeeReviewFeedbackForm } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackForm';
import { useAppSelector } from '@/stores/store';
import { Stack, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetEmployeeReview } from '@/hooks/employee-review/EmployeeReview.hook';
import { useGetEmployeeRoles } from '@/hooks/employee-role/EmployeeRole.hook';
import { useGetEmployeeReviewFeedback } from '@/hooks/employee-review-feedback/EmployeeReviewFeedback.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';

type EmployeeReviewFeedbackFormPageProps = {
    isPreview: boolean;
};

type LoadingButtonProps = FooterActionsProps['actions'][number];

export const EmployeeReviewFeedbackFormPage: FC<EmployeeReviewFeedbackFormPageProps> = ({ isPreview }) => {
    const employeeReviewIdString = useParams()?.employeeReviewId;
    const employeeReviewId = employeeReviewIdString ? Number(employeeReviewIdString) : undefined;
    const { data: employeeReview, error: employeeReviewError, isLoading: employeeReviewLoading } = useGetEmployeeReview(employeeReviewId);
    const [searchParams] = useSearchParams();
    const hasPreviewRole = searchParams.get('role');
    const previewRole = hasPreviewRole as ContributorType;
    const employeeId = employeeReview?.employee?.id;
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isCorrectionFeedbackMode, setIsCorrectionFeedbackMode] = useState(false);

    const {
        data: employeeRoles,
        error: employeeRolesError,
        isLoading: employeeRolesLoading,
    } = useGetEmployeeRoles(employeeId && previewRole ? { employeeIds: [employeeId] } : undefined);

    const {
        data: employeeReviewFeedback,
        error: employeeReviewFeedbackError,
        isLoading: employeeReviewFeedbackLoading,
    } = useGetEmployeeReviewFeedback(hasPreviewRole ? undefined : employeeReviewId);

    const {
        data: objectives,
        error: objectivesError,
        isLoading: objectivesLoading,
        refetch: refetchObjectives,
    } = useGetObjectives(employeeId ? { employeeIds: [employeeId] } : undefined);

    const isLoading = employeeReviewLoading || employeeRolesLoading || employeeReviewFeedbackLoading || objectivesLoading;
    const isError = !!employeeReviewError || !!employeeRolesError || !!employeeReviewFeedbackError || !employeeId || !!objectivesError;
    const isReviewAlreadySubmitted = employeeReviewFeedback?.submitted || false;
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const isFormDisabled = (isReviewAlreadySubmitted && !isCorrectionFeedbackMode) || (employeeReview?.review && isReviewClosed(employeeReview?.review));
    const canCorrectHisFeedback = employeeReviewFeedback?.submitted && employeeReview && isEmployeeReviewInputNeeded(employeeReview.status);

    const closeButton: LoadingButtonProps = {
        name: 'close',
        onClick: () => {
            navigate(-1);
        },
        children: t('reviews.write_feedback.close'),
        variant: 'contained',
    };

    const continueLaterButton: LoadingButtonProps = {
        name: 'continue-later',
        onClick: () => {
            navigate('/reviews/tasks');
        },
        children: t('reviews.write_feedback.continue_later'),
        variant: 'outlined',
    };

    const isPrimaryDisabled = () => {
        return (
            isPreview ||
            (employeeReview && !isEmployeeReviewInputNeeded(employeeReview.status)) ||
            (employeeReview?.review && isReviewClosed(employeeReview?.review))
        );
    };

    const submitButton: LoadingButtonProps = {
        name: 'submit',
        type: 'submit',
        form: 'employee-review-feedback-form',
        disabled: isPrimaryDisabled(),
        children: t('reviews.write_feedback.submit'),
        variant: 'contained',
    };

    const correctFeedbackButton: LoadingButtonProps = {
        name: 'correct',
        onClick: () => {
            setIsCorrectionFeedbackMode(true);
        },
        children: t('reviews.write_feedback.correct_feedback'),
        variant: 'text',
    };

    const getFooterActions = (): LoadingButtonProps[] => {
        if (isPreview) {
            return [closeButton];
        }

        if (isCorrectionFeedbackMode) {
            return [submitButton];
        }

        if (canCorrectHisFeedback) {
            return [correctFeedbackButton, closeButton];
        }
        if (isReviewAlreadySubmitted) {
            return [closeButton];
        }
        return [continueLaterButton, submitButton];
    };

    return (
        <StateHandler
            isLoading={isLoading}
            isError={isError}
            error={objectivesError || employeeRolesError || employeeReviewError || employeeReviewFeedbackError}
        >
            {employeeReview && employeeId && currentEmployee && (
                <EmployeeReviewFeedbackForm
                    objectives={objectives ?? []}
                    employeeReview={employeeReview}
                    employeeRoles={employeeRoles ?? []}
                    employeeId={employeeId}
                    isPreview={isPreview}
                    employeeReviewFeedback={employeeReviewFeedback}
                    previewRole={previewRole}
                    refetchObjectives={refetchObjectives}
                    isFormDisabled={!!isFormDisabled}
                    currentEmployee={currentEmployee}
                />
            )}
            <Footer>
                {!isReviewAlreadySubmitted && !isPreview && (
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <ColorDot color={theme.palette.success.main} />
                        <Typography variant='body2'>{t('reviews.write_feedback.automatically_saved')}</Typography>
                    </Stack>
                )}
                <FooterActions actions={getFooterActions()} />
            </Footer>
        </StateHandler>
    );
};
