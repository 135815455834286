import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { showSnackbar } from '@/utils/snackbar.util';
import { Autocomplete, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDialogHeader } from '../employee-dialog-header/EmployeeDialogHeader';

import { EmployeeReview, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { Employee } from '@/domain/employee/Employee.model';
import { isPeerReviewAsked, isUpwardReviewAsked } from '@/domain/review/Review.service';

type EmployeeReviewUpdateReviewersDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => void;
    activeEmployeeReview: EmployeeReview;
};

export const EmployeeReviewUpdateReviewersDialog: FC<EmployeeReviewUpdateReviewersDialogProps> = ({ open, onClose, activeEmployeeReview, onSave }) => {
    const { t } = useTranslation();
    const [selectedManagers, setSelectedManagers] = useState<Employee[]>(activeEmployeeReview?.managers.map(manager => manager.reviewer));
    const [selectedPeers, setSelectedPeers] = useState<Employee[]>(activeEmployeeReview?.peerReviewers.map(peer => peer.reviewer));
    const [selectedUpwards, setSelectedUpwards] = useState<Employee[]>(activeEmployeeReview?.upwardReviewers.map(upward => upward.reviewer));
    const activeReview = activeEmployeeReview?.review;
    const { data: employees = [], isLoading: isLoadingEmployees, isError: isErrorEmployees, error: employeesError } = useGetEmployees();
    const userOptions = employees.filter(employee => employee.id !== activeEmployeeReview.employee.id);

    useEffect(() => {
        if (activeEmployeeReview) {
            setSelectedManagers(activeEmployeeReview?.managers.map(manager => manager.reviewer));
            setSelectedPeers(activeEmployeeReview?.peerReviewers.map(peer => peer.reviewer));
            setSelectedUpwards(activeEmployeeReview?.upwardReviewers.map(upward => upward.reviewer));
        }
    }, [activeEmployeeReview]);

    const onSaveReviewers = () => {
        const request = {
            managersIds: selectedManagers.map(manager => manager.id as number),
            peerReviewerIds: selectedPeers.map(peer => peer.id as number),
            upwardReviewerIds: selectedUpwards.map(upward => upward.id as number),
        };

        if (request.managersIds.length === 0) {
            showSnackbar(t('reviews.manage_reviews.error.no_managers'), 'error');
            return;
        }
        onSave(request, activeEmployeeReview.id);
    };

    return (
        <DialogContainer
            open={open}
            onSave={onSaveReviewers}
            onClose={onClose}
            title={t('reviews.manage_reviews.update_review')}
            customHeader={<EmployeeDialogHeader employee={activeEmployeeReview?.employee} />}
        >
            <StateHandler isLoading={isLoadingEmployees} isError={isErrorEmployees} error={employeesError}>
                <Stack gap={2}>
                    <FormControlLabel
                        sx={{ width: '100%' }}
                        label={t('reviews.employee_review.manager')}
                        control={
                            <Autocomplete
                                multiple
                                fullWidth
                                disableCloseOnSelect
                                value={selectedManagers}
                                options={userOptions}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={option => option.displayName}
                                getOptionKey={option => option.id}
                                onChange={(_, selectedEmployees) => {
                                    setSelectedManagers(selectedEmployees);
                                }}
                                renderInput={params => <TextField {...params} />}
                            />
                        }
                    />
                    {activeReview && isPeerReviewAsked(activeReview) && (
                        <FormControlLabel
                            sx={{ width: '100%' }}
                            label={t('reviews.employee_review.peer_reviewers')}
                            control={
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    disableCloseOnSelect
                                    value={selectedPeers}
                                    options={userOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.displayName}
                                    getOptionKey={option => option.id}
                                    onChange={(_, selectedEmployees) => {
                                        setSelectedPeers(selectedEmployees);
                                    }}
                                    renderInput={params => <TextField {...params} />}
                                />
                            }
                        />
                    )}

                    {activeReview && isUpwardReviewAsked(activeReview) && (
                        <FormControlLabel
                            sx={{ width: '100%' }}
                            label={t('reviews.employee_review.upward_reviewers')}
                            control={
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    disableCloseOnSelect
                                    value={selectedUpwards}
                                    options={userOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.displayName}
                                    getOptionKey={option => option.id}
                                    onChange={(_, selectedEmployees) => {
                                        setSelectedUpwards(selectedEmployees);
                                    }}
                                    renderInput={params => <TextField {...params} />}
                                />
                            }
                        />
                    )}
                </Stack>
            </StateHandler>
        </DialogContainer>
    );
};
