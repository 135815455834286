import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import {
    ApproveTimesheetMutation,
    ClockInOutCreationMutation,
    DailyTimesheetReport,
    DayTimesheet,
    DeclineTimesheetMutation,
    EmployeeTimesheetMutation,
    MonthlyTimesheetReport,
    MonthTimesheet,
    PendingDayTimesheet,
    Timesheet,
    TimesheetClockInAreaUpdateMutation,
    TimesheetClockInRule,
    TimesheetEmployeeMonthSearch,
    TimesheetMutation,
    TimesheetPendingSearch,
    TimesheetSearch,
} from '@/domain/timesheet/Timesheet.model';
import { convertDateToUTCIsoString, convertUTCIsoStringToDate } from '@/utils/datetime.util';

import { AxiosResponse } from 'axios';
import { EmployeePayrollLockDTO, mapEmployeePayrollLockDTO } from '@/api/employee-payroll-lock/EmployeePayrollLock.api';

const mapDailyTimesheetReportDTO = (dto: DailyTimesheetReportDTO): DailyTimesheetReport => {
    const { employee, ...restDailyTimesheetReportDTO } = dto;
    return {
        ...restDailyTimesheetReportDTO,
        employee: mapEmployeeDTO(employee),
        dayTimesheets: dto.dayTimesheets?.map(dayTimesheet => ({
            ...dayTimesheet,
            timesheets: dayTimesheet.timesheets?.map(mapTimesheet) ?? [],
            plannedTimesheets: dayTimesheet.plannedTimesheets?.map(mapTimesheet) ?? [],
        })),
    };
};
const searchEmployeeTimesheets = async (request: TimesheetSearch): Promise<DailyTimesheetReport[]> => {
    const url = API_BASE_URL + `/timesheets/employee/search`;
    const { data } = await client.post<DailyTimesheetReportDTO[], AxiosResponse<DailyTimesheetReportDTO[]>, TimesheetSearchRequestDTO>(url, request);
    return data.map(mapDailyTimesheetReportDTO);
};

const searchEmployeeMonthTimesheets = async (request: TimesheetEmployeeMonthSearch): Promise<DailyTimesheetReport[]> => {
    const url = API_BASE_URL + `/timesheets/employee/month/search`;
    const { data } = await client.post<DailyTimesheetReportDTO[], AxiosResponse<DailyTimesheetReportDTO[]>, TimesheetEmployeeMonthSearchDTO>(url, request);
    return data.map(mapDailyTimesheetReportDTO);
};

const mapTimesheet = (dto: TimesheetDTO): Timesheet => {
    return {
        ...dto,
        employee: mapEmployeeDTO(dto.employee),
        lock: dto.lock ? mapEmployeePayrollLockDTO(dto.lock) : undefined,
        startAt: convertUTCIsoStringToDate(dto.startAt),
        endAt: convertUTCIsoStringToDate(dto.endAt),
        originalStartAt: convertUTCIsoStringToDate(dto.originalStartAt),
        originalEndAt: convertUTCIsoStringToDate(dto.originalEndAt),
        updatedAt: convertUTCIsoStringToDate(dto.updatedAt),
        statusUpdatedAt: convertUTCIsoStringToDate(dto.statusUpdatedAt),
    };
};

const searchTimesheets = async (request: TimesheetSearch): Promise<Timesheet[]> => {
    const url = API_BASE_URL + `/timesheets/search`;
    const { data } = await client.post<TimesheetDTO[], AxiosResponse<TimesheetDTO[]>, TimesheetSearchRequestDTO>(url, request);
    return data.map(mapTimesheet);
};

const searchPendingTimesheets = async (request: TimesheetPendingSearch): Promise<PendingDayTimesheet[]> => {
    const url = API_BASE_URL + `/timesheets/pending/search`;
    const { data } = await client.post<PendingDayTimesheetDTO[], AxiosResponse<PendingDayTimesheetDTO[]>, TimesheetPendingSearchRequestDTO>(url, request);
    return data.map(row => ({
        ...row,
        timesheets: row.timesheets.map(mapTimesheet),
        plannedTimesheets: row.plannedTimesheets.map(mapTimesheet),
        employee: mapEmployeeDTO(row.employee),
    }));
};

const updateTimesheet = async (employeeTimesheetMutation: EmployeeTimesheetMutation): Promise<Timesheet[]> => {
    const employeeTimesheetRequest = mapEmployeeTimesheetMutationToRequest(employeeTimesheetMutation);
    const url = API_BASE_URL + `/timesheets`;
    const { data } = await client.put<TimesheetDTO, AxiosResponse<TimesheetDTO[]>, EmployeeTimesheetRequest>(url, employeeTimesheetRequest);
    return data.map(mapTimesheet);
};

const updatePendingTimesheet = async (employeeTimesheetMutation: EmployeeTimesheetMutation): Promise<Timesheet[]> => {
    const employeeTimesheetRequest = mapEmployeeTimesheetMutationToRequest(employeeTimesheetMutation);
    const url = API_BASE_URL + `/timesheets/pending`;
    const { data } = await client.put<TimesheetDTO, AxiosResponse<TimesheetDTO[]>, EmployeeTimesheetRequest>(url, employeeTimesheetRequest);
    return data.map(mapTimesheet);
};

const createTimesheets = async (employeeTimesheetMutation: EmployeeTimesheetMutation): Promise<Timesheet[]> => {
    const employeeTimesheetRequest = mapEmployeeTimesheetMutationToRequest(employeeTimesheetMutation);
    const url = API_BASE_URL + `/timesheets`;
    const { data } = await client.post<TimesheetDTO, AxiosResponse<TimesheetDTO[]>, EmployeeTimesheetRequest>(url, employeeTimesheetRequest);
    return data.map(mapTimesheet);
};

const clockInOut = async (clockInOutCreateMutation: ClockInOutCreationMutation): Promise<Timesheet> => {
    const url = API_BASE_URL + `/timesheets/clock-in`;
    const { data } = await client.post<TimesheetDTO, AxiosResponse<TimesheetDTO>, ClockInOutCreationMutation>(url, clockInOutCreateMutation);
    return mapTimesheet(data);
};

const getLastTimesheetClockInOut = async (employeeId: number): Promise<Timesheet> => {
    const url = API_BASE_URL + `/timesheets/clock-in/${employeeId}`;
    const { data } = await client.get(url);
    return mapTimesheet(data);
};

const getAllowClockIn = async (employeeId: number): Promise<TimesheetClockInRule> => {
    const url = API_BASE_URL + `/timesheets/can-clock-in/${employeeId}`;
    const { data } = await client.get(url);
    return data;
};

const cancelPendingTimesheet = async (mutation: DeclineTimesheetMutation): Promise<Timesheet> => {
    const url = API_BASE_URL + `/timesheets/pending/cancel`;
    const { data } = await client.post<TimesheetDTO, AxiosResponse<TimesheetDTO>, DeclineTimesheetMutation>(url, mutation);
    return mapTimesheet(data);
};

const cancelTimesheet = async (mutation: DeclineTimesheetMutation): Promise<Timesheet> => {
    const url = API_BASE_URL + `/timesheets/cancel`;
    const { data } = await client.post<TimesheetDTO, AxiosResponse<TimesheetDTO>, DeclineTimesheetMutation>(url, mutation);
    return mapTimesheet(data);
};

const declineTimesheet = async (mutation: DeclineTimesheetMutation): Promise<Timesheet> => {
    const url = API_BASE_URL + `/timesheets/decline`;
    const { data } = await client.post<TimesheetDTO, AxiosResponse<TimesheetDTO>, DeclineTimesheetMutation>(url, mutation);
    return mapTimesheet(data);
};

const approveTimesheet = async (mutations: ApproveTimesheetMutation[]): Promise<Timesheet[]> => {
    const url = API_BASE_URL + `/timesheets/approve`;
    const { data } = await client.post<TimesheetDTO[], AxiosResponse<TimesheetDTO[]>, ApproveTimesheetMutation[]>(url, mutations);
    return data.map(mapTimesheet);
};

const deleteLastTimesheetClockInOut = async (employeeId: number): Promise<void> => {
    const url = API_BASE_URL + `/timesheets/clock-in/${employeeId}`;
    await client.delete(url);
};

const getClockOutForceBreakDuration = async (employeeId: number): Promise<number> => {
    const url = API_BASE_URL + `/timesheets/clock-out/force-break/${employeeId}`;
    const { data } = await client.get(url);
    return data;
};

const updateTimesheetArea = async (mutation: TimesheetClockInAreaUpdateMutation, timesheetId: number): Promise<Timesheet> => {
    const url = API_BASE_URL + `/timesheets/${timesheetId}/area`;
    const { data } = await client.patch<TimesheetDTO, AxiosResponse<TimesheetDTO>, TimesheetClockInAreaUpdateMutation>(url, mutation);
    return mapTimesheet(data);
};

const mapMonthlyTimesheetReportDTO = (dto: MonthlyTimesheetReportDTO): MonthlyTimesheetReport => {
    const { employee, ...rest } = dto;
    return {
        ...rest,
        employee: mapEmployeeDTO(employee),
        monthTimesheets: dto.monthTimesheets?.map(monthTimesheet => ({
            ...monthTimesheet,
            lastEmployeePayrollLock: monthTimesheet.lastEmployeePayrollLock ? mapEmployeePayrollLockDTO(monthTimesheet.lastEmployeePayrollLock) : undefined,
        })),
        exportMonthEndDate: convertUTCIsoStringToDate(dto.exportMonthEndDate),
    };
};

const searchEmployeeMonthlyTimesheets = async (request: TimesheetSearch): Promise<MonthlyTimesheetReport[]> => {
    const url = API_BASE_URL + `/timesheets/monthly/search`;
    const { data } = await client.post<MonthlyTimesheetReportDTO[], AxiosResponse<MonthlyTimesheetReportDTO[]>, TimesheetSearchRequestDTO>(url, request);
    return data.map(mapMonthlyTimesheetReportDTO);
};

const importTimesheets = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/timesheets/import`, request)).data;
};

export const timesheetAPI = {
    searchEmployeeTimesheets,
    searchEmployeeMonthTimesheets,
    searchTimesheets,
    searchPendingTimesheets,
    updateTimesheet,
    updatePendingTimesheet,
    createTimesheets,
    clockInOut,
    getLastTimesheetClockInOut,
    getAllowClockIn,
    cancelPendingTimesheet,
    cancelTimesheet,
    declineTimesheet,
    approveTimesheet,
    deleteLastTimesheetClockInOut,
    getClockOutForceBreakDuration,
    updateTimesheetArea,
    searchEmployeeMonthlyTimesheets,
    importTimesheets,
};

export type TimesheetDTO = Overwrite<
    DateToString<Timesheet>,
    {
        employee: EmployeeDTO;
        lock?: EmployeePayrollLockDTO;
    }
>;
export type PendingDayTimesheetDTO = Overwrite<
    PendingDayTimesheet,
    {
        employee: EmployeeDTO;
        timesheets: TimesheetDTO[];
        plannedTimesheets: TimesheetDTO[];
    }
>;

export type DailyTimesheetReportDTO = Overwrite<
    DailyTimesheetReport,
    {
        employee: EmployeeDTO;
        dayTimesheets: DayTimesheetDTO[];
    }
>;

export type DayTimesheetDTO = Overwrite<
    DayTimesheet,
    {
        timesheets?: TimesheetDTO[];
        plannedTimesheets?: TimesheetDTO[];
    }
>;
type MonthTimesheetDTO = Overwrite<MonthTimesheet, { lastEmployeePayrollLock?: EmployeePayrollLockDTO }>;
export type MonthlyTimesheetReportDTO = DateToString<Overwrite<MonthlyTimesheetReport, { employee: EmployeeDTO; monthTimesheets?: MonthTimesheetDTO[] }>>;
export type TimesheetSearchRequestDTO = TimesheetSearch;
export type TimesheetEmployeeMonthSearchDTO = TimesheetEmployeeMonthSearch;
export type TimesheetPendingSearchRequestDTO = TimesheetPendingSearch;

type EmployeeTimesheetRequest = {
    employeeId: number;
    referenceDate: LocalDate;
    timesheets: TimesheetRequest[];
};
type TimesheetRequest = DateToString<TimesheetMutation>;

const mapEmployeeTimesheetMutationToRequest = (employeeTimesheetMutation: EmployeeTimesheetMutation): EmployeeTimesheetRequest => {
    return {
        employeeId: employeeTimesheetMutation.employeeId,
        referenceDate: employeeTimesheetMutation.referenceDate,
        timesheets: employeeTimesheetMutation.timesheets.map(mapTimesheetMutationToRequest),
    };
};

const mapTimesheetMutationToRequest = (timesheetMutation: TimesheetMutation): TimesheetRequest => {
    return {
        ...timesheetMutation,
        startTime: convertDateToUTCIsoString(timesheetMutation.startTime),
        endTime: convertDateToUTCIsoString(timesheetMutation.endTime),
    };
};
