import { Button, ButtonProps, IconButton, IconButtonProps, Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';

import { cloneElement, FC, MouseEvent, MouseEventHandler, ReactElement, ReactNode, useState } from 'react';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';

export type BasicMenuItem = MenuItemProps & {
    title: string;
    disabled?: boolean;
    selected?: boolean;
    confirmationRequired?: boolean;
};

type BasicMenuProps = Omit<MenuProps, 'open'> & {
    items: BasicMenuItem[];
    endIcon?: ReactNode;
    dropdownTitle?: string;
    button?: ReactElement<ButtonProps | IconButtonProps>;
};

export const BasicMenu: FC<BasicMenuProps> = ({ items, endIcon, dropdownTitle, button, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const open = Boolean(anchorEl);
    const [confirmationActionState, setConfirmationActionState] = useState<
        | {
              onClick: MouseEventHandler<HTMLLIElement> | undefined;
              event: MouseEvent<HTMLLIElement>;
          }
        | undefined
    >(undefined);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(undefined);
    };

    const defaultButton = dropdownTitle ? <Button variant='text'>{dropdownTitle}</Button> : <IconButton aria-label='menu-icon-button'>{endIcon}</IconButton>;

    const renderedButton = cloneElement(button ?? defaultButton, {
        id: 'basic-button',
        'aria-controls': open ? 'basic-menu' : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': open ? 'true' : undefined,
        onClick: handleClick,
        // EndIcon is should be added only if dropdownTitle is present to avoid adding endIcon to IconButton
        ...(dropdownTitle ? { endIcon: endIcon } : {}),
    });

    const onDialogConfirm = (onClick: MouseEventHandler<HTMLLIElement> | undefined, event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
        onClick?.(event);
        setConfirmationActionState(undefined);
    };

    return (
        <div>
            {renderedButton}
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                {...rest}
            >
                {items?.map(({ title, onClick, children, confirmationRequired, ...rest }) => {
                    return (
                        <MenuItem
                            key={title}
                            onClick={event => {
                                event.stopPropagation();
                                event.preventDefault();
                                setAnchorEl(undefined);
                                if (confirmationRequired) {
                                    setConfirmationActionState({ onClick, event });
                                    return;
                                }
                                onClick?.(event);
                            }}
                            {...rest}
                        >
                            {children ?? title}
                        </MenuItem>
                    );
                })}
            </Menu>
            {!!confirmationActionState && (
                <ConfirmDialog
                    open={!!confirmationActionState}
                    onConfirm={() => {
                        if (!confirmationActionState) {
                            return;
                        }
                        onDialogConfirm(confirmationActionState.onClick, confirmationActionState.event);
                    }}
                    /*
                     * e.stopPropagation() prevents the click event from bubbling up,
                     * avoiding unwanted triggers on parent components, especially important for dialogs rendered in portals.
                     * https://github.com/facebook/react/issues/11387
                     */
                    onClick={e => {
                        e.stopPropagation();
                        setConfirmationActionState(undefined);
                    }}
                    onClose={() => setConfirmationActionState(undefined)}
                />
            )}
        </div>
    );
};
